import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CompanySelectors, IntegrationsSelectors, IntegrationsStateAction } from '@business/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { API_ROOT_URL, AuthSelectors } from '@nibol/auth';
import { openWindowPopup } from '@nibol/shared';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SlackMessageEventData } from './adv-slack.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bus-adv-slack',
  styleUrls: ['adv-slack.component.scss'],
  templateUrl: 'adv-slack.component.html',
})
export class AdvSlackComponent implements OnInit {
  @Dispatch() readSlackSettings = () => new IntegrationsStateAction.Slack.Read.Try();
  @Dispatch() failSlackConnection = () => new IntegrationsStateAction.Slack.Connect.Failure(null);

  @Select(CompanySelectors.hasInvalidSubscription) hasInvalidSubscription$!: Observable<boolean>;

  @SelectSnapshot(IntegrationsSelectors.slackIntegrationLink)
  slackIntegrationLink!: string;
  @SelectSnapshot(AuthSelectors.tenant) tenant!: string;

  isInProgress$ = new BehaviorSubject(false);

  private slackPopup?: Window | null;

  constructor(@Inject(API_ROOT_URL) private readonly apiRootUrl: string) {}

  ngOnInit(): void {
    this.manageSlackPopupMessages();
  }

  openSlackConnectionPopup(): void {
    this.isInProgress$.next(true);

    this.slackPopup = openWindowPopup(this.slackIntegrationLink, 400, 550, () => {
      this.isInProgress$.next(false);
    });
  }

  private manageSlackPopupMessages(): void {
    fromEvent<MessageEvent<SlackMessageEventData>>(window, 'message')
      .pipe(
        filter(
          ({ origin }) =>
            origin ===
            new URL(this.apiRootUrl.replace('https://api', `https://${this.tenant}`)).origin,
        ),
        untilDestroyed(this),
      )
      .subscribe(({ data: { status } }) => {
        this.isInProgress$.next(false);

        if (status) {
          this.readSlackSettings();
        } else {
          this.failSlackConnection();
        }
      });
  }
}
