// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { DeskStateModel } from '../desk/desk.model';
import { Reservation } from '../reservations';
import { SpaceStateModel } from '../space';
import { DesksStateModel } from './desks.model';

export namespace DesksStateAction {
  export namespace List {
    export namespace Add {
      export class Try {
        static readonly type = '[Desks] try Add Desk to List';
        constructor(public desk: DeskStateModel) {}
      }
      export class Success {
        static readonly type = '[Desks] then Add Desk to List success';
        constructor(public desks: DesksStateModel) {}
      }
      export class Failure {
        static readonly type = '[Desks] catch Add Desk to List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Desks] try Read Desks List';
        constructor(public spaceId: SpaceStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Desks] then Read Desks List success';
        constructor(public desks: DesksStateModel) {}
      }
      export class Failure {
        static readonly type = '[Desks] catch Read Desks List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Remove {
      export class Try {
        static readonly type = '[Desks] try Remove Desk from List';
        constructor(public id: DeskStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Desks] then Remove Desk from List success';
        constructor(public desks: DesksStateModel) {}
      }
      export class Failure {
        static readonly type = '[Desks] catch Remove Desk from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Desks] try Update Desk in List';
        constructor(public desk: DeskStateModel) {}
      }
      export class Success {
        static readonly type = '[Desks] then Update Desk in List success';
        constructor(public desks: DesksStateModel) {}
      }
      export class Failure {
        static readonly type = '[Desks] catch Update Desk in List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Reservations {
    export namespace Delete {
      export class Try {
        static readonly type = '[Desks] try Delete Desks Reservations';
        constructor(public ids: Array<Reservation['id']>) {}
      }
      export class Success {
        static readonly type = '[Desks] then Delete Desks Reservations success';
        constructor(public desks: DesksStateModel) {}
      }
      export class Failure {
        static readonly type = '[Desks] catch Delete Desks Reservations failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Desks] try Read Desks Reservations';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Desks] then Read Desks Reservations success';
        constructor(public desks: DesksStateModel) {}
      }
      export class Failure {
        static readonly type = '[Desks] catch Read Desks Reservations failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
      export class Cancel {
        static readonly type = '[Desks] catch Read Desks Reservations cancel';
        constructor(public reason?: string) {}
      }
    }
  }

  export namespace Settings {
    export namespace Read {
      export class Try {
        static readonly type = '[Desks] try Read Desks Settings';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Desks] then Read Desks Settings success';
        constructor(public desks: DesksStateModel) {}
      }
      export class Failure {
        static readonly type = '[Desks] catch Read Desks Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Desks] try Update Desks Settings';
        constructor(
          public buildingId: BuildingStateModel['id'],
          public data: DeepPartial<Pick<DesksStateModel, 'checkIn'>>,
        ) {}
      }
      export class Success {
        static readonly type = '[Desks] then Update Desks Settings success';
        constructor(public desks: DesksStateModel) {}
      }
      export class Failure {
        static readonly type = '[Desks] catch Update Desks Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
