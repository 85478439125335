import { EmployeeStateModel } from '../employee';
import { GroupStateModel } from '../group';
import { SpaceAmenities } from '../space';
import { ParkingType } from './parking.type';

export class ParkingStateModel {
  active!: boolean;
  coordinates!: { lat: number; lng: number };
  exclusivityAssignment!: boolean;
  id!: string;
  name!: string;
  type!: ParkingType;
  amenities?: SpaceAmenities;
  audiences?: Array<EmployeeStateModel['id'] | GroupStateModel['id']> | null;
  description?: string;
}
