// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { EmployeeStateModel } from '../employee';
import { EmployeesStateModel } from './employees.model';
import { EmployeesFilters } from './employees.type';

export namespace EmployeesStateAction {
  export namespace List {
    export namespace Add {
      export class Try {
        static readonly type = '[Employees] try Add Employee to List';
        constructor(public employee: EmployeeStateModel) {}
      }
      export class Success {
        static readonly type = '[Employees] then Add Employee to List success';
        constructor(public employees: EmployeesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Employees] catch Add Employee to List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Delete {
      export class Try {
        static readonly type = '[Employees] try Delete Employee from List';
        constructor(public ids: Array<EmployeeStateModel['id']>) {}
      }
      export class Success {
        static readonly type = '[Employees] then Delete Employee from List success';
        constructor(public employees: EmployeesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Employees] catch Delete Employee from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Invite {
      export class Try {
        static readonly type = '[Employees] try Invite Employee from List';
        constructor(public ids: Array<EmployeeStateModel['id']>) {}
      }
      export class Success {
        static readonly type = '[Employees] then Invite Employee from List success';
        constructor(public employees: EmployeesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Employees] catch Invite Employee from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Employees] try Read Employees List';
        constructor(public filters?: EmployeesFilters) {}
      }
      export class Success {
        static readonly type = '[Employees] then Read Employees List success';
        constructor(public employees: EmployeesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Employees] catch Read Employees List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
      export class Cancel {
        static readonly type = '[Employees] catch Read Employees List cancel';
        constructor(public reason?: string) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Employees] try Update Employee in List';
        constructor(
          public employee: DeepPartial<EmployeeStateModel> & Pick<EmployeeStateModel, 'id'>,
        ) {}
      }
      export class Success {
        static readonly type = '[Employees] then Update Employee in List success';
        constructor(public employees: EmployeesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Employees] catch Update Employee in List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
