import { Injectable } from "@angular/core";
import { StorageEngine } from "@ngxs/storage-plugin";
import Cookies from "js-cookie";

@Injectable()
export class MyStorageEngine implements StorageEngine {
  length = window.localStorage.length;
  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }
  clear(): void {
    window.localStorage.clear()
  }
  getItem(key: string): any {
    // Your logic here
    // tslint:disable-next-line:no-console
    console.log('get => ', key);
    return window.localStorage.getItem(key)
  }

  setItem(key: string, value: any): void {
    // Your logic here
    // tslint:disable-next-line:no-console
    console.log('set => ', key, value);

    window.localStorage.setItem(key, value);
  }
}
