import { BuildingStateModel } from '../building';
import { EmployeesStateModel } from '../employees';
import { SurveyStateModel } from '../survey';
import {
  Billing,
  CompanySettingsFeatures,
  Country,
  SpacesSettings,
  SubProcessors,
  Subscription,
  Term,
} from './company.type';

export class CompanyStateModel {
  name!: string;
  availableDesks?: number;
  billing?: Billing;
  buildings?: BuildingStateModel[];
  countries?: Country[];
  domains?: string[];
  employees?: EmployeesStateModel;
  logo?: string;
  occupancyRate?: number;
  companyPolicy?: string;
  reservedDesks?: number;
  settings?: Partial<CompanySettingsFeatures>;
  spacesSettings?: SpacesSettings;
  subProcessors?: SubProcessors;
  subscription?: Subscription;
  surveys?: SurveyStateModel[];
  terms?: Term[];
}

export type CompanyProfileStateModel = Pick<
  CompanyStateModel,
  'domains' | 'logo' | 'name' | 'settings'
>;
