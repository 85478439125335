import { CheckIn } from '../building';
import { DeskStateModel } from '../desk';
import { Reservation } from '../reservations';

export class DesksStateModel {
  list!: DeskStateModel[];
  reservations!: Reservation[];
  reservationsCurrentPage!: number;
  reservationsReachedElement!: number;
  reservationsReachedPage!: number;
  reservationsTotalElements!: number;
  reservationsTotalPages!: number;
  checkIn?: CheckIn;
  isViewColleaguesEnabled?: boolean;
}
