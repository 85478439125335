import { Injectable } from '@angular/core';
import { DeskService } from '@business/api';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { iif, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GroupStateModel } from '../group';
import { GroupsSelectors } from '../groups';
import { SpaceSelectors } from '../space';
import { DeskStateAction } from './desk.actions';
import { mapDeskToApi, mapDeskToClient } from './desk.helper';
import { DeskStateModel } from './desk.model';

@State<DeskStateModel>({
  name: 'desk',
})
@Injectable()
export class DeskState {
  @Select(SpaceSelectors.desks) desks$!: Observable<DeskStateModel[]>;

  @SelectSnapshot(GroupsSelectors.list) groups!: GroupStateModel[];

  constructor(private readonly deskService: DeskService) {}

  @Action(DeskStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<DeskStateModel>,
    { desk, spaceId }: DeskStateAction.Create.Try,
  ) {
    return this.deskService
      .create({ desk: mapDeskToApi(desk, this.groups), space_id: spaceId })
      .pipe(
        catchError(error => {
          dispatch(new DeskStateAction.Create.Failure(error));

          throw error;
        }),
        tap(({ id }) => {
          setState({
            ...desk,
            active: true,
            exclusivityAssignment: false,
            id,
            name: desk.name as string,
          });

          dispatch(new DeskStateAction.Create.Success(getState()));
        }),
      );
  }

  @Action(DeskStateAction.Delete.Try)
  delete({ dispatch, getState }: StateContext<DeskStateModel>, { id }: DeskStateAction.Delete.Try) {
    return this.deskService.delete({ id }).pipe(
      catchError(error => {
        dispatch(new DeskStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        dispatch([
          new DeskStateAction.Delete.Success({ ...getState(), id }),
          new StateReset(DeskState),
        ]);
      }),
    );
  }

  @Action(DeskStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<DeskStateModel>,
    { id, options }: DeskStateAction.Read.Try,
  ) {
    return iif(
      () => !!options?.fromLocal,
      this.desks$.pipe(map(desks => desks.find(desk => desk.id === id))),
      this.deskService.read(id).pipe(
        catchError(error => {
          dispatch(new DeskStateAction.Read.Failure(error));

          throw error;
        }),
        map(mapDeskToClient),
      ),
    ).pipe(
      tap(desk => {
        if (desk) {
          setState(desk);

          dispatch(new DeskStateAction.Read.Success(getState()));
        } else {
          dispatch(new DeskStateAction.Read.Failure(desk));
        }
      }),
    );
  }

  @Action(DeskStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<DeskStateModel>,
    { data, id }: DeskStateAction.Update.Try,
  ) {
    return this.deskService.update(mapDeskToApi({ ...data, id }, this.groups)).pipe(
      catchError(error => {
        dispatch(new DeskStateAction.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data, id }));

        dispatch(new DeskStateAction.Update.Success(getState()));
      }),
    );
  }
}
