import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@business/environment';
import { SubscriptionFeatureIsActiveToggleGuard } from '@business/guards';
import { SubscriptionFeatures } from '@business/store';
import { PermissionsGuard, Roles } from '@nibol/auth';
import { AppShells } from '@nibol/shared';
import {
  NgxFeatureToggleCanActivateChildGuard,
  NgxFeatureToggleCanActivateGuard,
} from 'ngx-feature-toggle';
import { redirectRoutes } from './redirect/redirect.routes';
import { InitialPathGuard } from './redirect/initialpath.guard';
import { RedirectGuard } from './redirect/redirect.guard';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [InitialPathGuard],
        children: [
          {
            path: '',
            canActivate: [RedirectGuard],
            pathMatch: 'full',
            redirectTo: environment.homepage,
          },
          {
            path: 'access-denied',
            loadChildren: () =>
              import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule),
            canActivate: [RedirectGuard],
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.guest],
                redirectTo: environment.homepage,
              },
              shell: AppShells.public,
            },
          },
          {
            path: 'account',
            loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
            canActivate: [RedirectGuard],
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.manager],
                redirectTo: '/login',
              },
            },
          },
          // {
          //   path: 'admin',
          //   loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
          //   canActivateChild: [PermissionsGuard],
          //   canLoad: [PermissionsGuard],
          //   data: {
          //     permissions: {
          //       only: [Roles.admin],
          //       redirectTo: environment.homepage,
          //     },
          //   },
          // },
          // {
          //   path: 'billing',
          //   loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
          //   canActivateChild: [PermissionsGuard],
          //   canLoad: [PermissionsGuard],
          //   data: {
          //     permissions: {
          //       only: [Roles.manager],
          //       // redirectTo with this value in order to mantain dependency between core routes
          //       redirectTo: '/terms-and-privacy-policy',
          //     },
          //   },
          // },
          {
            path: 'buildings',
            loadChildren: () => import('./buildings/buildings.module').then(m => m.BuildingsModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.hasValidSubscription],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'create-password',
            loadChildren: () =>
              import('./create-password/create-password.module')
                .then(m => m.CreatePasswordModule),
            canActivate: [RedirectGuard],
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.hasToChangePassword],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: '/login',
              },
              showMenu: false,
              showTitle: false,
            },
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            canActivate: [NgxFeatureToggleCanActivateGuard],
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              featureToggle: ['TOGGLE_ENABLE_DASHBOARD'],
              permissions: {
                only: [Roles.hasValidSubscription],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: '/terms-and-privacy-policy',
              },
              showMenu: false,
              showTitle: false,
              showHeader: false,
            },
          },
          {
            path: 'integrations',
            loadChildren: () =>
              import('./integrations/integrations.module').then(m => m.IntegrationsModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.hasValidSubscription],
                redirectTo: '/login',
              },
              showMenu: false,
              showTitle: true,
              showHeader: false,
            },
          },
          {
            path: 'login',
            loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
            canActivate: [RedirectGuard],
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.guest],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: environment.homepage,
              },
            },
          },
          // {
          //   path: 'members',
          //   loadChildren: () => import('./members/members.module').then(m => m.MembersModule),
          //   canActivateChild: [NgxFeatureToggleCanActivateChildGuard, PermissionsGuard],
          //   canLoad: [PermissionsGuard],
          //   data: {
          //     featureToggle: ['TOGGLE_ENABLE_MEMBERS'],
          //     permissions: {
          //       only: [Roles.hasValidSubscription],
          //       // redirectTo with this value in order to mantain dependency between core routes
          //       redirectTo: '/billing',
          //     },
          //   },
          // },
          {
            canActivate: [RedirectGuard],
            path: 'permission-denied',
            loadChildren: () =>
              import('./permission-denied/permission-denied.module').then(
                m => m.PermissionDeniedModule,
              ),
            data: {
              shell: AppShells.public,
            },
          },
          {
            canActivate: [RedirectGuard],
            path: 'recover-password',
            loadChildren: () =>
              import('./recover-password/recover-password.module').then(
                m => m.RecoverPasswordModule,
              ),
            data: {
              shell: AppShells.public,
              showMenu: false,
              showTitle: false,
            },
          },
          {
            canActivate: [RedirectGuard],
            path: 'reset-password',
            loadChildren: () =>
              import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
            data: {
              shell: AppShells.public,
              showMenu: false,
              showTitle: false,
            },
          },
          {
            path: 'settings',
            loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.hasValidSubscription],
                redirectTo: '/login',
              },
              withTabs: true,
              showMenu: false,
              showTitle: false,
              showHeader: false,
            },
          },
          // {
          //   path: 'signup',
          //   loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
          //   canActivateChild: [PermissionsGuard],
          //   canLoad: [PermissionsGuard],
          //   data: {
          //     permissions: {
          //       only: [Roles.guest],
          //       redirectTo: environment.homepage,
          //     },
          //   },
          // },
          // {
          //   path: 'surveys',
          //   loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule),
          //   canActivate: [SubscriptionFeatureIsActiveToggleGuard],
          //   canActivateChild: [PermissionsGuard],
          //   canLoad: [PermissionsGuard],
          //   data: {
          //     permissions: { only: [Roles.hasValidSubscription], redirectTo: '/login' },
          //     subscriptionFeatureToggle: SubscriptionFeatures.survey,
          //   },
          // },
          {
            canActivate: [RedirectGuard],
            path: 'terms-and-privacy-policy',
            loadChildren: () =>
              import('./terms-and-privacy-policy/terms-and-privacy-policy.module').then(
                m => m.TermsAndPrivacyPolicyModule,
              ),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.hasToAcceptTermsAndPrivacyPolicy],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: '/create-password',
              },
              showMenu: false,
              showTitle: false,
            },
          },
          // {
          //   path: 'work-from-anywhere',
          //   loadChildren: () =>
          //     import('./work-from-anywhere/work-from-anywhere.module').then(
          //       m => m.WorkFromAnywhereModule,
          //     ),
          //   canActivateChild: [PermissionsGuard],
          //   canLoad: [PermissionsGuard],
          //   data: {
          //     permissions: {
          //       only: [Roles.hasValidSubscription],
          //       redirectTo: '/login',
          //     },
          //   },
          // },
        ],
      },
      ...redirectRoutes,
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
