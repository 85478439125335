import { BuildingState } from './building';
import { BuildingsState } from './buildings';
import { CompanyState } from './company';
import { CustomEntitiesCategoriesState, CustomEntitiesState } from './custom-entities';
import { CustomEntityState } from './custom-entity';
import { DeliveriesState } from './deliveries';
import { DeskState } from './desk';
import { DesksState } from './desks';
import { EmailState } from './email';
import { EmailsState } from './emails';
import { EmployeeState } from './employee/employee.state';
import { EmployeesState } from './employees';
import { FeedbackSaga } from './feedback-saga';
import { GroupState } from './group';
import { GroupsState } from './groups';
import { IntegrationsState } from './integrations';
import { OfficeState } from './office';
import { OfficesState } from './offices';
import { ParkingState } from './parking';
import { ParkingsState } from './parkings';
import { RoomState } from './room';
import { RoomsState } from './rooms';
import { SafeState } from './safe';
import { Saga } from './saga';
import { SpaceState } from './space';
import { SpacesState } from './spaces';
import { StatsState } from './stats';
import { SurveyState } from './survey';
import { SurveysState } from './surveys';
import { VisitorsState } from './visitors';

export * from './building';
export * from './buildings';
export * from './company';
export * from './custom-entities';
export * from './custom-entity';
export * from './deliveries';
export * from './delivery';
export * from './desk';
export * from './desks';
export * from './email';
export * from './emails';
export * from './employee';
export * from './employees';
export * from './feedback-saga';
export * from './group';
export * from './groups';
export * from './integrations';
export * from './office';
export * from './offices';
export * from './parking';
export * from './parkings';
export * from './reservations';
export * from './room';
export * from './rooms';
export * from './safe';
export * from './saga';
export * from './space';
export * from './spaces';
export * from './stats';
export * from './survey';
export * from './surveys';
export * from './visitors';

export const states = [
  BuildingsState,
  BuildingState,
  CompanyState,
  CustomEntitiesCategoriesState,
  CustomEntitiesState,
  CustomEntityState,
  DeliveriesState,
  DesksState,
  DeskState,
  EmailsState,
  EmailState,
  EmployeesState,
  EmployeeState,
  FeedbackSaga,
  GroupsState,
  GroupState,
  IntegrationsState,
  OfficesState,
  OfficeState,
  ParkingsState,
  ParkingState,
  RoomsState,
  RoomState,
  SafeState,
  Saga,
  SpacesState,
  SpaceState,
  StatsState,
  SurveysState,
  SurveyState,
  VisitorsState,
];
