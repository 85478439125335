// tslint:disable: no-namespace

import { Office } from '../office';

export namespace Building {
  export namespace Create {
    export type Params = Pick<Read.Response, 'name' | 'position'>;
    export type Response = Pick<Read.Response, 'id'> & {
      status: boolean;
    };
  }

  export namespace Delete {
    export type Params = {
      building_id: Read.Response['id'];
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace List {
    export type Response = Array<Pick<Read.Response, 'id' | 'name'>>;
  }

  export namespace Offices {
    export type Params = {
      building_id: Read.Response['id'];
    };
    export type Response = Array<
      Pick<Office.Read.Response, 'id' | 'name'> & {
        seats: number;
      }
    >;
  }

  export namespace Read {
    export type Params = {
      id: Response['id'];
    };
    export type Response = {
      id: string;
      name: string;
      position: {
        address: string;
        coordinates: {
          lat: number;
          lng: number;
        };
        timezone: string;
      };
      settings: {
        availability_opening_time: { from: number; lunch: number; to: number };
        availability_weekdays: number[];
        closings: Array<{
          from: ReturnType<Date['toISOString']>;
          to?: ReturnType<Date['toISOString']>;
        }>;
      };
    };
  }

  export namespace Update {
    export type Params = Read.Response;
    export type Response = {
      status: boolean;
    };
  }
}
