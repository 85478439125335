import { Selector } from '@ngxs/store';
import { GroupStateModel } from './group.model';
import { GroupState } from './group.state';

export class GroupSelectors {
  @Selector([GroupState])
  static fullname({ name }: GroupStateModel): GroupStateModel['name'] {
    return name;
  }

  @Selector([GroupState])
  static id({ id }: GroupStateModel): GroupStateModel['id'] {
    return id;
  }

  @Selector([GroupState])
  static members({ members }: GroupStateModel): GroupStateModel['members'] {
    return members;
  }

  @Selector([GroupState])
  static totalMembers({ totalMembers }: GroupStateModel): GroupStateModel['totalMembers'] {
    return totalMembers;
  }

  @Selector([GroupState])
  static uid({ uid }: GroupStateModel): GroupStateModel['uid'] {
    return uid;
  }
}
