import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Emails } from './emails.type';

@Injectable({ providedIn: 'root' })
export class EmailsService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  design(): Observable<Emails.Design.Read.Response> {
    return this.httpClient
      .get<Emails.Design.Read.Response>(`${this.apiRootUrl}/company/settings/email/design`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  list(): Observable<Emails.List.Response> {
    return this.httpClient
      .get<Emails.List.Response>(`${this.apiRootUrl}/company/settings/email/list`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  smtp(): Observable<Emails.Smtp.Read.Response> {
    return this.httpClient
      .get<Emails.Smtp.Read.Response>(`${this.apiRootUrl}/company/settings/email/smtp`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  resetDesign(): Observable<Emails.Design.Reset.Response> {
    return this.httpClient
      .delete<Emails.Design.Reset.Response>(`${this.apiRootUrl}/company/settings/email/design`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  resetSmtp(): Observable<Emails.Smtp.Reset.Response> {
    return this.httpClient
      .delete<Emails.Smtp.Reset.Response>(`${this.apiRootUrl}/company/settings/email/smtp`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  testSmtp(): Observable<Emails.Smtp.Test.Response> {
    return this.httpClient
      .post<Emails.Smtp.Test.Response>(`${this.apiRootUrl}/company/settings/email/smtp/test`, {})
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateDesign(
    design: DeepPartial<Emails.Design.Update.Params>,
  ): Observable<Emails.Design.Update.Response> {
    return this.httpClient
      .put<Emails.Design.Update.Response>(
        `${this.apiRootUrl}/company/settings/email/design`,
        design,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSmtp(
    smtp: DeepPartial<Emails.Smtp.Update.Params>,
  ): Observable<Emails.Smtp.Update.Response> {
    return this.httpClient
      .put<Emails.Smtp.Update.Response>(`${this.apiRootUrl}/company/settings/email/smtp`, smtp)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
