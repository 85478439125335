import { Injectable } from '@angular/core';
import { EmployeeService } from '@business/api';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';
import { catchError, first, map, switchMapTo, tap } from 'rxjs/operators';
import { EmployeesSelectors } from '../employees';
import { EmployeeStateAction } from './employee.actions';
import { mapEmployeeToCreateApi, mapEmployeeToUpdateApi } from './employee.helper';
import { EmployeeStateModel } from './employee.model';

@State<EmployeeStateModel>({
  name: 'employee',
})
@Injectable()
export class EmployeeState {
  @Select(EmployeesSelectors.list) employees$!: Observable<EmployeeStateModel[]>;

  constructor(private readonly employeeService: EmployeeService) {}

  @Action(EmployeeStateAction.AssignBuildings.Try)
  assignBuilding(
    { dispatch, getState, setState }: StateContext<EmployeeStateModel>,
    { id: user, buildingIds: buildings }: EmployeeStateAction.AssignBuildings.Try,
  ) {
    return this.employeeService.assignBuilding({ buildings, user }).pipe(
      catchError(error => {
        dispatch(new EmployeeStateAction.AssignBuildings.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ assignedBuildings: buildings }));

        dispatch(new EmployeeStateAction.AssignBuildings.Success(getState()));
      }),
    );
  }

  @Action(EmployeeStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<EmployeeStateModel>,
    { email, firstname, lastname, role, sendInvitation }: EmployeeStateAction.Create.Try,
  ) {
    return this.employeeService
      .create(mapEmployeeToCreateApi({ email, firstname, lastname, role, sendInvitation }))
      .pipe(
        catchError(error => {
          dispatch(new EmployeeStateAction.Create.Failure(error));

          throw error;
        }),
        tap(({ id }) => {
          setState({
            email,
            firstname,
            id,
            lastname,
            roles: [role],
            // @todo(heavybeard): move default avatar to init configuration
            picture: 'https://cdn.nibol.co/profile/Avatar_User_NoPicture.jpg',
            status: sendInvitation ? 'inviting' : 'not_invited',
          });

          dispatch(new EmployeeStateAction.Create.Success(getState(), sendInvitation));
        }),
      );
  }

  @Action(EmployeeStateAction.Delete.Try)
  delete(
    { dispatch, getState }: StateContext<EmployeeStateModel>,
    { id }: EmployeeStateAction.Delete.Try,
  ) {
    return this.employeeService.delete({ ids: [id] }).pipe(
      catchError(error => {
        dispatch(new EmployeeStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        await dispatch(new EmployeeStateAction.Delete.Success(getState())).toPromise();
        dispatch(new StateReset(EmployeeState));
      }),
    );
  }

  @Action(EmployeeStateAction.Invite.Try)
  invite(
    { dispatch, getState }: StateContext<EmployeeStateModel>,
    { id }: EmployeeStateAction.Invite.Try,
  ) {
    return this.employeeService.invite({ ids: [id] }).pipe(
      catchError(error => {
        dispatch(new EmployeeStateAction.Invite.Failure(error));

        throw error;
      }),
      tap(async () => {
        await dispatch(new EmployeeStateAction.Invite.Success(getState())).toPromise();
        dispatch(new StateReset(EmployeeState));
      }),
    );
  }

  @Action(EmployeeStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<EmployeeStateModel>,
    { id }: EmployeeStateAction.Read.Try,
  ) {
    return this.employees$.pipe(
      map(employees => employees.find(employee => employee.id === id)),
      tap(employee => {
        if (employee) {
          setState(employee);

          dispatch(new EmployeeStateAction.Read.Success(getState()));
        } else {
          dispatch(new EmployeeStateAction.Read.Failure(employee));
        }
      }),
    );
  }

  @Action(EmployeeStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<EmployeeStateModel>,
    { id, role }: EmployeeStateAction.Update.Try,
  ) {
    return this.employeeService.update(mapEmployeeToUpdateApi({ id, role })).pipe(
      catchError(error => {
        dispatch(new EmployeeStateAction.Update.Failure(error));

        throw error;
      }),
      switchMapTo(this.employees$),
      first(),
      tap(employees => {
        setState(
          patch<EmployeeStateModel>({
            ...employees.find(employee => employee.id === id),
            roles: [role],
          }),
        );

        dispatch(new EmployeeStateAction.Update.Success(getState()));
      }),
    );
  }
}
