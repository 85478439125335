import { Selector } from '@ngxs/store';
import { IntegrationsStateModel } from './integrations.model';
import { IntegrationsState } from './integrations.state';
import {
  GoogleWorkspaceDirectorySettings,
  GoogleWorkspaceRoomsSettings,
  MicrosoftAzureActiveDirectorySettings,
} from './integrations.type';

export class IntegrationsSelectors {
  @Selector([
    IntegrationsSelectors.isMicrosoft365RoomsEnabled,
    IntegrationsSelectors.isGoogleWorkspaceRoomsEnabled,
  ])
  static enabledRoomsIntegrationName(
    isMicrosoft365RoomsEnabled: boolean,
    isGoogleWorkspaceRoomsEnabled: boolean,
  ): string {
    return {
      [`${isMicrosoft365RoomsEnabled}`]: 'integrations_microsoft365',
      [`${isGoogleWorkspaceRoomsEnabled}`]: 'integrations_googleworkspace',
    }.true;
  }

  @Selector([IntegrationsSelectors.googleWorkspaceDirectorySettings])
  static googleWorkspaceDirectoryIntegrationLink(
    googleWorkspaceDirectorySettings: IntegrationsStateModel['googleWorkspaceDirectory'],
  ): GoogleWorkspaceDirectorySettings['integrationLink'] {
    return googleWorkspaceDirectorySettings?.integrationLink ?? '';
  }

  @Selector([IntegrationsState])
  static googleWorkspaceDirectorySettings({
    googleWorkspaceDirectory,
  }: IntegrationsStateModel): IntegrationsStateModel['googleWorkspaceDirectory'] {
    return googleWorkspaceDirectory;
  }

  @Selector([IntegrationsSelectors.googleWorkspaceRoomsSettings])
  static googleWorkspaceRoomsIntegrationLink(
    googleWorkspaceRoomsSettings: IntegrationsStateModel['googleWorkspaceRooms'],
  ): GoogleWorkspaceRoomsSettings['integrationLink'] {
    return googleWorkspaceRoomsSettings?.integrationLink ?? '';
  }

  @Selector([IntegrationsSelectors.googleWorkspaceRoomsSettings])
  static googleWorkspaceRoomsAccountName(
    googleWorkspaceRoomsSettings: IntegrationsStateModel['googleWorkspaceRooms'],
  ): NonNullable<GoogleWorkspaceRoomsSettings['calendarIntegration']>['accountName'] {
    return googleWorkspaceRoomsSettings?.calendarIntegration?.accountName ?? '';
  }

  @Selector([IntegrationsSelectors.googleWorkspaceRoomsSettings])
  static googleWorkspaceRoomsRoomsAmount(
    googleWorkspaceRoomsSettings: IntegrationsStateModel['googleWorkspaceRooms'],
  ): NonNullable<GoogleWorkspaceRoomsSettings['calendarIntegration']>['roomsAmount'] {
    return googleWorkspaceRoomsSettings?.calendarIntegration?.roomsAmount ?? 0;
  }

  @Selector([IntegrationsState])
  static googleWorkspaceRoomsSettings({
    googleWorkspaceRooms,
  }: IntegrationsStateModel): IntegrationsStateModel['googleWorkspaceRooms'] {
    return googleWorkspaceRooms;
  }

  @Selector([IntegrationsSelectors.rooms])
  static hasRooms(rooms: IntegrationsStateModel['rooms']): boolean {
    return (rooms.length ?? 0) > 0 || false;
  }

  @Selector([
    IntegrationsSelectors.isGoogleWorkspaceDirectoryEnabled,
    IntegrationsSelectors.isMicrosoftAzureActiveDirectoryEnabled,
    IntegrationsSelectors.isOktaDirectoryEnabled,
  ])
  static isDirectoryEnabled(
    isGoogleWorkspaceDirectoryEnabled: boolean,
    isMicrosoftAzureActiveDirectoryEnabled: boolean,
    isOktaDirectoryEnabled: boolean,
  ): boolean {
    return (
      isGoogleWorkspaceDirectoryEnabled ||
      isMicrosoftAzureActiveDirectoryEnabled ||
      isOktaDirectoryEnabled
    );
  }

  @Selector([IntegrationsSelectors.googleWorkspaceDirectorySettings])
  static isGoogleWorkspaceDirectoryAcceptCustomIntegrationValues(
    googleWorkspaceDirectorySettings: IntegrationsStateModel['googleWorkspaceDirectory'],
  ): MicrosoftAzureActiveDirectorySettings['acceptCustomIntegrationFields'] {
    return googleWorkspaceDirectorySettings?.acceptCustomIntegrationFields ?? false;
  }

  @Selector([IntegrationsState])
  static isGoogleWorkspaceDirectoryEligibleToBeEnabled({
    googleWorkspaceDirectory,
  }: IntegrationsStateModel): boolean {
    return (
      !googleWorkspaceDirectory?.enabled &&
      googleWorkspaceDirectory?.fieldsAssociation?.length !== 0
    );
  }

  @Selector([IntegrationsState])
  static isGoogleWorkspaceDirectoryAuthNegated({
    googleWorkspaceDirectory,
  }: IntegrationsStateModel): boolean {
    return googleWorkspaceDirectory?.isAuthNegated ?? false;
  }

  @Selector([IntegrationsState])
  static isGoogleWorkspaceDirectoryEnabled({
    googleWorkspaceDirectory,
  }: IntegrationsStateModel): boolean {
    return googleWorkspaceDirectory?.enabled ?? false;
  }

  @Selector([IntegrationsSelectors.googleWorkspaceRoomsSettings])
  static isGoogleWorkspaceRoomsCompleted(
    googleWorkspaceRoomsSettings: IntegrationsStateModel['googleWorkspaceRooms'],
  ): boolean {
    return googleWorkspaceRoomsSettings?.completed ?? false;
  }

  @Selector([IntegrationsSelectors.googleWorkspaceRoomsSettings])
  static isGoogleWorkspaceRoomsEnabled(
    googleWorkspaceRoomsSettings: IntegrationsStateModel['googleWorkspaceRooms'],
  ): boolean {
    return googleWorkspaceRoomsSettings?.calendarIntegration?.source === 'google';
  }

  @Selector([IntegrationsSelectors.googleWorkspaceRoomsSettings])
  static isGoogleWorkspaceRoomsLoaded(
    googleWorkspaceRoomsSettings: IntegrationsStateModel['googleWorkspaceRooms'],
  ): boolean {
    return typeof googleWorkspaceRoomsSettings?.integrationLink !== 'undefined';
  }

  // @todo(heavybeard): method to implement when an integration will affected it
  @Selector()
  static isGroupEnabled(): boolean {
    return true;
  }

  @Selector([IntegrationsState])
  static isKisiEnabled({ kisi }: IntegrationsStateModel): boolean {
    return kisi?.enabled ?? false;
  }

  @Selector([IntegrationsState])
  static isSofiaEnabled({ sofia }: IntegrationsStateModel): boolean {
    return sofia?.enabled ?? false;
  }

  @Selector([IntegrationsSelectors.microsoft365RoomsSettings])
  static isMicrosoft365RoomsCompleted(
    microsoft365RoomsSettings: IntegrationsStateModel['microsoft365Rooms'],
  ): boolean {
    return microsoft365RoomsSettings?.completed ?? false;
  }

  @Selector([IntegrationsSelectors.microsoft365RoomsSettings])
  static isMicrosoft365RoomsEnabled(
    microsoft365RoomsSettings: IntegrationsStateModel['microsoft365Rooms'],
  ): boolean {
    return microsoft365RoomsSettings?.calendarIntegration?.source === 'microsoft';
  }

  @Selector([IntegrationsSelectors.microsoft365RoomsSettings])
  static isMicrosoft365RoomsLoaded(
    microsoft365RoomsSettings: IntegrationsStateModel['microsoft365Rooms'],
  ): boolean {
    return typeof microsoft365RoomsSettings?.integrationLink !== 'undefined';
  }

  @Selector([IntegrationsState])
  static isMicrosoftAzureActiveDirectoryEligibleToBeEnabled({
    microsoftAzureActiveDirectory,
  }: IntegrationsStateModel): boolean {
    return (
      !microsoftAzureActiveDirectory?.enabled &&
      microsoftAzureActiveDirectory?.fieldsAssociation?.length !== 0
    );
  }

  @Selector([IntegrationsState])
  static isMicrosoftAzureActiveDirectoryEnabled({
    microsoftAzureActiveDirectory,
  }: IntegrationsStateModel): boolean {
    return microsoftAzureActiveDirectory?.enabled ?? false;
  }

  @Selector([IntegrationsSelectors.microsoftAzureActiveDirectorySettings])
  static isMicrosoftAzureActiveDirectoryAcceptCustomIntegrationValues(
    microsoftAzureActiveDirectorySettings: IntegrationsStateModel['microsoftAzureActiveDirectory'],
  ): MicrosoftAzureActiveDirectorySettings['acceptCustomIntegrationFields'] {
    return microsoftAzureActiveDirectorySettings?.acceptCustomIntegrationFields ?? false;
  }

  @Selector([IntegrationsState])
  static isOktaDirectoryEligibleToBeEnabled({ oktaDirectory }: IntegrationsStateModel): boolean {
    return (
      typeof oktaDirectory?.apiKey !== 'undefined' && typeof oktaDirectory?.domain !== 'undefined'
    );
  }

  @Selector([IntegrationsState])
  static isOktaDirectoryEnabled({ oktaDirectory }: IntegrationsStateModel): boolean {
    return oktaDirectory?.enabled ?? false;
  }

  @Selector([
    IntegrationsSelectors.isGoogleWorkspaceRoomsCompleted,
    IntegrationsSelectors.isMicrosoft365RoomsCompleted,
  ])
  static isRoomsCompleted(
    isGoogleWorkspaceRoomsCompleted: boolean,
    isMicrosoft365RoomsCompleted: boolean,
  ): boolean {
    return isGoogleWorkspaceRoomsCompleted || isMicrosoft365RoomsCompleted;
  }

  @Selector([
    IntegrationsSelectors.isGoogleWorkspaceRoomsEnabled,
    IntegrationsSelectors.isMicrosoft365RoomsEnabled,
  ])
  static isRoomsEnabled(
    isGoogleWorkspaceRoomsEnabled: boolean,
    isMicrosoft365RoomsEnabled: boolean,
  ): boolean {
    return isGoogleWorkspaceRoomsEnabled || isMicrosoft365RoomsEnabled;
  }

  @Selector([IntegrationsState])
  static isSlackEnabled({ slack }: IntegrationsStateModel): boolean {
    return slack?.enabled ?? false;
  }

  @Selector([IntegrationsState])
  static kisiSettings({ kisi }: IntegrationsStateModel): IntegrationsStateModel['kisi'] {
    return kisi;
  }

  @Selector([IntegrationsState])
  static sofiaSettings({ sofia }: IntegrationsStateModel): IntegrationsStateModel['sofia'] {
    return sofia;
  }

  @Selector([IntegrationsSelectors.microsoft365RoomsSettings])
  static microsoft365RoomsIntegrationLink(
    microsoft365RoomsSettings: IntegrationsStateModel['microsoft365Rooms'],
  ): GoogleWorkspaceRoomsSettings['integrationLink'] {
    return microsoft365RoomsSettings?.integrationLink ?? '';
  }

  @Selector([IntegrationsSelectors.microsoft365RoomsSettings])
  static microsoft365RoomsRoomsAmount(
    microsoft365RoomsSettings: IntegrationsStateModel['microsoft365Rooms'],
  ): NonNullable<GoogleWorkspaceRoomsSettings['calendarIntegration']>['roomsAmount'] {
    return microsoft365RoomsSettings?.calendarIntegration?.roomsAmount ?? 0;
  }

  @Selector([IntegrationsState])
  static microsoft365RoomsSettings({
    microsoft365Rooms,
  }: IntegrationsStateModel): IntegrationsStateModel['microsoft365Rooms'] {
    return microsoft365Rooms;
  }

  @Selector([IntegrationsState])
  static microsoftAzureActiveDirectorySettings({
    microsoftAzureActiveDirectory,
  }: IntegrationsStateModel): IntegrationsStateModel['microsoftAzureActiveDirectory'] {
    return microsoftAzureActiveDirectory;
  }

  @Selector([IntegrationsSelectors.microsoftAzureActiveDirectorySettings])
  static microsoftAzureActiveDirectoryIntegrationLink(
    microsoftAzureActiveDirectorySettings: IntegrationsStateModel['microsoftAzureActiveDirectory'],
  ): MicrosoftAzureActiveDirectorySettings['integrationLink'] {
    return microsoftAzureActiveDirectorySettings?.integrationLink ?? '';
  }

  @Selector([IntegrationsState])
  static oktaDirectorySettings({
    oktaDirectory,
  }: IntegrationsStateModel): IntegrationsStateModel['oktaDirectory'] {
    return oktaDirectory;
  }

  @Selector([IntegrationsState])
  static remoteRooms({
    remoteRooms,
  }: IntegrationsStateModel): IntegrationsStateModel['remoteRooms'] {
    return remoteRooms;
  }

  @Selector([IntegrationsState])
  static rooms({ rooms }: IntegrationsStateModel): IntegrationsStateModel['rooms'] {
    return rooms;
  }

  @Selector([IntegrationsSelectors.rooms])
  static mappedRooms(rooms: IntegrationsStateModel['rooms']): IntegrationsStateModel['rooms'] {
    return rooms.filter(room => typeof room.calendarEmail !== 'undefined');
  }

  @Selector([IntegrationsState])
  static slackSettings({ slack }: IntegrationsStateModel): IntegrationsStateModel['slack'] {
    return slack;
  }

  @Selector([IntegrationsSelectors.slackSettings])
  static slackIntegrationLink(
    slackSettings: IntegrationsStateModel['slack'],
  ): GoogleWorkspaceDirectorySettings['integrationLink'] {
    return slackSettings?.integrationLink ?? '';
  }
}
