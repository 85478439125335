import { Safe } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { SafeStateModel } from './safe.model';

export function mapSafeSettingsToApi(
  buildingId: BuildingStateModel['id'],
  data: DeepPartial<SafeStateModel>,
): Safe.Settings.Update.Body {
  return {
    id: buildingId,
    safe: {
      booking: data.isEnabledForBooking,
      checkin: data.isEnabledForCheckIn,
      contact: data.contacts,
    },
  };
}

export function mapSafeSettingsToClient({ safe }: Safe.Settings.Read.Response): SafeStateModel {
  return {
    isEnabledForBooking: safe.booking,
    isEnabledForCheckIn: safe.checkin,
    contacts: safe.contact ?? undefined,
  };
}
