import { Visitors } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { VisitorsStateModel } from './visitors.model';

export function mapVisitorsReservationsToClient(
  buildingId: BuildingStateModel['id'],
  reservations: Visitors.Reservations.Read.Response['data'],
): VisitorsStateModel['reservations'] {
  return reservations.map(reservation => ({
    building: { id: buildingId },
    checkIn: reservation.checkin ? new Date(reservation.checkin) : undefined,
    checkOut: reservation.checkout ? new Date(reservation.checkout) : undefined,
    from: new Date(reservation.date),
    host: { firstname: reservation.employee.name, email: reservation.employee.email },
    id: '',
    isFullday: false,
    to: new Date(reservation.date),
    user: { firstname: reservation.visitor.name, email: reservation.visitor.email },
  }));
}

export function mapVisitorsReservationsApiParams({
  buildingId,
  elements,
  page,
}: {
  buildingId: BuildingStateModel['id'];
  elements: VisitorsStateModel['reservations']['length'];
  page: VisitorsStateModel['reservationsCurrentPage'];
}): Visitors.Reservations.Read.Params {
  return { building_id: buildingId, elements: elements.toString(), page: page.toString() };
}

export function mapVisitorsSettingsToApi(
  buildingId: BuildingStateModel['id'],
  data: DeepPartial<VisitorsStateModel>,
): DeepPartial<Visitors.Settings.Update.Params> {
  return {
    id: buildingId,
    visitors: {
      assign_custom_entity: data.isAssignedCustomEntityEnabled,
      assign_desk: data.isAssignedDeskEnabled,
      assign_parking: data.isAssignedParkingEnabled,
      enabled: data.isEnabled,
      wifi: data.wifi,
    },
  };
}

export function mapVisitorsSettingsToClient({
  visitors,
}: Visitors.Settings.Read.Response): Pick<
  VisitorsStateModel,
  | 'isAssignedCustomEntityEnabled'
  | 'isAssignedDeskEnabled'
  | 'isAssignedParkingEnabled'
  | 'isEnabled'
  | 'wifi'
> {
  return {
    isAssignedCustomEntityEnabled: visitors.assign_custom_entity,
    isAssignedDeskEnabled: visitors.assign_desk,
    isAssignedParkingEnabled: visitors.assign_parking,
    isEnabled: visitors.enabled,
    wifi: visitors.wifi,
  };
}
