import { Injectable } from '@angular/core';
import { OfficeService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { catchError, tap } from 'rxjs/operators';
import { SpacesState } from '../spaces';
import { OfficeStateAction } from './office.actions';
import { mapOfficeToApi, mapOfficeToClient } from './office.helper';
import { OfficeStateModel } from './office.model';

@State<OfficeStateModel>({
  name: 'office',
  children: [SpacesState],
})
@Injectable()
export class OfficeState {
  constructor(private readonly officeService: OfficeService) {}

  @Action(OfficeStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<OfficeStateModel>,
    { buildingId, floor, name }: OfficeStateAction.Create.Try,
  ) {
    return this.officeService
      .create({ building_id: buildingId, office: { name, position: { additional: floor } } })
      .pipe(
        catchError(error => {
          dispatch(new OfficeStateAction.Create.Failure(error));

          throw error;
        }),
        tap(({ id }) => {
          setState({ id, floor, name, seats: 0 });

          dispatch(new OfficeStateAction.Create.Success(getState()));
        }),
      );
  }

  @Action(OfficeStateAction.Delete.Try)
  delete(
    { dispatch, getState }: StateContext<OfficeStateModel>,
    { id }: OfficeStateAction.Delete.Try,
  ) {
    return this.officeService.delete(id).pipe(
      catchError(error => {
        dispatch(new OfficeStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        await dispatch(new OfficeStateAction.Delete.Success(getState())).toPromise();
        dispatch(new StateReset(OfficeState));
      }),
    );
  }

  @Action(OfficeStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<OfficeStateModel>,
    { id }: OfficeStateAction.Read.Try,
  ) {
    return this.officeService.read(id).pipe(
      catchError(error => {
        dispatch(new OfficeStateAction.Read.Failure(error));

        throw error;
      }),
      tap(building => {
        setState(patch(mapOfficeToClient(building)));

        dispatch(new OfficeStateAction.Read.Success(getState()));
      }),
    );
  }

  @Action(OfficeStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<OfficeStateModel>,
    { id, data }: OfficeStateAction.Update.Try,
  ) {
    return this.officeService.update(id, mapOfficeToApi(data)).pipe(
      catchError(error => {
        dispatch(new OfficeStateAction.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data, id }));

        dispatch(new OfficeStateAction.Update.Success(getState()));
      }),
    );
  }

  @Action(OfficeStateAction.UploadPhoto.Try)
  uploadPhoto(
    { dispatch, getState }: StateContext<OfficeStateModel>,
    { photo, id }: OfficeStateAction.UploadPhoto.Try,
  ) {
    const uploadData = new FormData();
    uploadData.append('office_id', id);
    uploadData.append('image_1', photo, photo.name);

    return this.officeService.uploadPhoto(uploadData).pipe(
      catchError(error => {
        dispatch(new OfficeStateAction.UploadPhoto.Failure(error));

        throw error;
      }),
      tap(uploads => {
        dispatch(
          new OfficeStateAction.Update.Try(id, {
            pictures: uploads.map(upload => upload.url),
          }),
        );
      }),
      catchError(error => {
        dispatch(new OfficeStateAction.UploadPhoto.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new OfficeStateAction.UploadPhoto.Success(getState()));
      }),
    );
  }
}
