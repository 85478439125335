import { CheckIn } from '../building';
import { ParkingStateModel } from '../parking/parking.model';
import { Reservation } from '../reservations';

export class ParkingsStateModel {
  list!: ParkingStateModel[];
  reservations!: Reservation[];
  reservationsCurrentPage!: number;
  reservationsReachedElement!: number;
  reservationsReachedPage!: number;
  reservationsTotalElements!: number;
  reservationsTotalPages!: number;
  checkIn?: CheckIn;
  isViewColleaguesEnabled?: boolean;
}
