import { Selector } from '@ngxs/store';
import { CustomEntitiesSelectors } from './custom-entities';
import { CustomEntityStateModel } from './custom-entity';
import { DeskStateModel } from './desk';
import { DesksSelectors } from './desks';
import { EmployeeStateModel } from './employee';
import { EmployeesSelectors } from './employees';
import { GroupStateModel } from './group';
import { GroupsSelectors } from './groups';
import { ParkingStateModel } from './parking';
import { ParkingsSelectors } from './parkings';

export class SagaSelectors {
  @Selector([
    GroupsSelectors.list,
    EmployeesSelectors.list,
    CustomEntitiesSelectors.list,
    DesksSelectors.list,
    ParkingsSelectors.list,
  ])
  static isEveryAudienceReferencedInLocal(
    groups: GroupStateModel[],
    employees: EmployeeStateModel[],
    customEntities: CustomEntityStateModel[],
    desks: DeskStateModel[],
    parkings: ParkingStateModel[],
  ): boolean {
    return [
      ...groups.map(group => group.id),
      ...employees.map(employee => employee.id),
    ].every(audience =>
      [
        ...customEntities.map(customEntity => customEntity.audiences),
        ...desks.map(desk => desk.audiences),
        ...parkings.map(parking => parking.audiences),
      ]
        .flat()
        .includes(audience),
    );
  }
}
