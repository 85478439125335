// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';
import { OfficeStateModel } from '../office/office.model';

export namespace OfficesStateAction {
  export namespace Add {
    export class Try {
      static readonly type = '[Offices] try Add Office to List';
      constructor(public office: OfficeStateModel) {}
    }
    export class Success {
      static readonly type = '[Offices] then Add Office to List success';
      constructor(public offices: OfficeStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Offices] catch Add Office to List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Offices] try Read Offices List';
      constructor(public id: BuildingStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Offices] then Read Offices List success';
      constructor(public offices: OfficeStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Offices] catch Read Offices List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Remove {
    export class Try {
      static readonly type = '[Offices] try Remove Office from List';
      constructor(public id: OfficeStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Offices] then Remove Office from List success';
      constructor(public offices: OfficeStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Offices] catch Remove Office from List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Offices] try Update Office in List';
      constructor(public office: OfficeStateModel) {}
    }
    export class Success {
      static readonly type = '[Offices] then Update Office in List success';
      constructor(public offices: OfficeStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Offices] catch Update Office in List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
