import { Injectable } from '@angular/core';
import { StatsService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { formatISO } from 'date-fns';
import { catchError, tap } from 'rxjs/operators';
import { StatsStateAction } from './stats.actions';
import {
  mapActiveMembersToClient,
  mapDesksBookingsToClient,
  mapDesksOccupancyToClient,
} from './stats.helper';
import { StatsStateModel } from './stats.model';

@State<StatsStateModel>({
  name: 'stats',
  defaults: {
    desksOccupancy: {
      dates: [new Date()],
      members: [0],
      visitors: [0],
      capacities: [0],
      rates: [0],
    },
    activeMembers: {
      dates: [new Date()],
      value: 0,
      total: 0,
      actives: [0],
      inactives: [0],
    },
    deskBookings: {
      dates: [new Date()],
      showCheckInStats: false,
      avgBookings: 0,
      total: 0,
      checked: [0],
      lost: [0],
      canceled: [0],
      future: [0],
    },
  },
})
@Injectable()
export class StatsState {
  constructor(private readonly statsService: StatsService) {}

  @Action(StatsStateAction.Desks.Occupancy.Try)
  readOccupancy(
    { dispatch, getState, setState }: StateContext<StatsStateModel>,
    { start, end, buildings, groups, spaces }: StatsStateAction.Desks.Occupancy.Try,
  ) {
    return this.statsService
      .desksOccupancy({
        start: formatISO(start, { representation: 'date' }),
        end: formatISO(end, { representation: 'date' }),
        buildings,
        groups,
        spaces,
      })
      .pipe(
        catchError(error => {
          dispatch(new StatsStateAction.Desks.Occupancy.Failure(error));
          throw error;
        }),
        tap(desksOccupancy => {
          try {
            setState(
              patch({
                desksOccupancy: mapDesksOccupancyToClient(desksOccupancy),
              }),
            );

            dispatch(new StatsStateAction.Desks.Occupancy.Success(getState()));
          } catch (error) {
            dispatch(new StatsStateAction.Desks.Occupancy.Failure(error));

            throw error;
          }
        }),
      );
  }

  @Action(StatsStateAction.Desks.Bookings.Try)
  readBookings(
    { dispatch, getState, setState }: StateContext<StatsStateModel>,
    { start, end, buildings, groups, spaces }: StatsStateAction.Desks.Bookings.Try,
  ) {
    return this.statsService
      .desksBookings({
        start: formatISO(start, { representation: 'date' }),
        end: formatISO(end, { representation: 'date' }),
        buildings,
        groups,
        spaces,
      })
      .pipe(
        catchError(error => {
          dispatch(new StatsStateAction.Desks.Bookings.Failure(error));
          throw error;
        }),
        tap(desksBookings => {
          try {
            setState(
              patch({
                deskBookings: mapDesksBookingsToClient(desksBookings),
              }),
            );

            dispatch(new StatsStateAction.Desks.Bookings.Success(getState()));
          } catch (error) {
            dispatch(new StatsStateAction.Desks.Bookings.Failure(error));
            throw error;
          }
        }),
      );
  }

  @Action(StatsStateAction.Members.Actives.Try)
  readActiveMembers(
    { dispatch, getState, setState }: StateContext<StatsStateModel>,
    { start, end, buildings, groups, spaces }: StatsStateAction.Members.Actives.Try,
  ) {
    return this.statsService
      .activeMembers({
        start: formatISO(start, { representation: 'date' }),
        end: formatISO(end, { representation: 'date' }),
        buildings,
        groups,
        spaces,
      })
      .pipe(
        catchError(error => {
          dispatch(new StatsStateAction.Members.Actives.Failure(error));
          throw error;
        }),
        tap(desksBookings => {
          try {
            setState(
              patch({
                activeMembers: mapActiveMembersToClient(desksBookings),
              }),
            );

            dispatch(new StatsStateAction.Members.Actives.Success(getState()));
          } catch (error) {
            dispatch(new StatsStateAction.Members.Actives.Failure(error));
            throw error;
          }
        }),
      );
  }
}
