import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Parking } from './parking.type';

@Injectable({ providedIn: 'root' })
export class ParkingService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  create({ parking, space_id }: Parking.Create.Params): Observable<Parking.Create.Response> {
    return this.httpClient
      .post<Parking.Create.Response>(`${this.apiRootUrl}/parking`, { parking, space_id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete({ id }: Parking.Delete.Params): Observable<Parking.Delete.Response> {
    return this.httpClient
      .request<Parking.Delete.Response>('delete', `${this.apiRootUrl}/parking`, {
        body: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  generateName({
    space_id,
  }: Parking.GenerateName.Params): Observable<Parking.GenerateName.Response> {
    return this.httpClient
      .get<Parking.GenerateName.Response>(`${this.apiRootUrl}/parking/name`, {
        params: { space_id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read(id: Parking.Read.Params['id']): Observable<Parking.Read.Response> {
    return this.httpClient
      .get<Parking.Read.Response>(`${this.apiRootUrl}/parking`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(body: DeepPartial<Parking.Update.Body>): Observable<Parking.Update.Response> {
    return this.httpClient.put<Parking.Update.Response>(`${this.apiRootUrl}/parking`, body).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }
}
