// tslint:disable: no-namespace

import { Building } from '../building';
import { Employee } from '../employee';

export namespace Visitors {
  export namespace Reservations {
    export namespace ExportCsv {
      export type Body = {
        building_id: Building.Read.Response['id'];
        from: number;
        to: number;
      };
      export type Response = Blob;
    }

    export namespace Read {
      export type Params = {
        building_id: Building.Read.Response['id'];
        elements: ReturnType<Response['_metadata']['page_elements']['toString']>;
        page: ReturnType<Response['_metadata']['page']['toString']>;
      };
      export type Response = {
        _links: { last: string; self: string; first: string; next: string; previous: string };
        _metadata: {
          page: number;
          page_elements: number;
          total_elements: number;
          total_pages: number;
        };
        data: Array<{
          date: string;
          employee: Pick<Employee.Read.Response, 'email' | 'id' | 'name' | 'pic'>;
          status: InvitationStatus;
          visitor: {
            email: string;
            name: string;
            note: string;
          };
          checkin?: string;
          checkout?: string;
        }>;
      };
    }
  }

  export namespace Settings {
    export namespace Read {
      export type Params = {
        id: Building.Read.Response['id'];
      };
      export type Response = {
        id: Building.Read.Response['id'];
        visitors: {
          assign_custom_entity: boolean;
          assign_desk: boolean;
          assign_parking: boolean;
          enabled: boolean;
          wifi: {
            mode: 'code' | 'password' | 'phone' | 'social';
            ssid: string;
            password?: string;
          };
        };
      };
    }
    export namespace Update {
      export type Params = Read.Response;
      export type Response = {
        status: boolean;
      };
    }
  }
}

export type InvitationStatus = keyof typeof InvitationStatuses;

export const enum InvitationStatuses {
  active = 'active',
  cancel = 'cancel',
  cancelled = 'cancelled',
  check_cancel = 'check_cancel',
  check = 'check',
  company_cancelled = 'company_cancelled',
  deleted = 'deleted',
  ended = 'ended',
  idle = 'idle',
  lost = 'lost',
}
