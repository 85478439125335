import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Desk } from './desk.type';

@Injectable({ providedIn: 'root' })
export class DeskService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  create({ desk, space_id }: Desk.Create.Params): Observable<Desk.Create.Response> {
    return this.httpClient
      .post<Desk.Create.Response>(`${this.apiRootUrl}/desk`, { desk, space_id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete({ id }: Desk.Delete.Params): Observable<Desk.Delete.Response> {
    return this.httpClient
      .request<Desk.Delete.Response>('delete', `${this.apiRootUrl}/desk`, {
        body: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  generateName({ space_id }: Desk.GenerateName.Params): Observable<Desk.GenerateName.Response> {
    return this.httpClient
      .get<Desk.GenerateName.Response>(`${this.apiRootUrl}/desk/name`, {
        params: { space_id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read(id: Desk.Read.Params['id']): Observable<Desk.Read.Response> {
    return this.httpClient
      .get<Desk.Read.Response>(`${this.apiRootUrl}/desk`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(body: DeepPartial<Desk.Update.Body>): Observable<Desk.Update.Response> {
    return this.httpClient.put<Desk.Update.Response>(`${this.apiRootUrl}/desk`, body).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }
}
