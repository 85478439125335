import { Selector } from '@ngxs/store';
import { StatsStateModel } from './stats.model';
import { StatsState } from './stats.state';

export class StatsSelectors {
  @Selector([StatsState])
  static desksOccupancy({ desksOccupancy }: StatsStateModel): StatsStateModel['desksOccupancy'] {
    return desksOccupancy;
  }
  @Selector([StatsState])
  static activeMembers({ activeMembers }: StatsStateModel): StatsStateModel['activeMembers'] {
    return activeMembers;
  }
  @Selector([StatsState])
  static deskBookings({ deskBookings }: StatsStateModel): StatsStateModel['deskBookings'] {
    return deskBookings;
  }
  @Selector([StatsState])
  static dates({ deskBookings }: StatsStateModel): Date[] {
    return deskBookings.dates;
  }
}
