import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filenameFromContentDisposition } from '@business/helpers';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Desks } from './desks.type';

@Injectable({ providedIn: 'root' })
export class DesksService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  deleteReservations(
    body: Desks.Reservations.Delete.Body,
  ): Observable<Desks.Reservations.Delete.Response> {
    return this.httpClient
      .post<Desks.Reservations.Delete.Response>(
        `${this.apiRootUrl}/building/desks/reservations/cancel`,
        body,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reservations(
    params: Desks.Reservations.Read.Params,
  ): Observable<Desks.Reservations.Read.Response> {
    return this.httpClient
      .get<Desks.Reservations.Read.Response>(`${this.apiRootUrl}/building/desks/reservations`, {
        params,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reservationsExportCsv({
    building_id,
    from,
    to,
  }: Desks.Reservations.ExportCsv.Body): Observable<{
    fileContent: string | Blob;
    fileName: string;
    fileType: string;
  }> {
    return this.httpClient
      .post(
        `${this.apiRootUrl}/building/desks/reservations/export`,
        { building_id, from, to },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
        map(({ body, headers }) => ({
          fileContent: body || '',
          fileName: filenameFromContentDisposition(headers) ?? `${building_id}_${from}_${to}.csv`,
          fileType: headers.get('content-type') || 'text/csv;charset=utf-8',
        })),
      );
  }

  settings(id: Desks.Settings.Read.Params['id']): Observable<Desks.Settings.Read.Response> {
    return this.httpClient
      .get<Desks.Settings.Read.Response>(`${this.apiRootUrl}/building/desks`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSettings(body: Desks.Settings.Update.Body): Observable<Desks.Settings.Update.Response> {
    return this.httpClient
      .put<Desks.Settings.Update.Response>(`${this.apiRootUrl}/building/desks`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
