import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanySelectors } from '@business/store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bus-subscription-banner',
  styleUrls: ['subscription-banner.component.scss'],
  templateUrl: 'subscription-banner.component.html',
})
export class SubscriptionBannerComponent {
  @Select(CompanySelectors.subscriptionRemainingDaysToExpiration)
  subscriptionRemainingDaysToExpiration$!: Observable<number>;
  @Select(CompanySelectors.subscriptionStatus) subscriptionStatus$!: Observable<string | undefined>;
}
