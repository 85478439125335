import { CompanySelectors, CompanyStateModel, SubscriptionStatuses } from '@business/store';
import { Selector } from '@ngxs/store';
import { AuthSelectors } from '@nibol/auth';
import { AppShell, FrontendySelectors } from '@nibol/shared';

export class AppShellSelectors {
  @Selector([CompanySelectors.subscription])
  static appShellAuthClasses(subscription: CompanyStateModel['subscription']): string[] {
    return [
      subscription?.name
        ? `bus-subscription-${
            subscription.name.toLowerCase().replace(/\./g, '-').replace(/\_/g, '-') ?? ''
          }`
        : '',
      subscription?.status
        ? `bus-subscription-${
            subscription.status.toLowerCase().replace(/\./g, '-').replace(/\_/g, '-') ?? ''
          }`
        : '',
    ];
  }

  @Selector([AuthSelectors.isAuthenticated, FrontendySelectors.shell])
  static showAuthShell(isAuthenticated: boolean, shell: AppShell | undefined): boolean {
    return isAuthenticated && (typeof shell === 'undefined' || shell === 'auth');
  }

  @Selector([CompanySelectors.subscription])
  static showSubscriptionBanner(subscription: CompanyStateModel['subscription']): boolean {
    return (
      subscription?.status === SubscriptionStatuses.ended ||
      subscription?.status === SubscriptionStatuses.free_trial ||
      subscription?.status === SubscriptionStatuses.grace
    );
  }
}
