// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { DeliveriesStateModel } from './deliveries.model';

export namespace DeliveriesStateAction {
  export namespace List {
    export namespace Read {
      export class Try {
        static readonly type = '[Deliveries] try Read Deliveries List';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Deliveries] then Read Deliveries List success';
        constructor(public deliveries: DeliveriesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Deliveries] catch Read Deliveries List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Settings {
    export namespace Read {
      export class Try {
        static readonly type = '[Deliveries] try Read Deliveries Settings';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Deliveries] then Read Deliveries Settings success';
        constructor(
          public deliveries: DeliveriesStateModel,
          public buildingId: BuildingStateModel['id'],
        ) {}
      }
      export class Failure {
        static readonly type = '[Deliveries] catch Read Deliveries Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Deliveries] try Update Deliveries Settings';
        constructor(
          public buildingId: BuildingStateModel['id'],
          public data: DeepPartial<DeliveriesStateModel>,
        ) {}
      }
      export class Success {
        static readonly type = '[Deliveries] then Update Deliveries Settings success';
        constructor(
          public deliveries: DeliveriesStateModel,
          public buildingId: BuildingStateModel['id'],
        ) {}
      }
      export class Failure {
        static readonly type = '[Deliveries] catch Update Deliveries Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
