import { Selector } from '@ngxs/store';
import { VisitorsStateModel } from './visitors.model';
import { VisitorsState } from './visitors.state';

export class VisitorsSelectors {
  @Selector([
    VisitorsSelectors.reservationsReachedElement,
    VisitorsSelectors.reservationsTotalElements,
  ])
  static hasFurtherReservations(
    reservationsReachedElement: number,
    reservationsTotalElements: number,
  ): boolean {
    return reservationsReachedElement < reservationsTotalElements;
  }

  @Selector([VisitorsState])
  static isEnabled({ isEnabled }: VisitorsStateModel): VisitorsStateModel['isEnabled'] {
    return isEnabled;
  }

  @Selector([VisitorsState])
  static isAssignedCustomEntityEnabled({
    isAssignedCustomEntityEnabled,
  }: VisitorsStateModel): VisitorsStateModel['isAssignedCustomEntityEnabled'] {
    return isAssignedCustomEntityEnabled;
  }

  @Selector([VisitorsState])
  static isAssignedDeskEnabled({
    isAssignedDeskEnabled,
  }: VisitorsStateModel): VisitorsStateModel['isAssignedDeskEnabled'] {
    return isAssignedDeskEnabled;
  }

  @Selector([VisitorsState])
  static isAssignedParkingEnabled({
    isAssignedParkingEnabled,
  }: VisitorsStateModel): VisitorsStateModel['isAssignedParkingEnabled'] {
    return isAssignedParkingEnabled;
  }

  @Selector([VisitorsState])
  static reservations({ reservations }: VisitorsStateModel): VisitorsStateModel['reservations'] {
    return reservations;
  }

  @Selector([VisitorsState])
  static reservationsReachedElement({
    reservationsReachedElement,
  }: VisitorsStateModel): VisitorsStateModel['reservationsReachedElement'] {
    return reservationsReachedElement;
  }

  @Selector([VisitorsState])
  static reservationsTotalElements({
    reservationsTotalElements,
  }: VisitorsStateModel): VisitorsStateModel['reservationsTotalElements'] {
    return reservationsTotalElements;
  }

  @Selector([VisitorsState])
  static wifi({ wifi }: VisitorsStateModel): VisitorsStateModel['wifi'] {
    return wifi;
  }
}
