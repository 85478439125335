// tslint:disable: no-namespace

import { DeepPartial } from '@nibol/shared';
import { Building } from '../building';
import { CustomEntity } from '../custom-entity';
import { Employee } from '../employee';
import { Office } from '../office';
import { Space } from '../space';

export namespace CustomEntities {
  export namespace Reservations {
    export namespace ExportCsv {
      export type Body = {
        building_id: Building.Read.Response['id'];
        from: number;
        to: number;
      };
      export type Response = Blob;
    }

    export namespace Read {
      export type Params = {
        building_id: Building.Read.Response['id'];
        elements: ReturnType<Response['_metadata']['page_elements']['toString']>;
        page: ReturnType<Response['_metadata']['page']['toString']>;
      };
      export type Response = {
        _links: { last: string; self: string; first: string; next: string; previous: string };
        _metadata: {
          page: number;
          page_elements: number;
          total_elements: number;
          total_pages: number;
        };
        data: Array<{
          id: string;
          time: { day: number; from: number | null; to: number | null };
          building: Pick<Building.Read.Response, 'id' | 'name'>;
          office: Pick<Office.Read.Response, 'id' | 'name'>;
          space: Pick<Space.Read.Response, 'id' | 'name' | 'pictures'>;
          custom_entity: Pick<CustomEntity.Read.Response, 'id' | 'mapEntityCategory' | 'name'>;
          user: Pick<Employee.Read.Response, 'email' | 'id' | 'name'>;
        }>;
      };
    }
  }

  export namespace Settings {
    export namespace Read {
      export type Params = {
        id: Response['id'];
      };
      export type Response = {
        id: Building.Read.Response['id'];
        custom_entities: {
          custom_amenities: [];
          map_show_users: boolean;
          reservation_checkin: boolean;
          reservation_checkin_settings: {
            before_from: number;
            after_from: number;
            missed_policy: 'keep' | 'lost';
            nearby_distance: number | null;
          };
        };
      };
    }
    export namespace Update {
      export type Body = Pick<Read.Response, 'id'> & {
        custom_entities: DeepPartial<Read.Response['custom_entities']>;
      };
      export type Response = {
        status: boolean;
      };
    }
  }
}
