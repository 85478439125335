import { Room } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { RoomStateModel } from './room.model';
import { CompanionClientStatuses } from './room.type';

export function mapRoomToApi({
  active,
  amenities,
  calendarEmail,
  coordinates,
  id,
  name,
  pictures,
  seats,
}: DeepPartial<RoomStateModel>): DeepPartial<Room.Update.Body> {
  return {
    active,
    amenities,
    calendar_email: calendarEmail,
    coordinates,
    id,
    name,
    picture: pictures?.[0],
    seats,
  };
}

export function mapRoomToClient({
  active,
  amenities,
  calendar_email,
  coordinates,
  id,
  name,
  picture,
  seats,
}: Room.Read.Response): RoomStateModel {
  return {
    active,
    amenities,
    calendarEmail: calendar_email ?? undefined,
    coordinates,
    id,
    name,
    pictures: picture ? [picture] : [],
    seats,
  };
}

export function mapCompanionClientsToClient(
  companionClients: Room.Companion.Clients.Response,
): NonNullable<RoomStateModel['companionClients']> {
  return companionClients.map(companionClient => ({
    id: companionClient.id,
    name: companionClient.name,
    status: companionClient.expired
      ? CompanionClientStatuses.expired
      : CompanionClientStatuses.connected,
  }));
}
