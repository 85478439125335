import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filenameFromContentDisposition } from '@business/helpers';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomEntities } from './custom-entities.type';

@Injectable({ providedIn: 'root' })
export class CustomEntitiesService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  reservations(
    params: CustomEntities.Reservations.Read.Params,
  ): Observable<CustomEntities.Reservations.Read.Response> {
    return this.httpClient
      .get<CustomEntities.Reservations.Read.Response>(
        `${this.apiRootUrl}/building/custom-entities/reservations`,
        {
          params,
        },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reservationsExportCsv({
    building_id,
    from,
    to,
  }: CustomEntities.Reservations.ExportCsv.Body): Observable<{
    fileContent: string | Blob;
    fileName: string;
    fileType: string;
  }> {
    return this.httpClient
      .post(
        `${this.apiRootUrl}/building/custom-entities/reservations/export`,
        { building_id, from, to },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
        map(({ body, headers }) => ({
          fileContent: body || '',
          fileName: filenameFromContentDisposition(headers) ?? `${building_id}_${from}_${to}.csv`,
          fileType: headers.get('content-type') || 'text/csv;charset=utf-8',
        })),
      );
  }

  settings(
    id: CustomEntities.Settings.Read.Params['id'],
  ): Observable<CustomEntities.Settings.Read.Response> {
    return this.httpClient
      .get<CustomEntities.Settings.Read.Response>(`${this.apiRootUrl}/building/custom-entities`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSettings(
    body: CustomEntities.Settings.Update.Body,
  ): Observable<CustomEntities.Settings.Update.Response> {
    return this.httpClient
      .put<CustomEntities.Settings.Update.Response>(
        `${this.apiRootUrl}/building/custom-entities`,
        body,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
