import { Space } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { SpaceStateModel } from './space.model';
import { SpaceTypes } from './space.type';

export function mapSpaceToApi(space: DeepPartial<SpaceStateModel>): Space.Update.Body {
  return {
    ...(space.customEntitiesAmount
      ? { mapEntities: [{ category: space.categoryId, count: space.customEntitiesAmount }] }
      : {}),
    ...(space.desksAmount || space.parkingsAmount || space.roomsAmount
      ? {
          map_entities: {
            desks: space.desksAmount,
            parkings: space.parkingsAmount,
            rooms: space.roomsAmount,
          },
        }
      : {}),
    amenities_desk: space.deskAmenities,
    amenities_parking: space.parkingAmenities,
    amenities_room: space.roomAmenities,
    description: space.description,
    id: space.id,
    map: space.map,
    name: space.name,
    max_capacity: space.maxCapacity,
    pictures: space.pictures,
  };
}

export function mapSpaceToClient(
  space: DeepPartial<Space.Read.Response>,
): DeepPartial<SpaceStateModel> {
  return {
    categoryId: space.mapEntities?.[0]?.category,
    customEntitiesAmount: space.mapEntities?.find(
      mapEntity =>
        typeof mapEntity.category !== 'undefined' &&
        !['desks', 'rooms', 'parkings'].includes(mapEntity.category),
    )?.count,
    description: space.description,
    deskAmenities: space.amenities_desk,
    desksAmount: space.map_entities?.desks,
    id: space.id,
    map: space.map,
    maxCapacity: space.max_capacity ?? undefined,
    name: space.name,
    parkingAmenities: space.amenities_parking,
    parkingsAmount: space.map_entities?.parkings,
    pictures: space.pictures,
    roomAmenities: space.amenities_room,
    roomsAmount: space.map_entities?.rooms,
    type: mapSpaceTypeToClient(space),
  };
}

// tslint:disable-next-line: cyclomatic-complexity
export function mapSpaceToCreateApi(
  space: Partial<SpaceStateModel> & Pick<SpaceStateModel, 'name' | 'type'>,
  category: string | null,
): Space.Create.Body['space'] {
  const type = space.type === SpaceTypes.map ? 'map' : 'open';
  const mapEntities = {
    ...(space.type === SpaceTypes.openCustomEntity ? { custom_entities: 0 } : {}),
    ...(space.type === SpaceTypes.openDesk ? { desks: 0 } : {}),
    ...(space.type === SpaceTypes.openParking ? { parkings: 0 } : {}),
  };

  return {
    // tslint:disable-next-line: no-nested-ternary
    ...(type === 'open'
      ? category !== null
        ? { mapEntities: [{ category, count: 0, defaultAmenities: [] }] }
        : { map_entities: mapEntities }
      : {}),
    name: space.name,
    type,
  };
}

export function mapSpaceTypeToClient(
  space: DeepPartial<Space.Read.Response>,
): SpaceStateModel['type'] {
  if (space.type === 'map') {
    return SpaceTypes.map;
  }

  if (typeof space.map_entities?.parkings !== 'undefined') {
    return SpaceTypes.openParking;
  }

  if (
    typeof space.mapEntities?.find(
      mapEntity =>
        typeof mapEntity.category !== 'undefined' &&
        !['desks', 'rooms', 'parkings'].includes(mapEntity.category),
    ) !== 'undefined'
  ) {
    return SpaceTypes.openCustomEntity;
  }

  return SpaceTypes.openDesk;
}
