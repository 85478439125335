import { Selector } from '@ngxs/store';
import { ParkingsStateModel } from './parkings.model';
import { ParkingsState } from './parkings.state';

export class ParkingsSelectors {
  @Selector([ParkingsState])
  static checkIn({ checkIn }: ParkingsStateModel): ParkingsStateModel['checkIn'] {
    return checkIn;
  }

  @Selector([ParkingsSelectors.reachedElement, ParkingsSelectors.totalElements])
  static hasFurtherReservations(reachedElement: number, totalElements: number): boolean {
    return reachedElement < totalElements;
  }

  @Selector([ParkingsState])
  static isViewColleaguesEnabled({
    isViewColleaguesEnabled,
  }: ParkingsStateModel): ParkingsStateModel['isViewColleaguesEnabled'] {
    return isViewColleaguesEnabled;
  }

  @Selector([ParkingsState])
  static list({ list }: ParkingsStateModel): ParkingsStateModel['list'] {
    return list;
  }

  @Selector([ParkingsState])
  static reservations({ reservations }: ParkingsStateModel): ParkingsStateModel['reservations'] {
    return reservations;
  }

  @Selector([ParkingsState])
  static reachedElement({
    reservationsReachedElement,
  }: ParkingsStateModel): ParkingsStateModel['reservationsReachedElement'] {
    return reservationsReachedElement;
  }

  @Selector([ParkingsState])
  static totalElements({
    reservationsTotalElements,
  }: ParkingsStateModel): ParkingsStateModel['reservationsTotalElements'] {
    return reservationsTotalElements;
  }
}
