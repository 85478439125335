import { Injectable } from '@angular/core';
import { BuildingService } from '@business/api/building';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { catchError, switchMapTo, tap } from 'rxjs/operators';
import { CustomEntitiesCategoriesState, CustomEntitiesState } from '../custom-entities';
import { DeliveriesState } from '../deliveries';
import { DesksState } from '../desks';
import { OfficesState } from '../offices';
import { ParkingsState } from '../parkings';
import { RoomsState } from '../rooms';
import { SafeState } from '../safe';
import { VisitorsState } from '../visitors';
import { BuildingStateAction } from './building.actions';
import { mapBuildingToApi, mapBuildingToClient } from './building.helper';
import { BuildingStateModel } from './building.model';

@State<BuildingStateModel>({
  name: 'building',
  children: [
    CustomEntitiesCategoriesState,
    CustomEntitiesState,
    DeliveriesState,
    DesksState,
    OfficesState,
    ParkingsState,
    RoomsState,
    SafeState,
    VisitorsState,
  ],
})
@Injectable()
export class BuildingState {
  constructor(private readonly buildingService: BuildingService, private readonly store: Store) {}

  @Action(BuildingStateAction.Create.Try)
  create(
    { dispatch }: StateContext<BuildingStateModel>,
    { address, coordinates, name, timezone }: BuildingStateAction.Create.Try,
  ) {
    return this.buildingService.create({ name, position: { address, coordinates, timezone } }).pipe(
      catchError(error => {
        dispatch(new BuildingStateAction.Create.Failure(error));

        throw error;
      }),
      tap(({ id }) => {
        dispatch(new BuildingStateAction.Create.Success({ address, coordinates, id, name }));
      }),
    );
  }

  @Action(BuildingStateAction.Delete.Try)
  delete(
    { dispatch, getState }: StateContext<BuildingStateModel>,
    { id }: BuildingStateAction.Delete.Try,
  ) {
    return this.buildingService.delete(id).pipe(
      catchError(error => {
        dispatch(new BuildingStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        await dispatch(new BuildingStateAction.Delete.Success(getState())).toPromise();
        dispatch(new StateReset(BuildingState));
      }),
    );
  }

  @Action(BuildingStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<BuildingStateModel>,
    { id }: BuildingStateAction.Read.Try,
  ) {
    return this.store
      .selectOnce(state =>
        (state.company.buildings as BuildingStateModel[]).find(building => building.id === id),
      )
      .pipe(
        tap(building => {
          if (building) {
            setState(patch({ ...building, features: building.features }));
          }
        }),
        switchMapTo(this.buildingService.read(id)),
        catchError(error => {
          dispatch(new BuildingStateAction.Read.Failure(error));

          throw error;
        }),
        tap(building => {
          setState(patch(mapBuildingToClient(building)));

          dispatch(new BuildingStateAction.Read.Success(getState()));
        }),
      );
  }

  @Action(BuildingStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<BuildingStateModel>,
    { id, data, options: { skipSetState } }: BuildingStateAction.Update.Try,
  ) {
    return this.buildingService.update(id, mapBuildingToApi(data)).pipe(
      catchError(error => {
        dispatch(new BuildingStateAction.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        if (!skipSetState) {
          setState(patch({ ...data, id }));
        }

        dispatch(new BuildingStateAction.Update.Success(getState()));
      }),
    );
  }
}
