import { Injectable } from '@angular/core';
import { DeliveriesService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { DeliveriesStateAction } from './deliveries.actions';
import {
  mapDeliveriesListToClient,
  mapDeliveriesSettingsToApi,
  mapDeliveriesSettingsToClient,
} from './deliveries.helper';
import { DeliveriesStateModel } from './deliveries.model';

@State<DeliveriesStateModel>({
  name: 'deliveries',
  defaults: { frontDesks: [], isEnabled: false, list: [] },
})
@Injectable()
export class DeliveriesState {
  constructor(private readonly deliveriesService: DeliveriesService) {}

  @Action(DeliveriesStateAction.Settings.Read.Try)
  readSettings(
    { dispatch, getState, setState }: StateContext<DeliveriesStateModel>,
    { buildingId }: DeliveriesStateAction.Settings.Read.Try,
  ) {
    return this.deliveriesService.settings({ id: buildingId }).pipe(
      catchError(error => {
        dispatch(new DeliveriesStateAction.Settings.Read.Failure(error));

        throw error;
      }),
      tap(roomsSettings => {
        setState(patch(mapDeliveriesSettingsToClient(roomsSettings)));

        dispatch(new DeliveriesStateAction.Settings.Read.Success(getState(), buildingId));
      }),
    );
  }

  @Action(DeliveriesStateAction.List.Read.Try)
  readList(
    { dispatch, getState, setState }: StateContext<DeliveriesStateModel>,
    { buildingId }: DeliveriesStateAction.List.Read.Try,
  ) {
    return this.deliveriesService.list({ building_id: buildingId }).pipe(
      catchError(error => {
        dispatch(new DeliveriesStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(deliveriesList => {
        setState(patch({ list: mapDeliveriesListToClient(deliveriesList) }));

        dispatch(new DeliveriesStateAction.List.Read.Success(getState()));
      }),
    );
  }

  @Action(DeliveriesStateAction.Settings.Update.Try)
  updateSettings(
    { dispatch, getState, setState }: StateContext<DeliveriesStateModel>,
    { buildingId: id, data }: DeliveriesStateAction.Settings.Update.Try,
  ) {
    return this.deliveriesService.updateSettings({ id, ...mapDeliveriesSettingsToApi(data) }).pipe(
      catchError(error => {
        dispatch(new DeliveriesStateAction.Settings.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data }));

        dispatch(new DeliveriesStateAction.Settings.Update.Success(getState(), id));
      }),
    );
  }
}
