import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IntegrationsSelectors, IntegrationsStateAction } from '@business/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { API_ROOT_URL, AuthSelectors } from '@nibol/auth';
import { openWindowPopup } from '@nibol/shared';
import { DialogService } from '@nibol/ui';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { GoogleWorkspaceDirectoryMessageEventData } from './google-workspace-directory-restore-integration-dialog.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bus-google-workspace-directory-restore-integration-dialog',
  styleUrls: ['google-workspace-directory-restore-integration-dialog.component.scss'],
  templateUrl: 'google-workspace-directory-restore-integration-dialog.component.html',
})
export class GoogleWorkspaceDirectoryRestoreIntegrationDialogComponent
  implements AfterViewInit, OnInit {
  @Dispatch() failGoogleWorkspaceDirectoryConnection = () =>
    new IntegrationsStateAction.GoogleWorkspaceDirectory.Connect.Failure(null);
  @Dispatch() navigateToDirectory = () => new Navigate(['/members/directory']);
  @Dispatch() readGoogleWorkspaceDirectorySettings = () =>
    new IntegrationsStateAction.GoogleWorkspaceDirectory.Read.Try();
  @Dispatch() syncGoogleWorkspaceDirectory = () =>
    new IntegrationsStateAction.GoogleWorkspaceDirectory.Sync.Try();

  @Select(IntegrationsSelectors.isGoogleWorkspaceDirectoryAuthNegated)
  isGoogleWorkspaceDirectoryAuthNegated$!: Observable<boolean>;

  @SelectSnapshot(IntegrationsSelectors.googleWorkspaceDirectoryIntegrationLink)
  googleWorkspaceDirectoryIntegrationLink!: string;
  @SelectSnapshot(AuthSelectors.tenant) tenant!: string;

  @ViewChild('confirmRestorationContent') confirmRestorationContent?: TemplateRef<HTMLDivElement>;

  isInProgress$ = new BehaviorSubject(false);
  tabIndex$ = new BehaviorSubject(0);

  private googleWorkspaceDirectoryPopup: Window | null = null;

  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly dialogService: DialogService,
  ) {}

  ngAfterViewInit(): void {
    this.manageGoogleWorkspaceDirectoryAuthNegation();
  }

  ngOnInit(): void {
    this.manageGoogleWorkspaceDirectoryPopupMessages();
  }

  cancel(): void {
    this.dialogService.dismiss();
  }

  confirmMembersSync(): void {
    this.syncGoogleWorkspaceDirectory();
    this.dialogService.dismiss();
    this.navigateToDirectory();
  }

  confirmRestoration(): void {
    this.isInProgress$.next(true);

    this.googleWorkspaceDirectoryPopup = openWindowPopup(
      this.googleWorkspaceDirectoryIntegrationLink,
      400,
      550,
      () => {
        this.isInProgress$.next(false);
      },
    );
  }

  private manageGoogleWorkspaceDirectoryAuthNegation() {
    this.isGoogleWorkspaceDirectoryAuthNegated$
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(isGoogleWorkspaceDirectoryAuthNegated => {
        if (isGoogleWorkspaceDirectoryAuthNegated) {
          this.dialogService.open('', this.confirmRestorationContent);
        }
      });
  }

  private manageGoogleWorkspaceDirectoryPopupMessages(): void {
    fromEvent<MessageEvent<GoogleWorkspaceDirectoryMessageEventData>>(window, 'message')
      .pipe(
        filter(
          ({ origin }) =>
            origin ===
            new URL(this.apiRootUrl.replace('https://api', `https://${this.tenant}`)).origin,
        ),
        untilDestroyed(this),
      )
      .subscribe(({ data: { status } }) => {
        this.googleWorkspaceDirectoryPopup?.close();
        this.isInProgress$.next(false);

        if (status) {
          this.readGoogleWorkspaceDirectorySettings();
          this.tabIndex$ = new BehaviorSubject(1);
        } else {
          this.failGoogleWorkspaceDirectoryConnection();
        }
      });
  }
}
