import { Building } from '@business/api';
import { DeepPartial, mapTimeNumberToTimeString } from '@nibol/shared';
import { formatISO } from 'date-fns';
import { BuildingStateModel } from './building.model';
import { WeekDay } from './building.type';

export function mapBuildingToApi(
  building: DeepPartial<BuildingStateModel>,
): DeepPartial<Building.Update.Params> {
  return {
    ...mapPositionToApi(building.address, building.coordinates, building.timezone),
    ...((building.openingTimes || building.specialClosingDays) && {
      settings: {
        ...mapOpeningTimesToApi(building.openingTimes),
        ...mapClosingsToApi(building.specialClosingDays),
      },
    }),
    id: building.id,
    name: building.name,
  };
}

export function mapBuildingToClient({
  id,
  name,
  position,
  settings,
}: DeepPartial<Building.Read.Response>): DeepPartial<BuildingStateModel> {
  return {
    address: position?.address,
    coordinates: position?.coordinates,
    specialClosingDays: settings?.closings?.map(closing => ({
      ...(closing.to ? { to: new Date(closing.to) } : {}),
      ...(closing.from ? { from: new Date(closing.from) } : {}),
    })),
    id,
    name,
    openingTimes: {
      interval: {
        from: mapTimeNumberToTimeString(settings?.availability_opening_time?.from || 0),
        to: mapTimeNumberToTimeString(settings?.availability_opening_time?.to || 0),
      },
      weekdays: settings?.availability_weekdays?.map(
        weekday => (['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as WeekDay[])[weekday],
      ),
    },
    timezone: position?.timezone,
  };
}

function mapClosingsToApi(
  closings: DeepPartial<BuildingStateModel['specialClosingDays']>,
): DeepPartial<Pick<Building.Update.Params['settings'], 'closings'>> {
  return closings
    ? {
        closings: closings.map(closing => ({
          ...(closing.to instanceof Date ? { to: formatISO(closing.to) } : {}),
          ...(closing.from instanceof Date ? { from: formatISO(closing.from) } : {}),
        })),
      }
    : {};
}

function mapOpeningTimesToApi(
  openingTimes: DeepPartial<BuildingStateModel['openingTimes']>,
): DeepPartial<
  Pick<Building.Update.Params['settings'], 'availability_opening_time' | 'availability_weekdays'>
> {
  return {
    ...(openingTimes?.weekdays && {
      availability_weekdays: openingTimes.weekdays.map(weekday =>
        ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(weekday),
      ),
    }),
    ...((openingTimes?.interval?.from || openingTimes?.interval?.to) && {
      availability_opening_time: {
        from: mapTimeToApi(openingTimes.interval.from),
        to: mapTimeToApi(openingTimes.interval.to),
      },
    }),
  };
}

function mapPositionToApi(
  address: BuildingStateModel['address'],
  coordinates: DeepPartial<BuildingStateModel['coordinates']>,
  timezone: BuildingStateModel['timezone'],
): DeepPartial<Pick<Building.Update.Params, 'position'>> {
  return address || coordinates || timezone ? { position: { address, coordinates, timezone } } : {};
}

function mapTimeToApi(time: string | undefined): number | undefined {
  return time ? parseInt(time.split(':').join(''), 10) : undefined;
}
