import { RoomStateModel } from '../room';
import {
  GoogleWorkspaceDirectorySettings,
  GoogleWorkspaceRoomsSettings,
  KisiSettings,
  Microsoft365RoomsSettings,
  MicrosoftAzureActiveDirectorySettings,
  OktaDirectorySettings,
  SlackSettings,
  SofiaSettings,
} from './integrations.type';

export class IntegrationsStateModel {
  remoteRooms!: Array<RoomStateModel & Required<Pick<RoomStateModel, 'calendarEmail'>>>;
  rooms!: RoomStateModel[];
  googleWorkspaceDirectory?: Partial<GoogleWorkspaceDirectorySettings>;
  googleWorkspaceRooms?: Partial<GoogleWorkspaceRoomsSettings>;
  kisi?: Partial<KisiSettings>;
  sofia?: Partial<SofiaSettings>;
  microsoft365Rooms?: Partial<Microsoft365RoomsSettings>;
  microsoftAzureActiveDirectory?: Partial<MicrosoftAzureActiveDirectorySettings>;
  oktaDirectory?: Partial<OktaDirectorySettings>;
  slack?: Partial<SlackSettings>;
}
