<ng-template #confirmRestorationContent>
  <nib-tab-group
    [selectedIndex]="tabIndex$ | async"
    class="bus-google-workspace-directory-restore-integration-dialog"
  >
    <nib-tab>
      <div class="nib-heading-1">{{ 'integrations_restoreintegration_title' | translate }}</div>
      <div class="nib-body">
        {{ 'integrations_restoreintegration_body' | translate }}
      </div>
      <div class="nib-dialog-actions">
        <div [fxLayout]="'row wrap'" [fxLayoutGap]="'16px grid'">
          <nib-button [clean]="true" (onClick)="cancel()">
            {{ 'integrations_restoreintegration_cancel' | translate }}
          </nib-button>
          <nib-button
            *nibLet="isInProgress$ | async as isInProgress"
            [color]="'primary'"
            [disabled]="isInProgress"
            (onClick)="confirmRestoration()"
          >
            <nib-icon *ngIf="isInProgress" [name]="'progress'"></nib-icon>
            {{ 'integrations_restoreintegration_confirm' | translate }}
          </nib-button>
        </div>
      </div>
    </nib-tab>
    <nib-tab>
      <div class="nib-heading-1">{{ 'integrations_restoremembers_title' | translate }}</div>
      <div class="nib-body">
        {{ 'integrations_restoremembers_body' | translate }}
      </div>
      <div class="nib-dialog-actions">
        <div [fxLayout]="'row wrap'" [fxLayoutGap]="'16px grid'">
          <nib-button [clean]="true" (onClick)="cancel()">
            {{ 'integrations_restoremembers_cancel' | translate }}
          </nib-button>
          <nib-button
            *nibLet="isInProgress$ | async as isInProgress"
            [color]="'primary'"
            [disabled]="isInProgress"
            (onClick)="confirmMembersSync()"
          >
            <nib-icon *ngIf="isInProgress" [name]="'progress'"></nib-icon>
            {{ 'integrations_restoremembers_confirm' | translate }}
          </nib-button>
        </div>
      </div>
    </nib-tab>
  </nib-tab-group>
</ng-template>
