// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { StoreOptions } from '@nibol/store';
import { SpaceStateModel } from '../space';
import { DeskStateModel } from './desk.model';

export namespace DeskStateAction {
  export namespace Create {
    export class Try {
      static readonly type = '[Desk] try Create Desk';
      constructor(
        public spaceId: SpaceStateModel['id'],
        public desk: Pick<DeskStateModel, 'coordinates'> &
          Pick<DeskStateModel, 'type'> &
          DeepPartial<Pick<DeskStateModel, 'active' | 'amenities' | 'audiences' | 'name'>>,
      ) {}
    }
    export class Success {
      static readonly type = '[Desk] then Create Desk success';
      constructor(public desk: DeskStateModel) {}
    }
    export class Failure {
      static readonly type = '[Desk] catch Create Desk failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Desk] try Delete Desk';
      constructor(public id: DeskStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Desk] then Delete Desk success';
      constructor(public desk: DeskStateModel) {}
    }
    export class Failure {
      static readonly type = '[Desk] catch Delete Desk failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Desk] try Read Desk';
      constructor(public id: DeskStateModel['id'], public options?: StoreOptions) {}
    }
    export class Success {
      static readonly type = '[Desk] then Read Desk success';
      constructor(public desk: DeskStateModel) {}
    }
    export class Failure {
      static readonly type = '[Desk] catch Read Desk failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Desk] try Update Desk';
      constructor(
        public id: DeskStateModel['id'],
        public data: DeepPartial<
          Pick<DeskStateModel, 'active' | 'amenities' | 'audiences' | 'coordinates' | 'name'>
        >,
      ) {}
    }
    export class Success {
      static readonly type = '[Desk] then Update Desk success';
      constructor(public desk: DeskStateModel) {}
    }
    export class Failure {
      static readonly type = '[Desk] catch Update Desk failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
