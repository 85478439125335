// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';

export namespace BuildingsStateAction {
  export namespace Add {
    export class Try {
      static readonly type = '[Buildings] try Add Building to List';
      constructor(public building: BuildingStateModel) {}
    }
    export class Success {
      static readonly type = '[Buildings] then Add Building to List success';
      constructor(public buildings: BuildingStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Buildings] catch Add Building to List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Buildings] try Read Buildings List';
    }
    export class Success {
      static readonly type = '[Buildings] then Read Buildings List success';
      constructor(public buildings: BuildingStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Buildings] catch Read Buildings List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Remove {
    export class Try {
      static readonly type = '[Buildings] try Remove Building from List';
      constructor(public id: BuildingStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Buildings] then Remove Building from List success';
      constructor(public buildings: BuildingStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Buildings] catch Remove Building from List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Buildings] try Update Building in List';
      constructor(public building: BuildingStateModel) {}
    }
    export class Success {
      static readonly type = '[Buildings] then Update Building in List success';
      constructor(public buildings: BuildingStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Buildings] catch Update Building in List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
