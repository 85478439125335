import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { environment } from '@business/environment';

@Injectable({ providedIn: 'root' })
export class RedirectGuard implements CanActivate {
  @Dispatch() navigateToPath = (path: string) => new Navigate([path], {});

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    window.location.href = environment.app50;
    return false;
  }
}
