// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { OfficeStateModel } from '../office';
import { SpaceStateModel } from './space.model';
import { SpaceType } from './space.type';

export namespace SpaceStateAction {
  export namespace Create {
    export class Try {
      static readonly type = '[Space] try Create Space';
      constructor(
        public officeId: OfficeStateModel['id'],
        public name: SpaceStateModel['name'],
        public type: SpaceType,
        public category: string | null,
      ) {}
    }
    export class Success {
      static readonly type = '[Space] then Create Space success';
      constructor(public space: SpaceStateModel) {}
    }
    export class Failure {
      static readonly type = '[Space] catch Create Space failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Space] try Delete Space';
      constructor(public id: SpaceStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Space] then Delete Space success';
      constructor(public space: SpaceStateModel) {}
    }
    export class Failure {
      static readonly type = '[Space] catch Delete Space failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Space] try Read Space';
      constructor(public id: SpaceStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Space] then Read Space success';
      constructor(public space: SpaceStateModel) {}
    }
    export class Failure {
      static readonly type = '[Space] catch Read Space failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Space] try Update Space';
      constructor(public id: SpaceStateModel['id'], public data: DeepPartial<SpaceStateModel>) {}
    }
    export class Success {
      static readonly type = '[Space] then Update Space success';
      constructor(public space: SpaceStateModel) {}
    }
    export class Failure {
      static readonly type = '[Space] catch Update Space failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace UploadMap {
    export class Try {
      static readonly type = '[Space] try Upload Map to Space';
      constructor(public id: SpaceStateModel['id'], public map: File) {}
    }
    export class Success {
      static readonly type = '[Space] then Upload Map to Space success';
      constructor(public space: SpaceStateModel) {}
    }
    export class Failure {
      static readonly type = '[Space] catch Upload Map to Space failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace UploadPhoto {
    export class Try {
      static readonly type = '[Space] try Upload Photo to Space';
      constructor(public id: SpaceStateModel['id'], public photo: File) {}
    }
    export class Success {
      static readonly type = '[Space] then Upload Photo to Space success';
      constructor(public space: SpaceStateModel) {}
    }
    export class Failure {
      static readonly type = '[Space] catch Upload Photo to Space failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
