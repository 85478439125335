import { CustomEntitiesCategoryStateModel, CustomEntitiesStateModel } from '../custom-entities';
import { DeliveriesStateModel } from '../deliveries';
import { DesksStateModel } from '../desks';
import { OfficeStateModel } from '../office';
import { ParkingsStateModel } from '../parkings';
import { RoomsStateModel } from '../rooms';
import { SafeStateModel } from '../safe';
import { VisitorsStateModel } from '../visitors';
import { BuildingFeatures, Coordinates, OpeningTimes, SpecialClosingDays } from './building.type';

export class BuildingStateModel {
  id!: string;
  address?: string;
  coordinates?: Coordinates;
  customentitiescategories?: CustomEntitiesCategoryStateModel[];
  customentities?: CustomEntitiesStateModel;
  deliveries?: DeliveriesStateModel;
  desks?: DesksStateModel;
  features?: { [buildingFeature in BuildingFeatures]?: boolean };
  name?: string;
  offices?: OfficeStateModel[];
  openingTimes?: OpeningTimes;
  parkings?: ParkingsStateModel;
  rooms?: RoomsStateModel;
  safe?: SafeStateModel;
  specialClosingDays?: SpecialClosingDays;
  timezone?: string;
  visitors?: VisitorsStateModel;
}
