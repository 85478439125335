import { Email } from '@business/api';
import { EmailStateModel } from './email.model';

export function mapEmailToClient(email: Partial<Email.Read.Response>): EmailStateModel {
  return {
    content: email.content?.sort((previous, current) => previous.order - current.order),
    footer: email.footer,
    header: email.header,
    id: email.key || '',
    isDefault: email.isDefault,
    lastUpdateDate: email.editor?.date,
    lastUpdateUser: email.editor?.user,
    params: email.params?.map(param => ({
      defaultValue: param.default_value,
      name: param.name,
    })),
    style: email.style,
    title: email.title,
    trigger: email.trigger,
  };
}
