// tslint:disable: no-namespace

import { DeepPartial } from '@nibol/shared';
import { Building } from '../building';
import { Employee } from '../employee';
import { Office } from '../office';
import { Room } from '../room';
import { Space } from '../space';

export namespace Rooms {
  export namespace Reservations {
    export namespace ExportCsv {
      export type Body = {
        building_id: Building.Read.Response['id'];
        from: number;
        to: number;
      };
      export type Response = Blob;
    }

    export namespace Read {
      export type Params = {
        building_id: Building.Read.Response['id'];
        elements: ReturnType<Response['_metadata']['page_elements']['toString']>;
        page: ReturnType<Response['_metadata']['page']['toString']>;
      };
      export type Response = {
        _links: { last: string; self: string; first: string; next: string; previous: string };
        _metadata: {
          page: number;
          page_elements: number;
          total_elements: number;
          total_pages: number;
        };
        data: Array<{
          attendees: Array<Pick<Employee.Read.Response, 'email' | 'id' | 'name' | 'pic'>>;
          building: Pick<Building.Read.Response, 'id' | 'name'>;
          end: string;
          id: string;
          office: Pick<Office.Read.Response, 'id' | 'name'>;
          room: Pick<Room.Read.Response, 'id' | 'name'>;
          space: Pick<Space.Read.Response, 'id' | 'name' | 'pictures'>;
          start: string;
          time: { day: number; from: number | null; to: number | null };
          organizer: Pick<Employee.Read.Response, 'email' | 'id' | 'name' | 'pic'> | null;
        }>;
      };
    }
  }

  export namespace Settings {
    export namespace Read {
      export type Params = {
        id: Response['id'];
      };
      export type Response = {
        id: Building.Read.Response['id'];
        rooms: {
          custom_amenities: [];
          reservation_checkin: boolean;
          reservation_checkin_settings: {
            before_from: number;
            after_from: number;
            missed_policy: 'keep' | 'lost';
          };
        };
      };
    }
    export namespace Update {
      export type Body = Pick<Read.Response, 'id'> & { rooms: DeepPartial<Read.Response['rooms']> };
      export type Response = { status: boolean };
    }
  }
}
