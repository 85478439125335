import { EmailContent, EmailCustomParam } from './email.type';

export class EmailStateModel {
  id!: string;
  content?: EmailContent[];
  footer?: string;
  header?: string;
  isDefault?: boolean;
  lastUpdateDate?: string;
  lastUpdateUser?: string;
  params?: EmailCustomParam[];
  style?: string;
  title?: string;
  trigger?: string;
}
