import { Office } from '@business/api';
import {
  mapReservationsOfficeTimeCheckToApi,
  mapReservationsOfficeTimeCheckToClient,
} from '@business/helpers';
import { DeepPartial } from '@nibol/shared';
import { OfficeStateModel } from './office.model';

export function mapOfficeToApi({
  address,
  coordinates,
  floor,
  id,
  name,
  pictures,
  settings,
}: DeepPartial<OfficeStateModel>): DeepPartial<Office.Update.Params> {
  return {
    id,
    name,
    pictures,
    position: { additional: floor, address, coordinates },
    settings: {
      ...mapReservationsOfficeTimeCheckToApi(settings?.reservations_office_time_check),
      reservations_office_minimum: settings?.reservations_office_minimum,
    },
  };
}

export function mapOfficeToClient({
  id,
  name,
  pictures,
  position,
  settings,
}: DeepPartial<Office.Read.Response>): DeepPartial<OfficeStateModel> {
  return {
    address: position?.address,
    coordinates: position?.coordinates,
    floor: position?.additional,
    id,
    name,
    pictures,
    settings: {
      ...mapReservationsOfficeTimeCheckToClient(settings?.reservations_office_time_check),
      reservations_office_minimum: settings?.reservations_office_minimum,
    },
  };
}
