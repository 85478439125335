import { Injectable } from '@angular/core';
import { SurveyService } from '@business/api';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { LanguageName, TranslationSelectors } from '@nibol/translation';
import { catchError, tap } from 'rxjs/operators';
import { SurveyStateAction } from './survey.actions';
import {
  mapSurveyQuestionsToClient,
  mapSurveyToClient,
  mapSurveyTriggersToClient,
} from './survey.helper';
import { SurveyStateModel } from './survey.model';

@State<SurveyStateModel>({
  name: 'survey',
  defaults: { id: '', questions: [], triggers: [] },
})
@Injectable()
export class SurveyState {
  @SelectSnapshot(TranslationSelectors.currentLanguageName) currentLanguageName!: LanguageName;

  constructor(private readonly surveyService: SurveyService) {}

  @Action(SurveyStateAction.Questions.Try)
  questions(
    { dispatch, getState, setState }: StateContext<SurveyStateModel>,
    { id }: SurveyStateAction.Questions.Try,
  ) {
    return this.surveyService.questions(id).pipe(
      catchError(error => {
        dispatch(new SurveyStateAction.Questions.Failure(error));

        throw error;
      }),
      tap(survey => {
        setState(
          patch<SurveyStateModel>({
            ...mapSurveyToClient(survey),
            questions: mapSurveyQuestionsToClient(survey.questions, this.currentLanguageName),
          }),
        );

        dispatch(new SurveyStateAction.Questions.Success(getState()));
      }),
    );
  }

  @Action(SurveyStateAction.Triggers.Try)
  triggers(
    { dispatch, getState, setState }: StateContext<SurveyStateModel>,
    { id }: SurveyStateAction.Triggers.Try,
  ) {
    return this.surveyService.triggers(id).pipe(
      catchError(error => {
        dispatch(new SurveyStateAction.Triggers.Failure(error));

        throw error;
      }),
      tap(triggers => {
        setState(
          patch<SurveyStateModel>({ triggers: mapSurveyTriggersToClient(triggers) }),
        );

        dispatch(new SurveyStateAction.Triggers.Success(getState()));
      }),
    );
  }
}
