import { Reservation } from '../reservations';
import { Wifi } from './visitors.type';

export class VisitorsStateModel {
  isEnabled!: boolean;
  reservations!: Reservation[];
  reservationsCurrentPage!: number;
  reservationsReachedElement!: number;
  reservationsReachedPage!: number;
  reservationsTotalElements!: number;
  reservationsTotalPages!: number;
  isAssignedCustomEntityEnabled?: boolean;
  isAssignedDeskEnabled?: boolean;
  isAssignedParkingEnabled?: boolean;
  wifi?: Wifi;
}
