import { HttpHeaders } from '@angular/common/http';

export function filenameFromContentDisposition(headers: HttpHeaders): string | undefined {
  return headers
    ?.get('content-disposition')
    ?.split(';')[1]
    .split('filename')[1]
    .split('=')[1]
    .replace(/"/g, '')
    .trim();
}
