import { SpaceStateModel } from '../space';
import { Coordinates } from './office.type';

export class OfficeStateModel {
  id!: string;
  address?: string;
  coordinates?: Coordinates;
  floor?: string;
  name?: string;
  pictures?: string[];
  seats?: number;
  settings?: OfficeSettings;
  spaces?: SpaceStateModel[];
}

export type OfficeSettings = {
  reservations_office_minimum: number;
  reservations_office_time_check?: string;
};
