import { Stats } from '@business/api';

export function mapDesksOccupancyToClient(desksOccupancy: Stats.Desks.Occupancy.Response) {
  return {
    dates: desksOccupancy.date.map(date => new Date(date)),
    members: desksOccupancy.employees_desk_bookings,
    visitors: desksOccupancy.visitors_desk_bookings,
    capacities: desksOccupancy.desks_number,
    rates: desksOccupancy.occupancy_rate,
  };
}

export function mapDesksBookingsToClient(desksBookings: Stats.Desks.Bookings.Response) {
  return {
    dates: desksBookings.date.map(date => new Date(date)),
    avgBookings: desksBookings.avgBookings,
    total: desksBookings.total,
    checked: desksBookings.checked,
    lost: desksBookings.lost,
    canceled: desksBookings.canceled,
    future: desksBookings.future,
    showCheckInStats: desksBookings.showCheckInStats,
  };
}

export function mapActiveMembersToClient(activeMembers: Stats.Members.Actives.Response) {
  return {
    dates: activeMembers.date.map(date => new Date(date)),
    value: activeMembers.value,
    total: activeMembers.total,
    actives: activeMembers.actives,
    inactives: activeMembers.inactives,
  };
}
