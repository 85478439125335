// tslint:disable: no-namespace max-classes-per-file

import { SurveyStateModel } from './survey.model';

export namespace SurveyStateAction {
  export namespace Questions {
    export class Try {
      static readonly type = '[Survey] try Read Survey Questions';
      constructor(public id: SurveyStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Survey] then Read Survey Questions success';
      constructor(public survey: SurveyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Survey] catch Read Survey Questions failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Triggers {
    export class Try {
      static readonly type = '[Survey] try Read Survey Triggers';
      constructor(public id: SurveyStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Survey] then Read Survey Triggers success';
      constructor(public survey: SurveyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Survey] catch Read Survey Triggers failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
