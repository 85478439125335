export type Version = keyof typeof Versions;

// tslint:disable-next-line: prefer-const-enum
export enum Versions {
  '2.4.0' = '2.4.0',
  '2.5.0' = '2.5.0',
  '2.6.0' = '2.6.0',
  '2.7.0' = '2.7.0',
  '2.7.2' = '2.7.2',
  '2.8.0' = '2.8.0',
  '2.9.0' = '2.9.0',
  '2.9.1' = '2.9.1',
  '2.9.2' = '2.9.2',
  '2.10.0' = '2.10.0',
  '3.0.0' = '3.0.0',
  '3.1.0' = '3.1.0',
  '3.1.1' = '3.1.1',
  '3.2.0' = '3.2.0',
  '3.2.1' = '3.2.1',
  '3.2.2' = '3.2.2',
  '3.2.3' = '3.2.3',
  '3.3.0' = '3.3.0',
  '3.3.1' = '3.3.1',
  '3.4.0' = '3.4.0',
  '3.5.0' = '3.5.0',
  '3.5.1' = '3.5.1',
  '3.6.0' = '3.6.0',
  '3.6.1' = '3.6.1',
  '3.7.0' = '3.7.0',
  '3.7.1' = '3.7.1',
  '3.8.0' = '3.8.0',
  '3.9.0' = '3.9.0',
  '3.10.0' = '3.10.0',
  '3.11.0' = '3.11.0',
  '3.12.0' = '3.12.0',
  '3.13.0' = '3.13.0',
  '3.14.0' = '3.14.0',
  '3.15.0' = '3.15.0',
  '4.0.0' = '4.0.0',
  '4.0.1' = '4.0.1',
  '4.1.0' = '4.1.0',
  '4.2.0' = '4.2.0',
  '4.3.0' = '4.3.0',
  '4.3.1' = '4.3.1',
}
