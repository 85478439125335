// tslint:disable: no-namespace max-classes-per-file

import { EmailStateModel } from './email.model';
import { EmailContent } from './email.type';

export namespace EmailStateAction {
  export namespace Read {
    export class Try {
      static readonly type = '[Email] try Read Email';
      constructor(public id: EmailStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Email] then Read Email success';
      constructor(public email: EmailStateModel) {}
    }
    export class Failure {
      static readonly type = '[Email] catch Read Email failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Reset {
    export class Try {
      static readonly type = '[Email] try Reset Email';
      constructor(public id: EmailStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Email] then Reset Email success';
      constructor(public email: EmailStateModel) {}
    }
    export class Failure {
      static readonly type = '[Email] catch Reset Email failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Test {
    export class Try {
      static readonly type = '[Email] try Test Email';
      constructor(public id: EmailStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Email] then Test Email success';
      constructor(public email: EmailStateModel) {}
    }
    export class Failure {
      static readonly type = '[Email] catch Test Email failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Email] try Update Email';
      constructor(
        public id: EmailStateModel['id'],
        public content: Array<Pick<EmailContent, 'content' | 'order'>>,
      ) {}
    }
    export class Success {
      static readonly type = '[Email] then Update Email success';
      constructor(public email: EmailStateModel) {}
    }
    export class Failure {
      static readonly type = '[Email] catch Update Email failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
