import { Deliveries } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { DeliveriesStateModel } from './deliveries.model';

export function mapDeliveriesListToClient(
  deliveries: Deliveries.Activities.Read.Response,
): DeliveriesStateModel['list'] {
  return deliveries.map(delivery => ({
    pickedUp: delivery.pickup_date ? new Date(delivery.pickup_date) : null,
    id: delivery.id,
    received: new Date(delivery.creation_date),
    frontDeskManager: {
      firstname: delivery.front_desk_manager.name,
      email: delivery.front_desk_manager.email,
      picture: delivery.front_desk_manager.pic,
    },
    recipient: {
      firstname: delivery.recipient.name,
      email: delivery.recipient.email,
      picture: delivery.recipient.pic,
    },
  }));
}

export function mapDeliveriesSettingsToApi(
  data: DeepPartial<DeliveriesStateModel>,
): DeepPartial<Deliveries.Settings.Update.Body> {
  return {
    deliveries: { accept_deliveries: data.isEnabled, users: data.frontDesks },
  };
}

export function mapDeliveriesSettingsToClient({
  deliveries: { accept_deliveries, users },
}: Deliveries.Settings.Read.Response): Pick<DeliveriesStateModel, 'frontDesks' | 'isEnabled'> {
  return { frontDesks: users, isEnabled: accept_deliveries };
}
