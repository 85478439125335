import { createSelector, Selector } from '@ngxs/store';
import { differenceInDays } from 'date-fns';
import { BuildingStateModel } from '../building';
import { CustomEntityStateModel } from '../custom-entity';
import { DeskStateModel } from '../desk';
import { EmployeesState, EmployeesStateModel } from '../employees';
import { OfficeStateModel } from '../office';
import { ParkingStateModel } from '../parking';
import { SpaceStateModel, SpaceTypes } from '../space';
import { CompanyProfileStateModel, CompanyStateModel } from './company.model';
import { CompanyState } from './company.state';
import {
  SpacesSettings,
  Subscription,
  SubscriptionFeature,
  SubscriptionStatus,
  SubscriptionStatuses,
} from './company.type';

export class CompanySelectors {
  @Selector([CompanyState])
  static areDesksAssignable({ settings }: CompanyStateModel): boolean {
    return settings?.map_desk_assigned || false;
  }

  @Selector([CompanyState])
  static availableDesks({
    availableDesks,
  }: CompanyStateModel): CompanyStateModel['availableDesks'] {
    return availableDesks || 0;
  }

  @Selector([CompanyState])
  static billing({ billing }: CompanyStateModel): CompanyStateModel['billing'] {
    return billing;
  }

  @Selector([CompanyState])
  static buildings({ buildings }: CompanyStateModel): CompanyStateModel['buildings'] {
    return buildings;
  }

  @Selector([CompanyState])
  static companyPolicy({ companyPolicy }: CompanyStateModel): CompanyStateModel['companyPolicy'] {
    return companyPolicy;
  }

  @Selector([CompanyState])
  static countries({ countries }: CompanyStateModel): CompanyStateModel['countries'] {
    return countries;
  }

  @Selector([CompanySelectors.buildings])
  static customEntities({ buildings }: CompanyStateModel): CustomEntityStateModel[] {
    return (buildings ?? []).flatMap(({ offices }) =>
      (offices ?? []).flatMap(({ spaces }) =>
        (spaces ?? []).flatMap(({ customEntities }) => customEntities ?? []),
      ),
    );
  }

  @Selector([CompanySelectors.buildings])
  static desks({ buildings }: CompanyStateModel): DeskStateModel[] {
    return (buildings ?? []).flatMap(({ offices }) =>
      (offices ?? []).flatMap(({ spaces }) => (spaces ?? []).flatMap(({ desks }) => desks ?? [])),
    );
  }

  @Selector([EmployeesState])
  static employees({ list }: EmployeesStateModel): EmployeesStateModel['list'] {
    return list;
  }

  static existsFeature(subscriptionFeature: SubscriptionFeature) {
    return createSelector(
      [CompanySelectors.subscription],
      (subscription: CompanyStateModel['subscription']): boolean =>
        typeof subscription?.features[subscriptionFeature] !== 'undefined',
    );
  }

  @Selector([CompanySelectors.buildings])
  static availableMapTypeSpace(
    buildings: CompanyStateModel['buildings'],
  ): Array<{ building: BuildingStateModel; office: OfficeStateModel; space: SpaceStateModel }> {
    const result: Array<{
      building: BuildingStateModel;
      office: OfficeStateModel;
      space: SpaceStateModel;
    }> = [];

    buildings?.forEach(building => {
      building.offices?.forEach(office => {
        office.spaces?.forEach(space => {
          if (space.type === 'map') {
            result.push({ building, office, space });
          }
        });
      });
    });

    return result;
  }

  @Selector([CompanyState])
  static fullname({ name }: CompanyStateModel): CompanyStateModel['name'] {
    return name;
  }

  @Selector([CompanySelectors.spaces])
  static hasAtLeastOneFloorplanSpace(spaces: SpaceStateModel[]): boolean {
    return spaces.filter(space => space?.type === SpaceTypes.map).length > 0;
  }

  @Selector([CompanyState])
  static hasBuildings({ buildings }: CompanyStateModel): boolean {
    return (buildings?.length && buildings.length > 0) || false;
  }

  @Selector([CompanySelectors.buildings])
  static hasBuildingsWithDeliveries(buildings: CompanyStateModel['buildings']): boolean {
    return buildings?.some(building => building.deliveries?.isEnabled) ?? false;
  }

  static hasFeature(subscriptionFeature: SubscriptionFeature) {
    return createSelector(
      [CompanySelectors.subscription],
      (subscription: CompanyStateModel['subscription']): boolean =>
        !!subscription?.features[subscriptionFeature],
    );
  }

  @Selector([CompanySelectors.subscriptionStatus])
  static hasInvalidSubscription(subscriptionStatus: SubscriptionStatus): boolean {
    return subscriptionStatus === SubscriptionStatuses.ended;
  }

  @Selector([CompanyState])
  static hasMultipleBuildings({ buildings }: CompanyStateModel): boolean {
    return (buildings?.length && buildings?.length > 1) || false;
  }

  @Selector([CompanySelectors.subscription])
  static hasUnlimitedMembers(subscription: CompanyStateModel['subscription']): boolean {
    return (
      isNaN(subscription?.availableMembersAmount ?? NaN) &&
      isNaN(subscription?.currentMembersAmount ?? NaN)
    );
  }

  @Selector([CompanySelectors.subscription])
  static isProPlan(subscription: CompanyStateModel['subscription']): boolean {
    return subscription?.name === 'pro';
  }

  @Selector([CompanyState])
  static isBookForAnotherEmployeeActive({ settings }: CompanyStateModel): boolean {
    return settings?.book_for_employee || false;
  }

  @Selector([CompanySelectors.settings])
  static isCompanyPolicyRequired(settings: CompanyStateModel['settings']): boolean {
    return settings?.require_accept_company_policy || false;
  }

  @Selector([CompanySelectors.settings])
  static isCustomTermsAndPrivacyRequired(settings: CompanyStateModel['settings']): boolean {
    return settings?.require_custom_terms_privacy || false;
  }

  @Selector([CompanySelectors.subscription])
  static isEnterprise(subscription: CompanyStateModel['subscription']): boolean {
    return subscription?.name === 'enterprise';
  }

  @Selector([CompanyState])
  static logo({ logo }: CompanyStateModel): CompanyStateModel['logo'] {
    return logo;
  }

  @Selector([CompanyState])
  static occupancyRate({ occupancyRate }: CompanyStateModel): CompanyStateModel['occupancyRate'] {
    return occupancyRate || 0;
  }

  @Selector([CompanySelectors.buildings])
  static offices({ buildings }: CompanyStateModel): OfficeStateModel[] {
    return (buildings ?? []).flatMap(({ offices }) => offices ?? []);
  }

  @Selector([CompanySelectors.buildings])
  static parkings({ buildings }: CompanyStateModel): ParkingStateModel[] {
    return (buildings ?? []).flatMap(({ offices }) =>
      (offices ?? []).flatMap(({ spaces }) =>
        (spaces ?? []).flatMap(({ parkings }) => parkings ?? []),
      ),
    );
  }

  @Selector([CompanyState])
  static profile({ domains, logo, name, settings }: CompanyStateModel): CompanyProfileStateModel {
    return { domains, logo, name, settings };
  }

  @Selector([CompanyState])
  static reservedDesks({ reservedDesks }: CompanyStateModel): CompanyStateModel['reservedDesks'] {
    return reservedDesks || 0;
  }

  @Selector([CompanyState])
  static settings({ settings }: CompanyStateModel): CompanyStateModel['settings'] {
    return settings;
  }

  @Selector([CompanySelectors.buildings])
  static spaces(buildings: CompanyStateModel['buildings']): SpaceStateModel[] {
    return (buildings ?? []).flatMap(({ offices }) =>
      (offices ?? []).flatMap(({ spaces }) => spaces ?? []),
    );
  }

  @Selector([CompanyState])
  static spacesSettings({
    spacesSettings,
  }: CompanyStateModel): CompanyStateModel['spacesSettings'] {
    return spacesSettings;
  }

  @Selector([CompanyState])
  static subscription({ subscription }: CompanyStateModel): CompanyStateModel['subscription'] {
    return subscription;
  }

  @Selector([CompanySelectors.subscription])
  static subscriptionStatus(
    subscription: CompanyStateModel['subscription'],
  ): Subscription['status'] | undefined {
    return subscription?.status;
  }

  @Selector([CompanySelectors.subscription])
  static subscriptionRemainingDaysToExpiration(
    subscription: CompanyStateModel['subscription'],
  ): number {
    return typeof subscription === 'undefined' || subscription.expirationDate === null
      ? -1
      : differenceInDays(subscription.expirationDate, Date.now());
  }

  @Selector([CompanyState])
  static surveys({ surveys }: CompanyStateModel): CompanyStateModel['surveys'] {
    return surveys;
  }

  @Selector([CompanyState])
  static terms({ terms }: CompanyStateModel): CompanyStateModel['terms'] {
    return terms;
  }
}
