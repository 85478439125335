import { Company, Office } from '@business/api';
import {
  CompanySettingsFeatures,
  CompanyStateModel,
  OfficeSettings,
  OfficeStateModel,
} from '@business/store';
import { mapTimeNumberToTimeString } from '@nibol/shared';

export function mapReservationsOfficeTimeCheckToApi(
  time?:
    | CompanySettingsFeatures['reservations_office_time_check']
    | OfficeSettings['reservations_office_time_check'],
):
  | Pick<
      Partial<Company.Info.Update.Body['settings']['features']>,
      'reservations_office_time_check'
    >
  | Pick<Partial<Office.Update.Params['settings']>, 'reservations_office_time_check'> {
  return typeof time === 'string'
    ? {
        reservations_office_time_check: parseInt(time.split(':').join(''), 10),
      }
    : {};
}

export function mapReservationsOfficeTimeCheckToClient(
  time?:
    | Company.Info.Read.Response['settings']['features']['reservations_office_time_check']
    | Office.Read.Response['settings']['reservations_office_time_check'],
):
  | Partial<Pick<NonNullable<CompanyStateModel['settings']>, 'reservations_office_time_check'>>
  | Partial<Pick<NonNullable<OfficeStateModel['settings']>, 'reservations_office_time_check'>> {
  return typeof time === 'number'
    ? { reservations_office_time_check: mapTimeNumberToTimeString(time) }
    : {};
}
