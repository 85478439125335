// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { StoreOptions } from '@nibol/store';
import { SpaceStateModel } from '../space';
import { RoomStateModel } from './room.model';

export namespace RoomStateAction {
  export namespace CompanionClientList {
    export class Try {
      static readonly type = '[Room] try Read Room Companion Clients';
      constructor(public id: RoomStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Room] then Read Room Companion Clients success';
      constructor(public room: RoomStateModel) {}
    }
    export class Failure {
      static readonly type = '[Room] catch Read Room Companion Clients failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Create {
    export class Try {
      static readonly type = '[Room] try Create Room';
      constructor(
        public spaceId: SpaceStateModel['id'],
        public room: Pick<RoomStateModel, 'coordinates'> &
          DeepPartial<
            Pick<RoomStateModel, 'active' | 'amenities' | 'calendarEmail' | 'name' | 'seats'>
          >,
      ) {}
    }
    export class Success {
      static readonly type = '[Room] then Create Room success';
      constructor(public room: RoomStateModel) {}
    }
    export class Failure {
      static readonly type = '[Room] catch Create Room failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Room] try Delete Room';
      constructor(public id: RoomStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Room] then Delete Room success';
      constructor(public room: RoomStateModel) {}
    }
    export class Failure {
      static readonly type = '[Room] catch Delete Room failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace PairingCode {
    export class Try {
      static readonly type = '[Room] try Read Room Pairing Code';
      constructor(public id: RoomStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Room] then Read Room Pairing Code success';
      constructor(public room: RoomStateModel, public pairingCode: string) {}
    }
    export class Failure {
      static readonly type = '[Room] catch Read Room Pairing Code failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any, public roomId: RoomStateModel['id']) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Room] try Read Room';
      constructor(public id: RoomStateModel['id'], public options?: StoreOptions) {}
    }
    export class Success {
      static readonly type = '[Room] then Read Room success';
      constructor(public room: RoomStateModel) {}
    }
    export class Failure {
      static readonly type = '[Room] catch Read Room failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Set {
    export class Try {
      static readonly type = '[Room] try Set Room';
      constructor(public room: RoomStateModel) {}
    }
  }

  export namespace UnpairAllClients {
    export class Try {
      static readonly type = '[Room] try Unpair all Room Devices ';
      constructor(public id: RoomStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Room] then Unpair all Room Devices success';
      constructor(public room: RoomStateModel) {}
    }
    export class Failure {
      static readonly type = '[Room] catch Unpair all Room Devices failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Room] try Update Room';
      constructor(
        public id: RoomStateModel['id'],
        public data: DeepPartial<
          Pick<
            RoomStateModel,
            'active' | 'amenities' | 'calendarEmail' | 'coordinates' | 'name' | 'pictures'
          >
        >,
      ) {}
    }
    export class Success {
      static readonly type = '[Room] then Update Room success';
      constructor(public room: RoomStateModel) {}
    }
    export class Failure {
      static readonly type = '[Room] catch Update Room failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace UploadPhoto {
    export class Try {
      static readonly type = '[Room] try Upload Photo to Room';
      constructor(public id: RoomStateModel['id'], public photo: File) {}
    }
    export class Success {
      static readonly type = '[Room] then Upload Photo to Room success';
      constructor(public room: RoomStateModel) {}
    }
    export class Failure {
      static readonly type = '[Room] catch Upload Photo to Room failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
