import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Space } from './space.type';

@Injectable({ providedIn: 'root' })
export class SpaceService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  create({ office_id, space }: Space.Create.Body): Observable<Space.Create.Response> {
    return this.httpClient
      .post<Space.Create.Response>(`${this.apiRootUrl}/space`, { office_id, space })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  customEntities(
    id: Space.CustomEntities.Params['space_id'],
  ): Observable<Space.CustomEntities.Response> {
    return this.httpClient
      .get<Space.CustomEntities.Response>(`${this.apiRootUrl}/custom-entity/map`, {
        params: { space_id: id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete(id: Space.Delete.Params['space_id']): Observable<Space.Delete.Response> {
    return this.httpClient
      .request<Space.Delete.Response>('delete', `${this.apiRootUrl}/space`, {
        body: { space_id: id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  desks(id: Space.Desks.Params['space_id']): Observable<Space.Desks.Response> {
    return this.httpClient
      .get<Space.Desks.Response>(`${this.apiRootUrl}/desk/map`, { params: { space_id: id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  parkings(id: Space.Parkings.Params['space_id']): Observable<Space.Parkings.Response> {
    return this.httpClient
      .get<Space.Parkings.Response>(`${this.apiRootUrl}/parking/map`, { params: { space_id: id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read(id: Space.Read.Params['space_id']): Observable<Space.Read.Response> {
    return this.httpClient
      .get<Space.Read.Response>(`${this.apiRootUrl}/space`, { params: { space_id: id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  rooms(params: Space.Rooms.Params): Observable<Space.Rooms.Response> {
    return this.httpClient
      .get<Space.Rooms.Response>(`${this.apiRootUrl}/room/map`, { params })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(
    id: Space.Update.Body['id'],
    data: DeepPartial<Space.Update.Body>,
  ): Observable<Space.Update.Response> {
    return this.httpClient
      .put<Space.Update.Response>(`${this.apiRootUrl}/space`, { ...data, id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  uploadMap(uploadData: Space.UploadMap.Params): Observable<Space.UploadMap.Response> {
    return this.httpClient
      .post<Space.UploadMap.Response>(`${this.apiRootUrl}/space/map/upload`, uploadData)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  uploadPhoto(uploadData: Space.UploadPhoto.Params): Observable<Space.UploadPhoto.Response> {
    return this.httpClient
      .post<Space.UploadPhoto.Response>(`${this.apiRootUrl}/space/pictures/upload`, uploadData)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
