import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filenameFromContentDisposition } from '@business/helpers';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Rooms } from './rooms.type';

@Injectable({ providedIn: 'root' })
export class RoomsService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  readSettings(params: Rooms.Settings.Read.Params): Observable<Rooms.Settings.Read.Response> {
    return this.httpClient
      .get<Rooms.Settings.Read.Response>(`${this.apiRootUrl}/building/rooms`, {
        params,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reservations(
    params: Rooms.Reservations.Read.Params,
  ): Observable<Rooms.Reservations.Read.Response> {
    return this.httpClient
      .get<Rooms.Reservations.Read.Response>(`${this.apiRootUrl}/building/rooms/reservations`, {
        params,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reservationsExportCsv({
    building_id,
    from,
    to,
  }: Rooms.Reservations.ExportCsv.Body): Observable<{
    fileContent: string | Blob;
    fileName: string;
    fileType: string;
  }> {
    return this.httpClient
      .post(
        `${this.apiRootUrl}/building/rooms/reservations/export`,
        { building_id, from, to },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
        map(({ body, headers }) => ({
          fileContent: body || '',
          fileName: filenameFromContentDisposition(headers) ?? `${building_id}_${from}_${to}.csv`,
          fileType: headers.get('content-type') || 'text/csv;charset=utf-8',
        })),
      );
  }

  updateSettings(body: Rooms.Settings.Update.Body): Observable<Rooms.Settings.Update.Response> {
    return this.httpClient
      .put<Rooms.Settings.Update.Response>(`${this.apiRootUrl}/building/rooms`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
