export class StatsStateModel {
  desksOccupancy!: {
    dates: Date[];
    members: number[];
    visitors: number[];
    capacities: number[];
    rates: number[];
  };
  activeMembers!: {
    dates: Date[];
    /**
     * count of active employees between dates range
     */
    value: number;
    /**
     * count of total employees between dates range
     */
    total: number;
    /**
     * active employees by date
     */
    actives: number[];
    /**
     * inactive employees by date
     */
    inactives: number[];
  };
  deskBookings!: {
    dates: Date[];
    /**
     * flag to hide stats about check-in if no building with check-in settings are selected
     */
    showCheckInStats: boolean;
    /**
     * avg desk bookings between dates range
     */
    avgBookings: number;
    /**
     * total desk bookings between dates range
     */
    total: number;
    /**
     * checked desk bookings by date
     */
    checked: number[];
    /**
     * lost desk bookings by date
     */
    lost: number[];
    /**
     * canceled (by user, by company, by routine...) desk bookings by date
     */
    canceled: number[];
    /**
     * future desk bookings by date (bookings with cancel status on BE)
     */
    future: number[];
  };
}
