export function mapDateToNumber(date: Date): number {
  const year = date.getFullYear();
  let day: string = date.getDate().toString();
  let month: string = (date.getMonth() + 1).toString();

  day = +day < 10 ? '0' + day : day;
  month = +month < 10 ? '0' + month : month;

  return parseInt(`${year}${month}${day}`, 10);
}
