import { createSelector, Selector } from '@ngxs/store';
import { BuildingsState } from '../buildings';
import { CompanyStateModel } from '../company';
import { OfficeStateModel } from '../office';
import { RoomStateModel } from '../room';
import { SpaceStateModel, SpaceTypes } from '../space';
import { BuildingStateModel } from './building.model';
import { BuildingState } from './building.state';
import { BuildingFeature } from './building.type';

export class BuildingSelectors {
  @Selector([BuildingState])
  static address({ address }: BuildingStateModel): BuildingStateModel['address'] {
    return address;
  }

  @Selector([BuildingState])
  static coordinates({ coordinates }: BuildingStateModel): BuildingStateModel['coordinates'] {
    return coordinates;
  }

  @Selector([BuildingState])
  static customentitiescategories({
    customentitiescategories,
  }: BuildingStateModel): BuildingStateModel['customentitiescategories'] {
    return customentitiescategories;
  }

  @Selector([BuildingState])
  static features({ features }: BuildingStateModel): BuildingStateModel['features'] {
    return features;
  }

  @Selector([BuildingState])
  static firstAvailableOffice({
    offices,
  }: BuildingStateModel): NonNullable<BuildingStateModel['offices']>[number] | undefined {
    return offices?.[0];
  }

  @Selector([BuildingState])
  static fullname({ name }: BuildingStateModel): BuildingStateModel['name'] {
    return name;
  }

  @Selector([BuildingState])
  static firstAvailableMapTypeSpace({ offices }: BuildingStateModel): SpaceStateModel | undefined {
    return offices?.flatMap(office => office.spaces).find(space => space?.type === SpaceTypes.map);
  }

  @Selector([BuildingState])
  static firstAvailableOfficeWithMapTypeSpace({
    offices,
  }: BuildingStateModel): OfficeStateModel | undefined {
    return offices?.find(office => office.spaces?.find(space => space.type === SpaceTypes.map));
  }

  @Selector([BuildingSelectors.specialClosingDays])
  static hasSpecialClosingDays(
    specialClosingDays: BuildingStateModel['specialClosingDays'],
  ): boolean {
    return (specialClosingDays?.length ?? 0) > 0;
  }

  @Selector([BuildingState])
  static id({ id }: BuildingStateModel): BuildingStateModel['id'] {
    return id;
  }

  static isFeatureEnabled(buildingFeature: BuildingFeature) {
    return createSelector(
      [BuildingSelectors.features, BuildingsState, BuildingSelectors.id],
      (
        features: BuildingStateModel['features'],
        buildings: BuildingStateModel[],
        id: BuildingStateModel['id'],
      ): boolean =>
        features?.[buildingFeature] ??
        buildings.find(building => building.id === id)?.features?.safe_c19 ??
        false,
    );
  }

  @Selector([BuildingSelectors.id, BuildingsState, BuildingState])
  static rooms(
    id: BuildingStateModel['id'],
    buildings: CompanyStateModel['buildings'],
    building: BuildingStateModel,
  ): Array<RoomStateModel & { buildingId: string; officeId: string; spaceId: string }> | undefined {
    return buildings
      ?.find(buildingFromList => buildingFromList.id === id)
      ?.offices?.flatMap(({ id: officeId, spaces }) =>
        (spaces ?? []).flatMap(({ id: spaceId, rooms }) =>
          (rooms ?? []).map(room => ({
            ...room,
            id:
              building.rooms?.list.find(roomFromList => roomFromList.id === room.id)?.id ?? room.id,
            buildingId: id,
            companionClients: building.rooms?.list.find(roomFromList => roomFromList.id === room.id)
              ?.companionClients,
            officeId,
            spaceId,
          })),
        ),
      );
  }

  @Selector([BuildingState])
  static specialClosingDays({
    specialClosingDays,
  }: BuildingStateModel): BuildingStateModel['specialClosingDays'] {
    return specialClosingDays;
  }

  @Selector([BuildingState])
  static timezone({ timezone }: BuildingStateModel): BuildingStateModel['timezone'] {
    return timezone;
  }
}
