// tslint:disable: no-namespace

import { DeepPartial } from '@nibol/shared';
import { LanguageCode } from '@nibol/translation';

export namespace Email {
  export namespace Read {
    export type Params = {
      key: string;
      default?: string;
    };
    export type Response = {
      category: string;
      content: Array<{ content: string; editable: boolean; order: number }>;
      editor: { date: string; user: string };
      footer: string;
      frame: string;
      header: string;
      isDefault: boolean;
      key: string;
      language: 'en' | 'es' | 'fr' | 'it';
      params: Array<{ default_value: string; name: string }>;
      style: string;
      subject: string;
      tag: string;
      title: string;
      trigger: string;
    };
  }

  export namespace Reset {
    export type Params = {
      key: string;
      language?: {
        api_code: 'en' | 'es' | 'fr' | 'it';
        code: LanguageCode;
        language: string;
      };
    };
    export type Response = Read.Response;
  }

  export namespace Test {
    export type Params = {
      key: string;
      language?: string;
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace Update {
    export type Params = Pick<Read.Response, 'key'> & DeepPartial<Pick<Read.Response, 'content'>>;
    export type Response = Read.Response;
  }
}
