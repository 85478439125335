import { Routes } from '@angular/router';
import { BuildingsGuard } from './buildings.guard';
import { MapGuard } from './map.guard';
import { OfficeGuard } from './office.guard';
import { SpaceGuard } from './space.guard';

export const redirectRoutes: Routes = [
  { path: 'building/general', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'building/offices', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'building/office', pathMatch: 'full', canActivate: [OfficeGuard], children: [] },
  { path: 'building/office/space', pathMatch: 'full', canActivate: [SpaceGuard], children: [] },
  { path: 'building/office/space/map', pathMatch: 'full', canActivate: [MapGuard], children: [] },
  { path: 'building/settings', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'building/visitors', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'custom-entities', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'deliveries', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'deliveries/settings', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'desks', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'desks/activities', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'desks/settings', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'parkings', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'parkings/activities', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'parkings/settings', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'rooms', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'rooms/activities', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'rooms/list', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'rooms/settings', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'safe', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'safe/settings', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
  { path: 'safe/contact-tracing', pathMatch: 'full', canActivate: [BuildingsGuard], children: [] },
];
