<div
  *nibLet="subscriptionRemainingDaysToExpiration$ | async as subscriptionRemainingDaysToExpiration"
  class="bus-subscription-banner"
>
  <span
    *nibLet="subscriptionStatus$ | async as subscriptionStatus"
    [ngPlural]="subscriptionRemainingDaysToExpiration"
  >
    <ng-template ngPluralCase="one">
      {{ 'subscription_banner_' + subscriptionStatus + '_singular' | translate }}
    </ng-template>
    <ng-template ngPluralCase="other">
      {{
        'subscription_banner_' + subscriptionStatus + '_plural'
          | translate: { days: subscriptionRemainingDaysToExpiration + 1 }
      }}
    </ng-template>
    <a
      [href]="'subscription_banner_' + subscriptionStatus + '_contactus_link' | translate"
      class="bus-subscription-banner-link"
    >
      {{ 'subscription_banner_' + subscriptionStatus + '_contactus' | translate }}
    </a>
  </span>
</div>
