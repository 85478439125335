<form [formGroup]="form" (submit)="add()">
  <div [fxLayout]="'column wrap'" [fxLayoutGap]="'16px grid'">
    <div [fxLayout]="'column wrap'" [fxLayoutGap]="'16px grid'">
      <nib-input
        [formControlName]="'name'"
        [fullWidth]="true"
        [label]="'inputs_text_building_name_label' | translate"
      ></nib-input>
      <nib-input
        [formControlName]="'address'"
        [fullWidth]="true"
        [label]="'inputs_text_building_address_label' | translate"
        [type]="'address'"
        (onChange)="
          latitude.value = $event.data?.location.latitude;
          longitude.value = $event.data?.location.longitude;
          timezone.value = $event.data?.timezone
        "
      ></nib-input>
    </div>
    <div hidden>
      <nib-input #latitude [formControlName]="'latitude'"></nib-input>
      <nib-input #longitude [formControlName]="'longitude'"></nib-input>
      <nib-input #timezone [formControlName]="'timezone'"></nib-input>
    </div>
    <div [fxLayout]="'row wrap'" [fxLayoutGap]="'16px grid'" class="nib-dialog-actions">
      <nib-button [color]="'tertiary'" (onClick)="cancel()">
        {{ 'building_adding_cancel' | translate }}
      </nib-button>
      <nib-button [color]="'primary'" [disabled]="!form.valid" [submit]="true">
        {{ 'building_adding_confirm' | translate }}
      </nib-button>
    </div>
  </div>
</form>
