import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filenameFromContentDisposition } from '@business/helpers';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Safe } from './safe.type';

@Injectable({ providedIn: 'root' })
export class SafeService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  contactTracingExportCsv({
    building_id,
    from,
    to,
    user_ids,
  }: Safe.ContactTracing.ExportCsv.Body): Observable<{
    fileContent: string | Blob;
    fileName: string;
    fileType: string;
  }> {
    return this.httpClient
      .post(
        `${this.apiRootUrl}/building/safe/tracing`,
        { building_id, from, to, user_ids },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
        map(({ body, headers }) => ({
          fileContent: body || '',
          fileName:
            filenameFromContentDisposition(headers) || `${from}_${to}_${user_ids.join('_')}.csv`,
          fileType: headers.get('content-type') || 'text/csv;charset=utf-8',
        })),
      );
  }

  settings({ id }: Safe.Settings.Read.Params): Observable<Safe.Settings.Read.Response> {
    return this.httpClient
      .get<Safe.Settings.Read.Response>(`${this.apiRootUrl}/building/safe`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSettings({
    id,
    safe,
  }: Safe.Settings.Update.Body): Observable<Safe.Settings.Update.Response> {
    return this.httpClient
      .put<Safe.Settings.Update.Response>(`${this.apiRootUrl}/building/safe`, {
        id,
        safe,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
