// tslint:disable: no-namespace max-classes-per-file

import { Roles } from '@nibol/auth';
import { BuildingStateModel } from '../building';
import { EmployeeStateModel } from './employee.model';

export namespace EmployeeStateAction {
  export namespace AssignBuildings {
    export class Try {
      static readonly type = '[Employee] try Assign Buildings to Employee';
      constructor(
        public id: EmployeeStateModel['id'],
        public buildingIds: Array<BuildingStateModel['id']>,
      ) {}
    }
    export class Success {
      static readonly type = '[Employee] then Assign Buildings to Employee success';
      constructor(public employee: EmployeeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Employee] catch Assign Buildings to Employee failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Create {
    export class Try {
      static readonly type = '[Employee] try Create Employee';
      constructor(
        public email: EmployeeStateModel['email'],
        public firstname: EmployeeStateModel['firstname'],
        public lastname: EmployeeStateModel['lastname'],
        public role: Roles.manager | Roles.user,
        public sendInvitation: boolean,
      ) {}
    }
    export class Success {
      static readonly type = '[Employee] then Create Employee success';
      constructor(public employee: EmployeeStateModel, public sendInvitation: boolean) {}
    }
    export class Failure {
      static readonly type = '[Employee] catch Create Employee failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Employee] try Delete Employee';
      constructor(public id: EmployeeStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Employee] then Delete Employee success';
      constructor(public employee: EmployeeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Employee] catch Delete Employee failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Invite {
    export class Try {
      static readonly type = '[Employee] try Invite Employee';
      constructor(public id: EmployeeStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Employee] then Invite Employee success';
      constructor(public employee: EmployeeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Employee] catch Invite Employee failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Employee] try Read Employee';
      constructor(public id: EmployeeStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Employee] then Read Employee success';
      constructor(public employee: EmployeeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Employee] catch Read Employee failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Employee] try Update Employee';
      constructor(
        public id: EmployeeStateModel['id'],
        public role: Roles.frontdesk | Roles.manager | Roles.user,
      ) {}
    }
    export class Success {
      static readonly type = '[Employee] then Update Employee success';
      constructor(public employee: EmployeeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Employee] catch Update Employee failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
