import { Injectable } from '@angular/core';
import { EmailService } from '@business/api';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EmailsSelectors } from '../emails';
import { EmailStateAction } from './email.actions';
import { mapEmailToClient } from './email.helper';
import { EmailStateModel } from './email.model';

@State<EmailStateModel>({
  name: 'email',
})
@Injectable()
export class EmailState {
  @Select(EmailsSelectors.list) emails$!: Observable<EmailStateModel[]>;

  constructor(private readonly emailService: EmailService) {}

  @Action(EmailStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<EmailStateModel>,
    { id }: EmailStateAction.Read.Try,
  ) {
    return this.emailService.read({ key: id }).pipe(
      catchError(error => {
        dispatch(new EmailStateAction.Read.Failure(error));

        throw error;
      }),
      tap(email => {
        setState(mapEmailToClient(email));

        dispatch(new EmailStateAction.Read.Success(getState()));
      }),
    );
  }

  @Action(EmailStateAction.Reset.Try)
  reset(
    { dispatch, getState, setState }: StateContext<EmailStateModel>,
    { id }: EmailStateAction.Reset.Try,
  ) {
    return this.emailService.reset({ key: id }).pipe(
      catchError(error => {
        dispatch(new EmailStateAction.Reset.Failure(error));

        throw error;
      }),
      tap(email => {
        setState(mapEmailToClient(email));

        dispatch(new EmailStateAction.Reset.Success(getState()));
      }),
    );
  }

  @Action(EmailStateAction.Test.Try)
  test({ dispatch, getState }: StateContext<EmailStateModel>, { id }: EmailStateAction.Test.Try) {
    return this.emailService.test({ key: id }).pipe(
      catchError(error => {
        dispatch(new EmailStateAction.Test.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          dispatch(new EmailStateAction.Test.Success(getState()));
        } else {
          dispatch(new EmailStateAction.Test.Failure(status));
        }
      }),
    );
  }

  @Action(EmailStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<EmailStateModel>,
    { id, content }: EmailStateAction.Update.Try,
  ) {
    return this.emailService.update({ key: id, content }).pipe(
      catchError(error => {
        dispatch(new EmailStateAction.Update.Failure(error));

        throw error;
      }),
      tap(email => {
        setState(mapEmailToClient(email));

        dispatch(new EmailStateAction.Update.Success(getState()));
      }),
    );
  }
}
