import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Group } from './group.type';

@Injectable({ providedIn: 'root' })
export class GroupService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  addMembers({ id, members }: Group.Members.Add.Params): Observable<Group.Members.Add.Response> {
    return this.httpClient
      .post<Group.Members.Add.Response>(`${this.apiRootUrl}/group/members`, { id, members })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  create(group: Group.Item.Create.Params): Observable<Group.Item.Create.Response> {
    return this.httpClient.post<Group.Item.Create.Response>(`${this.apiRootUrl}/group`, group).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  delete(id: Group.Item.Delete.Params['id']): Observable<Group.Item.Delete.Response> {
    return this.httpClient
      .request<Group.Item.Delete.Response>('delete', `${this.apiRootUrl}/group`, { body: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  members(id: Group.Members.Read.Params['id']): Observable<Group.Members.Read.Response> {
    return this.httpClient
      .get<Group.Members.Read.Response>(`${this.apiRootUrl}/group/members`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read(id: Group.Item.Read.Params['id']): Observable<Group.Item.Read.Response> {
    return this.httpClient
      .get<Group.Item.Read.Response>(`${this.apiRootUrl}/group`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  removeMembers({
    id,
    members,
  }: Group.Members.Remove.Params): Observable<Group.Members.Remove.Response> {
    return this.httpClient
      .request<Group.Members.Remove.Response>('delete', `${this.apiRootUrl}/group/members`, {
        body: { id, members },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(
    id: Group.Item.Update.Params['id'],
    data: DeepPartial<Omit<Group.Item.Update.Params, 'everyone' | 'members'>>,
  ): Observable<Group.Item.Update.Response> {
    return this.httpClient
      .put<Group.Item.Update.Response>(`${this.apiRootUrl}/group`, { ...data, id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
