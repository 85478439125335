import { Survey } from '@business/api';
import { mapSurveyToClient, SurveyStateModel } from '../survey';

export function mapSurveysToClient(surveys: Survey.List.Response): SurveyStateModel[] {
  return surveys.map(survey => ({
    ...mapSurveyToClient(survey),
    questions: Array(survey.questions_count),
    triggers: [],
  }));
}
