import { Selector } from '@ngxs/store';
import { CustomEntityStateModel } from './custom-entity.model';
import { CustomEntityState } from './custom-entity.state';

export class CustomEntitySelectors {
  @Selector([CustomEntityState])
  static amenities({ amenities }: CustomEntityStateModel): CustomEntityStateModel['amenities'] {
    return amenities;
  }

  @Selector([CustomEntityState])
  static category({ category }: CustomEntityStateModel): CustomEntityStateModel['category'] {
    return category;
  }

  @Selector([CustomEntityState])
  static coordinates({
    coordinates,
  }: CustomEntityStateModel): CustomEntityStateModel['coordinates'] {
    return coordinates;
  }

  @Selector([CustomEntityState])
  static audiences({ audiences }: CustomEntityStateModel): CustomEntityStateModel['audiences'] {
    return audiences;
  }

  @Selector([CustomEntityState])
  static id({ id }: CustomEntityStateModel): CustomEntityStateModel['id'] {
    return id;
  }

  @Selector([CustomEntityState])
  static isActive({ active }: CustomEntityStateModel): CustomEntityStateModel['active'] {
    return active;
  }

  @Selector([CustomEntitySelectors.audiences])
  static isAssigned(audiences: CustomEntityStateModel['audiences']): boolean {
    return audiences && audiences.length > 0 ? true : false;
  }

  @Selector([CustomEntityState])
  static fullname({ name }: CustomEntityStateModel): CustomEntityStateModel['name'] {
    return name;
  }

  @Selector([CustomEntityState])
  static type({ type }: CustomEntityStateModel): CustomEntityStateModel['type'] {
    return type;
  }
}
