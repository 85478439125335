import { Rooms } from '@business/api';
import { DeepPartial, mapTimeNumberToTimeString } from '@nibol/shared';
import { parse } from 'date-fns';
import { BuildingStateModel } from '../building';
import { mapIsFulldayReservation } from '../reservations';
import { RoomsStateModel } from './rooms.model';

export function mapRoomsReservationsToClient(
  reservations: Rooms.Reservations.Read.Response['data'],
): RoomsStateModel['reservations'] {
  return reservations.map(reservation => ({
    attendees: reservation.attendees.map(attendee => ({
      firstname: attendee?.name ?? '',
      email: attendee?.email ?? '',
    })),
    building: reservation.building,
    office: reservation.office,
    space: reservation.space,
    room: reservation.room,
    from: parse(
      `${reservation.time.day}${mapTimeNumberToTimeString(reservation.time.from || 0)}`,
      'yyyyMMddHH:mm',
      new Date(),
    ),
    id: reservation.id,
    isFullday: mapIsFulldayReservation(reservation.time),
    to: parse(
      `${reservation.time.day}${mapTimeNumberToTimeString(reservation.time.to || 0)}`,
      'yyyyMMddHH:mm',
      new Date(),
    ),
    user: reservation.organizer
      ? {
          firstname: reservation.organizer.name ?? '',
          email: reservation.organizer.email ?? '',
        }
      : null,
  }));
}

export function mapRoomsReservationsApiParams({
  buildingId,
  elements,
  page,
}: {
  buildingId: BuildingStateModel['id'];
  elements: RoomsStateModel['reservations']['length'];
  page: RoomsStateModel['reservationsCurrentPage'];
}): Rooms.Reservations.Read.Params {
  return { building_id: buildingId, elements: elements.toString(), page: page.toString() };
}

export function mapRoomsSettingsToApi(
  buildingId: BuildingStateModel['id'],
  data: DeepPartial<Pick<RoomsStateModel, 'checkIn'>>,
): Rooms.Settings.Update.Body {
  return {
    id: buildingId,
    rooms: {
      reservation_checkin_settings: {
        after_from: data.checkIn?.interval?.to,
        before_from: data.checkIn?.interval?.from,
        missed_policy: data.checkIn?.missingPolicy,
      },
      reservation_checkin: data.checkIn?.isEnabled,
    },
  };
}

export function mapRoomsSettingsToClient({
  rooms,
}: Rooms.Settings.Read.Response): Pick<RoomsStateModel, 'checkIn'> {
  return {
    checkIn: {
      isEnabled: rooms.reservation_checkin,
      interval: {
        from: rooms.reservation_checkin_settings?.before_from,
        to: rooms.reservation_checkin_settings?.after_from,
      },
      missingPolicy: rooms.reservation_checkin_settings?.missed_policy,
    },
  };
}
