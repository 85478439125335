import { Selector } from '@ngxs/store';
import { OfficeStateModel } from './office.model';
import { OfficeState } from './office.state';

export class OfficeSelectors {
  @Selector([OfficeState])
  static firstAvailableSpace({
    spaces,
  }: OfficeStateModel): NonNullable<OfficeStateModel['spaces']>[number] | undefined {
    return spaces?.[0];
  }

  @Selector([OfficeState])
  static fullname({ name }: OfficeStateModel): OfficeStateModel['name'] {
    return name;
  }

  @Selector([OfficeState])
  static id({ id }: OfficeStateModel): OfficeStateModel['id'] {
    return id;
  }

  @Selector([OfficeState])
  static photo({ pictures }: OfficeStateModel): string | undefined {
    return pictures?.[0];
  }
}
