import { Versions } from '@business/version';
import { FeatureToggle } from './feature-toggles.type';

export const versions: Record<Versions, Record<FeatureToggle, boolean>> = {
  [Versions['2.4.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: false,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: false,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: false,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.5.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: false,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: false,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: false,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.6.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: false,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: false,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.7.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: false,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.7.2']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: false,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.8.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: false,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.9.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.9.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.9.2']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['2.10.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.0.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.1.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: false,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.1.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.2.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: false,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.2.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: false,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: true,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.2.3']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.2.2']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: true,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: false,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.3.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.3.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.4.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.5.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: false,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: false,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: false,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.5.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: false,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.6.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.6.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.7.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.7.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.8.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: true,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.9.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: true,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.10.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: true,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.11.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: true,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.12.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: true,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.13.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: true,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: false,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.14.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: true,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: true,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['3.15.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: true,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: false,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['4.0.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: true,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: true,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['4.0.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: true,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: true,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['4.1.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: false,
    TOGGLE_ENABLE_DASHBOARD: false,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: true,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: true,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['4.2.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_DASHBOARD: true,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: false,
    TOGGLE_ENABLE_SOFIA: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: true,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: true,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['4.3.0']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_DASHBOARD: true,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: true,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: true,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: true,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
  [Versions['4.3.1']]: {
    TOGGLE_ENABLE_COMPANY_LIMITS: false,
    TOGGLE_ENABLE_CONTACT_TRACING: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: true,
    TOGGLE_ENABLE_CUSTOM_ENTITIES: true,
    TOGGLE_ENABLE_CUSTOM_TRANSACTIONAL_EMAILS: true,
    TOGGLE_ENABLE_DASHBOARD: true,
    TOGGLE_ENABLE_DELIVERIES: true,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: false,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_GOOGLE_WORKSPACE_DIRECTORY: true,
    TOGGLE_ENABLE_INTEGRATIONS: true,
    TOGGLE_ENABLE_KISI: true,
    TOGGLE_ENABLE_SOFIA: true,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MAP_ROOMS: false,
    TOGGLE_ENABLE_MEMBERS: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY_MAP_ENTITIES: true,
    TOGGLE_ENABLE_MICROSOFT_AZURE_ACTIVE_DIRECTORY: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_MULTIPLE_ASSIGNMENT: true,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_OKTA_MAP_ENTITIES: true,
    TOGGLE_ENABLE_OKTA: true,
    TOGGLE_ENABLE_PARKING: true,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_RESERVATIONS_LIMIT: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SAFE: false,
    TOGGLE_ENABLE_SIGNUP: false,
    TOGGLE_ENABLE_SLACK: true,
    TOGGLE_ENABLE_SUPPORT: false,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: true,
    TOGGLE_ENABLE_TOILETTE: false,
    TOGGLE_ENABLE_VISITORS: true,
    TOGGLE_ENABLE_WORKFROMANYWHERE: true,
    TOGGLE_SHOW_GROUPS_CONSTRAINTS: false,
    TOGGLE_SHOW_GROUPS: true,
    TOGGLE_SHOW_PHONE: false,
    TOGGLE_SHOW_PUSH_MOBILE: false,
  },
};
