import { CustomEntity } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { DeskTypes } from '../desk';
import { GroupStateModel } from '../group';
import { ParkingTypes } from '../parking';
import { CustomEntityStateModel } from './custom-entity.model';
import { CustomEntityTypes } from './custom-entity.type';

export function mapCustomEntityToApi(
  {
    active,
    amenities,
    audiences,
    category,
    coordinates,
    exclusivityAssignment,
    id,
    name,
  }: DeepPartial<CustomEntityStateModel>,
  groups: GroupStateModel[],
): DeepPartial<CustomEntity.Update.Body> {
  return {
    active,
    amenities,
    assigned: {
      exclusive_assignment: exclusivityAssignment,
      assignee:
        audiences === null
          ? []
          : audiences?.map(audience => ({
              entity_id: audience,
              entity: entityFromId(audience, groups),
            })),
    },
    mapEntityCategory: category,
    coordinates,
    id,
    name,
  };
}

export function mapCustomEntityToClient({
  active,
  amenities,
  assigned,
  mapEntityCategory,
  coordinates,
  id,
  name,
}: CustomEntity.Read.Response): CustomEntityStateModel {
  return {
    active,
    amenities,
    audiences: assigned?.assignee?.map(({ entity_id }) => entity_id),
    category: mapEntityCategory,
    coordinates,
    exclusivityAssignment: assigned?.exclusive_assignment ?? false,
    id,
    name,
    type:
      {
        desks: DeskTypes.desk,
        moto: ParkingTypes.moto,
        parkings: ParkingTypes.parking,
        toilettes: DeskTypes.toilette,
      }[mapEntityCategory as 'desks' | 'moto' | 'parkings' | 'toilettes'] ??
      CustomEntityTypes.customEntity,
  };
}

function entityFromId(
  audience: string,
  groups: GroupStateModel[],
): NonNullable<NonNullable<CustomEntity.Update.Body['assigned']>['assignee']>[number]['entity'] {
  if (groups.find(group => group.id === audience)) {
    return 'group';
  }

  return 'user';
}
