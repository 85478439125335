import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Integrations } from './integrations.type';

@Injectable({ providedIn: 'root' })
export class IntegrationsService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  associateRooms(
    body: Integrations.Rooms.Associate.Body,
  ): Observable<Integrations.Rooms.Associate.Response> {
    return this.httpClient
      .post<Integrations.Rooms.Associate.Response>(
        `${this.apiRootUrl}/company/integration/rooms/mapping`,
        body,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readAzureActiveDirectory(): Observable<Integrations.AzureActiveDirectory.Read.Response> {
    return this.httpClient
      .get<Integrations.AzureActiveDirectory.Read.Response>(
        `${this.apiRootUrl}/company/integration/directory/azure`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readGoogleWorkspaceDirectory(): Observable<Integrations.GoogleWorkspaceDirectory.Read.Response> {
    return this.httpClient
      .get<Integrations.GoogleWorkspaceDirectory.Read.Response>(
        `${this.apiRootUrl}/company/integration/directory/google`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readGoogleWorkspaceRooms(): Observable<Integrations.GoogleWorkspaceRooms.Read.Response> {
    return this.httpClient
      .get<Integrations.GoogleWorkspaceRooms.Read.Response>(
        `${this.apiRootUrl}/company/integration/rooms/google`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readKisi(): Observable<Integrations.Kisi.Read.Response> {
    return this.httpClient
      .get<Integrations.Kisi.Read.Response>(
        `${this.apiRootUrl}/company/integration/access_control/kisi`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readSofia(): Observable<Integrations.Sofia.Read.Response> {
    return this.httpClient
      .get<Integrations.Sofia.Read.Response>(
        `${this.apiRootUrl}/company/integration/access_control/sofiaLocks`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readOktaDirectory(): Observable<Integrations.OktaDirectory.Read.Response> {
    return this.httpClient
      .get<Integrations.OktaDirectory.Read.Response>(
        `${this.apiRootUrl}/company/integration/directory/okta`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readMicrosoft365Rooms(): Observable<Integrations.Microsoft365Rooms.Read.Response> {
    return this.httpClient
      .get<Integrations.Microsoft365Rooms.Read.Response>(
        `${this.apiRootUrl}/company/integration/rooms/microsoft`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readRoomsList(): Observable<Integrations.Rooms.List.Read.Response> {
    return this.httpClient
      .get<Integrations.Rooms.List.Read.Response>(
        `${this.apiRootUrl}/company/integration/rooms/list`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readRoomsPermissions(
    params: Integrations.Rooms.Permissions.Read.Params,
  ): Observable<Integrations.Rooms.Permissions.Read.Response> {
    return this.httpClient
      .get<Integrations.Rooms.Permissions.Read.Response>(
        `${this.apiRootUrl}/company/integration/rooms/permissions`,
        { params },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readRoomsRemote(): Observable<Integrations.Rooms.Remote.Read.Response> {
    return this.httpClient
      .get<Integrations.Rooms.Remote.Read.Response>(
        `${this.apiRootUrl}/company/integration/rooms/remote`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readSlack(): Observable<Integrations.Slack.Read.Response> {
    return this.httpClient
      .get<Integrations.Slack.Read.Response>(`${this.apiRootUrl}/company/slack/info`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  resetAzureActiveDirectory(): Observable<Integrations.AzureActiveDirectory.Reset.Response> {
    return this.httpClient
      .delete<Integrations.AzureActiveDirectory.Reset.Response>(
        `${this.apiRootUrl}/company/integration/directory/azure`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  // tslint:disable-next-line: max-line-length
  resetGoogleWorkspaceDirectory(): Observable<Integrations.GoogleWorkspaceDirectory.Reset.Response> {
    return this.httpClient
      .delete<Integrations.GoogleWorkspaceDirectory.Reset.Response>(
        `${this.apiRootUrl}/company/integration/directory/google`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  resetGoogleWorkspaceRooms(): Observable<Integrations.GoogleWorkspaceRooms.Reset.Response> {
    return this.httpClient
      .delete<Integrations.GoogleWorkspaceRooms.Reset.Response>(
        `${this.apiRootUrl}/company/integration/rooms/google`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  resetMicrosoft365Rooms(): Observable<Integrations.Microsoft365Rooms.Reset.Response> {
    return this.httpClient
      .delete<Integrations.Microsoft365Rooms.Reset.Response>(
        `${this.apiRootUrl}/company/integration/rooms/microsoft`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  resetOktaDirectory(): Observable<Integrations.OktaDirectory.Reset.Response> {
    return this.httpClient
      .delete<Integrations.OktaDirectory.Reset.Response>(
        `${this.apiRootUrl}/company/integration/directory/okta`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  syncAzureActiveDirectory(): Observable<Integrations.AzureActiveDirectory.Sync.Response> {
    return this.httpClient
      .post<Integrations.AzureActiveDirectory.Sync.Response>(
        `${this.apiRootUrl}/company/integration/directory/azure/sync`,
        {},
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  syncGoogleWorkspaceDirectory(): Observable<Integrations.GoogleWorkspaceDirectory.Sync.Response> {
    return this.httpClient
      .post<Integrations.GoogleWorkspaceDirectory.Sync.Response>(
        `${this.apiRootUrl}/company/integration/directory/google/sync`,
        {},
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  syncGoogleWorkspaceRooms(): Observable<Integrations.GoogleWorkspaceRooms.Sync.Response> {
    return this.httpClient
      .get<Integrations.GoogleWorkspaceRooms.Sync.Response>(
        `${this.apiRootUrl}/company/integration/rooms/remote`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  syncMicrosoft365Rooms(): Observable<Integrations.Microsoft365Rooms.Sync.Response> {
    return this.httpClient
      .get<Integrations.Microsoft365Rooms.Sync.Response>(
        `${this.apiRootUrl}/company/integration/rooms/remote`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  syncOktaDirectory(): Observable<Integrations.OktaDirectory.Sync.Response> {
    return this.httpClient
      .post<Integrations.OktaDirectory.Sync.Response>(
        `${this.apiRootUrl}/company/integration/directory/okta/sync`,
        {},
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  testOktaDirectory({
    api_key,
    domain_url,
  }: Integrations.OktaDirectory.Test.Params): Observable<Integrations.OktaDirectory.Test.Response> {
    return this.httpClient
      .post<Integrations.OktaDirectory.Test.Response>(
        `${this.apiRootUrl}/company/integration/directory/okta/test`,
        {
          api_key,
          domain_url,
        },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  uninstallSlack(): Observable<Integrations.Slack.Uninstall.Response> {
    return this.httpClient
      .delete<Integrations.Slack.Uninstall.Response>(`${this.apiRootUrl}/company/slack/delete`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateAzureActiveDirectory(
    settings: DeepPartial<Integrations.AzureActiveDirectory.Update.Params>,
  ): Observable<Integrations.AzureActiveDirectory.Update.Response> {
    return this.httpClient
      .put<Integrations.AzureActiveDirectory.Update.Response>(
        `${this.apiRootUrl}/company/integration/directory/azure`,
        settings,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateGoogleWorkspaceDirectory(
    settings: DeepPartial<Integrations.GoogleWorkspaceDirectory.Update.Params>,
  ): Observable<Integrations.GoogleWorkspaceDirectory.Update.Response> {
    return this.httpClient
      .put<Integrations.GoogleWorkspaceDirectory.Update.Response>(
        `${this.apiRootUrl}/company/integration/directory/google`,
        settings,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateGoogleWorkspaceRooms(
    settings: DeepPartial<Integrations.GoogleWorkspaceRooms.Update.Params>,
  ): Observable<Integrations.GoogleWorkspaceRooms.Update.Response> {
    return this.httpClient
      .put<Integrations.GoogleWorkspaceRooms.Update.Response>(
        `${this.apiRootUrl}/company/integration/rooms/google`,
        settings,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateKisi(
    settings: Partial<Integrations.Kisi.Update.Params>,
  ): Observable<Integrations.Kisi.Update.Response> {
    return this.httpClient
      .put<Integrations.Kisi.Update.Response>(
        `${this.apiRootUrl}/company/integration/access_control/kisi`,
        settings,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSofia(
    settings: Partial<Integrations.Sofia.Update.Params>,
  ): Observable<Integrations.Sofia.Update.Response> {
    return this.httpClient
      .put<Integrations.Sofia.Update.Response>(
        `${this.apiRootUrl}/company/integration/access_control/sofiaLocks`,
        settings,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateMicrosoft365Rooms(
    settings: DeepPartial<Integrations.Microsoft365Rooms.Update.Params>,
  ): Observable<Integrations.Microsoft365Rooms.Update.Response> {
    return this.httpClient
      .put<Integrations.Microsoft365Rooms.Update.Response>(
        `${this.apiRootUrl}/company/integration/rooms/microsoft`,
        settings,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateOktaDirectory(
    settings: DeepPartial<Integrations.OktaDirectory.Update.Params>,
  ): Observable<Integrations.OktaDirectory.Update.Response> {
    return this.httpClient
      .put<Integrations.OktaDirectory.Update.Response>(
        `${this.apiRootUrl}/company/integration/directory/okta`,
        settings,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
