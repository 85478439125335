import { Selector } from '@ngxs/store';
import {
  CustomEntitiesCategoriesState,
  CustomEntitiesCategoryStateModel,
} from '../custom-entities';
import { CustomEntitiesStateModel } from '../custom-entities/custom-entities.model';
import { CustomEntitiesState } from '../custom-entities/custom-entities.state';
import { DesksState, DesksStateModel } from '../desks';
import { ParkingsState, ParkingsStateModel } from '../parkings';
import { RoomsState, RoomsStateModel } from '../rooms';
import { SpaceStateModel } from './space.model';
import { SpaceState } from './space.state';
import { SpaceType, SpaceTypes } from './space.type';

export class SpaceSelectors {
  @Selector([SpaceState])
  static categoryId({ categoryId }: SpaceStateModel): SpaceStateModel['categoryId'] {
    return categoryId;
  }

  @Selector([SpaceSelectors.categoryId, CustomEntitiesCategoriesState])
  static categoryName(
    categoryId: SpaceStateModel['categoryId'],
    categories: CustomEntitiesCategoryStateModel[],
  ): CustomEntitiesCategoryStateModel['name'] {
    return categories.find(category => category.id === categoryId)?.name ?? '';
  }

  @Selector([CustomEntitiesState])
  static customEntities({ list }: CustomEntitiesStateModel): CustomEntitiesStateModel['list'] {
    return list;
  }

  @Selector([SpaceState])
  static customEntitiesAmount({ customEntitiesAmount }: SpaceStateModel): number {
    return customEntitiesAmount || 0;
  }

  @Selector([SpaceState])
  static deskAmenities({ deskAmenities }: SpaceStateModel): SpaceStateModel['deskAmenities'] {
    return deskAmenities;
  }

  @Selector([DesksState])
  static desks({ list }: DesksStateModel): DesksStateModel['list'] {
    return list;
  }

  @Selector([SpaceState])
  static desksAmount({ desksAmount }: SpaceStateModel): number {
    return desksAmount || 0;
  }

  @Selector([SpaceState])
  static fullname({ name }: SpaceStateModel): SpaceStateModel['name'] {
    return name;
  }

  @Selector([SpaceState])
  static id({ id }: SpaceStateModel): SpaceStateModel['id'] {
    return id;
  }

  @Selector([SpaceSelectors.type])
  static isMapType(type: SpaceType): boolean {
    return type === SpaceTypes.map;
  }

  @Selector([SpaceState])
  static map({ map }: SpaceStateModel): SpaceStateModel['map'] {
    return map;
  }

  @Selector([SpaceState])
  static mapUrl({ map }: SpaceStateModel): string | undefined {
    return map?.url;
  }

  @Selector([SpaceState])
  static parkingAmenities({
    parkingAmenities,
  }: SpaceStateModel): SpaceStateModel['parkingAmenities'] {
    return parkingAmenities;
  }

  @Selector([SpaceState])
  static parkingsAmount({ parkingsAmount }: SpaceStateModel): number {
    return parkingsAmount || 0;
  }

  @Selector([ParkingsState])
  static parkings({ list }: ParkingsStateModel): ParkingsStateModel['list'] {
    return list;
  }

  @Selector([SpaceState])
  static photo({ pictures }: SpaceStateModel): string | undefined {
    return pictures?.[0];
  }

  @Selector([SpaceState])
  static roomAmenities({ roomAmenities }: SpaceStateModel): SpaceStateModel['roomAmenities'] {
    return roomAmenities;
  }

  @Selector([SpaceState])
  static roomsAmount({ roomsAmount }: SpaceStateModel): number {
    return roomsAmount || 0;
  }

  @Selector([RoomsState])
  static rooms({ list }: RoomsStateModel): RoomsStateModel['list'] {
    return list;
  }

  @Selector([SpaceState])
  static type({ type }: SpaceStateModel): SpaceStateModel['type'] {
    return type;
  }
}
