import { Injectable } from '@angular/core';
import { GroupService } from '@business/api';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, State, StateContext } from '@ngxs/store';
import { append, compose, patch, removeItem } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { EmployeeStateModel } from '../employee';
import { EmployeesSelectors, EmployeesStateModel, mapEmployeesToClient } from '../employees';
import { GroupStateAction } from './group.actions';
import { mapGroupToApi, mapGroupToClient } from './group.helper';
import { GroupStateModel } from './group.model';

@State<GroupStateModel>({
  name: 'group',
  defaults: { id: '', members: [], uid: '', totalMembers: 0 },
})
@Injectable()
export class GroupState {
  @SelectSnapshot(EmployeesSelectors.list) employees!: EmployeesStateModel['list'];

  constructor(private readonly groupService: GroupService) {}

  @Action(GroupStateAction.AddMembers.Try)
  addMembers(
    { dispatch, getState, setState }: StateContext<GroupStateModel>,
    { id, members }: GroupStateAction.AddMembers.Try,
  ) {
    return this.groupService.addMembers({ id, members }).pipe(
      catchError(error => {
        dispatch(new GroupStateAction.AddMembers.Failure(error));

        throw error;
      }),
      tap(membersFromApi => {
        setState(
          patch({
            members: append(
              membersFromApi.map(memberFromApi =>
                this.employees.find(employee => employee.id === memberFromApi.id),
              ),
            ),
          }),
        );

        dispatch(
          new GroupStateAction.AddMembers.Success(
            getState(),
            this.employees.filter(employee => members.includes(employee.id)),
          ),
        );
      }),
    );
  }

  @Action(GroupStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<GroupStateModel>,
    { color, description, uid, name }: GroupStateAction.Create.Try,
  ) {
    return this.groupService
      .create({
        color,
        // @todo(heavybeard): remove temporary fix when server accept empty field
        description: description ?? ' ',
        name,
        uid,
      })
      .pipe(
        catchError(error => {
          dispatch(new GroupStateAction.Create.Failure(error));

          throw error;
        }),
        tap(group => {
          setState(mapGroupToClient(group));

          dispatch(new GroupStateAction.Create.Success(getState()));
        }),
      );
  }

  @Action(GroupStateAction.Delete.Try)
  delete(
    { dispatch, getState }: StateContext<GroupStateModel>,
    { id }: GroupStateAction.Delete.Try,
  ) {
    return this.groupService.delete(id).pipe(
      catchError(error => {
        dispatch(new GroupStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          dispatch(new GroupStateAction.Delete.Success(getState()));
        } else {
          dispatch(new GroupStateAction.Delete.Failure(status));
        }
      }),
    );
  }

  @Action(GroupStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<GroupStateModel>,
    { id }: GroupStateAction.Read.Try,
  ) {
    return this.groupService.read(id).pipe(
      catchError(error => {
        dispatch(new GroupStateAction.Read.Failure(error));

        throw error;
      }),
      tap(group => {
        setState(patch(mapGroupToClient(group)));

        dispatch(new GroupStateAction.Read.Success(getState()));
      }),
    );
  }

  @Action(GroupStateAction.ReadMembers.Try)
  readMembers(
    { dispatch, getState, setState }: StateContext<GroupStateModel>,
    { id }: GroupStateAction.ReadMembers.Try,
  ) {
    return this.groupService
      .members(id)
      .pipe(
        tap(members => {
          setState(patch({ members: mapEmployeesToClient(members) }));
        }),
      )
      .pipe(
        catchError(error => {
          dispatch(new GroupStateAction.ReadMembers.Failure(error));

          throw error;
        }),
        tap(() => {
          dispatch(new GroupStateAction.ReadMembers.Success(getState()));
        }),
      );
  }

  @Action(GroupStateAction.RemoveMembers.Try)
  removeMembers(
    { dispatch, getState, setState }: StateContext<GroupStateModel>,
    { id, members }: GroupStateAction.RemoveMembers.Try,
  ) {
    return this.groupService.removeMembers({ id, members }).pipe(
      catchError(error => {
        dispatch(new GroupStateAction.RemoveMembers.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(
          patch({
            members: compose(
              ...members.map(memberId =>
                removeItem<EmployeeStateModel>(member => member?.id === memberId),
              ),
            ),
          }),
        );

        dispatch(
          new GroupStateAction.RemoveMembers.Success(
            getState(),
            this.employees.filter(employee => members.includes(employee.id)),
          ),
        );
      }),
    );
  }

  @Action(GroupStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<GroupStateModel>,
    { id, data }: GroupStateAction.Update.Try,
  ) {
    return this.groupService.update(id, mapGroupToApi(data)).pipe(
      catchError(error => {
        dispatch(new GroupStateAction.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        if (id === getState().id) {
          setState(
            patch({
              ...data,
              ...(data.limits && { limits: patch(data.limits) }),
              ...(data.permissions && { permissions: patch(data.permissions), id }),
            }),
          );
        }

        dispatch(new GroupStateAction.Update.Success(getState()));
      }),
    );
  }
}
