import { Selector } from '@ngxs/store';
import { RoomStateModel } from './room.model';
import { RoomState } from './room.state';

export class RoomSelectors {
  @Selector([RoomState])
  static companionClients({
    companionClients,
  }: RoomStateModel): RoomStateModel['companionClients'] {
    return companionClients;
  }

  @Selector([RoomState])
  static coordinates({ coordinates }: RoomStateModel): RoomStateModel['coordinates'] {
    return coordinates;
  }

  @Selector([RoomState])
  static id({ id }: RoomStateModel): RoomStateModel['id'] {
    return id;
  }

  @Selector([RoomState])
  static fullname({ name }: RoomStateModel): RoomStateModel['name'] {
    return name;
  }

  @Selector([RoomState])
  static photo({ pictures }: RoomStateModel): string | undefined {
    return pictures?.[0];
  }
}
