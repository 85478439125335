import { Injectable } from '@angular/core';
import { CompanyService, EmailsService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { catchError, first, tap } from 'rxjs/operators';
import { EmailStateModel } from '../email/email.model';
import { EmailsStateAction } from './emails.actions';
import {
  mapDesignSettingsToApi,
  mapDesignSettingsToClient,
  mapEmailsToClient,
  mapSmtpSettingsToApi,
  mapSmtpSettingsToClient,
} from './emails.helper';
import { EmailsStateModel } from './emails.model';

@State<EmailsStateModel>({
  name: 'emails',
  defaults: { list: [] },
})
@Injectable()
export class EmailsState {
  constructor(
    private readonly companyService: CompanyService,
    private readonly emailsService: EmailsService,
  ) {}

  @Action(EmailsStateAction.DesignSettings.Read.Try)
  designSettings({ dispatch, getState, patchState }: StateContext<EmailsStateModel>) {
    return this.emailsService.design().pipe(
      first(),
      catchError(error => {
        dispatch(new EmailsStateAction.DesignSettings.Read.Failure(error));

        throw error;
      }),
      tap(designSettings => {
        patchState({ designSettings: mapDesignSettingsToClient(designSettings) });

        dispatch(new EmailsStateAction.DesignSettings.Read.Success(getState()));
      }),
    );
  }

  @Action(EmailsStateAction.List.Read.Try)
  list({ dispatch, getState, patchState }: StateContext<EmailsStateModel>) {
    return this.emailsService.list().pipe(
      first(),
      catchError(error => {
        dispatch(new EmailsStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(emails => {
        patchState({
          list: mapEmailsToClient(emails),
        });

        dispatch(new EmailsStateAction.List.Read.Success(getState()));
      }),
    );
  }

  @Action(EmailsStateAction.SmtpSettings.Read.Try)
  smtpSettings({ dispatch, getState, patchState }: StateContext<EmailsStateModel>) {
    return this.emailsService.smtp().pipe(
      first(),
      catchError(error => {
        dispatch(new EmailsStateAction.SmtpSettings.Read.Failure(error));

        throw error;
      }),
      tap(smtpSettings => {
        patchState({ smtpSettings: mapSmtpSettingsToClient(smtpSettings) });

        dispatch(new EmailsStateAction.SmtpSettings.Read.Success(getState()));
      }),
    );
  }

  @Action(EmailsStateAction.DesignSettings.Reset.Try)
  resetDesignSettings({ dispatch, getState, patchState }: StateContext<EmailsStateModel>) {
    return this.emailsService.resetDesign().pipe(
      catchError(error => {
        dispatch(new EmailsStateAction.DesignSettings.Reset.Failure(error));

        throw error;
      }),
      tap(designSettings => {
        patchState({ designSettings: mapDesignSettingsToClient(designSettings) });

        dispatch(new EmailsStateAction.DesignSettings.Reset.Success(getState()));
      }),
    );
  }

  @Action(EmailsStateAction.SmtpSettings.Reset.Try)
  resetSmtpSettings({ dispatch, getState, patchState }: StateContext<EmailsStateModel>) {
    return this.emailsService.resetSmtp().pipe(
      catchError(error => {
        dispatch(new EmailsStateAction.SmtpSettings.Reset.Failure(error));

        throw error;
      }),
      tap(smtpSettings => {
        patchState({ smtpSettings: mapSmtpSettingsToClient(smtpSettings) });

        dispatch(new EmailsStateAction.SmtpSettings.Reset.Success(getState()));
      }),
    );
  }

  @Action(EmailsStateAction.SmtpSettings.Test.Try)
  testSmtpSettings({ dispatch, getState }: StateContext<EmailsStateModel>) {
    return this.emailsService.testSmtp().pipe(
      first(),
      catchError(error => {
        dispatch(new EmailsStateAction.SmtpSettings.Test.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          dispatch(new EmailsStateAction.SmtpSettings.Test.Success(getState()));
        } else {
          dispatch(new EmailsStateAction.SmtpSettings.Test.Failure(status));
        }
      }),
    );
  }

  @Action(EmailsStateAction.DesignSettings.Update.Try)
  updateDesignSettings(
    { dispatch, getState, setState }: StateContext<EmailsStateModel>,
    { designSettings }: EmailsStateAction.DesignSettings.Update.Try,
  ) {
    return this.emailsService.updateDesign(mapDesignSettingsToApi(designSettings)).pipe(
      first(),
      catchError(error => {
        dispatch(new EmailsStateAction.DesignSettings.Update.Failure(error));

        throw error;
      }),
      tap(designSettingsFromApi => {
        setState(patch({ designSettings: mapDesignSettingsToClient(designSettingsFromApi) }));

        dispatch(new EmailsStateAction.DesignSettings.Update.Success(getState()));
      }),
    );
  }

  @Action(EmailsStateAction.List.Update.Try)
  updateItemInList(
    { dispatch, getState, setState }: StateContext<EmailsStateModel>,
    { email }: EmailsStateAction.List.Update.Try,
  ) {
    try {
      setState(
        patch({
          list: updateItem<EmailStateModel>(
            emailStore => emailStore?.id === email.id,
            patch(email),
          ),
        }),
      );

      dispatch(new EmailsStateAction.List.Update.Success(getState()));
    } catch (error) {
      dispatch(new EmailsStateAction.List.Update.Failure(error));

      throw error;
    }
  }

  @Action(EmailsStateAction.SmtpSettings.Update.Try)
  updateSmtpSettings(
    { dispatch, getState, patchState }: StateContext<EmailsStateModel>,
    { smtpSettings }: EmailsStateAction.SmtpSettings.Update.Try,
  ) {
    return this.emailsService.updateSmtp(mapSmtpSettingsToApi(smtpSettings)).pipe(
      first(),
      catchError(error => {
        dispatch(new EmailsStateAction.SmtpSettings.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        patchState({ smtpSettings });

        dispatch(new EmailsStateAction.SmtpSettings.Update.Success(getState()));
      }),
    );
  }

  @Action(EmailsStateAction.UploadLogo.Try)
  uploadLogo(
    { dispatch, getState }: StateContext<EmailsStateModel>,
    { logo: logoFile }: EmailsStateAction.UploadLogo.Try,
  ) {
    const uploadData = new FormData();
    uploadData.append('logo', logoFile, logoFile.name);

    return this.companyService.uploadLogo(uploadData).pipe(
      catchError(error => {
        dispatch(new EmailsStateAction.UploadLogo.Failure(error));

        throw error;
      }),
      tap(({ logo: { url: logo } }) => {
        dispatch(new EmailsStateAction.DesignSettings.Update.Try({ logo }));
      }),
      catchError(error => {
        dispatch(new EmailsStateAction.UploadLogo.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new EmailsStateAction.UploadLogo.Success(getState()));
      }),
    );
  }
}
