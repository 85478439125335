// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { StoreOptions } from '@nibol/store';
import { SpaceStateModel } from '../space';
import { CustomEntityStateModel } from './custom-entity.model';

export namespace CustomEntityStateAction {
  export namespace Create {
    export class Try {
      static readonly type = '[Custom Entity] try Create Custom Entity';
      constructor(
        public spaceId: SpaceStateModel['id'],
        public customEntity: Pick<CustomEntityStateModel, 'coordinates'> &
          Pick<CustomEntityStateModel, 'category' | 'type'> &
          DeepPartial<Pick<CustomEntityStateModel, 'active' | 'amenities' | 'audiences' | 'name'>>,
      ) {}
    }
    export class Success {
      static readonly type = '[Custom Entity] then Create Custom Entity success';
      constructor(public customEntity: CustomEntityStateModel) {}
    }
    export class Failure {
      static readonly type = '[Custom Entity] catch Create Custom Entity failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Custom Entity] try Delete Custom Entity';
      constructor(public id: CustomEntityStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Custom Entity] then Delete Custom Entity success';
      constructor(public customEntity: CustomEntityStateModel) {}
    }
    export class Failure {
      static readonly type = '[Custom Entity] catch Delete Custom Entity failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Custom Entity] try Read Custom Entity';
      constructor(public id: CustomEntityStateModel['id'], public options?: StoreOptions) {}
    }
    export class Success {
      static readonly type = '[Custom Entity] then Read Custom Entity success';
      constructor(public customEntity: CustomEntityStateModel) {}
    }
    export class Failure {
      static readonly type = '[Custom Entity] catch Read Custom Entity failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Custom Entity] try Update Custom Entity';
      constructor(
        public id: CustomEntityStateModel['id'],
        public data: DeepPartial<
          Pick<
            CustomEntityStateModel,
            'active' | 'amenities' | 'audiences' | 'category' | 'coordinates' | 'name'
          >
        >,
      ) {}
    }
    export class Success {
      static readonly type = '[Custom Entity] then Update Custom Entity success';
      constructor(public customEntity: CustomEntityStateModel) {}
    }
    export class Failure {
      static readonly type = '[Custom Entity] catch Update Custom Entity failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
