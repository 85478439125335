import { EmployeeStateModel } from '../employee/employee.model';
import { EmployeesFilters } from './employees.type';

export class EmployeesStateModel {
  currentPage!: number;
  list!: EmployeeStateModel[];
  overallElements!: number;
  reachedElement!: number;
  reachedPage!: number;
  totalElements!: number;
  totalPages!: number;
  roleFilter?: EmployeesFilters['role'];
  searchFilter?: EmployeesFilters['search'];
}
