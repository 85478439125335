import { Selector } from '@ngxs/store';
import { EmailStateModel } from './email.model';
import { EmailState } from './email.state';
import { EmailContent } from './email.type';

export class EmailSelectors {
  @Selector([EmailState])
  static contents({ content }: EmailStateModel): EmailContent[] {
    return content ?? [];
  }

  @Selector([EmailState])
  static footer({ footer }: EmailStateModel): EmailStateModel['footer'] {
    return footer;
  }

  @Selector([EmailState])
  static header({ header }: EmailStateModel): EmailStateModel['header'] {
    return header;
  }

  @Selector([EmailState])
  static id({ id }: EmailStateModel): EmailStateModel['id'] {
    return id;
  }

  @Selector([EmailState])
  static isDefault({ isDefault }: EmailStateModel): EmailStateModel['isDefault'] {
    return isDefault;
  }

  @Selector([EmailState])
  static params({ params }: EmailStateModel): EmailStateModel['params'] {
    return params;
  }

  @Selector([EmailState])
  static style({ style }: EmailStateModel): EmailStateModel['style'] {
    return `<style>${style}</style>`;
  }

  @Selector([EmailState])
  static title({ title }: EmailStateModel): EmailStateModel['title'] {
    return title;
  }

  @Selector([EmailState])
  static trigger({ trigger }: EmailStateModel): EmailStateModel['trigger'] {
    return trigger;
  }
}
