// tslint:disable: no-namespace

export namespace Integrations {
  export namespace AzureActiveDirectory {
    export namespace Read {
      export type Response = {
        accept_custom_remote_fields: boolean;
        enabled: boolean;
        integration_link: string;
        mapping?: Array<{
          active: boolean;
          disabled: boolean;
          editable_path: boolean;
          local_field: string;
          remote_path: string;
          remote_values?: Array<{ label: string; mapped_values: string[]; value: number | string }>;
        }>;
        remote_fields?: string[];
      };
    }
    export namespace Reset {
      export type Response = {};
    }
    export namespace Sync {
      export type Params = {};
      export type Response = { status: boolean };
    }
    export namespace Update {
      export type Params = Pick<Read.Response, 'enabled' | 'mapping'>;
      export type Response = Params & { status: boolean };
    }
  }

  export namespace GoogleWorkspaceDirectory {
    export namespace Read {
      export type Response = {
        accept_custom_remote_fields: boolean;
        auth_negated: boolean;
        enabled: boolean;
        exclude_filter: { remote_field: null; values: [] };
        include_filter: { remote_field: null; values: [] };
        integration_link: string;
        mapping?: Array<{
          active: boolean;
          disabled: boolean;
          editable_path: boolean;
          local_field: string;
          remote_path: string;
          remote_values?: Array<{ label: string; mapped_values: string[]; value: number | string }>;
        }>;
        remote_fields?: string[];
      };
    }
    export namespace Reset {
      export type Response = {};
    }
    export namespace Sync {
      export type Params = {};
      export type Response = { status: boolean };
    }
    export namespace Update {
      export type Params = Pick<Read.Response, 'enabled' | 'mapping'>;
      export type Response = Params & { status: boolean };
    }
  }

  export namespace GoogleWorkspaceRooms {
    export namespace Read {
      export type Response = {
        completed: boolean;
        enabled: boolean;
        integration_link: string;
        settings: {
          reservation_checkin: boolean;
          reservation_checkin_settings: {
            before_from: number;
            after_from: number;
            missed_policy: 'keep' | 'lost';
          };
        };
        info?: {
          account: string;
          source: 'google';
          count: number;
        };
      };
    }
    export namespace Reset {
      export type Response = { status: boolean };
    }
    export namespace Sync {
      export type Response = Array<{
        calendar_email: string;
        name: string;
        seats: number;
      }>;
    }
    export namespace Update {
      export type Params = Pick<Read.Response, 'settings'>;
      export type Response = Read.Response;
    }
  }

  export namespace Kisi {
    export namespace Read {
      export type Response = {
        api_key: string;
        enabled: boolean;
        mapping: Array<{
          building_id: string;
          group_id: string;
        }>;
      };
    }
    export namespace Update {
      export type Params = Read.Response;
      export type Response = Params;
    }
  }

  export namespace Sofia {
    export namespace Read {
      export type Response = {
        domain: string;
        token: string;
        enabled: boolean;
        userTagsList?: Array<{
          id: number;
          name: string;
        }>;
        mapping: Array<{
          office_id: string;
          user_tag_id: number;
        }>;
      };
    }
    export namespace Update {
      export type Params = Pick<Read.Response, 'domain' | 'token' | 'enabled' | 'mapping'>;
      export type Response = Params;
    }
  }

  export namespace Microsoft365Rooms {
    export namespace Read {
      export type Response = {
        completed: boolean;
        enabled: boolean;
        integration_link: string;
        settings: {
          reservation_checkin: boolean;
          reservation_checkin_settings: {
            before_from: number;
            after_from: number;
            missed_policy: 'keep' | 'lost';
          };
        };
        info?: {
          source: 'microsoft';
          count: number;
        };
      };
    }
    export namespace Reset {
      export type Response = { status: boolean };
    }
    export namespace Sync {
      export type Response = Array<{
        calendar_email: string;
        name: string;
        seats: number;
      }>;
    }
    export namespace Update {
      export type Params = Pick<Read.Response, 'settings'>;
      export type Response = Read.Response;
    }
  }

  export namespace OktaDirectory {
    export namespace Read {
      export type Response = {
        accept_custom_remote_fields: boolean;
        enabled: boolean;
        api_key?: string;
        domain_url?: string;
        mapping?: Array<{
          active: boolean;
          disabled: boolean;
          editable_path: boolean;
          local_field: string;
          remote_path: string;
          remote_values?: Array<{ label: string; mapped_values: string[]; value: number | string }>;
        }>;
        remote_fields?: string[];
      };
    }
    export namespace Reset {
      export type Response = {};
    }
    export namespace Sync {
      export type Params = {};
      export type Response = { status: boolean };
    }
    export namespace Test {
      export type Params = {
        api_key: string;
        domain_url: string;
      };
      export type Response = { status: boolean };
    }
    export namespace Update {
      export type Params = Pick<Read.Response, 'api_key' | 'domain_url' | 'enabled' | 'mapping'>;
      export type Response = Params;
    }
  }

  export namespace Rooms {
    export namespace Associate {
      export type Body = Array<{ calendar_email: string; room_id: string }>;
      export type Response =
        | {
            status: 'ok';
          }
        | {
            code: 500;
            message: 'integration-already-mapped';
          };
    }
    export namespace List {
      export namespace Read {
        export type Response = Array<{
          id: string;
          calendar_email: string | null;
          name: string;
          seats: number;
        }>;
      }
    }
    export namespace Permissions {
      export namespace Read {
        export type Params = { id: string };
        export type Response = {
          id: string;
          name: string;
          permissions: {
            read: boolean;
            write: boolean;
          };
        };
      }
    }
    export namespace Remote {
      export namespace Read {
        export type Response = Array<{
          calendar_email: string;
          name: string;
          seats: number;
        }>;
      }
    }
  }

  export namespace Slack {
    export namespace Read {
      export type Response = {
        enabled: boolean;
        integration_link: string;
      };
    }
    export namespace Uninstall {
      export type Response = {};
    }
  }
}
