import { CustomEntities } from '@business/api';
import { DeepPartial, mapTimeNumberToTimeString } from '@nibol/shared';
import { parse } from 'date-fns';
import { BuildingStateModel } from '../building';
import { mapIsFulldayReservation } from '../reservations';
import { CustomEntitiesStateModel } from './custom-entities.model';

export function mapCustomEntitiesReservationsToClient(
  reservations: CustomEntities.Reservations.Read.Response['data'],
): CustomEntitiesStateModel['reservations'] {
  return reservations.map(reservation => ({
    building: reservation.building,
    office: reservation.office,
    space: reservation.space,
    customEntity: reservation.custom_entity,
    from: parse(
      `${reservation.time.day}${mapTimeNumberToTimeString(reservation.time.from || 0)}`,
      'yyyyMMddHH:mm',
      new Date(),
    ),
    id: reservation.id,
    isFullday: mapIsFulldayReservation(reservation.time),
    to: parse(
      `${reservation.time.day}${mapTimeNumberToTimeString(reservation.time.to || 0)}`,
      'yyyyMMddHH:mm',
      new Date(),
    ),
    user: {
      firstname: reservation.user.name,
      email: reservation.user.email,
    },
  }));
}

export function mapCustomEntitiesReservationsApiParams({
  buildingId,
  elements,
  page,
}: {
  buildingId: BuildingStateModel['id'];
  elements: CustomEntitiesStateModel['reservations']['length'];
  page: CustomEntitiesStateModel['reservationsCurrentPage'];
}): CustomEntities.Reservations.Read.Params {
  return { building_id: buildingId, elements: elements.toString(), page: page.toString() };
}

export function mapCustomEntitiesSettingsToApi(
  buildingId: BuildingStateModel['id'],
  data: DeepPartial<Pick<CustomEntitiesStateModel, 'checkIn' | 'isViewColleaguesEnabled'>>,
): CustomEntities.Settings.Update.Body {
  return {
    id: buildingId,
    custom_entities: {
      map_show_users: data.isViewColleaguesEnabled,
      reservation_checkin_settings: {
        after_from: data.checkIn?.interval?.to,
        before_from: data.checkIn?.interval?.from,
        missed_policy: data.checkIn?.missingPolicy,
        nearby_distance: data.checkIn?.nearbyDistance || null,
      },
      reservation_checkin: data.checkIn?.isEnabled,
    },
  };
}

export function mapCustomEntitiesSettingsToClient({
  custom_entities: { map_show_users, reservation_checkin, reservation_checkin_settings },
}: CustomEntities.Settings.Read.Response): Pick<
  CustomEntitiesStateModel,
  'checkIn' | 'isViewColleaguesEnabled'
> {
  return {
    checkIn: {
      interval: {
        from: reservation_checkin_settings?.before_from,
        to: reservation_checkin_settings?.after_from,
      },
      isEnabled: reservation_checkin,
      missingPolicy: reservation_checkin_settings?.missed_policy,
      nearbyDistance: reservation_checkin_settings.nearby_distance || undefined,
    },
    isViewColleaguesEnabled: map_show_users,
  };
}
