// tslint:disable: no-namespace

import { DeepPartial } from '@nibol/shared';
import { Building } from '../building';
import { Employee } from '../employee';

export namespace Deliveries {
  export namespace Activities {
    export namespace ExportCsv {
      export type Body = {
        building_id: Building.Read.Response['id'];
        from: number;
        to: number;
      };
      export type Response = Blob;
    }

    export namespace Read {
      export type Params = {
        building_id: Building.Read.Response['id'];
      };
      export type Response = Array<{
        creation_date: ReturnType<Date['toISOString']>;
        front_desk_manager: Pick<Employee.Read.Response, 'email' | 'id' | 'name' | 'pic'>;
        id: string;
        image_path: string;
        recipient: Pick<Employee.Read.Response, 'email' | 'id' | 'name' | 'pic'>;
        pickup_date?: ReturnType<Date['toISOString']> | null;
      }>;
    }
  }

  export namespace Settings {
    export namespace Read {
      export type Params = {
        id: Response['id'];
      };
      export type Response = {
        id: Building.Read.Response['id'];
        deliveries: {
          accept_deliveries: boolean;
          users: Array<Employee.Read.Response['id']>;
        };
      };
    }
    export namespace Update {
      export type Body = Pick<Read.Response, 'id'> & DeepPartial<Pick<Read.Response, 'deliveries'>>;
      export type Response = Read.Response;
    }
  }
}
