import { Injectable } from '@angular/core';
import { SafeService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { SafeStateAction } from './safe.actions';
import { mapSafeSettingsToApi, mapSafeSettingsToClient } from './safe.helper';
import { SafeStateModel } from './safe.model';

@State<SafeStateModel>({
  name: 'safe',
  defaults: { isEnabledForBooking: false, isEnabledForCheckIn: false },
})
@Injectable()
export class SafeState {
  constructor(private readonly safeService: SafeService) {}

  @Action(SafeStateAction.Settings.Read.Try)
  readSettings(
    { dispatch, getState, setState }: StateContext<SafeStateModel>,
    { buildingId: id }: SafeStateAction.Settings.Read.Try,
  ) {
    return this.safeService.settings({ id }).pipe(
      catchError(error => {
        dispatch(new SafeStateAction.Settings.Read.Failure(error));

        throw error;
      }),
      tap(roomsSettings => {
        setState(patch(mapSafeSettingsToClient(roomsSettings)));

        dispatch(new SafeStateAction.Settings.Read.Success(getState()));
      }),
    );
  }

  @Action(SafeStateAction.Settings.Update.Try)
  updateSettings(
    { dispatch, getState, setState }: StateContext<SafeStateModel>,
    { buildingId, data }: SafeStateAction.Settings.Update.Try,
  ) {
    return this.safeService.updateSettings(mapSafeSettingsToApi(buildingId, data)).pipe(
      catchError(error => {
        dispatch(new SafeStateAction.Settings.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data }));

        dispatch(new SafeStateAction.Settings.Update.Success(getState()));
      }),
    );
  }
}
