import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AppShellAuthModule,
  AppShellGhostModule,
  AppShellPublicModule,
  AppShellWizardModule,
} from '@nibol/shared';
import { TranslationModule } from '@nibol/translation';
import {
  AppShellModule as NibolAppShellModule,
  ButtonModule,
  ChipModule,
  IconModule,
  IfBreakpointIsModule,
  InputModule,
  LetModule,
  SelectModule,
  TabModule,
} from '@nibol/ui';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AddNewBuildingDialogComponent } from './add-new-building-dialog/add-new-building-dialog.component';
import { AdvSlackComponent } from './adv-slack/adv-slack.component';
import { AppShellComponent } from './app-shell.component';
import { GoogleWorkspaceDirectoryRestoreIntegrationDialogComponent } from './google-workspace-directory-restore-integration-dialog/google-workspace-directory-restore-integration-dialog.component';
import { SubscriptionBannerComponent } from './subscription-banner/subscription-banner.component';

@NgModule({
  declarations: [
    AddNewBuildingDialogComponent,
    AdvSlackComponent,
    AppShellComponent,
    GoogleWorkspaceDirectoryRestoreIntegrationDialogComponent,
    SubscriptionBannerComponent,
  ],
  exports: [AppShellComponent],
  imports: [
    AppShellAuthModule,
    AppShellGhostModule,
    AppShellPublicModule,
    AppShellWizardModule,
    ButtonModule,
    ChipModule,
    CommonModule,
    FeatureToggleModule,
    FlexModule,
    IconModule,
    IfBreakpointIsModule,
    InputModule,
    LetModule,
    NgxPermissionsModule,
    NibolAppShellModule,
    ReactiveFormsModule,
    RouterModule,
    SelectModule,
    TabModule,
    TranslationModule,
  ],
})
export class AppShellModule {}
