import { Company } from '@business/api';
import { mapRoleToApi } from '@nibol/shared';
import { mapEmployeeToClient } from '../employee/employee.helper';
import { EmployeeStateModel } from '../employee/employee.model';
import { EmployeesStateModel } from './employees.model';
import { EmployeesFilters } from './employees.type';

export function mapEmployeesToClient(
  employees: Company.Employees.Response['data'],
): EmployeeStateModel[] {
  return employees.map(mapEmployeeToClient);
}

export function mapReadEmployeesApiParams({
  elements,
  page,
  role,
  search,
}: {
  elements: EmployeesStateModel['list']['length'];
  page: EmployeesStateModel['currentPage'];
} & EmployeesFilters): Company.Employees.Params {
  return {
    elements: elements.toString(),
    page: page.toString(),
    role: role === '' || typeof role === 'undefined' ? '' : mapRoleToApi(role).toString(),
    search: search ?? '',
  };
}
