import { Desk } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { GroupStateModel } from '../group';
import { DeskStateModel } from './desk.model';
import { DeskType, DeskTypes } from './desk.type';

export function mapDeskToApi(
  {
    active,
    amenities,
    audiences,
    coordinates,
    exclusivityAssignment,
    id,
    name,
  }: DeepPartial<DeskStateModel>,
  groups: GroupStateModel[],
): DeepPartial<Desk.Update.Body> {
  return {
    active,
    amenities,
    assigned: {
      exclusive_assignment: exclusivityAssignment,
      assignee:
        audiences === null
          ? []
          : audiences?.map(audience => ({
              entity_id: audience,
              entity: entityFromId(audience, groups),
            })),
    },
    coordinates,
    id,
    name,
  };
}

export function mapDeskToClient({
  active,
  amenities,
  assigned,
  coordinates,
  id,
  name,
}: Desk.Read.Response): DeskStateModel {
  return {
    active,
    amenities,
    audiences: assigned?.assignee?.map(({ entity_id }) => entity_id),
    coordinates,
    exclusivityAssignment: assigned?.exclusive_assignment ?? false,
    id,
    name,
    // @todo(heavybeard): remove this experiment when a desk type is ready on endpoint
    type: mapDeskTypeToClient(name),
  };
}

function mapDeskTypeToClient(name?: Desk.Read.Response['name']): DeskType {
  if (name?.includes('toilette')) {
    return DeskTypes.toilette;
  }

  return DeskTypes.desk;
}

function entityFromId(
  audience: string,
  groups: GroupStateModel[],
): NonNullable<NonNullable<Desk.Update.Body['assigned']>['assignee']>[number]['entity'] {
  if (groups.find(group => group.id === audience)) {
    return 'group';
  }

  return 'user';
}
