import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Employee } from './employee.type';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  assignBuilding({
    user,
    buildings,
  }: Employee.AssignBuilding.Body): Observable<Employee.AssignBuilding.Response> {
    return this.httpClient
      .put<Employee.AssignBuilding.Response>(`${this.apiRootUrl}/company/employee/delivery`, {
        user,
        buildings,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  create({
    role,
    send_invitation,
    user,
  }: Employee.Create.Params): Observable<Employee.Create.Response> {
    return this.httpClient
      .post<Employee.Create.Response>(`${this.apiRootUrl}/company/employee`, {
        role,
        send_invitation,
        user,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete({ ids }: Employee.Delete.Params): Observable<Employee.Delete.Response> {
    return this.httpClient
      .request<Employee.Delete.Response>('delete', `${this.apiRootUrl}/company/employees`, {
        body: { ids },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  invite({ ids }: Employee.Invite.Params): Observable<Employee.Invite.Response> {
    return this.httpClient
      .post<Employee.Invite.Response>(`${this.apiRootUrl}/company/employees/invite`, { ids })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update({ id, role }: Employee.Update.Body): Observable<Employee.Update.Response> {
    return this.httpClient
      .put<Employee.Update.Response>(`${this.apiRootUrl}/company/employee`, { id, role })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
