import { Versions } from '@business/version';
import { DebugLevels } from '@nibol/logger';
import { LanguageCodes } from '@nibol/translation';

export const environment = {
  apiUrl: 'https://api.nibol.dev/v2/business',
  authUrl: 'https://api.nibol.dev/v2/login',
  debugLevel: DebugLevels.debug,
  defaultLanguage: LanguageCodes['en-gb'],
  env: 'dev',
  googleKey: 'AIzaSyC7d23ugHdUF4QuEuifDCku1am51Li5R1g',
  googleRecaptchaApiKey: '6Ldm1kseAAAAAHDZ2kQ8yWSDh7xv3iVbr6-zebhC',
  hmr: false,
  homepage: '/dashboard',
  iconUrl: '/assets/icons/',
  logo: '/assets/logo.svg',
  origin: 'https://business.nibol.dev',
  platformCode: 'bus',
  platformName: 'Nibol Business',
  prismicApiEndpoint: 'https://nibol.cdn.prismic.io/api/v2',
  prismicDocuments: ['business', 'lists', 'manager_app'],
  production: false,
  project: 'business',
  sentryDsn: 'https://65e2806484ff4ce9962acab76b13c4ec@o550124.ingest.sentry.io/5673331',
  vapidPublicKey:
    'BCq1AE0aB2Aa_qFNvqNp47f-FZe8z5Z_E4WMfWY451G3KXWy1gtCPJJYu2gZsI4kTfm9yllSDABuX2PJum9tv18',
  version: Versions['4.3.1'],
  wootricAccountToken: 'NPS-62daee8a',
  app50: 'https://app.nibol.dev',
};
