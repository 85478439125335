import { Injectable } from '@angular/core';
import { DesksService, SpaceService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import {
  append,
  compose,
  iif as ngxsIif,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { defer, iif } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { mapDeskToClient } from '../desk/desk.helper';
import { DeskStateModel } from '../desk/desk.model';
import { Reservation } from '../reservations';
import { DesksStateAction } from './desks.actions';
import {
  mapDesksReservationsApiParams,
  mapDesksReservationsToClient,
  mapDesksSettingsToApi,
  mapDesksSettingsToClient,
} from './desks.helper';
import { DesksStateModel } from './desks.model';

@State<DesksStateModel>({
  name: 'desks',
  defaults: {
    list: [],
    reservations: [],
    reservationsCurrentPage: -1,
    reservationsReachedElement: -1,
    reservationsReachedPage: -1,
    reservationsTotalElements: 0,
    reservationsTotalPages: 1,
  },
})
@Injectable()
export class DesksState {
  constructor(
    private readonly desksService: DesksService,
    private readonly spaceService: SpaceService,
  ) {}

  @Action(DesksStateAction.List.Add.Try)
  addItemInList(
    { dispatch, getState, setState }: StateContext<DesksStateModel>,
    { desk }: DesksStateAction.List.Add.Try,
  ) {
    try {
      setState(
        patch({
          list: append([desk]),
        }),
      );

      dispatch(new DesksStateAction.List.Add.Success(getState()));
    } catch (error) {
      dispatch(new DesksStateAction.List.Add.Failure(error));

      throw error;
    }
  }

  @Action(DesksStateAction.Reservations.Delete.Try)
  deleteReservations(
    { dispatch, getState, setState }: StateContext<DesksStateModel>,
    { ids }: DesksStateAction.Reservations.Delete.Try,
  ) {
    return this.desksService.deleteReservations({ reservation_ids: ids }).pipe(
      catchError(error => {
        dispatch(new DesksStateAction.Reservations.Delete.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          setState(
            patch({
              reservations: getState().reservations.filter(
                reservation => !ids.includes(reservation.id),
              ),
            }),
          );

          dispatch(new DesksStateAction.Reservations.Delete.Success(getState()));
        }
      }),
    );
  }

  @Action(DesksStateAction.List.Read.Try)
  readListItem(
    { dispatch, getState, setState }: StateContext<DesksStateModel>,
    { spaceId }: DesksStateAction.List.Read.Try,
  ) {
    return this.spaceService.desks(spaceId).pipe(
      catchError(error => {
        dispatch(new DesksStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(desks => {
        setState(patch({ list: desks.map(mapDeskToClient) }));

        dispatch(new DesksStateAction.List.Read.Success(getState()));
      }),
    );
  }

  @Action(DesksStateAction.Reservations.Read.Try)
  readReservations(
    { dispatch, getState, setState }: StateContext<DesksStateModel>,
    { buildingId }: DesksStateAction.Reservations.Read.Try,
  ) {
    return iif(
      () =>
        getState().reservationsCurrentPage + 1 < getState().reservationsTotalPages &&
        getState().reservationsReachedElement < getState().reservationsTotalElements,
      this.desksService.reservations(
        mapDesksReservationsApiParams({
          buildingId,
          elements: 40,
          page: getState().reservationsCurrentPage + 1,
        }),
      ),
      defer(() => {
        dispatch(new DesksStateAction.Reservations.Read.Cancel());
      }),
    ).pipe(
      catchError(error => {
        dispatch(new DesksStateAction.Reservations.Read.Failure(error));

        throw error;
      }),
      tap(({ data, _metadata }) => {
        setState(
          patch<DesksStateModel>({
            ...(_metadata.page > getState().reservationsCurrentPage
              ? {
                  reservationsReachedPage: _metadata.page,
                  reservationsReachedElement: getState().reservations.length + data.length,
                }
              : {}),
            reservations: compose(
              ...mapDesksReservationsToClient(data).map(reservation =>
                ngxsIif<Reservation[]>(
                  reservationsFromStore =>
                    reservationsFromStore?.some(
                      reservationFromStore => reservationFromStore.id === reservation.id,
                    ) ?? false,
                  updateItem<Reservation>(
                    reservationStore => reservationStore?.id === reservation.id,
                    patch(reservation),
                  ),
                  append([reservation]),
                ),
              ),
            ),
            reservationsCurrentPage: _metadata.page,
            reservationsTotalElements: _metadata.total_elements,
            reservationsTotalPages: _metadata.total_pages,
          }),
        );

        dispatch(new DesksStateAction.Reservations.Read.Success(getState()));
      }),
    );
  }

  @Action(DesksStateAction.Settings.Read.Try)
  readSettings(
    { dispatch, getState, setState }: StateContext<DesksStateModel>,
    { buildingId }: DesksStateAction.Settings.Read.Try,
  ) {
    return this.desksService.settings(buildingId).pipe(
      catchError(error => {
        dispatch(new DesksStateAction.Settings.Read.Failure(error));

        throw error;
      }),
      tap(roomsSettings => {
        setState(patch(mapDesksSettingsToClient(roomsSettings)));

        dispatch(new DesksStateAction.Settings.Read.Success(getState()));
      }),
    );
  }

  @Action(DesksStateAction.List.Remove.Try)
  removeItemFromList(
    { dispatch, getState, setState }: StateContext<DesksStateModel>,
    { id }: DesksStateAction.List.Remove.Try,
  ) {
    try {
      setState(
        patch({
          list: removeItem<DeskStateModel>(desk => desk?.id === id),
        }),
      );

      dispatch(new DesksStateAction.List.Remove.Success(getState()));
    } catch (error) {
      dispatch(new DesksStateAction.List.Remove.Failure(error));

      throw error;
    }
  }

  @Action(DesksStateAction.List.Update.Try)
  updateItemInList(
    { dispatch, getState, setState }: StateContext<DesksStateModel>,
    { desk }: DesksStateAction.List.Update.Try,
  ) {
    try {
      setState(
        patch({
          list: updateItem<DeskStateModel>(deskStore => deskStore?.id === desk.id, patch(desk)),
        }),
      );

      dispatch(new DesksStateAction.List.Update.Success(getState()));
    } catch (error) {
      dispatch(new DesksStateAction.List.Update.Failure(error));

      throw error;
    }
  }

  @Action(DesksStateAction.Settings.Update.Try)
  updateSettings(
    { dispatch, getState, setState }: StateContext<DesksStateModel>,
    { buildingId, data }: DesksStateAction.Settings.Update.Try,
  ) {
    return this.desksService.updateSettings(mapDesksSettingsToApi(buildingId, data)).pipe(
      catchError(error => {
        dispatch(new DesksStateAction.Settings.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data }));

        dispatch(new DesksStateAction.Settings.Update.Success(getState()));
      }),
    );
  }
}
