import { CustomEntitiesCategoryStateModel } from '../custom-entities';
import { CustomEntityStateModel } from '../custom-entity';
import { DeskStateModel } from '../desk';
import { ParkingStateModel } from '../parking';
import { RoomStateModel } from '../room';
import { SpaceAmenities, SpaceMap, SpaceType } from './space.type';

export class SpaceStateModel {
  id!: string;
  name!: string;
  type!: SpaceType;
  categoryId?: CustomEntitiesCategoryStateModel['id'];
  customEntities?: CustomEntityStateModel[];
  customEntitiesAmount?: number;
  description?: string | null;
  deskAmenities?: SpaceAmenities;
  desks?: DeskStateModel[];
  desksAmount?: number;
  map?: SpaceMap;
  maxCapacity?: number | null;
  parkingAmenities?: SpaceAmenities;
  parkings?: ParkingStateModel[];
  parkingsAmount?: number;
  pictures?: string[];
  roomAmenities?: SpaceAmenities;
  rooms?: RoomStateModel[];
  roomsAmount?: number;
}
