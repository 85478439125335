import { EmployeeStateModel } from '../employee';
import { GroupLimits, GroupPermissions } from './group.type';

export class GroupStateModel {
  id!: string;
  uid!: string;
  members!: EmployeeStateModel[];
  totalMembers!: number;
  budget?: number;
  color?: string;
  description?: string;
  limits?: GroupLimits;
  name?: string;
  permissions?: GroupPermissions;
}
