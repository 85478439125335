import { Selector } from '@ngxs/store';
import { CustomEntitiesStateModel } from './custom-entities.model';
import { CustomEntitiesState } from './custom-entities.state';

export class CustomEntitiesSelectors {
  @Selector([CustomEntitiesState])
  static checkIn({ checkIn }: CustomEntitiesStateModel): CustomEntitiesStateModel['checkIn'] {
    return checkIn;
  }

  @Selector([
    CustomEntitiesSelectors.reservationsReachedElement,
    CustomEntitiesSelectors.reservationsTotalElements,
  ])
  static hasFurtherReservations(
    reservationsReachedElement: number,
    reservationsTotalElements: number,
  ): boolean {
    return reservationsReachedElement < reservationsTotalElements;
  }

  @Selector([CustomEntitiesState])
  static isViewColleaguesEnabled({
    isViewColleaguesEnabled,
  }: CustomEntitiesStateModel): CustomEntitiesStateModel['isViewColleaguesEnabled'] {
    return isViewColleaguesEnabled;
  }

  @Selector([CustomEntitiesState])
  static list({ list }: CustomEntitiesStateModel): CustomEntitiesStateModel['list'] {
    return list;
  }

  @Selector([CustomEntitiesState])
  static reservations({
    reservations,
  }: CustomEntitiesStateModel): CustomEntitiesStateModel['reservations'] {
    return reservations;
  }

  @Selector([CustomEntitiesState])
  static reservationsReachedElement({
    reservationsReachedElement,
  }: CustomEntitiesStateModel): CustomEntitiesStateModel['reservationsReachedElement'] {
    return reservationsReachedElement;
  }

  @Selector([CustomEntitiesState])
  static reservationsTotalElements({
    reservationsTotalElements,
  }: CustomEntitiesStateModel): CustomEntitiesStateModel['reservationsTotalElements'] {
    return reservationsTotalElements;
  }
}
