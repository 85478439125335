import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({ providedIn: 'root' })
export class InitialPathGuard implements CanActivate {
  @Dispatch() navigateToPath = (path: string) => new Navigate([path], {});

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const initialPath = window.localStorage.getItem('initialPath');
    if (initialPath && initialPath !== document.location.pathname) {
      this.navigateToPath(initialPath);
      window.localStorage.removeItem('initialPath');
      return false;
    }

    return true;
  }
}
