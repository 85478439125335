import { Injectable } from '@angular/core';
import { SpaceService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { catchError, tap } from 'rxjs/operators';
import { SpaceStateAction } from './space.actions';
import { mapSpaceToApi, mapSpaceToClient, mapSpaceToCreateApi } from './space.helper';
import { SpaceStateModel } from './space.model';

@State<SpaceStateModel>({
  name: 'space',
})
@Injectable()
export class SpaceState {
  constructor(private readonly spaceService: SpaceService) {}

  @Action(SpaceStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<SpaceStateModel>,
    { officeId, type, name, category }: SpaceStateAction.Create.Try,
  ) {
    return this.spaceService
      .create({ office_id: officeId, space: mapSpaceToCreateApi({ name, type }, category) })
      .pipe(
        catchError(error => {
          dispatch(new SpaceStateAction.Create.Failure(error));

          throw error;
        }),
        tap(({ id }) => {
          setState({
            id,
            type,
            name,
            customEntitiesAmount: 0,
            desksAmount: 0,
            parkingsAmount: 0,
            roomsAmount: 0,
          });

          dispatch(new SpaceStateAction.Create.Success(getState()));
        }),
      );
  }

  @Action(SpaceStateAction.Delete.Try)
  delete(
    { dispatch, getState }: StateContext<SpaceStateModel>,
    { id }: SpaceStateAction.Delete.Try,
  ) {
    return this.spaceService.delete(id).pipe(
      catchError(error => {
        dispatch(new SpaceStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        await dispatch(new SpaceStateAction.Delete.Success(getState())).toPromise();

        dispatch(new StateReset(SpaceState));
      }),
    );
  }

  @Action(SpaceStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<SpaceStateModel>,
    { id }: SpaceStateAction.Read.Try,
  ) {
    return this.spaceService.read(id).pipe(
      catchError(error => {
        dispatch(new SpaceStateAction.Read.Failure(error));

        throw error;
      }),
      tap(space => {
        setState(patch(mapSpaceToClient(space)));

        dispatch(new SpaceStateAction.Read.Success(getState()));
      }),
    );
  }

  @Action(SpaceStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<SpaceStateModel>,
    { id, data }: SpaceStateAction.Update.Try,
  ) {
    return this.spaceService
      .update(id, mapSpaceToApi({ ...data, categoryId: getState().categoryId }))
      .pipe(
        catchError(error => {
          dispatch(new SpaceStateAction.Update.Failure(error));

          throw error;
        }),
        tap(() => {
          setState(patch({ ...data, id }));

          dispatch(new SpaceStateAction.Update.Success(getState()));
        }),
      );
  }

  @Action(SpaceStateAction.UploadMap.Try)
  uploadMap(
    { dispatch, getState }: StateContext<SpaceStateModel>,
    { id, map: mapFile }: SpaceStateAction.UploadMap.Try,
  ) {
    const uploadData = new FormData();
    uploadData.append('space_id', id);
    uploadData.append('map', mapFile, mapFile.name);

    return this.spaceService.uploadMap(uploadData).pipe(
      catchError(error => {
        dispatch(new SpaceStateAction.UploadMap.Failure(error));

        throw error;
      }),
      tap(map => {
        dispatch(new SpaceStateAction.Update.Try(id, { map }));
      }),
      catchError(error => {
        dispatch(new SpaceStateAction.UploadMap.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new SpaceStateAction.UploadMap.Success(getState()));
      }),
    );
  }

  @Action(SpaceStateAction.UploadPhoto.Try)
  uploadPhoto(
    { dispatch, getState }: StateContext<SpaceStateModel>,
    { photo, id }: SpaceStateAction.UploadPhoto.Try,
  ) {
    const uploadData = new FormData();
    uploadData.append('space_id', id);
    uploadData.append('image_1', photo, photo.name);

    return this.spaceService.uploadPhoto(uploadData).pipe(
      catchError(error => {
        dispatch(new SpaceStateAction.UploadPhoto.Failure(error));

        throw error;
      }),
      tap(uploads => {
        dispatch(
          new SpaceStateAction.Update.Try(id, {
            pictures: uploads.map(upload => upload.url),
          }),
        );
      }),
      catchError(error => {
        dispatch(new SpaceStateAction.UploadPhoto.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new SpaceStateAction.UploadPhoto.Success(getState()));
      }),
    );
  }
}
