// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { CustomEntityStateModel } from '../custom-entity/custom-entity.model';
import { SpaceStateModel } from '../space';
import { CustomEntitiesStateModel } from './custom-entities.model';

export namespace CustomEntitiesStateAction {
  export namespace List {
    export namespace Add {
      export class Try {
        static readonly type = '[Custom Entities] try Add Custom Entity to List';
        constructor(public customEntity: CustomEntityStateModel) {}
      }
      export class Success {
        static readonly type = '[Custom Entities] then Add Custom Entity to List success';
        constructor(public customEntities: CustomEntitiesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Custom Entities] catch Add Custom Entity to List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Custom Entities] try Read Custom Entities List';
        constructor(public spaceId: SpaceStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Custom Entities] then Read Custom Entities List success';
        constructor(public customEntities: CustomEntitiesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Custom Entities] catch Read Custom Entities List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Remove {
      export class Try {
        static readonly type = '[Custom Entities] try Remove Custom Entity from List';
        constructor(public id: CustomEntityStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Custom Entities] then Remove Custom Entity from List success';
        constructor(public customEntities: CustomEntitiesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Custom Entities] catch Remove Custom Entity from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Custom Entities] try Update Custom Entity in List';
        constructor(public customEntity: CustomEntityStateModel) {}
      }
      export class Success {
        static readonly type = '[Custom Entities] then Update Custom Entity in List success';
        constructor(public customEntities: CustomEntitiesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Custom Entities] catch Update Custom Entity in List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Reservations {
    export namespace Read {
      export class Try {
        static readonly type = '[Custom Entities] try Read Custom Entities Reservations';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Custom Entities] then Read Custom Entities Reservations success';
        constructor(public customEntities: CustomEntitiesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Custom Entities] catch Read Custom Entities Reservations failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
      export class Cancel {
        static readonly type = '[Custom Entities] catch Read Custom Entities Reservations cancel';
        constructor(public reason?: string) {}
      }
    }
  }

  export namespace Settings {
    export namespace Read {
      export class Try {
        static readonly type = '[Custom Entities] try Read Custom Entities Settings';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Custom Entities] then Read Custom Entities Settings success';
        constructor(public customEntities: CustomEntitiesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Custom Entities] catch Read Custom Entities Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Custom Entities] try Update Custom Entities Settings';
        constructor(
          public buildingId: BuildingStateModel['id'],
          public data: DeepPartial<Pick<CustomEntitiesStateModel, 'checkIn'>>,
        ) {}
      }
      export class Success {
        static readonly type = '[Custom Entities] then Update Custom Entities Settings success';
        constructor(public customEntities: CustomEntitiesStateModel) {}
      }
      export class Failure {
        static readonly type = '[Custom Entities] catch Update Custom Entities Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
