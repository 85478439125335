// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { CompanyStateModel } from './company.model';
import { CompanySettingsFeatures, SpacesSettings } from './company.type';

export namespace CompanyStateAction {
  export namespace Analytics {
    export class Try {
      static readonly type = '[Company] try Read Company Analytics';
    }
    export class Success {
      static readonly type = '[Company] then Read Company Analytics success';
      constructor(public company: CompanyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Read Company Analytics failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Billing {
    export namespace Read {
      export class Try {
        static readonly type = '[Company] try Read Company Billing';
      }
      export class Success {
        static readonly type = '[Company] then Read Company Billing success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Read Company Billing failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Company] try Update Company Billing';
        constructor(public billing: DeepPartial<CompanyStateModel['billing']>) {}
      }
      export class Success {
        static readonly type = '[Company] then Update Company Billing success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Update Company Billing failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace CompanyPolicy {
    export namespace Read {
      export class Try {
        static readonly type = '[Company] try Read Company Policy';
      }
      export class Success {
        static readonly type = '[Company] then Read Company Policy success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Read Company Policy failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Company] try Update Company Policy';
        constructor(
          public companyPolicy: CompanyStateModel['companyPolicy'],
          public requireAcceptance: CompanySettingsFeatures['require_accept_company_policy'],
        ) {}
      }
      export class Success {
        static readonly type = '[Company] then Update Company Policy success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Update Company Policy failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Countries {
    export class Try {
      static readonly type = '[Company] try Read Company Available Countries';
    }
    export class Success {
      static readonly type = '[Company] then Read Company Available Countries success';
      constructor(public company: CompanyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Read Company Available Countries failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Info {
    export namespace Read {
      export class Try {
        static readonly type = '[Company] try Read Company Info';
      }
      export class Success {
        static readonly type = '[Company] then Read Company Info success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Read Company Info failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Company] try Update Company Info';
        constructor(public company: DeepPartial<CompanyStateModel>) {}
      }
      export class Success {
        static readonly type = '[Company] then Update Company Info success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Update Company Info failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Spaces {
    export namespace Read {
      export class Try {
        static readonly type = '[Company] try Read Company Spaces';
      }
      export class Success {
        static readonly type = '[Company] then Read Company Spaces success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Read Company Spaces failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Company] try Update Company Spaces';
        constructor(public spacesSettings: Pick<SpacesSettings, 'enabled'>) {}
      }
      export class Success {
        static readonly type = '[Company] then Update Company Spaces success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Update Company Spaces failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace SpacesActivate {
    export class Try {
      static readonly type = '[Company] try Activate Company Spaces on demand';
    }
    export class Success {
      static readonly type = '[Company] then Activate Company Spaces on demand success';
      constructor(public company: CompanyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Activate Company Spaces on demand failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Store {
    export class Try {
      static readonly type = '[Company] try Store Company';
      constructor(public company: DeepPartial<CompanyStateModel>) {}
    }
    export class Success {
      static readonly type = '[Company] then Store Company success';
      constructor(public company: CompanyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Store Company failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Structure {
    export class Try {
      static readonly type = '[Company] try Read Company Structure';
    }
    export class Success {
      static readonly type = '[Company] then Read Company Structure success';
      constructor(public company: CompanyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Read Company Structure failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace SubProccessor {
    export class Try {
      static readonly type = '[Company] try Read Company Sub Proccessor';
    }
    export class Success {
      static readonly type = '[Company] then Read Company Sub Proccessor success';
      constructor(public company: CompanyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Read Company Sub Proccessor failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Subscription {
    export class Try {
      static readonly type = '[Company] try Read Company Subscription';
    }
    export class Success {
      static readonly type = '[Company] then Read Company Subscription success';
      constructor(public company: CompanyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Read Company Subscription failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Terms {
    export namespace Read {
      export class Try {
        static readonly type = '[Company] try Read Company Terms';
      }
      export class Success {
        static readonly type = '[Company] then Read Company Terms success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Read Company Terms failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Company] try Update Company Terms';
        constructor(
          public terms: CompanyStateModel['terms'],
          public requireAcceptance: CompanySettingsFeatures['require_custom_terms_privacy'],
        ) {}
      }
      export class Success {
        static readonly type = '[Company] then Update Company Terms success';
        constructor(public company: CompanyStateModel) {}
      }
      export class Failure {
        static readonly type = '[Company] catch Update Company Terms failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace UploadLogo {
    export class Try {
      static readonly type = '[Company] try Upload Logo to Company';
      constructor(public logo: File) {}
    }
    export class Success {
      static readonly type = '[Company] then Upload Logo to Company success';
      constructor(public company: CompanyStateModel) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Upload Logo to Company failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
