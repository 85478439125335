// tslint:disable: no-namespace

export namespace Survey {
  export namespace List {
    export type Response = Array<{
      active: Questions.Response['active'];
      id: Questions.Response['id'];
      name: Questions.Response['name'];
      questions_count: number;
    }>;
  }

  export namespace Questions {
    export type Params = {
      id: Response['id'];
    };
    export type Response = {
      id: string;
      name: string;
      active: boolean;
      questions: Array<{
        id: string;
        text: { key: string; text: { en: string; es: string; fr: string; it: string } };
        type: 'yesNo';
        required: boolean;
        order: number;
        answers_type: string | boolean;
        answers: Array<{
          id: string;
          value: string | boolean | number;
          text: { key: string; text: { en: string; es: string; fr: string; it: string } };
          order: 0;
        }>;
        outcome: Array<{
          id: string;
          value: string | boolean | number;
          type: 'message' | 'next';
          args: string | { key: string; text: { en: string; es: string; fr: string; it: string } };
        }>;
      }>;
    };
  }

  export namespace Responses {
    export namespace ExportCsv {
      export type Body = {
        survey_id: Questions.Response['id'];
        from: number;
        to: number;
      };
      export type Response = Blob;
    }
  }

  export namespace Triggers {
    export type Params = {
      id: Questions.Response['id'];
    };
    export type Response = {
      id: Questions.Response['id'];
      triggers: Array<{
        id: string;
        audiences: Array<{
          entity: 'user' | 'group';
          entity_data: { id: string; name: string };
        }> | null;
        hook: {
          time: 'before' | 'after';
          entity: 'desk_reservation' | 'visitor_reservation';
          action: 'checkin' | 'booking';
        };
        locations: Array<{
          entity: 'building';
          entity_data: { id: 'string'; name: 'string' };
        }> | null;
      }>;
    };
  }
}
