import { Integrations } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { IntegrationsStateModel } from './integrations.model';
import {
  DirectoryIntegrationSettings,
  GoogleWorkspaceRoomsSettings,
  Microsoft365RoomsSettings,
} from './integrations.type';

export function mapGoogleWorkspaceDirectoryToApi(
  settings: DeepPartial<IntegrationsStateModel['googleWorkspaceDirectory']>,
): DeepPartial<Pick<Integrations.GoogleWorkspaceDirectory.Read.Response, 'enabled' | 'mapping'>> {
  return {
    ...mapDirectoryIntegrationToApi(settings),
  };
}

export function mapGoogleWorkspaceDirectoryToClient(
  settings: DeepPartial<Integrations.GoogleWorkspaceDirectory.Read.Response>,
): DeepPartial<IntegrationsStateModel['googleWorkspaceDirectory']> {
  return {
    ...mapDirectoryIntegrationToClient(settings),
    integrationLink: settings.integration_link === '' ? undefined : settings.integration_link,
    isAuthNegated: settings.auth_negated,
  };
}

export function mapGoogleWorkspaceRoomsToApi({
  checkIn,
}: DeepPartial<
  Pick<GoogleWorkspaceRoomsSettings, 'checkIn'>
>): DeepPartial<Integrations.GoogleWorkspaceRooms.Update.Params> {
  return {
    settings: {
      ...((checkIn?.interval?.to ||
        checkIn?.interval?.from ||
        checkIn?.missingPolicy ||
        checkIn?.nearbyDistance) && {
        reservation_checkin_settings: {
          after_from: checkIn?.interval?.to,
          before_from: checkIn?.interval?.from,
          missed_policy: checkIn?.missingPolicy,
        },
      }),
      reservation_checkin: checkIn?.isEnabled,
    },
  };
}

export function mapGoogleWorkspaceRoomsToClient(
  rooms: Integrations.GoogleWorkspaceRooms.Read.Response,
): IntegrationsStateModel['googleWorkspaceRooms'] {
  return {
    calendarIntegration: rooms.info
      ? {
          accountName: rooms.info.account,
          roomsAmount: rooms.info.count,
          source: rooms.info.source,
        }
      : undefined,
    checkIn: {
      isEnabled: rooms.settings.reservation_checkin,
      interval: {
        from: rooms.settings.reservation_checkin_settings?.before_from,
        to: rooms.settings.reservation_checkin_settings?.after_from,
      },
      missingPolicy: rooms.settings.reservation_checkin_settings?.missed_policy,
    },
    completed: rooms.completed,
    enabled: rooms.enabled,
    integrationLink: rooms.integration_link,
  };
}

export function mapKisiToApi(
  settings: Partial<IntegrationsStateModel['kisi']>,
): Partial<Integrations.Kisi.Read.Response> {
  return {
    api_key: settings?.apiKey,
    enabled: settings?.enabled,
    mapping: settings?.buildingGroupAssociation?.map(buildingGroupAssociation => ({
      building_id: buildingGroupAssociation.buildingId,
      group_id: buildingGroupAssociation.groupId,
    })),
  };
}

export function mapKisiToClient(
  settings: Integrations.Kisi.Read.Response,
): IntegrationsStateModel['kisi'] {
  return {
    apiKey: settings.api_key,
    enabled: settings.enabled,
    buildingGroupAssociation: settings.mapping.map(buildingGroupAssociation => ({
      buildingId: buildingGroupAssociation.building_id,
      groupId: buildingGroupAssociation.group_id,
    })),
  };
}

export function mapSofiaToApi(
  settings: Partial<IntegrationsStateModel['sofia']>,
): Partial<Integrations.Sofia.Read.Response> {
  return {
    domain: settings?.domain,
    token: settings?.token,
    enabled: settings?.enabled,
    mapping: settings?.officeTags
      ? settings.officeTags
          // .filter(officeTag => officeTag.tagId)
          .map(officeTag => ({
            office_id: officeTag.office,
            user_tag_id: officeTag.tagId,
          }))
      : [],
  };
}

export function mapSofiaToClient(
  settings: Integrations.Sofia.Read.Response,
): IntegrationsStateModel['sofia'] {
  return {
    domain: settings.domain,
    token: settings.token,
    enabled: settings.enabled,
    userTagsList: settings?.userTagsList || [],
    officeTags: settings.mapping.map(mapping => ({
      office: mapping.office_id,
      tagId: mapping.user_tag_id,
    })),
  };
}

export function mapMicrosoft365RoomsToApi({
  checkIn,
}: DeepPartial<
  Pick<Microsoft365RoomsSettings, 'checkIn'>
>): DeepPartial<Integrations.Microsoft365Rooms.Update.Params> {
  return {
    settings: {
      ...((checkIn?.interval?.to ||
        checkIn?.interval?.from ||
        checkIn?.missingPolicy ||
        checkIn?.nearbyDistance) && {
        reservation_checkin_settings: {
          after_from: checkIn?.interval?.to,
          before_from: checkIn?.interval?.from,
          missed_policy: checkIn?.missingPolicy,
        },
      }),
      reservation_checkin: checkIn?.isEnabled,
    },
  };
}

export function mapMicrosoft365RoomsToClient(
  rooms: Integrations.Microsoft365Rooms.Read.Response,
): IntegrationsStateModel['microsoft365Rooms'] {
  return {
    calendarIntegration: rooms.info
      ? { source: rooms.info.source, roomsAmount: rooms.info.count }
      : undefined,
    checkIn: {
      isEnabled: rooms.settings.reservation_checkin,
      interval: {
        from: rooms.settings.reservation_checkin_settings?.before_from,
        to: rooms.settings.reservation_checkin_settings?.after_from,
      },
      missingPolicy: rooms.settings.reservation_checkin_settings?.missed_policy,
    },
    completed: rooms.completed,
    enabled: rooms.enabled,
    integrationLink: rooms.integration_link,
  };
}

export function mapMicrosoftAzureActiveDirectoryToApi(
  settings: DeepPartial<IntegrationsStateModel['microsoftAzureActiveDirectory']>,
): DeepPartial<Pick<Integrations.AzureActiveDirectory.Read.Response, 'enabled' | 'mapping'>> {
  return {
    ...mapDirectoryIntegrationToApi(settings),
  };
}

export function mapMicrosoftAzureActiveDirectoryToClient(
  settings: DeepPartial<Integrations.AzureActiveDirectory.Read.Response>,
): DeepPartial<IntegrationsStateModel['microsoftAzureActiveDirectory']> {
  return {
    ...mapDirectoryIntegrationToClient(settings),
    integrationLink: settings.integration_link === '' ? undefined : settings.integration_link,
  };
}

export function mapOktaDirectoryToApi(
  settings: DeepPartial<IntegrationsStateModel['oktaDirectory']>,
): DeepPartial<
  Pick<Integrations.OktaDirectory.Read.Response, 'api_key' | 'domain_url' | 'enabled' | 'mapping'>
> {
  return {
    ...mapDirectoryIntegrationToApi(settings),
    api_key: settings?.apiKey,
    domain_url: settings?.domain,
  };
}

export function mapOktaDirectoryToClient(
  settings: DeepPartial<Integrations.OktaDirectory.Read.Response>,
): DeepPartial<IntegrationsStateModel['oktaDirectory']> {
  return {
    ...mapDirectoryIntegrationToClient(settings),
    apiKey: settings.api_key === '' ? undefined : settings.api_key,
    domain: settings.domain_url === '' ? undefined : settings.domain_url,
  };
}

export function mapRoomsListToClient(
  rooms: Integrations.Rooms.List.Read.Response,
): IntegrationsStateModel['rooms'] {
  return rooms.map(room => ({
    active: true,
    coordinates: [{ lat: 0, lng: 0 }],
    id: room.id,
    name: room.name,
    seats: room.seats,
  }));
}

export function mapSlackToClient(
  slack: Integrations.Slack.Read.Response,
): IntegrationsStateModel['slack'] {
  return { enabled: slack.enabled, integrationLink: slack.integration_link };
}

function mapDirectoryIntegrationToApi(
  settings?: DeepPartial<DirectoryIntegrationSettings>,
): DeepPartial<
  | Pick<Integrations.AzureActiveDirectory.Read.Response, 'enabled' | 'mapping'>
  | Pick<Integrations.GoogleWorkspaceDirectory.Read.Response, 'enabled' | 'mapping'>
  | Pick<Integrations.OktaDirectory.Read.Response, 'enabled' | 'mapping'>
> {
  return {
    enabled: settings?.enabled,
    mapping: settings?.fieldsAssociation?.map(fieldAssociation => ({
      active: fieldAssociation.isActive,
      local_field: fieldAssociation.fieldFromNibol,
      remote_path: fieldAssociation.fieldFromIntegration,
      remote_values: fieldAssociation.valuesAssociation?.map(valueAssociation => ({
        mapped_values: valueAssociation.valuesFromIntegration,
        value: valueAssociation.valueFromNibol?.value,
      })),
    })),
  };
}

function mapDirectoryIntegrationToClient(
  settings:
    | DeepPartial<Integrations.AzureActiveDirectory.Read.Response>
    | DeepPartial<Integrations.GoogleWorkspaceDirectory.Read.Response>
    | DeepPartial<Integrations.OktaDirectory.Read.Response>,
): DeepPartial<DirectoryIntegrationSettings> {
  return {
    acceptCustomIntegrationFields: settings.accept_custom_remote_fields,
    availableFieldsFromIntegration: settings.remote_fields,
    enabled: settings.enabled,
    fieldsAssociation: settings.mapping?.map(entity => ({
      fieldFromIntegration: entity.remote_path,
      fieldFromNibol: entity.local_field,
      isActive: entity.active,
      isDisabled: entity.disabled,
      isEditable: entity.editable_path,
      valuesAssociation:
        entity.remote_values &&
        entity.remote_values.map(({ label, mapped_values, value }) => ({
          valueFromNibol: { label, value },
          valuesFromIntegration: mapped_values,
        })),
    })),
  };
}
