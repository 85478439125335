import { Selector } from '@ngxs/store';
import { ParkingStateModel } from './parking.model';
import { ParkingState } from './parking.state';

export class ParkingSelectors {
  @Selector([ParkingState])
  static amenities({ amenities }: ParkingStateModel): ParkingStateModel['amenities'] {
    return amenities;
  }

  @Selector([ParkingState])
  static coordinates({ coordinates }: ParkingStateModel): ParkingStateModel['coordinates'] {
    return coordinates;
  }

  @Selector([ParkingState])
  static audiences({ audiences }: ParkingStateModel): ParkingStateModel['audiences'] {
    return audiences;
  }

  @Selector([ParkingState])
  static id({ id }: ParkingStateModel): ParkingStateModel['id'] {
    return id;
  }

  @Selector([ParkingState])
  static isActive({ active }: ParkingStateModel): ParkingStateModel['active'] {
    return active;
  }

  @Selector([ParkingSelectors.audiences])
  static isAssigned(audiences: ParkingStateModel['audiences']): boolean {
    return audiences && audiences.length > 0 ? true : false;
  }

  @Selector([ParkingState])
  static fullname({ name }: ParkingStateModel): ParkingStateModel['name'] {
    return name;
  }

  @Selector([ParkingState])
  static type({ type }: ParkingStateModel): ParkingStateModel['type'] {
    return type;
  }
}
