import { Injectable } from '@angular/core';
import { environment } from '@business/environment';
import { FeatureToggles } from '@business/feature-toggles';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsAfterBootstrap, State, StateContext } from '@ngxs/store';
import {
  AuthSelectors,
  AuthStateAction,
  PermissionsStateAction,
  RegistrationStatuses,
  Roles,
} from '@nibol/auth';
import { UserState } from '@nibol/shared';
import { AppStateAction, FeatureTogglesStateAction } from '@nibol/store';
import { endOfWeek, startOfWeek } from 'date-fns';
import { isOn } from 'feature-toggle-service';
import { StateReset } from 'ngxs-reset-plugin';
import {
  BuildingSelectors,
  BuildingState,
  BuildingStateAction,
  BuildingStateModel,
} from './building';
import { BuildingsState, BuildingsStateAction } from './buildings';
import {
  CompanySelectors,
  CompanyState,
  CompanyStateAction,
  CompanyStateModel,
  SubscriptionFeatures,
  SubscriptionStatuses,
} from './company';
import {
  CustomEntitiesCategoryStateAction,
  CustomEntitiesState,
  CustomEntitiesStateAction,
} from './custom-entities';
import { CustomEntityState, CustomEntityStateAction } from './custom-entity';
import { DeliveriesStateAction } from './deliveries';
import { DeskState, DeskStateAction } from './desk';
import { DesksState, DesksStateAction } from './desks';
import { EmailState, EmailStateAction } from './email';
import { EmailsState, EmailsStateAction } from './emails';
import { EmployeeStateAction, EmployeeStateModel } from './employee';
import { EmployeeState } from './employee/employee.state';
import { EmployeesSelectors, EmployeesState, EmployeesStateAction } from './employees';
import { GroupState, GroupStateAction, GroupStateModel } from './group';
import { GroupsSelectors, GroupsState, GroupsStateAction } from './groups';
import { IntegrationsSelectors, IntegrationsState, IntegrationsStateAction } from './integrations';
import { OfficeSelectors, OfficeState, OfficeStateAction } from './office';
import { OfficesState, OfficesStateAction } from './offices';
import { ParkingStateAction } from './parking';
import { ParkingsStateAction } from './parkings';
import { RoomState, RoomStateAction } from './room';
import { RoomsState, RoomsStateAction } from './rooms';
import { SafeStateAction } from './safe';
import { SagaSelectors } from './saga.selectors';
import { SpaceSelectors, SpaceState, SpaceStateAction } from './space';
import { SpacesState, SpacesStateAction } from './spaces';
import { StatsStateAction } from './stats';
import { SurveysStateAction } from './surveys';
import { VisitorsState, VisitorsStateAction } from './visitors';

@State({
  name: 'saga',
})
@Injectable()
export class Saga implements NgxsAfterBootstrap {
  @SelectSnapshot(BuildingSelectors.id) buildingId!: string;
  @SelectSnapshot(CompanySelectors.buildings) buildings!: BuildingStateModel[];
  @SelectSnapshot(CompanySelectors.employees) employees!: EmployeeStateModel[];
  @SelectSnapshot(GroupsSelectors.everyoneGroup) everyoneGroup!: GroupStateModel | undefined;
  @SelectSnapshot(GroupsSelectors.list) groups!: GroupStateModel[];
  @SelectSnapshot(CompanySelectors.hasFeature(SubscriptionFeatures.safe_c19))
  hasSafeC19!: boolean;
  @SelectSnapshot(CompanySelectors.hasFeature(SubscriptionFeatures.slack)) hasSlack!: boolean;
  @SelectSnapshot(EmployeesSelectors.hasFurtherEmployees) hasFurtherEmployees!: boolean;
  @SelectSnapshot(AuthSelectors.isAuthenticated) isAuthenticated!: boolean;
  @SelectSnapshot(IntegrationsSelectors.isRoomsCompleted) isRoomsCompleted!: boolean;
  @SelectSnapshot(OfficeSelectors.id) officeId!: string;
  @SelectSnapshot(SpaceSelectors.id) spaceId!: string;
  @SelectSnapshot(CompanySelectors.subscription) subscription!: CompanyStateModel['subscription'];
  @SelectSnapshot(SagaSelectors.isEveryAudienceReferencedInLocal)
  isEveryAudienceReferencedInLocal!: boolean;

  ngxsAfterBootstrap({ dispatch }: StateContext<unknown>): void {
    dispatch(new AppStateAction.Init.Try());
  }

  @Action(BuildingStateAction.Create.Success)
  buildingCreateSuccess(
    { dispatch }: StateContext<unknown>,
    { building }: BuildingStateAction.Create.Success,
  ) {
    dispatch([
      new BuildingsStateAction.Add.Try(building),
      new Navigate([`/buildings/${building.id}`]),
    ]);
  }

  @Action(BuildingStateAction.Delete.Success)
  buildingDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { building: { id } }: BuildingStateAction.Delete.Success,
  ) {
    // @todo(heavybeard): edit employee locally instead of call endpoint
    dispatch([new BuildingsStateAction.Remove.Try(id), new Navigate([environment.homepage])]);
  }

  // tslint:disable-next-line: cyclomatic-complexity
  @Action(BuildingStateAction.Read.Success)
  async buildingReadSuccess(
    { dispatch }: StateContext<unknown>,
    { building }: BuildingStateAction.Read.Success,
  ) {
    await dispatch([
      ...(isOn(FeatureToggles.TOGGLE_ENABLE_CUSTOM_ENTITIES)
        ? [
            new CustomEntitiesCategoryStateAction.List.Try(building.id),
            new CustomEntitiesStateAction.Settings.Read.Try(building.id),
          ]
        : []),
      ...(isOn(FeatureToggles.TOGGLE_ENABLE_DELIVERIES)
        ? [new DeliveriesStateAction.Settings.Read.Try(building.id)]
        : []),
      ...(isOn(FeatureToggles.TOGGLE_ENABLE_PARKING)
        ? [new ParkingsStateAction.Settings.Read.Try(building.id)]
        : []),
      ...(isOn(FeatureToggles.TOGGLE_ENABLE_SAFE) && this.hasSafeC19
        ? [new SafeStateAction.Settings.Read.Try(building.id)]
        : []),
      ...(isOn(FeatureToggles.TOGGLE_ENABLE_VISITORS)
        ? [new VisitorsStateAction.Settings.Read.Try(building.id)]
        : []),
      new DesksStateAction.Settings.Read.Try(building.id),
      new OfficesStateAction.Read.Try(building.id),
      new RoomsStateAction.Settings.Read.Try(building.id),
    ]).toPromise();

    dispatch(new BuildingsStateAction.Update.Try(building));
  }

  @Action(BuildingStateAction.Update.Success)
  buildingUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { building }: BuildingStateAction.Update.Success,
  ) {
    dispatch(new BuildingsStateAction.Update.Try(building));
  }

  // tslint:disable-next-line: cyclomatic-complexity
  @Action(AuthStateAction.CheckIdentity.Success)
  checkIdentitySuccess(
    { dispatch }: StateContext<unknown>,
    {
      body: { token: email },
      response: { company, registration_statuses },
    }: AuthStateAction.CheckIdentity.Success,
  ) {
    if (company) {
      dispatch(new CompanyStateAction.Store.Try(company));
    }

    if (registration_statuses.includes(RegistrationStatuses.not_invited)) {
      dispatch([
        new AuthStateAction.SupportContacts.Try({ email }),
        new Navigate(['/access-denied'], { email, error: 'not-invited' }),
      ]);
    } else if (registration_statuses.includes(RegistrationStatuses.not_registered)) {
      dispatch(new Navigate(['/access-denied'], { email, error: 'not-registered' }));
    } else if (registration_statuses.includes(RegistrationStatuses.bus_manager)) {
      dispatch(new PermissionsStateAction.List.Add.Try([Roles.couldLogIn]));
    } else if (registration_statuses.includes(RegistrationStatuses.bus_employee)) {
      dispatch([
        new AuthStateAction.SupportContacts.Try({ email }),
        new Navigate(['/access-denied'], { email, error: 'business-employee' }),
      ]);
    } else if (registration_statuses.includes(RegistrationStatuses.mkt_manager)) {
      dispatch(new Navigate(['/access-denied'], { email, error: 'marketplace-manager' }));
    } else if (registration_statuses.includes(RegistrationStatuses.mkt_user)) {
      dispatch(new Navigate(['/access-denied'], { email, error: 'marketplace-user' }));
    } else if (!registration_statuses.includes(RegistrationStatuses.not_registered)) {
      dispatch(new PermissionsStateAction.List.Add.Try([Roles.couldLogIn]));
    }
  }

  @Action(RoomStateAction.CompanionClientList.Success)
  companionClientListSuccess(
    { dispatch }: StateContext<unknown>,
    { room }: RoomStateAction.CompanionClientList.Success,
  ) {
    dispatch(new RoomsStateAction.List.Add.Try(room));

    if (this.isRoomsCompleted) {
      dispatch(new IntegrationsStateAction.Rooms.List.Update.Try(room));
    }
  }

  @Action(CompanyStateAction.Info.Read.Success)
  companyInfoReadSuccess({ dispatch }: StateContext<unknown>) {
    dispatch([
      ...(this.hasSlack ? [new IntegrationsStateAction.Slack.Read.Try()] : []),
      new CompanyStateAction.Analytics.Try(),
      new CompanyStateAction.Billing.Read.Try(),
      new CompanyStateAction.CompanyPolicy.Read.Try(),
      new CompanyStateAction.Countries.Try(),
      new CompanyStateAction.Structure.Try(),
      new CompanyStateAction.SubProccessor.Try(),
      new CompanyStateAction.Subscription.Try(),
      new CompanyStateAction.Terms.Read.Try(),
      new CompanyStateAction.Spaces.Read.Try(),
      new EmailsStateAction.DesignSettings.Read.Try(),
      new EmailsStateAction.List.Read.Try(),
      new EmailsStateAction.SmtpSettings.Read.Try(),
      new EmployeesStateAction.List.Read.Try(),
      new GroupsStateAction.List.Read.Try(),
      new IntegrationsStateAction.GoogleWorkspaceDirectory.Read.Try(),
      new IntegrationsStateAction.GoogleWorkspaceRooms.Read.Try(),
      new IntegrationsStateAction.Kisi.Read.Try(),
      new IntegrationsStateAction.Sofia.Read.Try(),
      new IntegrationsStateAction.Microsoft365Rooms.Read.Try(),
      new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Read.Try(),
      new IntegrationsStateAction.OktaDirectory.Read.Try(),
      new StatsStateAction.Desks.Occupancy.Try(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ),
      new StatsStateAction.Desks.Bookings.Try(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ),
      new StatsStateAction.Members.Actives.Try(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ),
      new SurveysStateAction.Read.Try(),
    ]);
  }

  @Action(CompanyStateAction.SubProccessor.Success)
  companySubProcessorReadSuccess(
    { dispatch }: StateContext<unknown>,
    { company: { subProcessors } }: CompanyStateAction.SubProccessor.Success,
  ) {
    if (typeof subProcessors !== 'undefined') {
      dispatch(new FeatureTogglesStateAction.Update.Try(subProcessors));
    }
  }

  @Action(CompanyStateAction.Subscription.Success)
  companySubscriptionReadSuccess(
    { dispatch }: StateContext<unknown>,
    { company }: CompanyStateAction.Subscription.Success,
  ) {
    if (company.subscription?.status === SubscriptionStatuses.ended) {
      dispatch([
        new PermissionsStateAction.List.Remove.Try([Roles.hasValidSubscription]),
        new Navigate(['/billing']),
      ]);
    }

    if (typeof company.subscription?.features.safe_c19 === 'object') {
      company.subscription?.features.safe_c19.forEach(buildingId => {
        dispatch(
          new BuildingsStateAction.Update.Try({ id: buildingId, features: { safe_c19: true } }),
        );
      });
    }
  }

  @Action(CustomEntityStateAction.Create.Success)
  customEntityCreateSuccess({ dispatch }: StateContext<unknown>) {
    // @todo(heavybeard): change store locally instead of call endpoint
    dispatch([
      new CompanyStateAction.Structure.Try(),
      new CustomEntitiesStateAction.List.Read.Try(this.spaceId),
      new CustomEntitiesCategoryStateAction.List.Try(this.buildingId),
      new SpaceStateAction.Read.Try(this.spaceId),
    ]);
  }

  @Action(CustomEntityStateAction.Delete.Success)
  customEntityDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { customEntity: { id } }: CustomEntityStateAction.Delete.Success,
  ) {
    // @todo(heavybeard): change store locally instead of call endpoint
    dispatch([
      new CompanyStateAction.Structure.Try(),
      new CustomEntitiesStateAction.List.Remove.Try(id),
      new CustomEntitiesCategoryStateAction.List.Try(this.buildingId),
      new Navigate(
        [
          `/buildings/${this.buildingId}/building/offices/${this.officeId}/spaces/${this.spaceId}/map`,
        ],
        {},
        { queryParamsHandling: 'preserve' },
      ),
      new SpaceStateAction.Read.Try(this.spaceId),
    ]);
  }

  @Action(CustomEntityStateAction.Update.Success)
  customEntityUpdateSuccess({ dispatch }: StateContext<unknown>) {
    // @todo(heavybeard): edit locally employee, custom entity and space instead of call endpoint
    dispatch([
      new CustomEntitiesStateAction.List.Read.Try(this.spaceId),
      new SpaceStateAction.Read.Try(this.spaceId),
      new CompanyStateAction.Structure.Try(),
    ]);
  }

  @Action(DeliveriesStateAction.Settings.Read.Success)
  deliverySettingsReadSuccess(
    { dispatch }: StateContext<unknown>,
    { deliveries, buildingId }: DeliveriesStateAction.Settings.Read.Success,
  ) {
    dispatch(new BuildingsStateAction.Update.Try({ id: buildingId, deliveries }));
  }

  @Action(DeliveriesStateAction.Settings.Update.Success)
  deliverySettingsUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { deliveries, buildingId }: DeliveriesStateAction.Settings.Update.Success,
  ) {
    dispatch(new BuildingsStateAction.Update.Try({ id: buildingId, deliveries }));

    this.employees
      .filter(
        employee =>
          employee.assignedBuildings?.includes(buildingId) ||
          deliveries.frontDesks.includes(employee.id),
      )
      .forEach(employee => {
        dispatch(
          new EmployeesStateAction.List.Update.Try({
            id: employee.id,
            assignedBuildings: deliveries.frontDesks.includes(employee.id)
              ? [...(employee.assignedBuildings ?? []), buildingId].filter(
                  (assignedBuilding, index, assignedBuildings) =>
                    assignedBuildings.indexOf(assignedBuilding) === index,
                )
              : employee.assignedBuildings?.filter(
                  assignedBuilding => assignedBuilding !== buildingId,
                ),
          }),
        );
      });
  }

  @Action(DeskStateAction.Create.Success)
  deskCreateSuccess({ dispatch }: StateContext<unknown>) {
    // @todo(heavybeard): change store locally instead of call endpoint
    dispatch([
      new CompanyStateAction.Structure.Try(),
      new CustomEntitiesStateAction.List.Read.Try(this.spaceId),
      new SpaceStateAction.Read.Try(this.spaceId),
    ]);
  }

  @Action(DeskStateAction.Delete.Success)
  deskDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { desk: { id } }: DeskStateAction.Delete.Success,
  ) {
    // @todo(heavybeard): change store locally instead of call endpoint
    dispatch([
      new CompanyStateAction.Structure.Try(),
      new DesksStateAction.List.Remove.Try(id),
      new Navigate(
        [
          `/buildings/${this.buildingId}/building/offices/${this.officeId}/spaces/${this.spaceId}/map`,
        ],
        {},
        { queryParamsHandling: 'preserve' },
      ),
      new SpaceStateAction.Read.Try(this.spaceId),
    ]);
  }

  @Action(DeskStateAction.Update.Success)
  deskUpdateSuccess({ dispatch }: StateContext<unknown>) {
    // @todo(heavybeard): edit locally employee, desk and space instead of call endpoint
    dispatch([
      new CustomEntitiesStateAction.List.Read.Try(this.spaceId),
      new SpaceStateAction.Read.Try(this.spaceId),
      new CompanyStateAction.Structure.Try(),
    ]);
  }

  @Action(EmailStateAction.Reset.Success)
  @Action(EmailStateAction.Update.Success)
  emailReadSuccess(
    { dispatch }: StateContext<unknown>,
    { email }: EmailStateAction.Reset.Success | EmailStateAction.Update.Success,
  ) {
    dispatch(new EmailsStateAction.List.Update.Try(email));
  }

  @Action(EmployeeStateAction.AssignBuildings.Success)
  employeeAssignBuildingsSuccess(
    { dispatch }: StateContext<unknown>,
    { employee }: EmployeeStateAction.Create.Success,
  ) {
    dispatch(new EmployeesStateAction.List.Update.Try(employee));
  }

  @Action(EmployeeStateAction.Create.Success)
  employeeCreateSuccess(
    { dispatch }: StateContext<unknown>,
    { employee }: EmployeeStateAction.Create.Success,
  ) {
    dispatch(new EmployeesStateAction.List.Add.Try(employee));

    if (employee.status === 'inviting') {
      setTimeout(() => {
        dispatch(new EmployeesStateAction.List.Update.Try({ ...employee, status: 'invited' }));
      }, Math.random() * 3000 + 30000);
    }
  }

  @Action(EmployeeStateAction.Update.Success)
  employeeUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { employee }: EmployeeStateAction.Update.Success,
  ) {
    dispatch(new EmployeesStateAction.List.Update.Try(employee));
  }

  @Action(EmployeesStateAction.List.Read.Success)
  employeesListReadSuccess(
    { dispatch }: StateContext<unknown>,
    { employees }: EmployeesStateAction.List.Read.Success,
  ) {
    if (this.everyoneGroup) {
      dispatch(
        new GroupsStateAction.List.Update.Try({ ...this.everyoneGroup, members: employees.list }),
      );
    }
  }

  @Action(EmployeesStateAction.List.Invite.Success)
  employeesListInviteSuccess(
    { dispatch }: StateContext<unknown>,
    { employees }: EmployeesStateAction.List.Invite.Success,
  ) {
    employees.list.forEach(employee => {
      if (employee.status === 'inviting') {
        setTimeout(() => {
          dispatch(new EmployeesStateAction.List.Update.Try({ ...employee, status: 'invited' }));
        }, Math.random() * 3000 + 30000);
      }
    });
  }

  @Action(GroupStateAction.Create.Success)
  groupCreateSuccess(
    { dispatch }: StateContext<unknown>,
    { group }: GroupStateAction.Create.Success,
  ) {
    dispatch(new GroupsStateAction.List.Add.Try(group));
  }

  @Action(GroupStateAction.Delete.Success)
  groupDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { group }: GroupStateAction.Delete.Success,
  ) {
    dispatch(new GroupsStateAction.List.Remove.Try(group.id));
  }

  @Action(GroupsStateAction.List.Read.Success)
  groupsReadSuccess(
    { dispatch }: StateContext<unknown>,
    { groups }: GroupsStateAction.List.Read.Success,
  ) {
    const everyoneGroupId = groups.list.find(group => group.uid === 'everyone')?.id;

    if (everyoneGroupId) {
      dispatch(new GroupStateAction.Read.Try(everyoneGroupId));
    }
  }

  @Action(GroupStateAction.AddMembers.Success)
  @Action(GroupStateAction.Read.Success)
  @Action(GroupStateAction.ReadMembers.Success)
  @Action(GroupStateAction.RemoveMembers.Success)
  @Action(GroupStateAction.Update.Success)
  groupUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { group }: GroupStateAction.Update.Success,
  ) {
    dispatch(new GroupsStateAction.List.Update.Try(group));
  }

  @Action(AppStateAction.Init.Success)
  async initSuccess({ dispatch }: StateContext<unknown>) {
    if (this.isAuthenticated) {
      await dispatch(new CompanyStateAction.Subscription.Try()).toPromise();

      dispatch(new CompanyStateAction.Info.Read.Try());
    }
  }

  @Action(IntegrationsStateAction.Rooms.Associate.Success)
  integrationRoomsAssociateSuccess(
    { dispatch }: StateContext<unknown>,
    {
      integrations: { googleWorkspaceRooms, microsoft365Rooms },
    }: IntegrationsStateAction.Rooms.Associate.Success,
  ) {
    dispatch([
      ...(googleWorkspaceRooms?.completed
        ? [new IntegrationsStateAction.GoogleWorkspaceRooms.Read.Try()]
        : []),
      ...(microsoft365Rooms?.completed
        ? [new IntegrationsStateAction.Microsoft365Rooms.Read.Try()]
        : []),
    ]);
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Read.Success)
  @Action(IntegrationsStateAction.Microsoft365Rooms.Read.Success)
  integrationsRoomsReadSuccess(
    { dispatch }: StateContext<unknown>,
    {
      integrations,
    }:
      | IntegrationsStateAction.GoogleWorkspaceRooms.Read.Success
      | IntegrationsStateAction.Microsoft365Rooms.Read.Success,
  ) {
    if (integrations.googleWorkspaceRooms?.enabled || integrations.microsoft365Rooms?.enabled) {
      dispatch([
        new IntegrationsStateAction.Rooms.List.Read.Try(),
        new IntegrationsStateAction.Rooms.Remote.Try(),
      ]);
    }
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Uninstall.Success)
  @Action(IntegrationsStateAction.Microsoft365Rooms.Uninstall.Success)
  integrationsRoomsUninstallSuccess({ dispatch }: StateContext<unknown>) {
    dispatch([
      new IntegrationsStateAction.GoogleWorkspaceRooms.Read.Try(),
      new IntegrationsStateAction.Microsoft365Rooms.Read.Try(),
      new Navigate(['/integrations']),
    ]);
  }

  @Action(AuthStateAction.Login.Success)
  @Action(AuthStateAction.LoginTemporary.Success)
  async loginSuccess({ dispatch }: StateContext<unknown>) {
    await dispatch(new CompanyStateAction.Subscription.Try()).toPromise();

    await dispatch([
      new CompanyStateAction.Info.Read.Try(),
      new BuildingsStateAction.Read.Try(),
    ]).toPromise();

    dispatch(new Navigate([environment.homepage]));
  }

  @Action(AuthStateAction.Logout.Success)
  logoutSuccess({ dispatch }: StateContext<unknown>) {
    dispatch(
      new StateReset(
        BuildingsState,
        BuildingState,
        CompanyState,
        CustomEntitiesState,
        CustomEntityState,
        DesksState,
        DeskState,
        EmailsState,
        EmailState,
        EmployeesState,
        EmployeeState,
        GroupsState,
        GroupState,
        IntegrationsState,
        OfficesState,
        OfficeState,
        RoomsState,
        RoomState,
        SpacesState,
        SpaceState,
        UserState,
        VisitorsState,
      ),
    );
  }

  @Action(CustomEntitiesStateAction.List.Read.Success)
  @Action(DesksStateAction.List.Read.Success)
  @Action(ParkingsStateAction.List.Read.Success)
  async mapEntitiesReadSuccess({ dispatch }: StateContext<unknown>) {
    while (!this.isEveryAudienceReferencedInLocal && this.hasFurtherEmployees) {
      await dispatch(new EmployeesStateAction.List.Read.Try()).toPromise();
    }
  }

  @Action(OfficeStateAction.Create.Success)
  officeCreateSuccess(
    { dispatch }: StateContext<unknown>,
    { office }: OfficeStateAction.Create.Success,
  ) {
    dispatch(new OfficesStateAction.Add.Try(office));
  }

  @Action(OfficeStateAction.Delete.Success)
  officeDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { office: { id } }: OfficeStateAction.Delete.Success,
  ) {
    // @todo(heavybeard): edit employee locally instead of call endpoint
    dispatch([
      new OfficesStateAction.Remove.Try(id),
      new CompanyStateAction.Structure.Try(),
      new Navigate([`/buildings/${this.buildingId}`]),
    ]);
  }

  @Action(OfficesStateAction.Read.Success)
  officesReadSuccess({ dispatch }: StateContext<unknown>) {
    this.buildings
      .find(building => building.id === this.buildingId)
      ?.offices?.map(office => {
        dispatch(new OfficesStateAction.Update.Try(office));
      });
  }

  @Action(OfficeStateAction.Update.Success)
  officeUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { office }: OfficeStateAction.Update.Success,
  ) {
    dispatch(new OfficesStateAction.Update.Try(office));
  }

  @Action(ParkingStateAction.Create.Success)
  parkingCreateSuccess({ dispatch }: StateContext<unknown>) {
    // @todo(heavybeard): change store locally instead of call endpoint
    dispatch([
      new CompanyStateAction.Structure.Try(),
      new CustomEntitiesStateAction.List.Read.Try(this.spaceId),
      new SpaceStateAction.Read.Try(this.spaceId),
    ]);
  }

  @Action(ParkingStateAction.Delete.Success)
  parkingDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { parking: { id } }: ParkingStateAction.Delete.Success,
  ) {
    // @todo(heavybeard): change store locally instead of call endpoint
    dispatch([
      new CompanyStateAction.Structure.Try(),
      new ParkingsStateAction.List.Remove.Try(id),
      new Navigate(
        [
          `/buildings/${this.buildingId}/building/offices/${this.officeId}/spaces/${this.spaceId}/map`,
        ],
        {},
        { queryParamsHandling: 'preserve' },
      ),
      new SpaceStateAction.Read.Try(this.spaceId),
    ]);
  }

  @Action(ParkingStateAction.Update.Success)
  parkingUpdateSuccess({ dispatch }: StateContext<unknown>) {
    // @todo(heavybeard): edit locally employee, parking and space instead of call endpoint
    dispatch([
      new CustomEntitiesStateAction.List.Read.Try(this.spaceId),
      new SpaceStateAction.Read.Try(this.spaceId),
    ]);
  }

  @Action(RoomStateAction.Create.Success)
  roomCreateSuccess({ dispatch }: StateContext<unknown>) {
    // @todo(heavybeard): create room locally instead of call endpoint
    dispatch([
      new CompanyStateAction.Structure.Try(),
      new RoomsStateAction.List.Read.Try(this.spaceId),
    ]);
  }

  @Action(RoomStateAction.Delete.Success)
  roomDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { room: { id } }: RoomStateAction.Delete.Success,
  ) {
    // @todo(heavybeard): delete room locally instead of call endpoint
    dispatch([
      new RoomsStateAction.List.Remove.Try(id),
      new Navigate(
        [
          `/buildings/${this.buildingId}/building/offices/${this.officeId}/spaces/${this.spaceId}/map`,
        ],
        {},
        { queryParamsHandling: 'preserve', replaceUrl: true },
      ),
      new CompanyStateAction.Structure.Try(),
    ]);
  }

  @Action(RoomStateAction.Update.Success)
  roomUpdateSuccess({ dispatch }: StateContext<unknown>) {
    // @todo(heavybeard): edit room instead of call endpoint
    dispatch([new RoomsStateAction.List.Read.Try(this.spaceId)]);
  }

  @Action(SpaceStateAction.Create.Success)
  spaceCreateSuccess(
    { dispatch }: StateContext<unknown>,
    { space }: SpaceStateAction.Create.Success,
  ) {
    dispatch(new SpacesStateAction.Add.Try(space));
  }

  @Action(SpaceStateAction.Delete.Success)
  spaceDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { space: { id } }: SpaceStateAction.Delete.Success,
  ) {
    // @todo(heavybeard): edit employee locally instead of call endpoint
    dispatch([
      new SpacesStateAction.Remove.Try(id),
      new CompanyStateAction.Structure.Try(),
      new Navigate([`/buildings/${this.buildingId}/building/offices/${this.officeId}`]),
    ]);
  }

  @Action(SpaceStateAction.Update.Success)
  spaceUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { space }: SpaceStateAction.Update.Success,
  ) {
    dispatch(new SpacesStateAction.Update.Try(space));
  }

  @Action(CompanyStateAction.Structure.Success)
  structureReadSuccess(
    { dispatch }: StateContext<unknown>,
    { company: { buildings, subscription } }: CompanyStateAction.Structure.Success,
  ) {
    buildings?.forEach(({ id }) => {
      if (isOn(FeatureToggles.TOGGLE_ENABLE_DELIVERIES)) {
        dispatch(new DeliveriesStateAction.Settings.Read.Try(id));
      }

      if (typeof subscription?.features.safe_c19 === 'object') {
        dispatch(
          new BuildingsStateAction.Update.Try({
            id,
            features: { safe_c19: subscription?.features.safe_c19.includes(id) },
          }),
        );
      }
    });
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Uninstall.Success)
  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Uninstall.Success)
  @Action(IntegrationsStateAction.OktaDirectory.Uninstall.Success)
  async uninstallDirectoryIntegrationSuccess({ dispatch }: StateContext<unknown>) {
    await dispatch(new AuthStateAction.RefreshToken.Try()).toPromise();

    setTimeout(() => {
      dispatch(new Navigate(['/create-password']));
    }, 1000);
  }

  @Action(RoomStateAction.UnpairAllClients.Success)
  unpairAllClientSuccess(
    { dispatch }: StateContext<unknown>,
    { room }: RoomStateAction.UnpairAllClients.Success,
  ) {
    dispatch(new RoomsStateAction.List.Update.Try({ ...room, companionClients: [] }));
  }
}
