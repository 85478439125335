// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';
import { GroupStateModel } from '../group';
import { SpaceStateModel } from '../space';
import { StatsStateModel } from './stats.model';

export namespace StatsStateAction {
  export namespace Desks {
    export namespace Occupancy {
      export class Try {
        static readonly type = '[Stats] try Read Desks Occupancy stats';
        constructor(
          public start: Date,
          public end: Date,
          public buildings?: Array<BuildingStateModel['id']>,
          public groups?: Array<GroupStateModel['id']>,
          public spaces?: Array<SpaceStateModel['id']>,
        ) {}
      }
      export class Success {
        static readonly type = '[Stats] then Read Desks Occupancy stats success';
        constructor(public stats: StatsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Stats] catch Read Desks Occupancy stats failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Bookings {
      export class Try {
        static readonly type = '[Stats] try Read Desks Bookings stats';
        constructor(
          public start: Date,
          public end: Date,
          public buildings?: Array<BuildingStateModel['id']>,
          public groups?: Array<GroupStateModel['id']>,
          public spaces?: Array<SpaceStateModel['id']>,
        ) {}
      }
      export class Success {
        static readonly type = '[Stats] then Read Desks Bookings stats success';
        constructor(public stats: StatsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Stats] catch Read Desks Bookings stats failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
  export namespace Members {
    export namespace Actives {
      export class Try {
        static readonly type = '[Stats] try Read Active Members stats';
        constructor(
          public start: Date,
          public end: Date,
          public buildings?: Array<BuildingStateModel['id']>,
          public groups?: Array<GroupStateModel['id']>,
          public spaces?: Array<SpaceStateModel['id']>,
        ) {}
      }
      export class Success {
        static readonly type = '[Stats] then Read Active Members stats success';
        constructor(public stats: StatsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Stats] catch Read Active Members stats failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
