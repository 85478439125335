import { Injectable } from '@angular/core';
import { IntegrationsService } from '@business/api';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { append, compose, iif, patch, updateItem } from '@ngxs/store/operators';
import { AppStateAction, RouterSelectors } from '@nibol/store';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BuildingStateModel } from '../building';
import { BuildingsState } from '../buildings';
import { RoomStateModel } from '../room';
import { IntegrationsStateAction } from './integrations.actions';
import {
  mapGoogleWorkspaceDirectoryToApi,
  mapGoogleWorkspaceDirectoryToClient,
  mapGoogleWorkspaceRoomsToApi,
  mapGoogleWorkspaceRoomsToClient,
  mapKisiToApi,
  mapKisiToClient,
  mapMicrosoft365RoomsToApi,
  mapMicrosoft365RoomsToClient,
  mapMicrosoftAzureActiveDirectoryToApi,
  mapMicrosoftAzureActiveDirectoryToClient,
  mapOktaDirectoryToApi,
  mapOktaDirectoryToClient,
  mapSlackToClient,
  mapSofiaToApi,
  mapSofiaToClient,
} from './integrations.helper';
import { IntegrationsStateModel } from './integrations.model';
import { GoogleWorkspaceRoomsSettings, Microsoft365RoomsSettings } from './integrations.type';

@State<IntegrationsStateModel>({
  name: 'integrations',
  defaults: {
    googleWorkspaceDirectory: { enabled: false },
    googleWorkspaceRooms: { enabled: false },
    kisi: { enabled: false },
    microsoft365Rooms: { enabled: false },
    microsoftAzureActiveDirectory: { enabled: false },
    oktaDirectory: { enabled: false },
    remoteRooms: [],
    rooms: [],
    slack: { enabled: false },
  },
})
@Injectable()
export class IntegrationsState {
  @Select(BuildingsState) buildings$!: Observable<BuildingStateModel[]>;

  @SelectSnapshot(RouterSelectors.failureMessage) failureMessage!: string;
  @SelectSnapshot(RouterSelectors.successMessage) successMessage!: string;

  constructor(private readonly integrationsService: IntegrationsService) {}

  // tslint:disable-next-line: cyclomatic-complexity
  @Action(AppStateAction.State.Ready.Success)
  appStateReadySuccess({ dispatch }: StateContext<IntegrationsStateModel>) {
    switch (this.failureMessage) {
      case 'integration-provider-already-set-google':
        dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Add.Failure(this.failureMessage));
        break;

      case 'integration-provider-already-set-microsoft':
        dispatch(new IntegrationsStateAction.Microsoft365Rooms.Add.Failure(this.failureMessage));
        break;

      case 'integration-provider-not-set':
      case 'integration-provider-not-valid':
        dispatch(new IntegrationsStateAction.Generic.Add.Failure(this.failureMessage));
        break;
    }

    switch (this.successMessage) {
      case 'google-integration-success':
        dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Add.Success(this.successMessage));
        break;

      case 'microsoft-integration-success':
        dispatch(new IntegrationsStateAction.Microsoft365Rooms.Add.Success(this.successMessage));
        break;
    }
  }

  @Action(IntegrationsStateAction.Rooms.Associate.Try)
  associateRooms(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    { roomsAssociation }: IntegrationsStateAction.Rooms.Associate.Try,
  ) {
    return this.integrationsService.associateRooms(roomsAssociation).pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Rooms.Associate.Failure(error));

        throw error;
      }),
      tap(response => {
        if ('status' in response && response.status) {
          setState(
            patch({
              googleWorkspaceRooms: patch<GoogleWorkspaceRoomsSettings>({ completed: true }),
              microsoft365Rooms: patch<Microsoft365RoomsSettings>({ completed: true }),
            }),
          );

          dispatch(new IntegrationsStateAction.Rooms.Associate.Success(getState()));
        } else {
          dispatch(new IntegrationsStateAction.Rooms.Associate.Failure(response));
        }
      }),
    );
  }

  @Action(IntegrationsStateAction.OktaDirectory.Connect.Try)
  connectOktaDirectoryIntegration(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    { apiKey, domain }: IntegrationsStateAction.OktaDirectory.Connect.Try,
  ) {
    return this.integrationsService.testOktaDirectory({ api_key: apiKey, domain_url: domain }).pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.OktaDirectory.Connect.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          setState(patch({ oktaDirectory: patch({ apiKey, domain }) }));

          dispatch(new IntegrationsStateAction.OktaDirectory.Connect.Success(getState()));
        } else {
          dispatch(new IntegrationsStateAction.OktaDirectory.Connect.Failure(status));
        }
      }),
    );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Disconnect.Try)
  disconnectGoogleWorkspaceDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.resetGoogleWorkspaceDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Disconnect.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(
          patch({
            googleWorkspaceDirectory: patch<IntegrationsStateModel['googleWorkspaceDirectory']>({
              availableFieldsFromIntegration: [],
              enabled: false,
              fieldsAssociation: [],
            }),
          }),
        );

        dispatch(
          new IntegrationsStateAction.GoogleWorkspaceDirectory.Disconnect.Success(getState()),
        );
      }),
    );
  }

  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Disconnect.Try)
  disconnectMicrosoftAzureActiveDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.resetAzureActiveDirectory().pipe(
      catchError(error => {
        dispatch(
          new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Disconnect.Failure(error),
        );

        throw error;
      }),
      tap(() => {
        setState(
          patch({
            microsoftAzureActiveDirectory: patch<
              IntegrationsStateModel['microsoftAzureActiveDirectory']
            >({
              availableFieldsFromIntegration: [],
              enabled: false,
              fieldsAssociation: [],
            }),
          }),
        );

        dispatch(
          new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Disconnect.Success(getState()),
        );
      }),
    );
  }

  @Action(IntegrationsStateAction.OktaDirectory.Disconnect.Try)
  disconnectOktaDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    try {
      setState(
        patch({
          oktaDirectory: patch<IntegrationsStateModel['oktaDirectory']>({
            apiKey: undefined,
            domain: undefined,
          }),
        }),
      );

      dispatch(new IntegrationsStateAction.OktaDirectory.Disconnect.Success(getState()));
    } catch (error) {
      dispatch(new IntegrationsStateAction.OktaDirectory.Disconnect.Failure(error));
    }
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Install.Try)
  installGoogleWorkspaceDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return dispatch(
      new IntegrationsStateAction.GoogleWorkspaceDirectory.Update.Try({ enabled: true }),
    ).pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Install.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ googleWorkspaceDirectory: patch({ enabled: true }) }));

        dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Install.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Install.Try)
  installMicrosoftAzureActiveDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return dispatch(
      new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Update.Try({ enabled: true }),
    ).pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Install.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ microsoftAzureActiveDirectory: patch({ enabled: true }) }));

        dispatch(
          new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Install.Success(getState()),
        );
      }),
    );
  }

  @Action(IntegrationsStateAction.OktaDirectory.Install.Try)
  installOktaDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return dispatch(new IntegrationsStateAction.OktaDirectory.Update.Try({ enabled: true })).pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.OktaDirectory.Install.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ oktaDirectory: patch({ enabled: true }) }));

        dispatch(new IntegrationsStateAction.OktaDirectory.Install.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Read.Try)
  readGoogleWorkspaceDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readGoogleWorkspaceDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Read.Failure(error));

        throw error;
      }),
      tap(settings => {
        setState(
          patch({
            googleWorkspaceDirectory: mapGoogleWorkspaceDirectoryToClient(settings),
          }),
        );

        dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Read.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Read.Try)
  readGoogleWorkspaceRoomsIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readGoogleWorkspaceRooms().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Read.Failure(error));

        throw error;
      }),
      tap(async settings => {
        setState(
          patch({
            googleWorkspaceRooms: mapGoogleWorkspaceRoomsToClient(settings),
          }),
        );

        dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Read.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Kisi.Read.Try)
  readKisiIntegration({ dispatch, getState, setState }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readKisi().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Kisi.Read.Failure(error));

        throw error;
      }),
      tap(settings => {
        setState(patch({ kisi: mapKisiToClient(settings) }));

        dispatch(new IntegrationsStateAction.Kisi.Read.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Sofia.Read.Try)
  readSofiaIntegration({ dispatch, getState, setState }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readSofia().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Sofia.Read.Failure(error));

        throw error;
      }),
      tap(settings => {
        setState(patch({ sofia: mapSofiaToClient(settings) }));

        dispatch(new IntegrationsStateAction.Sofia.Read.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Microsoft365Rooms.Read.Try)
  readMicrosoft365RoomsIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readMicrosoft365Rooms().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Microsoft365Rooms.Read.Failure(error));

        throw error;
      }),
      tap(async settings => {
        setState(patch({ microsoft365Rooms: mapMicrosoft365RoomsToClient(settings) }));

        dispatch(new IntegrationsStateAction.Microsoft365Rooms.Read.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Read.Try)
  readMicrosoftAzureActiveDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readAzureActiveDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Read.Failure(error));

        throw error;
      }),
      tap(settings => {
        setState(
          patch({
            microsoftAzureActiveDirectory: mapMicrosoftAzureActiveDirectoryToClient(settings),
          }),
        );

        dispatch(
          new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Read.Success(getState()),
        );
      }),
    );
  }

  @Action(IntegrationsStateAction.OktaDirectory.Read.Try)
  readOktaDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readOktaDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.OktaDirectory.Read.Failure(error));

        throw error;
      }),
      tap(settings => {
        setState(patch({ oktaDirectory: mapOktaDirectoryToClient(settings) }));

        dispatch(new IntegrationsStateAction.OktaDirectory.Read.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Rooms.List.Read.Try)
  readRoomsList({ dispatch, getState, setState }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readRoomsList().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Rooms.List.Read.Failure(error));

        throw error;
      }),
      tap(rooms => {
        setState(
          patch({
            rooms: compose(
              ...rooms.map(room =>
                iif<RoomStateModel[]>(
                  roomsFromStore =>
                    roomsFromStore?.some(roomFromStore => roomFromStore.id === room.id) ?? false,
                  updateItem<RoomStateModel>(
                    roomStore => roomStore?.id === room.id,
                    patch<RoomStateModel>({
                      calendarEmail: room.calendar_email ?? undefined,
                      id: room.id,
                      name: room.name,
                      seats: room.seats,
                    }),
                  ),
                  append<RoomStateModel>([
                    {
                      active: false,
                      calendarEmail: room.calendar_email ?? undefined,
                      coordinates: [],
                      id: room.id,
                      name: room.name,
                      seats: room.seats,
                    },
                  ]),
                ),
              ),
            ),
          }),
        );

        dispatch(new IntegrationsStateAction.Rooms.List.Read.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Rooms.Remote.Try)
  readRoomsRemote({ dispatch, getState, setState }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readRoomsRemote().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Rooms.Remote.Failure(error));

        throw error;
      }),
      tap(rooms => {
        setState(
          patch({
            remoteRooms: compose(
              ...rooms.map(room =>
                iif<RoomStateModel[]>(
                  roomsFromStore =>
                    roomsFromStore?.some(
                      roomFromStore => roomFromStore.calendarEmail === room.calendar_email,
                    ) ?? false,
                  updateItem<RoomStateModel>(
                    roomStore => roomStore?.calendarEmail === room.calendar_email,
                    patch<RoomStateModel>({
                      calendarEmail: room.calendar_email ?? undefined,
                      name: room.name,
                      seats: room.seats,
                    }),
                  ),
                  append<RoomStateModel>([
                    {
                      active: false,
                      calendarEmail: room.calendar_email,
                      coordinates: [],
                      id: room.calendar_email,
                      name: room.name,
                      seats: room.seats,
                    },
                  ]),
                ),
              ),
            ),
          }),
        );

        dispatch(new IntegrationsStateAction.Rooms.Remote.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Slack.Read.Try)
  readSlackIntegration({ dispatch, getState, setState }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.readSlack().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Slack.Read.Failure(error));

        throw error;
      }),
      tap(slack => {
        setState(patch({ slack: mapSlackToClient(slack) }));

        dispatch(new IntegrationsStateAction.Slack.Read.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Sync.Try)
  syncGoogleWorkspaceDirectoryIntegration({
    dispatch,
    getState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.syncGoogleWorkspaceDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Sync.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Sync.Success(getState()));
        } else {
          dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Sync.Failure(status));
        }
      }),
    );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Sync.Try)
  syncGoogleWorkspaceRoomsIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.syncGoogleWorkspaceRooms().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Sync.Failure(error));

        throw error;
      }),
      tap(rooms => {
        setState(
          patch({
            rooms: compose(
              ...rooms.map(room =>
                updateItem<RoomStateModel>(
                  roomStore => roomStore?.name === room.name,
                  patch({ calendarEmail: room.calendar_email }),
                ),
              ),
            ),
          }),
        );

        dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Sync.Success(getState(), rooms));
      }),
    );
  }

  @Action(IntegrationsStateAction.Microsoft365Rooms.Sync.Try)
  syncMicrosoft365RoomsIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.syncMicrosoft365Rooms().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Microsoft365Rooms.Sync.Failure(error));

        throw error;
      }),
      tap(rooms => {
        setState(
          patch({
            rooms: compose(
              ...rooms.map(room =>
                updateItem<RoomStateModel>(
                  roomStore => roomStore?.name === room.name,
                  patch({ calendarEmail: room.calendar_email }),
                ),
              ),
            ),
          }),
        );

        dispatch(new IntegrationsStateAction.Microsoft365Rooms.Sync.Success(getState(), rooms));
      }),
    );
  }

  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Sync.Try)
  syncMicrosoftAzureActiveDirectoryIntegration({
    dispatch,
    getState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.syncAzureActiveDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Sync.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          dispatch(
            new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Sync.Success(getState()),
          );
        } else {
          dispatch(new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Sync.Failure(status));
        }
      }),
    );
  }

  @Action(IntegrationsStateAction.OktaDirectory.Sync.Try)
  syncOktaDirectoryIntegration({ dispatch, getState }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.syncOktaDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.OktaDirectory.Sync.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          dispatch(new IntegrationsStateAction.OktaDirectory.Sync.Success(getState()));
        } else {
          dispatch(new IntegrationsStateAction.OktaDirectory.Sync.Failure(status));
        }
      }),
    );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Uninstall.Try)
  uninstallGoogleWorkspaceDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.resetGoogleWorkspaceDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Uninstall.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(
          patch({
            googleWorkspaceDirectory: patch<IntegrationsStateModel['googleWorkspaceDirectory']>({
              availableFieldsFromIntegration: [],
              enabled: false,
              fieldsAssociation: [],
            }),
          }),
        );

        dispatch(
          new IntegrationsStateAction.GoogleWorkspaceDirectory.Uninstall.Success(getState()),
        );
      }),
    );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Uninstall.Try)
  uninstallGoogleWorkspaceRoomsIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.resetGoogleWorkspaceRooms().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Uninstall.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          setState(
            patch({
              googleWorkspaceRooms: patch<GoogleWorkspaceRoomsSettings>({
                calendarIntegration: undefined,
              }),
            }),
          );

          dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Uninstall.Success(getState()));
        } else {
          dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Uninstall.Failure(status));
        }
      }),
    );
  }

  @Action(IntegrationsStateAction.Kisi.Uninstall.Try)
  async uninstallKisiIntegration({ dispatch, getState }: StateContext<IntegrationsStateModel>) {
    try {
      await dispatch(new IntegrationsStateAction.Kisi.Update.Try({ enabled: false })).toPromise();
      dispatch(new IntegrationsStateAction.Kisi.Uninstall.Success(getState()));
    } catch (error) {
      dispatch(new IntegrationsStateAction.Kisi.Uninstall.Failure(error));
    }
  }

  @Action(IntegrationsStateAction.Sofia.Uninstall.Try)
  async uninstallSofiaIntegration({ dispatch, getState }: StateContext<IntegrationsStateModel>) {
    try {
      await dispatch(new IntegrationsStateAction.Sofia.Update.Try({ enabled: false })).toPromise();
      dispatch(new IntegrationsStateAction.Sofia.Uninstall.Success(getState()));
    } catch (error) {
      dispatch(new IntegrationsStateAction.Sofia.Uninstall.Failure(error));
    }
  }

  @Action(IntegrationsStateAction.Microsoft365Rooms.Uninstall.Try)
  uninstallMicrosoft365RoomsIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.resetMicrosoft365Rooms().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Microsoft365Rooms.Uninstall.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          setState(
            patch({
              microsoft365Rooms: patch<Microsoft365RoomsSettings>({
                calendarIntegration: undefined,
              }),
            }),
          );

          dispatch(new IntegrationsStateAction.Microsoft365Rooms.Uninstall.Success(getState()));
        } else {
          dispatch(new IntegrationsStateAction.Microsoft365Rooms.Uninstall.Failure(status));
        }
      }),
    );
  }

  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Uninstall.Try)
  uninstallMicrosoftAzureActiveDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.resetAzureActiveDirectory().pipe(
      catchError(error => {
        dispatch(
          new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Uninstall.Failure(error),
        );

        throw error;
      }),
      tap(() => {
        setState(
          patch({
            microsoftAzureActiveDirectory: patch<
              IntegrationsStateModel['microsoftAzureActiveDirectory']
            >({
              availableFieldsFromIntegration: [],
              enabled: false,
              fieldsAssociation: [],
            }),
          }),
        );

        dispatch(
          new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Uninstall.Success(getState()),
        );
      }),
    );
  }

  @Action(IntegrationsStateAction.OktaDirectory.Uninstall.Try)
  uninstallOktaDirectoryIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.resetOktaDirectory().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.OktaDirectory.Uninstall.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(
          patch({
            oktaDirectory: patch<IntegrationsStateModel['oktaDirectory']>({
              apiKey: undefined,
              domain: undefined,
              enabled: false,
            }),
          }),
        );

        dispatch(new IntegrationsStateAction.OktaDirectory.Uninstall.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Slack.Uninstall.Try)
  uninstallSlackIntegration({
    dispatch,
    getState,
    setState,
  }: StateContext<IntegrationsStateModel>) {
    return this.integrationsService.uninstallSlack().pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Slack.Uninstall.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ slack: patch({ enabled: false }) }));

        dispatch(new IntegrationsStateAction.Slack.Uninstall.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Update.Try)
  updateGoogleWorkspaceDirectoryIntegration(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    {
      googleWorkspaceDirectorySettings,
    }: IntegrationsStateAction.GoogleWorkspaceDirectory.Update.Try,
  ) {
    return this.integrationsService
      .updateGoogleWorkspaceDirectory(
        mapGoogleWorkspaceDirectoryToApi(googleWorkspaceDirectorySettings),
      )
      .pipe(
        catchError(error => {
          dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Update.Failure(error));

          throw error;
        }),
        tap(settings => {
          setState(
            patch({
              googleWorkspaceDirectory: mapGoogleWorkspaceDirectoryToClient(settings),
            }),
          );

          dispatch(new IntegrationsStateAction.GoogleWorkspaceDirectory.Update.Success(getState()));
        }),
      );
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Update.Try)
  updateGoogleWorkspaceRoomsIntegration(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    { googleWorkspaceRoomsSettings }: IntegrationsStateAction.GoogleWorkspaceRooms.Update.Try,
  ) {
    return this.integrationsService
      .updateGoogleWorkspaceRooms(mapGoogleWorkspaceRoomsToApi(googleWorkspaceRoomsSettings))
      .pipe(
        catchError(error => {
          dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Update.Failure(error));

          throw error;
        }),
        tap(settings => {
          setState(patch({ googleWorkspaceRooms: mapGoogleWorkspaceRoomsToClient(settings) }));

          dispatch(new IntegrationsStateAction.GoogleWorkspaceRooms.Update.Success(getState()));
        }),
      );
  }

  @Action(IntegrationsStateAction.Kisi.Update.Try)
  updateKisiIntegration(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    { kisiSettings }: IntegrationsStateAction.Kisi.Update.Try,
  ) {
    return this.integrationsService.updateKisi(mapKisiToApi(kisiSettings)).pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Kisi.Update.Failure(error));

        throw error;
      }),
      tap(settings => {
        setState(patch({ kisi: mapKisiToClient(settings) }));

        dispatch(new IntegrationsStateAction.Kisi.Update.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Sofia.Update.Try)
  updateSofiaIntegration(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    { sofiaSettings }: IntegrationsStateAction.Sofia.Update.Try,
  ) {
    return this.integrationsService.updateSofia(mapSofiaToApi(sofiaSettings)).pipe(
      catchError(error => {
        dispatch(new IntegrationsStateAction.Sofia.Update.Failure(error));

        throw error;
      }),
      tap(settings => {
        setState(patch({ sofia: mapSofiaToClient(settings) }));

        dispatch(new IntegrationsStateAction.Sofia.Update.Success(getState()));
      }),
    );
  }

  @Action(IntegrationsStateAction.Microsoft365Rooms.Update.Try)
  updateMicrosoft365RoomsIntegration(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    { microsoft365RoomsSettings }: IntegrationsStateAction.Microsoft365Rooms.Update.Try,
  ) {
    return this.integrationsService
      .updateMicrosoft365Rooms(mapMicrosoft365RoomsToApi(microsoft365RoomsSettings))
      .pipe(
        catchError(error => {
          dispatch(new IntegrationsStateAction.Microsoft365Rooms.Update.Failure(error));

          throw error;
        }),
        tap(settings => {
          setState(patch({ microsoft365Rooms: mapMicrosoft365RoomsToClient(settings) }));

          dispatch(new IntegrationsStateAction.Microsoft365Rooms.Update.Success(getState()));
        }),
      );
  }

  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Update.Try)
  updateMicrosoftAzureActiveDirectoryIntegration(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    {
      microsoftAzureActiveDirectorySettings,
    }: IntegrationsStateAction.MicrosoftAzureActiveDirectory.Update.Try,
  ) {
    return this.integrationsService
      .updateAzureActiveDirectory(
        mapMicrosoftAzureActiveDirectoryToApi(microsoftAzureActiveDirectorySettings),
      )
      .pipe(
        catchError(error => {
          dispatch(new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Update.Failure(error));

          throw error;
        }),
        tap(settings => {
          setState(
            patch({
              microsoftAzureActiveDirectory: mapMicrosoftAzureActiveDirectoryToClient(settings),
            }),
          );

          dispatch(
            new IntegrationsStateAction.MicrosoftAzureActiveDirectory.Update.Success(getState()),
          );
        }),
      );
  }

  @Action(IntegrationsStateAction.OktaDirectory.Update.Try)
  updateOktaDirectoryIntegration(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    { oktaDirectorySettings }: IntegrationsStateAction.OktaDirectory.Update.Try,
  ) {
    return this.integrationsService
      .updateOktaDirectory(mapOktaDirectoryToApi(oktaDirectorySettings))
      .pipe(
        catchError(error => {
          dispatch(new IntegrationsStateAction.OktaDirectory.Update.Failure(error));

          throw error;
        }),
        tap(settings => {
          setState(patch({ oktaDirectory: mapOktaDirectoryToClient(settings) }));

          dispatch(new IntegrationsStateAction.OktaDirectory.Update.Success(getState()));
        }),
      );
  }

  @Action(IntegrationsStateAction.Rooms.List.Update.Try)
  updateRoomsList(
    { dispatch, getState, setState }: StateContext<IntegrationsStateModel>,
    { room }: IntegrationsStateAction.Rooms.List.Update.Try,
  ) {
    try {
      setState(
        patch({
          rooms: updateItem<RoomStateModel>(roomStore => roomStore?.id === room.id, patch(room)),
        }),
      );

      dispatch(new IntegrationsStateAction.Rooms.List.Update.Success(getState()));
    } catch (error) {
      dispatch(new IntegrationsStateAction.Rooms.List.Update.Failure(error));

      throw error;
    }
  }
}
