import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomEntity } from './custom-entity.type';

@Injectable({ providedIn: 'root' })
export class CustomEntityService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  create({
    customEntity,
    space_id,
  }: CustomEntity.Create.Params): Observable<CustomEntity.Create.Response> {
    return this.httpClient
      .post<CustomEntity.Create.Response>(`${this.apiRootUrl}/custom-entity`, {
        customEntity,
        space_id,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete({ id }: CustomEntity.Delete.Params): Observable<CustomEntity.Delete.Response> {
    return this.httpClient
      .request<CustomEntity.Delete.Response>('delete', `${this.apiRootUrl}/custom-entity`, {
        body: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  generateName({
    space_id,
  }: CustomEntity.GenerateName.Params): Observable<CustomEntity.GenerateName.Response> {
    return this.httpClient
      .get<CustomEntity.GenerateName.Response>(`${this.apiRootUrl}/custom-entity/name`, {
        params: { space_id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read(id: CustomEntity.Read.Params['id']): Observable<CustomEntity.Read.Response> {
    return this.httpClient
      .get<CustomEntity.Read.Response>(`${this.apiRootUrl}/custom-entity`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(body: DeepPartial<CustomEntity.Update.Body>): Observable<CustomEntity.Update.Response> {
    return this.httpClient
      .put<CustomEntity.Update.Response>(`${this.apiRootUrl}/custom-entity`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
