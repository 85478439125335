import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { API_ROOT_URL, AuthSelectors } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Company } from './company.type';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  @SelectSnapshot(AuthSelectors.token) token!: string;

  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  billing(): Observable<Company.Billing.Read.Response> {
    return this.httpClient
      .get<Company.Billing.Read.Response>(`${this.apiRootUrl}/company/billing/info`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  companyPolicy(): Observable<Company.CompanyPolicy.Read.Response> {
    return this.httpClient
      .get<Company.CompanyPolicy.Read.Response>(`${this.apiRootUrl}/company/policy`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  countries(): Observable<Company.Countries.Response> {
    return this.httpClient
      .get<Company.Countries.Response>(
        `${this.apiRootUrl.replace('/business', '')}/common/countries`,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  dashboard(): Observable<Company.Dashboard.Response> {
    return this.httpClient
      .get<Company.Dashboard.Response>(`${this.apiRootUrl}/company/dashboard`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  employees(params?: Company.Employees.Params): Observable<Company.Employees.Response> {
    return this.httpClient
      .get<Company.Employees.Response>(`${this.apiRootUrl}/company/employees`, {
        params,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  employeesLight(params?: Company.Employees.Params): Observable<Company.EmployeesLight.Response> {
    return this.httpClient
      .get<Company.EmployeesLight.Response>(`${this.apiRootUrl}/company/employees/list`, { params })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  groups(): Observable<Company.Groups.Response> {
    return this.httpClient.get<Company.Groups.Response>(`${this.apiRootUrl}/group/list`).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  read(): Observable<Company.Info.Read.Response> {
    return this.httpClient.get<Company.Info.Read.Response>(`${this.apiRootUrl}/company/info`).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  readSpaces(): Observable<Company.Spaces.Read.Response> {
    return this.httpClient
      .get<Company.Spaces.Read.Response>(`${this.apiRootUrl}/company/spaces`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  structure(): Observable<Company.Structure.Response> {
    return this.httpClient
      .get<Company.Structure.Response>(`${this.apiRootUrl}/company/structure`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  subProcessor(): Observable<Company.SubProcessor.Response> {
    return this.httpClient
      .get<Company.SubProcessor.Response>(
        `${this.apiRootUrl.replace('/business', '')}/sub-processors`,
        { headers: { Authorization: `Bearer ${this.token}`, nib_platform: 'bus' } },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  subscription(): Observable<Company.Subscription.Response> {
    return this.httpClient
      .get<Company.Subscription.Response>(`${this.apiRootUrl}/company/subscription`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  terms(): Observable<Company.Terms.Read.Response> {
    return this.httpClient
      .get<Company.Terms.Read.Response>(`${this.apiRootUrl}/company/terms`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(info: DeepPartial<Company.Info.Update.Body>): Observable<Company.Info.Update.Response> {
    return this.httpClient
      .put<Company.Info.Update.Response>(`${this.apiRootUrl}/company/info`, info)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSpaces(
    settings: DeepPartial<Company.Spaces.Update.Body>,
  ): Observable<Company.Spaces.Update.Response> {
    return this.httpClient
      .put<Company.Spaces.Update.Response>(`${this.apiRootUrl}/company/spaces`, settings)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  activateSpaces(): Observable<Company.Spaces.Activate.Response> {
    return this.httpClient
      .post<Company.Spaces.Activate.Response>(`${this.apiRootUrl}/company/spaces`, {})
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateBilling(
    billing: DeepPartial<Company.Billing.Update.Body>,
  ): Observable<Company.Billing.Update.Response> {
    return this.httpClient
      .put<Company.Billing.Update.Response>(`${this.apiRootUrl}/company/billing/info`, billing)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateCompanyPolicy(
    companyPolicy: DeepPartial<Company.CompanyPolicy.Update.Body>,
  ): Observable<Company.CompanyPolicy.Update.Response> {
    return this.httpClient
      .put<Company.CompanyPolicy.Update.Response>(
        `${this.apiRootUrl}/company/policy`,
        companyPolicy,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateTerms(
    terms?: Partial<Company.Terms.Update.Body>,
  ): Observable<Company.Terms.Update.Response> {
    return this.httpClient
      .put<Company.Terms.Update.Response>(`${this.apiRootUrl}/company/terms`, terms)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  uploadLogo(uploadData: Company.UploadLogo.Body): Observable<Company.UploadLogo.Response> {
    return this.httpClient
      .post<Company.UploadLogo.Response>(`${this.apiRootUrl}/company/logo/upload`, uploadData)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
