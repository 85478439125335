import { Injectable } from '@angular/core';
import { ParkingService } from '@business/api';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { iif, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GroupStateModel } from '../group';
import { GroupsSelectors } from '../groups';
import { SpaceSelectors } from '../space';
import { ParkingStateAction } from './parking.actions';
import { mapParkingToApi, mapParkingToClient } from './parking.helper';
import { ParkingStateModel } from './parking.model';

@State<ParkingStateModel>({
  name: 'parking',
})
@Injectable()
export class ParkingState {
  @Select(SpaceSelectors.parkings) parkings$!: Observable<ParkingStateModel[]>;

  @SelectSnapshot(GroupsSelectors.list) groups!: GroupStateModel[];

  constructor(private readonly parkingService: ParkingService) {}

  @Action(ParkingStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<ParkingStateModel>,
    { parking, spaceId }: ParkingStateAction.Create.Try,
  ) {
    return this.parkingService
      .create({ parking: mapParkingToApi(parking, this.groups), space_id: spaceId })
      .pipe(
        catchError(error => {
          dispatch(new ParkingStateAction.Create.Failure(error));

          throw error;
        }),
        tap(({ id }) => {
          setState({
            ...parking,
            active: true,
            exclusivityAssignment: false,
            id,
            name: parking.name as string,
          });

          dispatch(new ParkingStateAction.Create.Success(getState()));
        }),
      );
  }

  @Action(ParkingStateAction.Delete.Try)
  delete(
    { dispatch, getState }: StateContext<ParkingStateModel>,
    { id }: ParkingStateAction.Delete.Try,
  ) {
    return this.parkingService.delete({ id }).pipe(
      catchError(error => {
        dispatch(new ParkingStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        dispatch([
          new ParkingStateAction.Delete.Success({ ...getState(), id }),
          new StateReset(ParkingState),
        ]);
      }),
    );
  }

  @Action(ParkingStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<ParkingStateModel>,
    { id, options }: ParkingStateAction.Read.Try,
  ) {
    return iif(
      () => !!options?.fromLocal,
      this.parkings$.pipe(map(parkings => parkings.find(parking => parking.id === id))),
      this.parkingService.read(id).pipe(
        catchError(error => {
          dispatch(new ParkingStateAction.Read.Failure(error));

          throw error;
        }),
        map(mapParkingToClient),
      ),
    ).pipe(
      tap(parking => {
        if (parking) {
          setState(parking);

          dispatch(new ParkingStateAction.Read.Success(getState()));
        } else {
          dispatch(new ParkingStateAction.Read.Failure(parking));
        }
      }),
    );
  }

  @Action(ParkingStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<ParkingStateModel>,
    { data, id }: ParkingStateAction.Update.Try,
  ) {
    return this.parkingService.update(mapParkingToApi({ ...data, id }, this.groups)).pipe(
      catchError(error => {
        dispatch(new ParkingStateAction.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data, id }));

        dispatch(new ParkingStateAction.Update.Success(getState()));
      }),
    );
  }
}
