// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { EmployeeStateModel } from '../employee';
import { GroupStateModel } from './group.model';

export namespace GroupStateAction {
  export namespace AddMembers {
    export class Try {
      static readonly type = '[Group] try Add Members To Group';
      constructor(
        public id: GroupStateModel['id'],
        public members: Array<EmployeeStateModel['id']>,
      ) {}
    }
    export class Success {
      static readonly type = '[Group] then Add Members To Group success';
      constructor(public group: GroupStateModel, public addedMembers: GroupStateModel['members']) {}
    }
    export class Failure {
      static readonly type = '[Group] catch Add Members To Group failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Create {
    export class Try {
      static readonly type = '[Group] try Create Group';
      constructor(
        public color: NonNullable<GroupStateModel['color']>,
        public name: NonNullable<GroupStateModel['name']>,
        public uid: GroupStateModel['uid'],
        public description: GroupStateModel['description'],
      ) {}
    }
    export class Success {
      static readonly type = '[Group] then Create Group success';
      constructor(public group: GroupStateModel) {}
    }
    export class Failure {
      static readonly type = '[Group] catch Create Group failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Group] try Delete Group';
      constructor(public id: GroupStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Group] then Delete Group success';
      constructor(public group: GroupStateModel) {}
    }
    export class Failure {
      static readonly type = '[Group] catch Delete Group failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Group] try Read Group';
      constructor(public id: GroupStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Group] then Read Group success';
      constructor(public group: GroupStateModel) {}
    }
    export class Failure {
      static readonly type = '[Group] catch Read Group failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace ReadMembers {
    export class Try {
      static readonly type = '[Group] try Read Group Members';
      constructor(public id: GroupStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Group] then Read Group Members success';
      constructor(public group: GroupStateModel) {}
    }
    export class Failure {
      static readonly type = '[Group] catch Read Group Members failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace RemoveMembers {
    export class Try {
      static readonly type = '[Group] try Remove Members From Group';
      constructor(
        public id: GroupStateModel['id'],
        public members: Array<EmployeeStateModel['email']>,
      ) {}
    }
    export class Success {
      static readonly type = '[Group] then Remove Members From Group success';
      constructor(
        public group: GroupStateModel,
        public removedMembers: GroupStateModel['members'],
      ) {}
    }
    export class Failure {
      static readonly type = '[Group] catch Remove Members From Group failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Group] try Update Group';
      constructor(public id: GroupStateModel['id'], public data: DeepPartial<GroupStateModel>) {}
    }
    export class Success {
      static readonly type = '[Group] then Update Group success';
      constructor(public group: GroupStateModel) {}
    }
    export class Failure {
      static readonly type = '[Group] catch Update Group failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
