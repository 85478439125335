<p>
  <nib-chip [color]="'nibol'">
    {{ 'main_badge_new' | translate }}
  </nib-chip>
</p>
<p>{{ 'integrations_slack_advdescription' | translate }}</p>
<p>
  <nib-button
    [color]="'secondary'"
    [disabled]="(hasInvalidSubscription$ | async) || (isInProgress$ | async)"
    [size]="'sm'"
    (onClick)="openSlackConnectionPopup()"
  >
    <nib-icon [name]="'slack'"></nib-icon>
    {{ 'integrations_slack_connect' | translate }}
  </nib-button>
</p>
