import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { BuildingsState, BuildingStateModel } from '@business/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SpaceGuard implements CanActivate {
  @Dispatch() navigateToSpace = (path: string) => new Navigate([path]);

  @Select(BuildingsState) buildings$!: Observable<BuildingStateModel[]>;

  async canActivate() {
    const buildings = await this.buildings$
      .pipe(
        filter(buildingsStore => buildingsStore.length > 0),
        first(),
      )
      .toPromise();

    this.navigateToSpace(
      `buildings/${buildings[0].id}/building/offices/${buildings[0].offices?.[0].id}/spaces/${buildings[0].offices?.[0].spaces?.[0].id}`,
    );

    return false;
  }
}
