// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { VisitorsStateModel } from './visitors.model';

export namespace VisitorsStateAction {
  export namespace Reservations {
    export namespace Read {
      export class Try {
        static readonly type = '[Visitors] try Read Visitors Reservations';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Visitors] then Read Visitors Reservations success';
        constructor(public visitors: VisitorsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Visitors] catch Read Visitors Reservations failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
      export class Cancel {
        static readonly type = '[Visitors] catch Read Visitors Reservations cancel';
        constructor(public reason?: string) {}
      }
    }
  }

  export namespace Settings {
    export namespace Read {
      export class Try {
        static readonly type = '[Visitors] try Read Visitors Settings';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Visitors] then Read Visitors Settings success';
        constructor(public visitors: VisitorsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Visitors] catch Read Visitors Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Visitors] try Update Visitors Settings';
        constructor(
          public buildingId: BuildingStateModel['id'],
          public data: DeepPartial<VisitorsStateModel>,
        ) {}
      }
      export class Success {
        static readonly type = '[Visitors] then Update Visitors Settings success';
        constructor(public visitors: VisitorsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Visitors] catch Update Visitors Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
