import { Injectable } from '@angular/core';
import { RoomService } from '@business/api';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { append, compose, iif as ngxsIif, patch, updateItem } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { iif, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IntegrationsSelectors } from '../integrations';
import { SpaceSelectors, SpaceStateModel } from '../space';
import { RoomStateAction } from './room.actions';
import { mapCompanionClientsToClient, mapRoomToApi, mapRoomToClient } from './room.helper';
import { RoomStateModel } from './room.model';
import { CompanionClient } from './room.type';

@State<RoomStateModel>({
  name: 'room',
})
@Injectable()
export class RoomState {
  @Select(SpaceSelectors.rooms) rooms$!: Observable<RoomStateModel[]>;

  @SelectSnapshot(IntegrationsSelectors.isRoomsEnabled) isRoomsEnabled!: boolean;
  @SelectSnapshot(SpaceSelectors.id) spaceId!: SpaceStateModel['id'];

  constructor(private readonly roomService: RoomService) {}

  @Action(RoomStateAction.CompanionClientList.Try)
  readCompanionClientLists(
    { dispatch, getState, setState }: StateContext<RoomStateModel>,
    { id }: RoomStateAction.CompanionClientList.Try,
  ) {
    return this.roomService.companionClients({ room_id: id }).pipe(
      catchError(error => {
        dispatch(new RoomStateAction.CompanionClientList.Failure(error));

        throw error;
      }),
      tap(companionClients => {
        if (id === getState().id) {
          setState(
            patch({
              companionClients: compose(
                ...mapCompanionClientsToClient(companionClients).map(companionClient =>
                  ngxsIif<CompanionClient[]>(
                    companionClientsFromStore =>
                      companionClientsFromStore?.some(
                        companionClientFromStore =>
                          companionClientFromStore.id === companionClient.id,
                      ) ?? false,
                    updateItem(
                      companionClientStore => companionClientStore?.id === companionClient.id,
                      patch(companionClient),
                    ),
                    append([companionClient]),
                  ),
                ),
              ),
              id,
            }),
          );
        } else {
          setState(patch({ companionClients: mapCompanionClientsToClient(companionClients) }));
        }

        dispatch(new RoomStateAction.CompanionClientList.Success(getState()));
      }),
    );
  }

  @Action(RoomStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<RoomStateModel>,
    { room, spaceId }: RoomStateAction.Create.Try,
  ) {
    return this.roomService.create({ room: mapRoomToApi(room), space_id: spaceId }).pipe(
      catchError(error => {
        dispatch(new RoomStateAction.Create.Failure(error));

        throw error;
      }),
      tap(({ room_id: id }) => {
        setState({ ...room, active: true, id, name: room.name as string });

        dispatch(new RoomStateAction.Create.Success(getState()));
      }),
    );
  }

  @Action(RoomStateAction.Delete.Try)
  delete({ dispatch, getState }: StateContext<RoomStateModel>, { id }: RoomStateAction.Delete.Try) {
    return this.roomService.delete({ id }).pipe(
      catchError(error => {
        dispatch(new RoomStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        dispatch([
          new RoomStateAction.Delete.Success({ ...getState(), id }),
          new StateReset(RoomState),
        ]);
      }),
    );
  }

  @Action(RoomStateAction.PairingCode.Try)
  pairingCode(
    { dispatch, getState }: StateContext<RoomStateModel>,
    { id }: RoomStateAction.PairingCode.Try,
  ) {
    return this.roomService.pairingCode({ room_id: id }).pipe(
      catchError(error => {
        dispatch(new RoomStateAction.PairingCode.Failure(error, id));

        throw error;
      }),
      tap(room => {
        if (room.status) {
          dispatch(new RoomStateAction.PairingCode.Success({ ...getState(), id }, room.code));
        } else {
          dispatch(new RoomStateAction.PairingCode.Failure(room, id));
        }
      }),
    );
  }

  @Action(RoomStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<RoomStateModel>,
    { id, options }: RoomStateAction.Read.Try,
  ) {
    return iif(
      () => !!options?.fromLocal,
      this.rooms$.pipe(map(rooms => rooms.find(room => room.id === id))),
      this.roomService.read({ id }).pipe(
        catchError(error => {
          dispatch(new RoomStateAction.Read.Failure(error));

          throw error;
        }),
        map(mapRoomToClient),
      ),
    ).pipe(
      tap(room => {
        if (room) {
          setState(room);

          dispatch(new RoomStateAction.Read.Success(getState()));
        } else {
          dispatch(new RoomStateAction.Read.Failure(room));
        }
      }),
    );
  }

  @Action(RoomStateAction.Set.Try)
  set({ setState }: StateContext<RoomStateModel>, { room }: RoomStateAction.Set.Try) {
    setState(room);
  }

  @Action(RoomStateAction.UnpairAllClients.Try)
  unpairAllDevices(
    { dispatch, getState, setState }: StateContext<RoomStateModel>,
    { id }: RoomStateAction.UnpairAllClients.Try,
  ) {
    return this.roomService.unpairAllDevices({ room_id: id }).pipe(
      catchError(error => {
        dispatch(new RoomStateAction.UnpairAllClients.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          setState(patch({ companionClients: [] as CompanionClient[] }));

          dispatch(new RoomStateAction.UnpairAllClients.Success(getState()));
        } else {
          dispatch(new RoomStateAction.UnpairAllClients.Failure(status));
        }
      }),
    );
  }

  @Action(RoomStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<RoomStateModel>,
    { data, id }: RoomStateAction.Update.Try,
  ) {
    return this.roomService
      .update({
        ...mapRoomToApi({ ...data, id }),
        ...(this.isRoomsEnabled
          ? { active: undefined, name: undefined, seats: undefined, space_id: this.spaceId }
          : {}),
      })
      .pipe(
        catchError(error => {
          dispatch(new RoomStateAction.Update.Failure(error));

          throw error;
        }),
        tap(() => {
          setState(patch({ ...data, id }));

          dispatch(new RoomStateAction.Update.Success(getState()));
        }),
      );
  }

  @Action(RoomStateAction.UploadPhoto.Try)
  uploadPhoto(
    { dispatch, getState }: StateContext<RoomStateModel>,
    { photo, id }: RoomStateAction.UploadPhoto.Try,
  ) {
    const uploadData = new FormData();
    uploadData.append('room_id', id);
    uploadData.append('picture', photo, photo.name);

    return this.roomService.uploadPhoto(uploadData).pipe(
      catchError(error => {
        dispatch(new RoomStateAction.UploadPhoto.Failure(error));

        throw error;
      }),
      tap(({ picture: { url } }) => {
        dispatch(
          new RoomStateAction.Update.Try(id, {
            pictures: [url],
          }),
        );
      }),
      catchError(error => {
        dispatch(new RoomStateAction.UploadPhoto.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new RoomStateAction.UploadPhoto.Success(getState()));
      }),
    );
  }
}
