import { Injectable } from '@angular/core';
import { CompanyService } from '@business/api/company';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { mapBuildingToClient } from '../building';
import { BuildingsState } from '../buildings';
import { mapCustomEntityToClient } from '../custom-entity';
import { mapDeskToClient } from '../desk';
import { EmployeesState } from '../employees';
import { mapOfficeToClient } from '../office';
import { mapParkingToClient } from '../parking';
import { mapRoomToClient } from '../room';
import { mapSpaceToClient } from '../space';
import { SurveysState } from '../surveys';
import { CompanyStateAction } from './company.actions';
import {
  mapAnalyticsToClient,
  mapBillingToApi,
  mapBillingToClient,
  mapCompanyTermsToClient,
  mapCompanyToApi,
  mapCompanyToClient,
  mapSubProcessorsToClient,
  mapSubscriptionToClient,
} from './company.helper';
import { CompanyStateModel } from './company.model';

@State<CompanyStateModel>({
  name: 'company',
  children: [BuildingsState, EmployeesState, SurveysState],
})
@Injectable()
export class CompanyState {
  constructor(private readonly companyService: CompanyService) {}

  @Action(CompanyStateAction.Analytics.Try)
  analytics({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.dashboard().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Analytics.Failure(error));

        throw error;
      }),
      tap(dashboard => {
        setState(patch({ ...mapAnalyticsToClient(dashboard) }));

        dispatch(new CompanyStateAction.Analytics.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Billing.Read.Try)
  billing({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.billing().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Billing.Read.Failure(error));

        throw error;
      }),
      tap(billing => {
        setState(patch({ billing: mapBillingToClient(billing) }));

        dispatch(new CompanyStateAction.Billing.Read.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.CompanyPolicy.Read.Try)
  companyPolicy({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.companyPolicy().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.CompanyPolicy.Read.Failure(error));

        throw error;
      }),
      tap(companyPolicy => {
        setState(patch({ companyPolicy: companyPolicy.text }));

        dispatch(new CompanyStateAction.CompanyPolicy.Read.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Countries.Try)
  countries({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.countries().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Countries.Failure(error));

        throw error;
      }),
      tap(countries => {
        setState(patch({ countries }));

        dispatch(new CompanyStateAction.Countries.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Info.Read.Try)
  readInfo({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.read().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Info.Read.Failure(error));

        throw error;
      }),
      tap(info => {
        setState(patch(mapCompanyToClient(info)));

        dispatch(new CompanyStateAction.Info.Read.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Spaces.Read.Try)
  readSpaces({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.readSpaces().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Spaces.Read.Failure(error));

        throw error;
      }),
      tap(spacesSettings => {
        setState(patch({ spacesSettings }));

        dispatch(new CompanyStateAction.Spaces.Read.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Store.Try)
  store(
    { dispatch, getState, setState }: StateContext<CompanyStateModel>,
    { company }: CompanyStateAction.Store.Try,
  ) {
    try {
      setState(patch({ ...company }));

      dispatch(new CompanyStateAction.Store.Success(getState()));
    } catch (error) {
      dispatch(new CompanyStateAction.Store.Failure(error));

      throw error;
    }
  }

  @Action(CompanyStateAction.Structure.Try)
  structure({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.structure().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Structure.Failure(error));

        throw error;
      }),
      tap(buildings => {
        setState(
          patch({
            buildings: buildings.map(building => ({
              ...mapBuildingToClient(building),
              offices: building.offices.map(office => ({
                ...mapOfficeToClient(office),
                spaces: office.spaces.map(space => ({
                  ...mapSpaceToClient(space),
                  customEntities: space.custom_entities?.map(customEntity =>
                    mapCustomEntityToClient({
                      ...customEntity,
                      active: true,
                      amenities: {},
                      coordinates: { lat: 0, lng: 0 },
                    }),
                  ),
                  desks: space.desks?.map(desk =>
                    mapDeskToClient({
                      ...desk,
                      active: true,
                      amenities: {},
                      coordinates: { lat: 0, lng: 0 },
                    }),
                  ),
                  parkings: space.parkings?.map(parking =>
                    mapParkingToClient({
                      ...parking,
                      active: true,
                      amenities: {},
                      coordinates: { lat: 0, lng: 0 },
                    }),
                  ),
                  rooms: space.rooms?.map(room =>
                    mapRoomToClient({
                      ...room,
                      active: true,
                      amenities: {},
                      coordinates: [],
                      picture: null,
                      seats: room.seats,
                    }),
                  ),
                })),
              })),
            })),
          }),
        );

        dispatch(new CompanyStateAction.Structure.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.SubProccessor.Try)
  subProcessor({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.subProcessor().pipe(
      catchError(error => {
        dispatch([new CompanyStateAction.SubProccessor.Failure(error)]);

        throw error;
      }),
      tap(subProcessors => {
        setState(patch({ subProcessors: mapSubProcessorsToClient(subProcessors) }));

        dispatch(new CompanyStateAction.SubProccessor.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Subscription.Try)
  subscription({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.subscription().pipe(
      catchError(error => {
        dispatch([new CompanyStateAction.Subscription.Failure(error)]);

        throw error;
      }),
      tap(subscription => {
        // @fixme(heavybeard): remove slack hack when it will be available forever
        const slack = getState().subscription?.features.slack;
        const subscriptionToSave = mapSubscriptionToClient(subscription);

        setState(
          patch({
            subscription: {
              ...subscriptionToSave,
              features: { ...subscriptionToSave?.features, slack },
            },
          }),
        );

        dispatch(new CompanyStateAction.Subscription.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Terms.Read.Try)
  terms({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.terms().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Terms.Read.Failure(error));

        throw error;
      }),
      tap(terms => {
        setState(patch({ terms: mapCompanyTermsToClient(terms) }));

        dispatch(new CompanyStateAction.Terms.Read.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Billing.Update.Try)
  updateBilling(
    { dispatch, getState, setState }: StateContext<CompanyStateModel>,
    { billing }: CompanyStateAction.Billing.Update.Try,
  ) {
    return this.companyService.updateBilling(mapBillingToApi(billing)).pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Billing.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ billing }));

        dispatch(new CompanyStateAction.Billing.Update.Success(getState()));
        dispatch(new CompanyStateAction.Spaces.Read.Try());
      }),
    );
  }

  @Action(CompanyStateAction.Info.Update.Try)
  updateInfo(
    { dispatch, getState, setState }: StateContext<CompanyStateModel>,
    { company }: CompanyStateAction.Info.Update.Try,
  ) {
    return this.companyService.update(mapCompanyToApi(company)).pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Info.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...company, settings: patch({ ...company.settings }) }));

        dispatch(new CompanyStateAction.Info.Update.Success(getState()));
      }),
    );
  }

  @Action(CompanyStateAction.Spaces.Update.Try)
  updateSpaces(
    { dispatch, getState, setState }: StateContext<CompanyStateModel>,
    { spacesSettings }: CompanyStateAction.Spaces.Update.Try,
  ) {
    return this.companyService.updateSpaces(spacesSettings).pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Spaces.Update.Failure(error));

        throw error;
      }),
      tap(updatedSpaces => {
        setState(patch({ updatedSpaces }));
        dispatch(new CompanyStateAction.Spaces.Update.Success(getState()));
        dispatch(new CompanyStateAction.Spaces.Read.Try());
      }),
    );
  }

  @Action(CompanyStateAction.SpacesActivate.Try)
  activateSpaces({ dispatch, getState, setState }: StateContext<CompanyStateModel>) {
    return this.companyService.activateSpaces().pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Spaces.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new CompanyStateAction.SpacesActivate.Success(getState()));
        dispatch(new CompanyStateAction.Spaces.Read.Try());
      }),
    );
  }

  @Action(CompanyStateAction.CompanyPolicy.Update.Try)
  updateCompanyPolicy(
    { dispatch, getState, setState }: StateContext<CompanyStateModel>,
    { companyPolicy, requireAcceptance }: CompanyStateAction.CompanyPolicy.Update.Try,
  ) {
    return this.companyService
      .updateCompanyPolicy({
        text: companyPolicy,
        require_accept_company_policy: requireAcceptance,
      })
      .pipe(
        catchError(error => {
          dispatch(new CompanyStateAction.CompanyPolicy.Update.Failure(error));

          throw error;
        }),
        tap(() => {
          setState(
            patch({
              companyPolicy,
              settings: patch({ require_accept_company_policy: requireAcceptance }),
            }),
          );

          dispatch(new CompanyStateAction.CompanyPolicy.Update.Success(getState()));
        }),
      );
  }

  @Action(CompanyStateAction.Terms.Update.Try)
  updateTerms(
    { dispatch, getState, setState }: StateContext<CompanyStateModel>,
    { terms, requireAcceptance }: CompanyStateAction.Terms.Update.Try,
  ) {
    return this.companyService
      .updateTerms({ terms, require_custom_terms_privacy: requireAcceptance })
      .pipe(
        catchError(error => {
          dispatch(new CompanyStateAction.Terms.Update.Failure(error));

          throw error;
        }),
        tap(updatedTerms => {
          setState(
            patch({
              terms: updatedTerms.terms,
              settings: patch({
                require_custom_terms_privacy: updatedTerms.require_custom_terms_privacy,
              }),
            }),
          );

          dispatch(new CompanyStateAction.Terms.Update.Success(getState()));
        }),
      );
  }

  @Action(CompanyStateAction.UploadLogo.Try)
  uploadLogo(
    { dispatch, getState }: StateContext<CompanyStateModel>,
    { logo: logoFile }: CompanyStateAction.UploadLogo.Try,
  ) {
    const uploadData = new FormData();
    uploadData.append('logo', logoFile, logoFile.name);

    return this.companyService.uploadLogo(uploadData).pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.UploadLogo.Failure(error));

        throw error;
      }),
      tap(({ logo: { url: logo } }) => {
        dispatch(new CompanyStateAction.Info.Update.Try({ logo }));
      }),
      catchError(error => {
        dispatch(new CompanyStateAction.UploadLogo.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new CompanyStateAction.UploadLogo.Success(getState()));
      }),
    );
  }
}
