// tslint:disable: no-namespace

import { Building } from '../building';
import { Group } from '../group';
import { Space } from '../space';

export namespace Stats {
  export type Body = {
    start: ReturnType<Date['toString']>;
    end: ReturnType<Date['toString']>;
    buildings?: Array<Building.Read.Response['id']>;
    groups?: Array<Group.Item.Read.Response['id']>;
    spaces?: Array<Space.Read.Response['id']>;
  };
  export namespace Desks {
    export namespace Occupancy {
      export type Response = {
        date: Array<ReturnType<Date['toString']>>;
        desks_number: number[];
        employees_desk_bookings: number[];
        occupancy_rate: number[];
        visitors_desk_bookings: number[];
      };
    }
    export namespace Bookings {
      export type Response = {
        date: Array<ReturnType<Date['toString']>>;
        avgBookings: number;
        total: number;
        checked: number[];
        lost: number[];
        canceled: number[];
        future: number[];
        showCheckInStats: boolean;
      };
    }
  }
  export namespace Members {
    export namespace Actives {
      export type Response = {
        date: Array<ReturnType<Date['toString']>>;
        value: number;
        total: number;
        actives: number[];
        inactives: number[];
      };
    }
  }
}
