import { Company } from '@business/api/company';
import {
  mapReservationsOfficeTimeCheckToApi,
  mapReservationsOfficeTimeCheckToClient,
} from '@business/helpers';
import { DeepPartial } from '@nibol/shared';
import { CompanyStateModel } from './company.model';

export function mapAnalyticsToClient({
  desk_available,
  desk_reserved,
  occupancy_rate,
}: Company.Dashboard.Response): Pick<
  CompanyStateModel,
  'availableDesks' | 'occupancyRate' | 'reservedDesks'
> {
  return {
    availableDesks: desk_available,
    reservedDesks: desk_reserved,
    occupancyRate: occupancy_rate,
  };
}

export function mapBillingToApi(
  billing: DeepPartial<CompanyStateModel['billing']>,
): DeepPartial<Company.Billing.Update.Body> {
  return {
    address: billing?.address,
    city: billing?.city,
    country: billing?.country,
    email: billing?.billingEmail,
    it: {
      fiscal_code: billing?.italianFiscalCode,
      payment_terms: billing?.paymentTerms,
      pec: billing?.pec,
      sdi: billing?.electronicCode,
    },
    name: billing?.organizationName,
    note: billing?.extraNotes,
    state: billing?.stateRegion,
    tax_id: billing?.taxId,
    zip: billing?.postalCode,
  };
}

export function mapBillingToClient(
  billing: Company.Billing.Read.Response,
): CompanyStateModel['billing'] {
  return {
    address: billing.address,
    billingEmail: billing.email,
    city: billing.city,
    country: billing.country.toUpperCase(),
    organizationName: billing.name,
    postalCode: billing.zip,
    stateRegion: billing.state,
    taxId: billing.tax_id,
    electronicCode: billing.it?.sdi,
    extraNotes: billing.note,
    italianFiscalCode: billing.it?.fiscal_code,
    paymentTerms: billing.it?.payment_terms,
    pec: billing.it?.pec,
  };
}

export function mapCompanyToApi({
  domains,
  logo,
  name,
  settings,
}: DeepPartial<CompanyStateModel>): DeepPartial<Company.Info.Update.Body> {
  return {
    ...(logo ? { info: { logo } } : {}),
    ...(settings
      ? {
          settings: {
            domains,
            features: {
              ...mapReservationsOfficeTimeCheckToApi(settings.reservations_office_time_check),
              availability_future_days: settings.availability_future_days,
              availability_weekly_reservation_limit: settings.availability_weekly_reservation_limit,
              book_for_employee: settings.book_for_employee,
              login_token_valid_time: settings.login_token_valid_time,
              map_custom_desk_name: settings.map_custom_desk_name,
              map_desk_assigned: settings.map_desk_assigned,
              map_show_users: settings.map_show_users,
              require_accept_company_policy: settings.require_accept_company_policy,
              require_custom_terms_privacy: settings.require_custom_terms_privacy,
              reservations_limit_days: settings.reservations_limit_days,
              reservations_limit_number: settings.reservations_limit_number,
              reservations_office_minimum: settings.reservations_office_minimum,
              reservations_time_between: settings.reservations_time_between,
              support_contacts: settings.support_contacts,
            },
          },
        }
      : {}),
    name,
  };
}

export function mapCompanyTermsToClient({
  terms,
}: Company.Terms.Read.Response): CompanyStateModel['terms'] {
  return terms.map(term => ({ ...term, country: term.country ?? 'null' }));
}

export function mapCompanyToClient({
  info,
  name,
  settings,
  slack,
}: DeepPartial<Company.Info.Read.Response>): DeepPartial<CompanyStateModel> {
  return {
    domains: info?.domain?.split(','),
    name,
    logo: info?.logo,
    settings: {
      ...mapReservationsOfficeTimeCheckToClient(settings?.features?.reservations_office_time_check),
      availability_future_days: settings?.features?.availability_future_days,
      availability_weekly_reservation_limit:
        settings?.features?.availability_weekly_reservation_limit,
      login_token_valid_time: settings?.features?.login_token_valid_time,
      book_for_employee: settings?.features?.book_for_employee,
      map_desk_assigned: settings?.features?.map_desk_assigned,
      map_custom_desk_name: settings?.features?.map_custom_desk_name,
      map_show_users: settings?.features?.map_show_users,
      require_accept_company_policy: settings?.features?.require_accept_company_policy,
      require_custom_terms_privacy: settings?.features?.require_custom_terms_privacy,
      reservations_limit_days: settings?.features?.reservations_limit_days,
      reservations_limit_number: settings?.features?.reservations_limit_number,
      reservations_office_minimum: settings?.features?.reservations_office_minimum,
      reservations_time_between: settings?.features?.reservations_time_between,
      support_contacts:
        settings?.features?.support_contacts === ''
          ? undefined
          : settings?.features?.support_contacts,
    },
    subscription: { features: { slack: typeof slack !== 'undefined' } },
  };
}

export function mapSubProcessorsToClient(
  subProcessors: Company.SubProcessor.Response,
): CompanyStateModel['subProcessors'] {
  return Object.entries(subProcessors).reduce(
    (entries: CompanyStateModel['subProcessors'], entry) => ({
      ...entries,
      [`TOGGLE_ALLOW_${entry[0].toUpperCase()}`]: entry[1],
    }),
    {},
  );
}

export function mapSubscriptionToClient(
  subscription: Company.Subscription.Response,
): CompanyStateModel['subscription'] {
  return {
    availableMembersAmount: subscription.members?.total ?? NaN,
    currentMembersAmount: subscription.members?.current ?? NaN,
    expirationDate: subscription.status?.expire ? new Date(subscription.status.expire) : null,
    features: {
      custom_email: subscription.features.custom_email,
      custom_terms_privacy: subscription.features.custom_terms_privacy,
      safe_c19: !subscription.features.safe_c19 ? false : subscription.features.safe_c19_buildings,
      survey: subscription.features.survey,
      work_from_anywhere: subscription.features.work_from_anywhere,
    },
    name: subscription.name,
    status: subscription.status?.current ?? 'free_trial',
  };
}
