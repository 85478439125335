import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filenameFromContentDisposition } from '@business/helpers';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Visitors } from './visitors.type';

@Injectable({ providedIn: 'root' })
export class VisitorsService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  reservations(
    params: Visitors.Reservations.Read.Params,
  ): Observable<Visitors.Reservations.Read.Response> {
    return this.httpClient
      .get<Visitors.Reservations.Read.Response>(
        `${this.apiRootUrl}/building/visitors/reservations`,
        { params },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reservationsExportCsv({
    building_id,
    from,
    to,
  }: Visitors.Reservations.ExportCsv.Body): Observable<{
    fileContent: string | Blob;
    fileName: string;
    fileType: string;
  }> {
    return this.httpClient
      .post(
        `${this.apiRootUrl}/building/visitors/reservations/export`,
        { building_id, from, to },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
        map(({ body, headers }) => ({
          fileContent: body || '',
          fileName: filenameFromContentDisposition(headers) ?? `${building_id}_${from}_${to}.csv`,
          fileType: headers.get('content-type') || 'text/csv;charset=utf-8',
        })),
      );
  }

  settings(id: Visitors.Settings.Read.Params['id']): Observable<Visitors.Settings.Read.Response> {
    return this.httpClient
      .get<Visitors.Settings.Read.Response>(`${this.apiRootUrl}/building/visitors`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSettings(
    id: Visitors.Settings.Update.Params['id'],
    data: DeepPartial<Visitors.Settings.Update.Params>,
  ): Observable<Visitors.Settings.Update.Response> {
    return this.httpClient
      .put<Visitors.Settings.Update.Response>(`${this.apiRootUrl}/building/visitors`, {
        ...data,
        id,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
