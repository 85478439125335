import { Parking } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { GroupStateModel } from '../group';
import { ParkingStateModel } from './parking.model';
import { ParkingType, ParkingTypes } from './parking.type';

export function mapParkingToApi(
  {
    active,
    amenities,
    audiences,
    coordinates,
    description,
    exclusivityAssignment,
    id,
    name,
  }: DeepPartial<ParkingStateModel>,
  groups: GroupStateModel[],
): DeepPartial<Parking.Update.Body> {
  return {
    active,
    amenities,
    assigned: {
      exclusive_assignment: exclusivityAssignment,
      assignee:
        audiences === null
          ? []
          : audiences?.map(audience => ({
              entity_id: audience,
              entity: entityFromId(audience, groups),
            })),
    },
    description,
    coordinates,
    id,
    name,
  };
}

export function mapParkingToClient({
  active,
  amenities,
  assigned,
  description,
  coordinates,
  id,
  name,
}: Parking.Read.Response): ParkingStateModel {
  return {
    active,
    amenities,
    audiences: assigned?.assignee?.map(({ entity_id }) => entity_id),
    coordinates,
    description,
    exclusivityAssignment: assigned?.exclusive_assignment ?? false,
    id,
    name,
    // @todo(heavybeard): remove this experiment when a parking type is ready on endpoint
    type: mapParkingTypeToClient(name),
  };
}

function mapParkingTypeToClient(name?: Parking.Read.Response['name']): ParkingType {
  if (name?.includes('moto')) {
    return ParkingTypes.moto;
  }

  return ParkingTypes.parking;
}

function entityFromId(
  audience: string,
  groups: GroupStateModel[],
): NonNullable<NonNullable<Parking.Update.Body['assigned']>['assignee']>[number]['entity'] {
  if (groups.find(group => group.id === audience)) {
    return 'group';
  }

  return 'user';
}
