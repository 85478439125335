// tslint:disable: no-namespace max-classes-per-file

import { Integrations } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { RoomStateModel } from '../room';
import { IntegrationsStateModel } from './integrations.model';
import {
  GoogleWorkspaceRoomsSettings,
  Microsoft365RoomsSettings,
  OktaDirectorySettings,
} from './integrations.type';

export namespace IntegrationsStateAction {
  export namespace Generic {
    export namespace Add {
      export class Try {
        static readonly type = '[Integrations] try Add Generic Integration';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Add Generic Integration success';
        // tslint:disable-next-line: no-any
        constructor(public success: any) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Add Generic Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace GoogleWorkspaceDirectory {
    export namespace Connect {
      export class Failure {
        static readonly type =
          '[Integrations] catch Connect Google Workspace Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Disconnect {
      export class Try {
        static readonly type =
          '[Integrations] try Disconnect Google Workspace Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Disconnect Google Workspace Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Disconnect Google Workspace Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Install {
      export class Try {
        static readonly type = '[Integrations] try Install Google Workspace Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Install Google Workspace Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Install Google Workspace Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Integrations] try Read Google Workspace Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Read Google Workspace Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Read Google Workspace Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Sync {
      export class Try {
        static readonly type = '[Integrations] try Sync Google Workspace Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Sync Google Workspace Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Sync Google Workspace Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Uninstall {
      export class Try {
        static readonly type =
          '[Integrations] try Uninstall Google Workspace Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Uninstall Google Workspace Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Uninstall Google Workspace Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Integrations] try Update Google Workspace Directory Integration';
        constructor(
          public googleWorkspaceDirectorySettings: DeepPartial<
            IntegrationsStateModel['googleWorkspaceDirectory']
          >,
        ) {}
      }
      export class Success {
        static readonly type =
          '[Integrations] then Update Google Workspace Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Update Google Workspace Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace GoogleWorkspaceRooms {
    export namespace Add {
      export class Try {
        static readonly type = '[Integrations] try Add Google Integration';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Add Google Integration success';
        // tslint:disable-next-line: no-any
        constructor(public success: any) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Add Google Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Integrations] try Read Google Workspace Rooms Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Read Google Workspace Rooms Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Read Google Workspace Rooms Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Sync {
      export class Try {
        static readonly type = '[Integrations] try Sync Google Workspace Rooms Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Sync Google Workspace Rooms Integration success';
        constructor(
          public integrations: IntegrationsStateModel,
          public remoteRooms: Integrations.GoogleWorkspaceRooms.Sync.Response,
        ) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Sync Google Workspace Rooms Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Uninstall {
      export class Try {
        static readonly type = '[Integrations] try Uninstall Google Workspace Rooms Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Uninstall Google Workspace Rooms Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Uninstall Google Workspace Rooms Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Integrations] try Update Google Workspace Rooms Integration';
        constructor(
          public googleWorkspaceRoomsSettings: DeepPartial<
            Pick<GoogleWorkspaceRoomsSettings, 'checkIn'>
          >,
        ) {}
      }
      export class Success {
        static readonly type =
          '[Integrations] then Update Google Workspace Rooms Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Update Google Workspace Rooms Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Kisi {
    export namespace Read {
      export class Try {
        static readonly type = '[Integrations] try Read Kisi Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Read Kisi Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Read Kisi Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Uninstall {
      export class Try {
        static readonly type = '[Integrations] try Uninstall Kisi Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Uninstall Kisi Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Uninstall Kisi Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Integrations] try Update Kisi Integration';
        constructor(public kisiSettings: Partial<IntegrationsStateModel['kisi']>) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Update Kisi Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Update Kisi Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Sofia {
    export namespace Read {
      export class Try {
        static readonly type = '[Integrations] try Read Sofia Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Read Sofia Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Read Sofia Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Uninstall {
      export class Try {
        static readonly type = '[Integrations] try Uninstall Sofia Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Uninstall Sofia Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Uninstall Sofia Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Integrations] try Update Sofia Integration';
        constructor(public sofiaSettings: Partial<IntegrationsStateModel['sofia']>) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Update Sofia Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Update Sofia Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Microsoft365Rooms {
    export namespace Add {
      export class Try {
        static readonly type = '[Integrations] try Add Microsoft Integration';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Add Microsoft Integration success';
        // tslint:disable-next-line: no-any
        constructor(public success: any) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Add Microsoft Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Integrations] try Read Microsoft 365 Rooms Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Read Microsoft 365 Rooms Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Read Microsoft 365 Rooms Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Sync {
      export class Try {
        static readonly type = '[Integrations] try Sync Microsoft 365 Rooms Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Sync Microsoft 365 Rooms Integration success';
        constructor(
          public integrations: IntegrationsStateModel,
          public remoteRooms: Integrations.Microsoft365Rooms.Sync.Response,
        ) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Sync Microsoft 365 Rooms Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Uninstall {
      export class Try {
        static readonly type = '[Integrations] try Uninstall Microsoft 365 Rooms Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Uninstall Microsoft 365 Rooms Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Uninstall Microsoft 365 Rooms Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Integrations] try Update Microsoft 365 Rooms Integration';
        constructor(
          public microsoft365RoomsSettings: DeepPartial<Pick<Microsoft365RoomsSettings, 'checkIn'>>,
        ) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Update Microsoft 365 Rooms Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Update Microsoft 365 Rooms Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace MicrosoftAzureActiveDirectory {
    export namespace Connect {
      export class Failure {
        static readonly type =
          '[Integrations] catch Connect Microsoft Azure Active Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Disconnect {
      export class Try {
        static readonly type =
          '[Integrations] try Disconnect Microsoft Azure Active Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Disconnect Microsoft Azure Active Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Disconnect Microsoft Azure Active Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Install {
      export class Try {
        static readonly type =
          '[Integrations] try Install Microsoft Azure Active Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Install Microsoft Azure Active Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Install Microsoft Azure Active Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type =
          '[Integrations] try Read Microsoft Azure Active Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Read Microsoft Azure Active Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Read Microsoft Azure Active Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Sync {
      export class Try {
        static readonly type =
          '[Integrations] try Sync Microsoft Azure Active Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Sync Microsoft Azure Active Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Sync Microsoft Azure Active Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Uninstall {
      export class Try {
        static readonly type =
          '[Integrations] try Uninstall Microsoft Azure Active Directory Integration';
      }
      export class Success {
        static readonly type =
          '[Integrations] then Uninstall Microsoft Azure Active Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Uninstall Microsoft Azure Active Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type =
          '[Integrations] try Update Microsoft Azure Active Directory Integration';
        constructor(
          public microsoftAzureActiveDirectorySettings: DeepPartial<
            IntegrationsStateModel['microsoftAzureActiveDirectory']
          >,
        ) {}
      }
      export class Success {
        static readonly type =
          '[Integrations] then Update Microsoft Azure Active Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type =
          '[Integrations] catch Update Microsoft Azure Active Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace OktaDirectory {
    export namespace Connect {
      export class Try {
        static readonly type = '[Integrations] try Connect Okta Directory Integration';
        constructor(
          public apiKey: OktaDirectorySettings['apiKey'],
          public domain: OktaDirectorySettings['domain'],
        ) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Connect Okta Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Connect Okta Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Disconnect {
      export class Try {
        static readonly type = '[Integrations] try Disconnect Okta Directory Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Disconnect Okta Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Disconnect Okta Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Install {
      export class Try {
        static readonly type = '[Integrations] try Install Okta Directory Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Install Okta Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Install Okta Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Integrations] try Read Okta Directory Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Read Okta Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Read Okta Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Sync {
      export class Try {
        static readonly type = '[Integrations] try Sync Okta Directory Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Sync Okta Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Sync Okta Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Uninstall {
      export class Try {
        static readonly type = '[Integrations] try Uninstall Okta Directory Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Uninstall Okta Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Uninstall Okta Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Integrations] try Update Okta Directory Integration';
        constructor(
          public oktaDirectorySettings: DeepPartial<IntegrationsStateModel['oktaDirectory']>,
        ) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Update Okta Directory Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Update Okta Directory Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Rooms {
    export namespace Associate {
      export class Try {
        static readonly type = '[Integrations] try Associate Integrated Rooms';
        constructor(public roomsAssociation: Integrations.Rooms.Associate.Body) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Associate Integrated Rooms success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Associate Integrated Rooms failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace List {
      export namespace Read {
        export class Try {
          static readonly type = '[Integrations] try Read Integrated Rooms List';
        }
        export class Success {
          static readonly type = '[Integrations] then Read Integrated Rooms List success';
          constructor(public integrations: IntegrationsStateModel) {}
        }
        export class Failure {
          static readonly type = '[Integrations] catch Read Integrated Rooms List failure';
          // tslint:disable-next-line: no-any
          constructor(public error: any) {}
        }
      }
      export namespace Update {
        export class Try {
          static readonly type = '[Integrations] try Update Integrated Rooms List';
          constructor(public room: RoomStateModel) {}
        }
        export class Success {
          static readonly type = '[Integrations] then Update Integrated Rooms List success';
          constructor(public integrations: IntegrationsStateModel) {}
        }
        export class Failure {
          static readonly type = '[Integrations] catch Update Integrated Rooms List failure';
          // tslint:disable-next-line: no-any
          constructor(public error: any) {}
        }
      }
    }
    export namespace Permissions {
      export class Try {
        static readonly type = '[Integrations] try Read Integrated Room Permission';
        constructor(public integratedRoomId: RoomStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Integrations] then Read Integrated Room Permission success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Read Integrated Room Permission failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Remote {
      export class Try {
        static readonly type = '[Integrations] try Read Integrated Remote Rooms';
      }
      export class Success {
        static readonly type = '[Integrations] then Read Integrated Remote Rooms success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Read Integrated Remote Rooms failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Slack {
    export namespace Connect {
      export class Failure {
        static readonly type = '[Integrations] catch Connect Slack Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Integrations] try Read Slack Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Read Slack Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Read Slack Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Uninstall {
      export class Try {
        static readonly type = '[Integrations] try Uninstall Slack Integration';
      }
      export class Success {
        static readonly type = '[Integrations] then Uninstall Slack Integration success';
        constructor(public integrations: IntegrationsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Integrations] catch Uninstall Slack Integration failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
