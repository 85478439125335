import { Injectable } from '@angular/core';
import { VisitorsService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { append, compose, iif as ngxsIif, patch, updateItem } from '@ngxs/store/operators';
import { defer, iif } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Reservation } from '../reservations';
import { VisitorsStateAction } from './visitors.actions';
import {
  mapVisitorsReservationsApiParams,
  mapVisitorsReservationsToClient,
  mapVisitorsSettingsToApi,
  mapVisitorsSettingsToClient,
} from './visitors.helper';
import { VisitorsStateModel } from './visitors.model';

@State<VisitorsStateModel>({
  name: 'visitors',
  defaults: {
    isEnabled: false,
    reservations: [],
    reservationsCurrentPage: -1,
    reservationsReachedElement: -1,
    reservationsReachedPage: -1,
    reservationsTotalElements: 0,
    reservationsTotalPages: 1,
  },
})
@Injectable()
export class VisitorsState {
  constructor(private readonly visitorsService: VisitorsService) {}

  @Action(VisitorsStateAction.Settings.Read.Try)
  readSettings(
    { dispatch, getState, setState }: StateContext<VisitorsStateModel>,
    { buildingId }: VisitorsStateAction.Settings.Read.Try,
  ) {
    return this.visitorsService.settings(buildingId).pipe(
      catchError(error => {
        dispatch(new VisitorsStateAction.Settings.Read.Failure(error));

        throw error;
      }),
      tap(roomsSettings => {
        setState(patch(mapVisitorsSettingsToClient(roomsSettings)));

        dispatch(new VisitorsStateAction.Settings.Read.Success(getState()));
      }),
    );
  }

  @Action(VisitorsStateAction.Reservations.Read.Try)
  readReservations(
    { dispatch, getState, setState }: StateContext<VisitorsStateModel>,
    { buildingId }: VisitorsStateAction.Reservations.Read.Try,
  ) {
    return iif(
      () =>
        getState().reservationsCurrentPage + 1 < getState().reservationsTotalPages &&
        getState().reservationsReachedElement < getState().reservationsTotalElements,
      this.visitorsService.reservations(
        mapVisitorsReservationsApiParams({
          buildingId,
          elements: 40,
          page: getState().reservationsCurrentPage + 1,
        }),
      ),
      defer(() => {
        dispatch(new VisitorsStateAction.Reservations.Read.Cancel());
      }),
    ).pipe(
      catchError(error => {
        dispatch(new VisitorsStateAction.Reservations.Read.Failure(error));

        throw error;
      }),
      tap(({ data, _metadata }) => {
        setState(
          patch<VisitorsStateModel>({
            ...(_metadata.page > getState().reservationsCurrentPage
              ? {
                  reservationsReachedPage: _metadata.page,
                  reservationsReachedElement: getState().reservations.length + data.length,
                }
              : {}),
            reservations: compose(
              ...mapVisitorsReservationsToClient(buildingId, data).map(reservation =>
                ngxsIif<Reservation[]>(
                  reservationsFromStore =>
                    reservationsFromStore?.some(
                      reservationFromStore => reservationFromStore.id === reservation.id,
                    ) ?? false,
                  updateItem<Reservation>(
                    reservationStore => reservationStore?.id === reservation.id,
                    patch(reservation),
                  ),
                  append([reservation]),
                ),
              ),
            ),
            reservationsCurrentPage: _metadata.page,
            reservationsTotalElements: _metadata.total_elements,
            reservationsTotalPages: _metadata.total_pages,
          }),
        );

        dispatch(new VisitorsStateAction.Reservations.Read.Success(getState()));
      }),
    );
  }

  @Action(VisitorsStateAction.Settings.Update.Try)
  updateSettings(
    { dispatch, getState, setState }: StateContext<VisitorsStateModel>,
    { buildingId, data }: VisitorsStateAction.Settings.Update.Try,
  ) {
    return this.visitorsService
      .updateSettings(buildingId, mapVisitorsSettingsToApi(buildingId, data))
      .pipe(
        catchError(error => {
          dispatch(new VisitorsStateAction.Settings.Update.Failure(error));

          throw error;
        }),
        tap(() => {
          setState(patch({ ...data }));

          dispatch(new VisitorsStateAction.Settings.Update.Success(getState()));
        }),
      );
  }
}
