// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { StoreOptions } from '@nibol/store';
import { SpaceStateModel } from '../space';
import { ParkingStateModel } from './parking.model';

export namespace ParkingStateAction {
  export namespace Create {
    export class Try {
      static readonly type = '[Parking] try Create Parking';
      constructor(
        public spaceId: SpaceStateModel['id'],
        public parking: Pick<ParkingStateModel, 'coordinates'> &
          Pick<ParkingStateModel, 'type'> &
          DeepPartial<Pick<ParkingStateModel, 'active' | 'amenities' | 'audiences' | 'name'>>,
      ) {}
    }
    export class Success {
      static readonly type = '[Parking] then Create Parking success';
      constructor(public parking: ParkingStateModel) {}
    }
    export class Failure {
      static readonly type = '[Parking] catch Create Parking failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Parking] try Delete Parking';
      constructor(public id: ParkingStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Parking] then Delete Parking success';
      constructor(public parking: ParkingStateModel) {}
    }
    export class Failure {
      static readonly type = '[Parking] catch Delete Parking failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Parking] try Read Parking';
      constructor(public id: ParkingStateModel['id'], public options?: StoreOptions) {}
    }
    export class Success {
      static readonly type = '[Parking] then Read Parking success';
      constructor(public parking: ParkingStateModel) {}
    }
    export class Failure {
      static readonly type = '[Parking] catch Read Parking failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Parking] try Update Parking';
      constructor(
        public id: ParkingStateModel['id'],
        public data: DeepPartial<
          Pick<ParkingStateModel, 'active' | 'amenities' | 'audiences' | 'coordinates' | 'name'>
        >,
      ) {}
    }
    export class Success {
      static readonly type = '[Parking] then Update Parking success';
      constructor(public parking: ParkingStateModel) {}
    }
    export class Failure {
      static readonly type = '[Parking] catch Update Parking failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
