import { Injectable } from '@angular/core';
import { BuildingService } from '@business/api/building';
import { Action, State, StateContext } from '@ngxs/store';
import { append, removeItem, updateItem } from '@ngxs/store/operators';
import { mergeDeep } from '@nibol/shared';
import { catchError, first, tap } from 'rxjs/operators';
import { BuildingStateModel } from '../building';
import { BuildingsStateAction } from './buildings.actions';
import { processBuildings } from './buildings.helper';

@State<BuildingStateModel[]>({
  name: 'buildings',
  defaults: [],
})
@Injectable()
export class BuildingsState {
  constructor(private readonly buildingService: BuildingService) {}

  @Action(BuildingsStateAction.Add.Try)
  add(
    { dispatch, getState, setState }: StateContext<BuildingStateModel[]>,
    { building }: BuildingsStateAction.Add.Try,
  ) {
    try {
      setState(append([building]));

      dispatch(new BuildingsStateAction.Add.Success(getState()));
    } catch (error) {
      dispatch(new BuildingsStateAction.Add.Failure(error));

      throw error;
    }
  }

  @Action(BuildingsStateAction.Read.Try)
  read({ dispatch, setState }: StateContext<BuildingStateModel[]>) {
    return this.buildingService.list().pipe(
      first(),
      catchError(error => {
        dispatch(new BuildingsStateAction.Read.Failure(error));

        throw error;
      }),
      tap(buildingList => {
        try {
          const buildings = processBuildings(buildingList);

          setState(buildings);

          dispatch(new BuildingsStateAction.Read.Success(buildings));
        } catch (error) {
          dispatch(new BuildingsStateAction.Read.Failure(error));

          throw error;
        }
      }),
    );
  }

  @Action(BuildingsStateAction.Remove.Try)
  remove(
    { dispatch, getState, setState }: StateContext<BuildingStateModel[]>,
    { id }: BuildingsStateAction.Remove.Try,
  ) {
    try {
      setState(removeItem(building => building?.id === id));

      dispatch(new BuildingsStateAction.Remove.Success(getState()));
    } catch (error) {
      dispatch(new BuildingsStateAction.Remove.Failure(error));

      throw error;
    }
  }

  @Action(BuildingsStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<BuildingStateModel[]>,
    { building }: BuildingsStateAction.Update.Try,
  ) {
    try {
      setState(
        updateItem<BuildingStateModel>(
          buildingStore => buildingStore?.id === building.id,
          value => mergeDeep(building, value),
        ),
      );

      dispatch(new BuildingsStateAction.Update.Success(getState()));
    } catch (error) {
      dispatch(new BuildingsStateAction.Update.Failure(error));

      throw error;
    }
  }
}
