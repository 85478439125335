import { Selector } from '@ngxs/store';
import { RoomsStateModel } from './rooms.model';
import { RoomsState } from './rooms.state';

export class RoomsSelectors {
  @Selector([RoomsState])
  static checkIn({ checkIn }: RoomsStateModel): RoomsStateModel['checkIn'] {
    return checkIn;
  }

  @Selector([RoomsSelectors.reservationsReachedElement, RoomsSelectors.reservationsTotalElements])
  static hasFurtherReservations(
    reservationsReachedElement: number,
    reservationsTotalElements: number,
  ): boolean {
    return reservationsReachedElement < reservationsTotalElements;
  }

  @Selector([RoomsState])
  static list({ list }: RoomsStateModel): RoomsStateModel['list'] {
    return list;
  }

  @Selector([RoomsState])
  static reservations({ reservations }: RoomsStateModel): RoomsStateModel['reservations'] {
    return reservations;
  }

  @Selector([RoomsState])
  static reservationsReachedElement({
    reservationsReachedElement,
  }: RoomsStateModel): RoomsStateModel['reservationsReachedElement'] {
    return reservationsReachedElement;
  }

  @Selector([RoomsState])
  static reservationsTotalElements({
    reservationsTotalElements,
  }: RoomsStateModel): RoomsStateModel['reservationsTotalElements'] {
    return reservationsTotalElements;
  }
}
