// tslint:disable: no-namespace max-classes-per-file

import { EmailStateModel } from '../email/email.model';
import { EmailsStateModel } from './emails.model';

export namespace EmailsStateAction {
  export namespace DesignSettings {
    export namespace Read {
      export class Try {
        static readonly type = '[Emails] try Read Design Settings';
      }
      export class Success {
        static readonly type = '[Emails] then Read Design Settings success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Read Design Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Reset {
      export class Try {
        static readonly type = '[Emails] try Reset Design Settings';
      }
      export class Success {
        static readonly type = '[Emails] then Reset Design Settings success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Reset Design Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Emails] try Update Design Settings';
        constructor(public designSettings: EmailsStateModel['designSettings']) {}
      }
      export class Success {
        static readonly type = '[Emails] then Update Design Settings success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Update Design Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace List {
    export namespace Read {
      export class Try {
        static readonly type = '[Emails] try Read Emails List';
      }
      export class Success {
        static readonly type = '[Emails] then Read Emails List success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Read Emails List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Emails] try Update Email in List';
        constructor(public email: EmailStateModel) {}
      }
      export class Success {
        static readonly type = '[Emails] then Update Email in List success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Update Email in List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace SmtpSettings {
    export namespace Read {
      export class Try {
        static readonly type = '[Emails] try Read Smtp Settings';
      }
      export class Success {
        static readonly type = '[Emails] then Read Smtp Settings success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Read Smtp Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Reset {
      export class Try {
        static readonly type = '[Emails] try Reset Smtp Settings';
      }
      export class Success {
        static readonly type = '[Emails] then Reset Smtp Settings success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Reset Smtp Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Test {
      export class Try {
        static readonly type = '[Emails] try Test Smtp Settings';
      }
      export class Success {
        static readonly type = '[Emails] then Test Smtp Settings success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Test Smtp Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Emails] try Update Smtp Settings';
        constructor(public smtpSettings: Partial<EmailsStateModel['smtpSettings']>) {}
      }
      export class Success {
        static readonly type = '[Emails] then Update Smtp Settings success';
        constructor(public emails: EmailsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Emails] catch Update Smtp Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace UploadLogo {
    export class Try {
      static readonly type = '[Emails] try Upload Email Logo';
      constructor(public logo: File) {}
    }
    export class Success {
      static readonly type = '[Emails] then Upload Email Logo success';
      constructor(public emails: EmailsStateModel) {}
    }
    export class Failure {
      static readonly type = '[Emails] catch Upload Email Logo failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
