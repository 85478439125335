import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filenameFromContentDisposition } from '@business/helpers';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Deliveries } from './deliveries.type';

@Injectable({ providedIn: 'root' })
export class DeliveriesService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  list({
    building_id,
  }: Deliveries.Activities.Read.Params): Observable<Deliveries.Activities.Read.Response> {
    return this.httpClient
      .get<Deliveries.Activities.Read.Response>(
        `${this.apiRootUrl}/building/deliveries/activities`,
        { params: { building_id } },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  listExportCsv({
    building_id,
    from,
    to,
  }: Deliveries.Activities.ExportCsv.Body): Observable<{
    fileContent: string | Blob;
    fileName: string;
    fileType: string;
  }> {
    return this.httpClient
      .post(
        `${this.apiRootUrl}/building/deliveries/activities/export`,
        { building_id, from, to },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
        map(({ body, headers }) => ({
          fileContent: body || '',
          fileName: filenameFromContentDisposition(headers) ?? `${building_id}_${from}_${to}.csv`,
          fileType: headers.get('content-type') || 'text/csv;charset=utf-8',
        })),
      );
  }

  settings({ id }: Deliveries.Settings.Read.Params): Observable<Deliveries.Settings.Read.Response> {
    return this.httpClient
      .get<Deliveries.Settings.Read.Response>(`${this.apiRootUrl}/building/deliveries`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSettings({
    id,
    deliveries,
  }: Deliveries.Settings.Update.Body): Observable<Deliveries.Settings.Update.Response> {
    return this.httpClient
      .put<Deliveries.Settings.Update.Response>(`${this.apiRootUrl}/building/deliveries`, {
        id,
        deliveries,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
