// tslint:disable: no-namespace

import { DeepPartial } from '@nibol/shared';
import { CustomEntitiesCategory } from '../custom-entities';
import { CustomEntity } from '../custom-entity';
import { Desk } from '../desk';
import { Office } from '../office';
import { Parking } from '../parking';
import { Room } from '../room';

export namespace Space {
  export namespace Create {
    export type Body = {
      office_id: Office.Read.Response['id'];
      space: Pick<Read.Response, 'name' | 'type'> &
        Partial<Pick<Read.Response, 'map_entities' | 'mapEntities'>>;
    };
    export type Response = Pick<Read.Response, 'id'> & {
      status: boolean;
    };
  }

  export namespace CustomEntities {
    export type Params = {
      space_id: Read.Response['id'];
    };
    export type Response = Array<
      Pick<
        CustomEntity.Read.Response,
        'active' | 'amenities' | 'assigned' | 'coordinates' | 'id' | 'mapEntityCategory' | 'name'
      >
    >;
  }

  export namespace Delete {
    export type Params = {
      space_id: Read.Response['id'];
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace Desks {
    export type Params = {
      space_id: Read.Response['id'];
    };
    export type Response = Array<
      Pick<Desk.Read.Response, 'active' | 'amenities' | 'assigned' | 'coordinates' | 'id' | 'name'>
    >;
  }

  export namespace Parkings {
    export type Params = {
      space_id: Read.Response['id'];
    };
    export type Response = Array<
      Pick<
        Parking.Read.Response,
        'active' | 'amenities' | 'assigned' | 'coordinates' | 'description' | 'id' | 'name'
      >
    >;
  }

  export namespace Read {
    export type Params = {
      space_id: Response['id'];
    };
    export type Response = {
      amenities_desk: { [amenity: string]: boolean | undefined };
      amenities_parking: { [amenity: string]: boolean | undefined };
      amenities_room: { [amenity: string]: boolean | undefined };
      id: string;
      map: {
        url: string;
        height: number;
        width: number;
      };
      map_entities: {
        desks?: number;
        rooms?: number;
        parkings?: number;
        [category: string]: number | undefined;
      };
      mapEntities: Array<{
        category:
          | 'desks'
          | 'parkings'
          | 'rooms'
          | CustomEntitiesCategory.List.Response[number]['id'];
        count: number;
        defaultAmenities: string[];
      }>;
      max_capacity: number | null;
      name: string;
      pictures: string[];
      type: 'open' | 'map';
      description?: string | null;
    };
  }

  export namespace Rooms {
    export type Params = {
      space_id: Read.Response['id'];
    };
    export type Response = Array<
      Pick<
        Room.Read.Response,
        | 'active'
        | 'amenities'
        | 'calendar_email'
        | 'coordinates'
        | 'id'
        | 'name'
        | 'picture'
        | 'seats'
      >
    >;
  }

  export namespace Update {
    export type Body = DeepPartial<
      Pick<
        Read.Response,
        | 'amenities_desk'
        | 'amenities_room'
        | 'amenities_parking'
        | 'description'
        | 'id'
        | 'map'
        | 'map_entities'
        | 'mapEntities'
        | 'max_capacity'
        | 'name'
        | 'pictures'
      >
    >;
    export type Response = {
      status: boolean;
    };
  }

  export namespace UploadMap {
    export type Params = FormData;
    export type Response = {
      height: number;
      url: string;
      width: number;
    };
  }

  export namespace UploadPhoto {
    export type Params = FormData;
    export type Response = Array<{ url: string }>;
  }
}
