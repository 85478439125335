import { Selector } from '@ngxs/store';
import { IntegrationsSelectors } from '../integrations';
import { GroupsStateModel } from './groups.model';
import { GroupsState } from './groups.state';

export class GroupsSelectors {
  @Selector([IntegrationsSelectors.isGroupEnabled])
  static isManagementEnabled(isGroupEnabled: boolean): boolean {
    return isGroupEnabled;
  }

  @Selector([GroupsState])
  static everyoneGroup({ list }: GroupsStateModel): GroupsStateModel['list'][number] | undefined {
    return list.find(group => group.uid === 'everyone');
  }

  @Selector([GroupsState])
  static list({ list }: GroupsStateModel): GroupsStateModel['list'] {
    return list;
  }
}
