import { Injectable } from '@angular/core';
import { CustomEntityService } from '@business/api';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { iif, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GroupStateModel } from '../group';
import { GroupsSelectors } from '../groups';
import { SpaceSelectors } from '../space';
import { CustomEntityStateAction } from './custom-entity.actions';
import { mapCustomEntityToApi, mapCustomEntityToClient } from './custom-entity.helper';
import { CustomEntityStateModel } from './custom-entity.model';

@State<CustomEntityStateModel>({
  name: 'customentity',
})
@Injectable()
export class CustomEntityState {
  @Select(SpaceSelectors.customEntities) customEntities$!: Observable<CustomEntityStateModel[]>;

  @SelectSnapshot(GroupsSelectors.list) groups!: GroupStateModel[];

  constructor(private readonly customEntityService: CustomEntityService) {}

  @Action(CustomEntityStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<CustomEntityStateModel>,
    { customEntity, spaceId }: CustomEntityStateAction.Create.Try,
  ) {
    return this.customEntityService
      .create({ customEntity: mapCustomEntityToApi(customEntity, this.groups), space_id: spaceId })
      .pipe(
        catchError(error => {
          dispatch(new CustomEntityStateAction.Create.Failure(error));

          throw error;
        }),
        tap(({ id }) => {
          setState({
            ...customEntity,
            active: true,
            category: customEntity.category,
            exclusivityAssignment: false,
            id,
            name: customEntity.name as string,
          });

          dispatch(new CustomEntityStateAction.Create.Success(getState()));
        }),
      );
  }

  @Action(CustomEntityStateAction.Delete.Try)
  delete(
    { dispatch, getState }: StateContext<CustomEntityStateModel>,
    { id }: CustomEntityStateAction.Delete.Try,
  ) {
    return this.customEntityService.delete({ id }).pipe(
      catchError(error => {
        dispatch(new CustomEntityStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        dispatch([
          new CustomEntityStateAction.Delete.Success({ ...getState(), id }),
          new StateReset(CustomEntityState),
        ]);
      }),
    );
  }

  @Action(CustomEntityStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<CustomEntityStateModel>,
    { id, options }: CustomEntityStateAction.Read.Try,
  ) {
    return iif(
      () => !!options?.fromLocal,
      this.customEntities$.pipe(
        map(customEntities => customEntities.find(customEntity => customEntity.id === id)),
      ),
      this.customEntityService.read(id).pipe(
        catchError(error => {
          dispatch(new CustomEntityStateAction.Read.Failure(error));

          throw error;
        }),
        map(mapCustomEntityToClient),
      ),
    ).pipe(
      tap(customEntity => {
        if (customEntity) {
          setState(customEntity);

          dispatch(new CustomEntityStateAction.Read.Success(getState()));
        } else {
          dispatch(new CustomEntityStateAction.Read.Failure(customEntity));
        }
      }),
    );
  }

  @Action(CustomEntityStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<CustomEntityStateModel>,
    { data, id }: CustomEntityStateAction.Update.Try,
  ) {
    return this.customEntityService.update(mapCustomEntityToApi({ ...data, id }, this.groups)).pipe(
      catchError(error => {
        dispatch(new CustomEntityStateAction.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data, id }));

        dispatch(new CustomEntityStateAction.Update.Success(getState()));
      }),
    );
  }
}
