import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filenameFromContentDisposition } from '@business/helpers';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Parkings } from './parkings.type';

@Injectable({ providedIn: 'root' })
export class ParkingsService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  deleteReservations(
    body: Parkings.Reservations.Delete.Body,
  ): Observable<Parkings.Reservations.Delete.Response> {
    return this.httpClient
      .post<Parkings.Reservations.Delete.Response>(
        `${this.apiRootUrl}/building/parkings/reservations/cancel`,
        body,
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reservations(
    params: Parkings.Reservations.Read.Params,
  ): Observable<Parkings.Reservations.Read.Response> {
    return this.httpClient
      .get<Parkings.Reservations.Read.Response>(
        `${this.apiRootUrl}/building/parkings/reservations`,
        { params },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reservationsExportCsv({
    building_id,
    from,
    to,
  }: Parkings.Reservations.ExportCsv.Body): Observable<{
    fileContent: string | Blob;
    fileName: string;
    fileType: string;
  }> {
    return this.httpClient
      .post(
        `${this.apiRootUrl}/building/parkings/reservations/export`,
        { building_id, from, to },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
        map(({ body, headers }) => ({
          fileContent: body || '',
          fileName: filenameFromContentDisposition(headers) ?? `${building_id}_${from}_${to}.csv`,
          fileType: headers.get('content-type') || 'text/csv;charset=utf-8',
        })),
      );
  }

  settings(id: Parkings.Settings.Read.Params['id']): Observable<Parkings.Settings.Read.Response> {
    return this.httpClient
      .get<Parkings.Settings.Read.Response>(`${this.apiRootUrl}/building/parkings`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateSettings(
    body: Parkings.Settings.Update.Body,
  ): Observable<Parkings.Settings.Update.Response> {
    return this.httpClient
      .put<Parkings.Settings.Update.Response>(`${this.apiRootUrl}/building/parkings`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
