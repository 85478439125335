import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filenameFromContentDisposition } from '@business/helpers';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Survey } from './survey.type';

@Injectable({ providedIn: 'root' })
export class SurveyService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  list(): Observable<Survey.List.Response> {
    return this.httpClient.get<Survey.List.Response>(`${this.apiRootUrl}/survey/list`).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  questions(id: Survey.Questions.Params['id']): Observable<Survey.Questions.Response> {
    return this.httpClient
      .get<Survey.Questions.Response>(`${this.apiRootUrl}/survey/questions`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  responsesExportCsv({
    survey_id,
    from,
    to,
  }: Survey.Responses.ExportCsv.Body): Observable<{
    fileContent: string | Blob;
    fileName: string;
    fileType: string;
  }> {
    return this.httpClient
      .post(
        `${this.apiRootUrl}/survey/responses/export`,
        { survey_id, from, to },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
        map(({ body, headers }) => ({
          fileContent: body || '',
          fileName: filenameFromContentDisposition(headers) ?? `${survey_id}_${from}_${to}.csv`,
          fileType: headers.get('content-type') || 'text/csv;charset=utf-8',
        })),
      );
  }

  triggers(id: Survey.Triggers.Params['id']): Observable<Survey.Triggers.Response> {
    return this.httpClient
      .get<Survey.Triggers.Response>(`${this.apiRootUrl}/survey/triggers`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
