import { Injectable } from '@angular/core';
import { CompanyService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { catchError, first, tap } from 'rxjs/operators';
import { GroupStateModel } from '../group/group.model';
import { GroupsStateAction } from './groups.actions';
import { mapGroupsToClient } from './groups.helper';
import { GroupsStateModel } from './groups.model';

@State<GroupsStateModel>({
  name: 'groups',
  defaults: { list: [] },
})
@Injectable()
export class GroupsState {
  constructor(private readonly companyService: CompanyService) {}

  @Action(GroupsStateAction.List.Add.Try)
  addItemInList(
    { dispatch, getState, setState }: StateContext<GroupsStateModel>,
    { group }: GroupsStateAction.List.Add.Try,
  ) {
    try {
      setState(
        patch({
          list: insertItem(group, 0),
        }),
      );

      dispatch(new GroupsStateAction.List.Add.Success(getState()));
    } catch (error) {
      dispatch(new GroupsStateAction.List.Add.Failure(error));

      throw error;
    }
  }

  @Action(GroupsStateAction.List.Read.Try)
  readList({ dispatch, getState, patchState }: StateContext<GroupsStateModel>) {
    return this.companyService.groups().pipe(
      first(),
      catchError(error => {
        dispatch(new GroupsStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(groups => {
        patchState({
          list: mapGroupsToClient(groups),
        });

        dispatch(new GroupsStateAction.List.Read.Success(getState()));
      }),
    );
  }

  @Action(GroupsStateAction.List.Remove.Try)
  removeItemFromList(
    { dispatch, getState, setState }: StateContext<GroupsStateModel>,
    { id }: GroupsStateAction.List.Remove.Try,
  ) {
    try {
      setState(
        patch({
          list: removeItem<GroupStateModel>(group => group?.id === id),
        }),
      );

      dispatch(new GroupsStateAction.List.Remove.Success(getState()));
    } catch (error) {
      dispatch(new GroupsStateAction.List.Remove.Failure(error));

      throw error;
    }
  }

  @Action(GroupsStateAction.List.Update.Try)
  updateItemInList(
    { dispatch, getState, setState }: StateContext<GroupsStateModel>,
    { group }: GroupsStateAction.List.Update.Try,
  ) {
    try {
      setState(
        patch({
          list: updateItem<GroupStateModel>(
            groupStore => groupStore?.id === group.id,
            patch(group),
          ),
        }),
      );
      dispatch(new GroupsStateAction.List.Update.Success(getState()));
    } catch (error) {
      dispatch(new GroupsStateAction.List.Update.Failure(error));

      throw error;
    }
  }
}
