import { Selector } from '@ngxs/store';
import { DeliveriesStateModel } from './deliveries.model';
import { DeliveriesState } from './deliveries.state';

export class DeliveriesSelectors {
  @Selector([DeliveriesState])
  static isEnabled({ isEnabled }: DeliveriesStateModel): DeliveriesStateModel['isEnabled'] {
    return isEnabled;
  }

  @Selector([DeliveriesState])
  static list({ list }: DeliveriesStateModel): DeliveriesStateModel['list'] {
    return list;
  }
}
