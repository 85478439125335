import { CustomEntityType } from '../custom-entity';
import { DeskType } from '../desk';
import { ParkingType } from '../parking';
import { RoomType } from '../room';

export type BookableItemType = CustomEntityType & DeskType & ParkingType & RoomType;

export type SpaceAmenities = {
  [amenity in SpaceAmenitiesDeskDefault | SpaceAmenitiesParkingDefaults | string]?: boolean;
};

export type SpaceAmenitiesDeskDefault = keyof typeof SpaceAmenitiesDeskDefaults;

// tslint:disable-next-line: prefer-const-enum
export enum SpaceAmenitiesDeskDefaults {
  'monitor' = 'monitor',
  'monitor_big' = 'monitor_big',
  'keyboard' = 'keyboard',
  'adjustable' = 'adjustable',
  'air' = 'air',
  'light' = 'light',
}

export type SpaceAmenitiesParkingDefault = keyof typeof SpaceAmenitiesParkingDefaults;

// tslint:disable-next-line: prefer-const-enum
export enum SpaceAmenitiesParkingDefaults {
  bicycle = 'bicycle',
  car = 'car',
  covered = 'covered',
  electric = 'electric',
  handicap = 'handicap',
  locked = 'locked',
  motorcycle = 'motorcycle',
  nogpl = 'nogpl',
}

export type SpaceAmenitiesRoomDefault = keyof typeof SpaceAmenitiesRoomDefaults;

// tslint:disable-next-line: prefer-const-enum
export enum SpaceAmenitiesRoomDefaults {
  air = 'air',
  hdmi = 'hdmi',
  microphone = 'microphone',
  projector = 'projector',
  speaker = 'speaker',
  whiteboard = 'whiteboard',
}

export type SpaceMap = {
  height: number;
  url: string;
  width: number;
};

export type SpaceType = keyof typeof SpaceTypes;

export const enum SpaceTypes {
  map = 'map',
  openCustomEntity = 'openCustomEntity',
  openDesk = 'openDesk',
  openParking = 'openParking',
}
