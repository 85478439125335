import { Injectable } from '@angular/core';
import { ParkingsService, SpaceService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import {
  append,
  compose,
  iif as ngxsIif,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { defer, iif } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { mapParkingToClient } from '../parking/parking.helper';
import { ParkingStateModel } from '../parking/parking.model';
import { Reservation } from '../reservations';
import { ParkingsStateAction } from './parkings.actions';
import {
  mapParkingsReservationsApiParams,
  mapParkingsReservationsToClient,
  mapParkingsSettingsToApi,
  mapParkingsSettingsToClient,
} from './parkings.helper';
import { ParkingsStateModel } from './parkings.model';

@State<ParkingsStateModel>({
  name: 'parkings',
  defaults: {
    list: [],
    reservations: [],
    reservationsCurrentPage: -1,
    reservationsReachedElement: -1,
    reservationsReachedPage: -1,
    reservationsTotalElements: 0,
    reservationsTotalPages: 1,
  },
})
@Injectable()
export class ParkingsState {
  constructor(
    private readonly parkingsService: ParkingsService,
    private readonly spaceService: SpaceService,
  ) {}

  @Action(ParkingsStateAction.List.Add.Try)
  addItemInList(
    { dispatch, getState, setState }: StateContext<ParkingsStateModel>,
    { parking }: ParkingsStateAction.List.Add.Try,
  ) {
    try {
      setState(
        patch({
          list: append([parking]),
        }),
      );

      dispatch(new ParkingsStateAction.List.Add.Success(getState()));
    } catch (error) {
      dispatch(new ParkingsStateAction.List.Add.Failure(error));

      throw error;
    }
  }

  @Action(ParkingsStateAction.Reservations.Delete.Try)
  deleteReservations(
    { dispatch, getState, setState }: StateContext<ParkingsStateModel>,
    { ids }: ParkingsStateAction.Reservations.Delete.Try,
  ) {
    return this.parkingsService.deleteReservations({ reservation_ids: ids }).pipe(
      catchError(error => {
        dispatch(new ParkingsStateAction.Reservations.Delete.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          setState(
            patch({
              reservations: getState().reservations.filter(
                reservation => !ids.includes(reservation.id),
              ),
            }),
          );

          dispatch(new ParkingsStateAction.Reservations.Delete.Success(getState()));
        }
      }),
    );
  }

  @Action(ParkingsStateAction.List.Read.Try)
  readListItem(
    { dispatch, getState, setState }: StateContext<ParkingsStateModel>,
    { spaceId }: ParkingsStateAction.List.Read.Try,
  ) {
    return this.spaceService.parkings(spaceId).pipe(
      catchError(error => {
        dispatch(new ParkingsStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(parkings => {
        setState(patch({ list: parkings.map(mapParkingToClient) }));

        dispatch(new ParkingsStateAction.List.Read.Success(getState()));
      }),
    );
  }

  @Action(ParkingsStateAction.Reservations.Read.Try)
  readReservations(
    { dispatch, getState, setState }: StateContext<ParkingsStateModel>,
    { buildingId }: ParkingsStateAction.Reservations.Read.Try,
  ) {
    return iif(
      () =>
        getState().reservationsCurrentPage + 1 < getState().reservationsTotalPages &&
        getState().reservationsReachedElement < getState().reservationsTotalElements,
      this.parkingsService.reservations(
        mapParkingsReservationsApiParams({
          buildingId,
          elements: 40,
          page: getState().reservationsCurrentPage + 1,
        }),
      ),
      defer(() => {
        dispatch(new ParkingsStateAction.Reservations.Read.Cancel());
      }),
    ).pipe(
      catchError(error => {
        dispatch(new ParkingsStateAction.Reservations.Read.Failure(error));

        throw error;
      }),
      tap(({ data, _metadata }) => {
        setState(
          patch<ParkingsStateModel>({
            ...(_metadata.page > getState().reservationsCurrentPage
              ? {
                  reservationsReachedPage: _metadata.page,
                  reservationsReachedElement: getState().reservations.length + data.length,
                }
              : {}),
            reservations: compose(
              ...mapParkingsReservationsToClient(data).map(reservation =>
                ngxsIif<Reservation[]>(
                  reservationsFromStore =>
                    reservationsFromStore?.some(
                      reservationFromStore => reservationFromStore.id === reservation.id,
                    ) ?? false,
                  updateItem<Reservation>(
                    reservationStore => reservationStore?.id === reservation.id,
                    patch(reservation),
                  ),
                  append([reservation]),
                ),
              ),
            ),
            reservationsCurrentPage: _metadata.page,
            reservationsTotalElements: _metadata.total_elements,
            reservationsTotalPages: _metadata.total_pages,
          }),
        );

        dispatch(new ParkingsStateAction.Reservations.Read.Success(getState()));
      }),
    );
  }

  @Action(ParkingsStateAction.Settings.Read.Try)
  readSettings(
    { dispatch, getState, setState }: StateContext<ParkingsStateModel>,
    { buildingId }: ParkingsStateAction.Settings.Read.Try,
  ) {
    return this.parkingsService.settings(buildingId).pipe(
      catchError(error => {
        dispatch(new ParkingsStateAction.Settings.Read.Failure(error));

        throw error;
      }),
      tap(parkingsSettings => {
        setState(patch(mapParkingsSettingsToClient(parkingsSettings)));

        dispatch(new ParkingsStateAction.Settings.Read.Success(getState()));
      }),
    );
  }

  @Action(ParkingsStateAction.List.Remove.Try)
  removeItemFromList(
    { dispatch, getState, setState }: StateContext<ParkingsStateModel>,
    { id }: ParkingsStateAction.List.Remove.Try,
  ) {
    try {
      setState(
        patch({
          list: removeItem<ParkingStateModel>(parking => parking?.id === id),
        }),
      );

      dispatch(new ParkingsStateAction.List.Remove.Success(getState()));
    } catch (error) {
      dispatch(new ParkingsStateAction.List.Remove.Failure(error));

      throw error;
    }
  }

  @Action(ParkingsStateAction.List.Update.Try)
  updateItemInList(
    { dispatch, getState, setState }: StateContext<ParkingsStateModel>,
    { parking }: ParkingsStateAction.List.Update.Try,
  ) {
    try {
      setState(
        patch({
          list: updateItem<ParkingStateModel>(
            parkingStore => parkingStore?.id === parking.id,
            patch(parking),
          ),
        }),
      );

      dispatch(new ParkingsStateAction.List.Update.Success(getState()));
    } catch (error) {
      dispatch(new ParkingsStateAction.List.Update.Failure(error));

      throw error;
    }
  }

  @Action(ParkingsStateAction.Settings.Update.Try)
  updateSettings(
    { dispatch, getState, setState }: StateContext<ParkingsStateModel>,
    { buildingId, data }: ParkingsStateAction.Settings.Update.Try,
  ) {
    return this.parkingsService.updateSettings(mapParkingsSettingsToApi(buildingId, data)).pipe(
      catchError(error => {
        dispatch(new ParkingsStateAction.Settings.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data }));

        dispatch(new ParkingsStateAction.Settings.Update.Success(getState()));
      }),
    );
  }
}
