import { Injectable } from '@angular/core';
import { CustomEntitiesService, SpaceService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import {
  append,
  compose,
  iif as ngxsIif,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { defer, iif } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { mapCustomEntityToClient } from '../custom-entity/custom-entity.helper';
import { CustomEntityStateModel } from '../custom-entity/custom-entity.model';
import { Reservation } from '../reservations';
import { CustomEntitiesStateAction } from './custom-entities.actions';
import {
  mapCustomEntitiesReservationsApiParams,
  mapCustomEntitiesReservationsToClient,
  mapCustomEntitiesSettingsToApi,
  mapCustomEntitiesSettingsToClient,
} from './custom-entities.helper';
import { CustomEntitiesStateModel } from './custom-entities.model';

@State<CustomEntitiesStateModel>({
  name: 'customentities',
  defaults: {
    list: [],
    reservations: [],
    reservationsCurrentPage: -1,
    reservationsReachedElement: -1,
    reservationsReachedPage: -1,
    reservationsTotalElements: 0,
    reservationsTotalPages: 1,
  },
})
@Injectable()
export class CustomEntitiesState {
  constructor(
    private readonly customEntitiesService: CustomEntitiesService,
    private readonly spaceService: SpaceService,
  ) {}

  @Action(CustomEntitiesStateAction.List.Add.Try)
  addItemInList(
    { dispatch, getState, setState }: StateContext<CustomEntitiesStateModel>,
    { customEntity }: CustomEntitiesStateAction.List.Add.Try,
  ) {
    try {
      setState(
        patch({
          list: append([customEntity]),
        }),
      );

      dispatch(new CustomEntitiesStateAction.List.Add.Success(getState()));
    } catch (error) {
      dispatch(new CustomEntitiesStateAction.List.Add.Failure(error));

      throw error;
    }
  }

  @Action(CustomEntitiesStateAction.List.Read.Try)
  readListItem(
    { dispatch, getState, setState }: StateContext<CustomEntitiesStateModel>,
    { spaceId }: CustomEntitiesStateAction.List.Read.Try,
  ) {
    return this.spaceService.customEntities(spaceId).pipe(
      catchError(error => {
        dispatch(new CustomEntitiesStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(customEntities => {
        setState(patch({ list: customEntities.map(mapCustomEntityToClient) }));

        dispatch(new CustomEntitiesStateAction.List.Read.Success(getState()));
      }),
    );
  }

  @Action(CustomEntitiesStateAction.Reservations.Read.Try)
  readReservations(
    { dispatch, getState, setState }: StateContext<CustomEntitiesStateModel>,
    { buildingId }: CustomEntitiesStateAction.Reservations.Read.Try,
  ) {
    return iif(
      () =>
        getState().reservationsCurrentPage + 1 < getState().reservationsTotalPages &&
        getState().reservationsReachedElement < getState().reservationsTotalElements,
      this.customEntitiesService.reservations(
        mapCustomEntitiesReservationsApiParams({
          buildingId,
          elements: 40,
          page: getState().reservationsCurrentPage + 1,
        }),
      ),
      defer(() => {
        dispatch(new CustomEntitiesStateAction.Reservations.Read.Cancel());
      }),
    ).pipe(
      catchError(error => {
        dispatch(new CustomEntitiesStateAction.Reservations.Read.Failure(error));

        throw error;
      }),
      tap(({ data, _metadata }) => {
        setState(
          patch<CustomEntitiesStateModel>({
            ...(_metadata.page > getState().reservationsCurrentPage
              ? {
                  reservationsReachedPage: _metadata.page,
                  reservationsReachedElement: getState().reservations.length + data.length,
                }
              : {}),
            reservations: compose(
              ...mapCustomEntitiesReservationsToClient(data).map(reservation =>
                ngxsIif<Reservation[]>(
                  reservationsFromStore =>
                    reservationsFromStore?.some(
                      reservationFromStore => reservationFromStore.id === reservation.id,
                    ) ?? false,
                  updateItem<Reservation>(
                    reservationStore => reservationStore?.id === reservation.id,
                    patch(reservation),
                  ),
                  append([reservation]),
                ),
              ),
            ),
            reservationsCurrentPage: _metadata.page,
            reservationsTotalElements: _metadata.total_elements,
            reservationsTotalPages: _metadata.total_pages,
          }),
        );

        dispatch(new CustomEntitiesStateAction.Reservations.Read.Success(getState()));
      }),
    );
  }

  @Action(CustomEntitiesStateAction.Settings.Read.Try)
  readSettings(
    { dispatch, getState, setState }: StateContext<CustomEntitiesStateModel>,
    { buildingId }: CustomEntitiesStateAction.Settings.Read.Try,
  ) {
    return this.customEntitiesService.settings(buildingId).pipe(
      catchError(error => {
        dispatch(new CustomEntitiesStateAction.Settings.Read.Failure(error));

        throw error;
      }),
      tap(roomsSettings => {
        setState(patch(mapCustomEntitiesSettingsToClient(roomsSettings)));

        dispatch(new CustomEntitiesStateAction.Settings.Read.Success(getState()));
      }),
    );
  }

  @Action(CustomEntitiesStateAction.List.Remove.Try)
  removeItemFromList(
    { dispatch, getState, setState }: StateContext<CustomEntitiesStateModel>,
    { id }: CustomEntitiesStateAction.List.Remove.Try,
  ) {
    try {
      setState(
        patch({
          list: removeItem<CustomEntityStateModel>(customEntity => customEntity?.id === id),
        }),
      );

      dispatch(new CustomEntitiesStateAction.List.Remove.Success(getState()));
    } catch (error) {
      dispatch(new CustomEntitiesStateAction.List.Remove.Failure(error));

      throw error;
    }
  }

  @Action(CustomEntitiesStateAction.List.Update.Try)
  updateItemInList(
    { dispatch, getState, setState }: StateContext<CustomEntitiesStateModel>,
    { customEntity }: CustomEntitiesStateAction.List.Update.Try,
  ) {
    try {
      setState(
        patch({
          list: updateItem<CustomEntityStateModel>(
            customEntityStore => customEntityStore?.id === customEntity.id,
            patch(customEntity),
          ),
        }),
      );

      dispatch(new CustomEntitiesStateAction.List.Update.Success(getState()));
    } catch (error) {
      dispatch(new CustomEntitiesStateAction.List.Update.Failure(error));

      throw error;
    }
  }

  @Action(CustomEntitiesStateAction.Settings.Update.Try)
  updateSettings(
    { dispatch, getState, setState }: StateContext<CustomEntitiesStateModel>,
    { buildingId, data }: CustomEntitiesStateAction.Settings.Update.Try,
  ) {
    return this.customEntitiesService
      .updateSettings(mapCustomEntitiesSettingsToApi(buildingId, data))
      .pipe(
        catchError(error => {
          dispatch(new CustomEntitiesStateAction.Settings.Update.Failure(error));

          throw error;
        }),
        tap(() => {
          setState(patch({ ...data }));

          dispatch(new CustomEntitiesStateAction.Settings.Update.Success(getState()));
        }),
      );
  }
}
