import { Selector } from '@ngxs/store';
import { IntegrationsSelectors } from '../integrations';
import { EmployeesStateModel } from './employees.model';
import { EmployeesState } from './employees.state';

export class EmployeesSelectors {
  @Selector([IntegrationsSelectors.isDirectoryEnabled])
  static isManagementEnabled(isDirectoryEnabled: boolean): boolean {
    return !isDirectoryEnabled;
  }

  @Selector([EmployeesState])
  static list({ list }: EmployeesStateModel): EmployeesStateModel['list'] {
    return list;
  }

  @Selector([EmployeesState])
  static overallElements({
    overallElements,
  }: EmployeesStateModel): EmployeesStateModel['overallElements'] {
    return overallElements;
  }

  @Selector([EmployeesState])
  static reachedElement({
    reachedElement,
  }: EmployeesStateModel): EmployeesStateModel['reachedElement'] {
    return reachedElement;
  }

  @Selector([EmployeesState])
  static totalElements({
    totalElements,
  }: EmployeesStateModel): EmployeesStateModel['totalElements'] {
    return totalElements;
  }

  @Selector([EmployeesSelectors.reachedElement, EmployeesSelectors.totalElements])
  static hasFurtherEmployees(reachedElement: number, totalElements: number): boolean {
    return reachedElement < totalElements;
  }
}
