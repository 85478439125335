import { Employee } from '@business/api';
import { mapRolesToClient, mapRoleToApi } from '@nibol/shared';
import { isValid } from 'date-fns';
import { mapGroupsToClient } from '../groups/groups.helper';
import { EmployeeStateAction } from './employee.actions';
import { EmployeeStateModel } from './employee.model';

export function mapEmployeeToClient(employee: Employee.Read.Response): EmployeeStateModel {
  return {
    ...(employee.note && isValid(new Date(employee.note))
      ? { invitationDate: new Date(employee.note) }
      : { error: mapErrorName(employee.note) }),
    assignedBuildings: employee.buildings_deliveries.map(building => building.id),
    customEntity: employee.assigned_custom_entity?.custom_entity,
    customEntityBuilding: employee.assigned_custom_entity?.building,
    customEntityOffice: employee.assigned_custom_entity?.office,
    customEntitySpace: employee.assigned_custom_entity?.space,
    desk: employee.assigned_desk?.desk,
    deskBuilding: employee.assigned_desk?.building,
    deskOffice: employee.assigned_desk?.office,
    deskSpace: employee.assigned_desk?.space,
    email: employee.email,
    firstname: employee.name,
    groups: mapGroupsToClient(employee.groups),
    id: employee.id,
    lastname: '',
    parking: employee.assigned_parking?.parking,
    parkingBuilding: employee.assigned_parking?.building,
    parkingOffice: employee.assigned_parking?.office,
    parkingSpace: employee.assigned_parking?.space,
    picture: employee.pic,
    roles: mapRolesToClient(employee.roles).sort(),
    status: employee.status,
  };
}

export function mapEmployeeToCreateApi(
  employee: EmployeeStateAction.Create.Try,
): Employee.Create.Params {
  return {
    user: {
      email: employee.email,
      first_name: employee.firstname,
      last_name: employee.lastname,
    },
    role: mapRoleToApi(employee.role),
    send_invitation: employee.sendInvitation,
  };
}

export function mapEmployeeToUpdateApi({
  id,
  role,
}: EmployeeStateAction.Update.Try): Employee.Update.Body {
  return { id, role: mapRoleToApi(role) };
}

function mapErrorName(error?: string): string | undefined {
  return error === 'hard-bounce' ? 'emailnotcorrect' : undefined;
}
