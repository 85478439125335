import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Building } from './building.type';

@Injectable({ providedIn: 'root' })
export class BuildingService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  create({ name, position }: Building.Create.Params): Observable<Building.Create.Response> {
    return this.httpClient
      .post<Building.Create.Response>(`${this.apiRootUrl}/building`, { name, position })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete(id: Building.Delete.Params['building_id']): Observable<Building.Delete.Response> {
    return this.httpClient
      .request<Building.Delete.Response>('delete', `${this.apiRootUrl}/building`, {
        body: { building_id: id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  list(): Observable<Building.List.Response> {
    return this.httpClient.get<Building.List.Response>(`${this.apiRootUrl}/building/list`).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  offices(id: Building.Offices.Params['building_id']): Observable<Building.Offices.Response> {
    return this.httpClient
      .get<Building.Offices.Response>(`${this.apiRootUrl}/office/list`, {
        params: { building_id: id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read(id: Building.Read.Params['id']): Observable<Building.Read.Response> {
    return this.httpClient
      .get<Building.Read.Response>(`${this.apiRootUrl}/building`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(
    id: Building.Update.Params['id'],
    data: DeepPartial<Building.Update.Params>,
  ): Observable<Building.Update.Response> {
    return this.httpClient
      .put<Building.Update.Response>(`${this.apiRootUrl}/building`, { ...data, id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
