// tslint:disable: no-namespace

import { Building } from '../building';

export namespace CustomEntitiesCategory {
  export namespace Create {
    export type Params = {
      building_id: Building.Read.Response['id'];
      name: string;
    };
    export type Response = {
      id: string;
      status: boolean;
    };
  }

  export namespace Delete {
    export type Body = {
      id: string;
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace List {
    export type Params = {
      building_id: Building.Read.Response['id'];
    };
    export type Response = Array<{ id: string; name: string; count: number }>;
  }
}
