import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Office } from './office.type';

@Injectable({ providedIn: 'root' })
export class OfficeService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  create({ building_id, office }: Office.Create.Params): Observable<Office.Create.Response> {
    return this.httpClient
      .post<Office.Create.Response>(`${this.apiRootUrl}/office/add`, { building_id, office })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete(id: Office.Delete.Params['office_id']): Observable<Office.Delete.Response> {
    return this.httpClient
      .post<Office.Delete.Response>(`${this.apiRootUrl}/office/delete`, { office_id: id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read(id: Office.Read.Params['office_id']): Observable<Office.Read.Response> {
    return this.httpClient
      .get<Office.Read.Response>(`${this.apiRootUrl}/office/info`, { params: { office_id: id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  spaces(id: Office.Spaces.Params['office_id']): Observable<Office.Spaces.Response> {
    return this.httpClient
      .get<Office.Spaces.Response>(`${this.apiRootUrl}/space/list`, { params: { office_id: id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(
    id: Office.Update.Params['id'],
    data: DeepPartial<Office.Update.Params>,
  ): Observable<Office.Update.Response> {
    return this.httpClient
      .put<Office.Update.Response>(`${this.apiRootUrl}/office/info`, { ...data, id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  uploadPhoto(uploadData: Office.UploadPhoto.Params): Observable<Office.UploadPhoto.Response> {
    return this.httpClient
      .post<Office.UploadPhoto.Response>(`${this.apiRootUrl}/office/pictures/upload`, uploadData)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
