import { Selector } from '@ngxs/store';
import { DeskStateModel } from './desk.model';
import { DeskState } from './desk.state';

export class DeskSelectors {
  @Selector([DeskState])
  static amenities({ amenities }: DeskStateModel): DeskStateModel['amenities'] {
    return amenities;
  }

  @Selector([DeskState])
  static coordinates({ coordinates }: DeskStateModel): DeskStateModel['coordinates'] {
    return coordinates;
  }

  @Selector([DeskState])
  static audiences({ audiences }: DeskStateModel): DeskStateModel['audiences'] {
    return audiences;
  }

  @Selector([DeskState])
  static id({ id }: DeskStateModel): DeskStateModel['id'] {
    return id;
  }

  @Selector([DeskState])
  static isActive({ active }: DeskStateModel): DeskStateModel['active'] {
    return active;
  }

  @Selector([DeskSelectors.audiences])
  static isAssigned(audiences: DeskStateModel['audiences']): boolean {
    return audiences && audiences.length > 0 ? true : false;
  }

  @Selector([DeskState])
  static fullname({ name }: DeskStateModel): DeskStateModel['name'] {
    return name;
  }

  @Selector([DeskState])
  static type({ type }: DeskStateModel): DeskStateModel['type'] {
    return type;
  }
}
