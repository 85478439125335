// tslint:disable: no-namespace

import { Status } from '@business/store';
import { User } from '@nibol/shared';
import { Building } from '../building';
import { CustomEntity } from '../custom-entity';
import { Desk } from '../desk';
import { Group } from '../group';
import { Office } from '../office';
import { Parking } from '../parking';
import { Space } from '../space';

export namespace Employee {
  export namespace AssignBuilding {
    export type Body = {
      user: Read.Response['id'];
      buildings: Array<Building.Read.Response['id']>;
    };
    export type Response = {
      status: 'ok';
    };
  }

  export namespace Create {
    export type Error = 'user-email-already-registered';
    export type Params = {
      role: Read.Response['roles'][number];
      send_invitation: boolean;
      user: {
        email: string;
        first_name?: string;
        last_name?: string;
      };
    };
    export type Response = {
      id: string;
      status: boolean;
    };
  }

  export namespace Delete {
    export type Params = {
      ids: Array<Read.Response['id']>;
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace Invite {
    export type Params = {
      ids: Array<Read.Response['id']>;
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace Read {
    export type Params = {
      id: Response['id'];
    };
    export type Response = {
      buildings_deliveries: Array<Pick<Building.Read.Response, 'id' | 'name'>>;
      email: string;
      groups: Array<Pick<Group.Item.Read.Response, 'color' | 'id' | 'name'>>;
      id: string;
      name: string;
      pic: string;
      roles: User.Info.Read.Response['user']['roles'];
      status: Status;
      assigned_custom_entity?: {
        building: Building.Read.Response['id'];
        custom_entity: CustomEntity.Read.Response['id'];
        office: Office.Read.Response['id'];
        space: Space.Read.Response['id'];
      };
      assigned_desk?: {
        building: Building.Read.Response['id'];
        desk: Desk.Read.Response['id'];
        office: Office.Read.Response['id'];
        space: Space.Read.Response['id'];
      };
      assigned_parking?: {
        building: Building.Read.Response['id'];
        parking: Parking.Read.Response['id'];
        office: Office.Read.Response['id'];
        space: Space.Read.Response['id'];
      };
      note?: string;
    };
  }

  export namespace Update {
    export type Body = {
      id: Read.Response['id'];
      role: Read.Response['roles'][number];
    };
    export type Response = {
      status: boolean;
    };
  }
}
