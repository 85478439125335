// tslint:disable: no-namespace max-classes-per-file

import { GroupStateModel } from '../group/group.model';
import { GroupsStateModel } from './groups.model';

export namespace GroupsStateAction {
  export namespace List {
    export namespace Add {
      export class Try {
        static readonly type = '[Groups] try Add Group to List';
        constructor(public group: GroupStateModel) {}
      }
      export class Success {
        static readonly type = '[Groups] then Add Group to List success';
        constructor(public groups: GroupsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Groups] catch Add Group to List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }

    export namespace Read {
      export class Try {
        static readonly type = '[Groups] try Read Groups List';
      }
      export class Success {
        static readonly type = '[Groups] then Read Groups List success';
        constructor(public groups: GroupsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Groups] catch Read Groups List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }

    export namespace Remove {
      export class Try {
        static readonly type = '[Groups] try Remove Group from List';
        constructor(public id: GroupStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Groups] then Remove Group from List success';
        constructor(public groups: GroupsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Groups] catch Remove Group from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }

    export namespace Update {
      export class Try {
        static readonly type = '[Groups] try Update Group in List';
        constructor(public group: GroupStateModel) {}
      }
      export class Success {
        static readonly type = '[Groups] then Update Group in List success';
        constructor(public groups: GroupsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Groups] catch Update Group in List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
