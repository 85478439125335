import { Injectable } from '@angular/core';
import { SurveyService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { SurveyStateModel } from '../survey/survey.model';
import { SurveysStateAction } from './surveys.actions';
import { mapSurveysToClient } from './surveys.helper';

@State<SurveyStateModel[]>({
  name: 'surveys',
  defaults: [],
})
@Injectable()
export class SurveysState {
  constructor(private readonly surveyService: SurveyService) {}

  @Action(SurveysStateAction.Read.Try)
  read({ dispatch, getState, setState }: StateContext<SurveyStateModel[]>) {
    return this.surveyService.list().pipe(
      catchError(error => {
        dispatch(new SurveysStateAction.Read.Failure(error));

        throw error;
      }),
      tap(surveyList => {
        try {
          setState(mapSurveysToClient(surveyList));

          dispatch(new SurveysStateAction.Read.Success(getState()));
        } catch (error) {
          dispatch(new SurveysStateAction.Read.Failure(error));

          throw error;
        }
      }),
    );
  }
}
