// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { OfficeStateModel } from './office.model';

export namespace OfficeStateAction {
  export namespace Create {
    export class Try {
      static readonly type = '[Office] try Create Office';
      constructor(
        public buildingId: BuildingStateModel['id'],
        public name: NonNullable<OfficeStateModel['name']>,
        public floor: OfficeStateModel['floor'],
      ) {}
    }
    export class Success {
      static readonly type = '[Office] then Create Office success';
      constructor(public office: OfficeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Office] catch Create Office failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Office] try Delete Office';
      constructor(public id: OfficeStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Office] then Delete Office success';
      constructor(public office: OfficeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Office] catch Delete Office failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Office] try Read Office';
      constructor(public id: OfficeStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Office] then Read Office success';
      constructor(public office: OfficeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Office] catch Read Office failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Office] try Update Office';
      constructor(public id: OfficeStateModel['id'], public data: DeepPartial<OfficeStateModel>) {}
    }
    export class Success {
      static readonly type = '[Office] then Update Office success';
      constructor(public office: OfficeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Office] catch Update Office failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace UploadPhoto {
    export class Try {
      static readonly type = '[Office] try Upload Photo to Office';
      constructor(public id: OfficeStateModel['id'], public photo: File) {}
    }
    export class Success {
      static readonly type = '[Office] then Upload Photo to Office success';
      constructor(public office: OfficeStateModel) {}
    }
    export class Failure {
      static readonly type = '[Office] catch Upload Photo to Office failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
