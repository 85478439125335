// tslint:disable: no-namespace

import { DeepPartial } from '@nibol/shared';
import { Building } from '../building';
import { Employee } from '../employee';

export namespace Safe {
  export namespace ContactTracing {
    export namespace ExportCsv {
      export type Body = {
        building_id: Building.Read.Response['id'];
        from: number;
        to: number;
        user_ids: Array<Employee.Read.Response['id']>;
      };
      export type Response = Blob;
    }
  }

  export namespace Settings {
    export namespace Read {
      export type Params = {
        id: Building.Read.Response['id'];
      };
      export type Response = {
        id: Building.Read.Response['id'];
        safe: {
          booking: boolean;
          checkin: boolean;
          contact: string | null;
        };
      };
    }
    export namespace Update {
      export type Body = DeepPartial<Read.Response> & Pick<Read.Response, 'id'>;
      export type Response = {
        status: boolean;
      };
    }
  }
}
