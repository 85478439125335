import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BuildingStateAction } from '@business/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { DialogService } from '@nibol/ui';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bus-add-new-building-dialog',
  styleUrls: ['add-new-building-dialog.component.scss'],
  templateUrl: 'add-new-building-dialog.component.html',
})
export class AddNewBuildingDialogComponent {
  @Dispatch() createBuilding = (
    name: string,
    address: string,
    latitude: number,
    longitude: number,
    timezone: string,
  ) =>
    new BuildingStateAction.Create.Try(name, address, { lat: latitude, lng: longitude }, timezone);

  form = new FormGroup({
    address: new FormControl(null, Validators.required),
    latitude: new FormControl(null, Validators.required),
    longitude: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.required),
    timezone: new FormControl(null, Validators.required),
  });

  constructor(private readonly dialogService: DialogService) {}

  add(): void {
    const { address, latitude, longitude, name, timezone } = this.form.value;

    this.createBuilding(name, address, latitude, longitude, timezone);

    this.dialogService.dismiss();
  }

  cancel(): void {
    this.dialogService.dismiss();
  }
}
