import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { DeepPartial } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Room } from './room.type';

@Injectable({ providedIn: 'root' })
export class RoomService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  companionClients(
    params: Room.Companion.Clients.Params,
  ): Observable<Room.Companion.Clients.Response> {
    return this.httpClient
      .get<Room.Companion.Clients.Response>(`${this.apiRootUrl}/room/clients`, { params })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  create(body: Room.Create.Body): Observable<Room.Create.Response> {
    return this.httpClient.post<Room.Create.Response>(`${this.apiRootUrl}/room`, body).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  delete(body: Room.Delete.Body): Observable<Room.Delete.Response> {
    return this.httpClient
      .request<Room.Delete.Response>('delete', `${this.apiRootUrl}/room`, {
        body,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  pairingCode(
    params: Room.Companion.PairingCode.Params,
  ): Observable<Room.Companion.PairingCode.Response> {
    return this.httpClient
      .get<Room.Companion.PairingCode.Response>(`${this.apiRootUrl}/room/code`, { params })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read(params: Room.Read.Params): Observable<Room.Read.Response> {
    return this.httpClient
      .get<Room.Read.Response>(`${this.apiRootUrl}/room`, { params })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  unpairAllDevices(
    body: DeepPartial<Room.Companion.UnpairAllClients.Body>,
  ): Observable<Room.Companion.UnpairAllClients.Response> {
    return this.httpClient
      .post<Room.Companion.UnpairAllClients.Response>(`${this.apiRootUrl}/room/disconnect`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update(body: DeepPartial<Room.Update.Body>): Observable<Room.Update.Response> {
    return this.httpClient.put<Room.Update.Response>(`${this.apiRootUrl}/room`, body).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  uploadPhoto(body: Room.UploadPhoto.Body): Observable<Room.UploadPhoto.Response> {
    return this.httpClient
      .post<Room.UploadPhoto.Response>(`${this.apiRootUrl}/room/picture`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
