import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomEntitiesCategory } from './custom-entities-category.type';

@Injectable({ providedIn: 'root' })
export class CustomEntitiesCategoryService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  create({
    building_id,
    name,
  }: CustomEntitiesCategory.Create.Params): Observable<CustomEntitiesCategory.Create.Response> {
    return this.httpClient
      .post<CustomEntitiesCategory.Create.Response>(
        `${this.apiRootUrl}/building/mapEntity/category`,
        { name, building_id },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete({
    id,
  }: CustomEntitiesCategory.Delete.Body): Observable<CustomEntitiesCategory.Delete.Response> {
    return this.httpClient
      .request<CustomEntitiesCategory.Delete.Response>(
        'delete',
        `${this.apiRootUrl}/building/mapEntity/category`,
        {
          body: { id },
        },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  list(
    params: CustomEntitiesCategory.List.Params,
  ): Observable<CustomEntitiesCategory.List.Response> {
    return this.httpClient
      .get<CustomEntitiesCategory.List.Response>(
        `${this.apiRootUrl}/building/mapEntity/categories`,
        { params },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
