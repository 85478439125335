// tslint:disable: no-namespace

import { Building } from '../building';
import { CustomEntity } from '../custom-entity';
import { Desk } from '../desk';
import { Employee } from '../employee';
import { Group } from '../group';
import { Parking } from '../parking';
import { Room } from '../room';

export namespace Company {
  export namespace Billing {
    export namespace Read {
      export type Response = {
        address: string;
        city: string;
        country: string;
        email: string;
        name: string;
        state: string;
        tax_id: string;
        zip: string;
        it?: { fiscal_code: string; payment_terms: number; pec: string; sdi: string };
        note?: string;
      };
    }
    export namespace Update {
      export type Body = Read.Response;
      export type Response = Body;
    }
  }

  export namespace CompanyPolicy {
    export namespace Read {
      export type Response = { require_accept_company_policy: boolean; text: string };
    }
    export namespace Update {
      export type Body = Read.Response;
      export type Response = { status: boolean };
    }
  }

  export namespace Countries {
    export type Response = Array<{
      code: string;
      name: string;
    }>;
  }

  export namespace Dashboard {
    export type Response = {
      desk_available: number;
      desk_reserved: number;
      occupancy_rate: number;
    };
  }

  export namespace Employees {
    export type Params = {
      elements: ReturnType<Response['_metadata']['page_elements']['toString']>;
      page: ReturnType<Response['_metadata']['page']['toString']>;
      role: ReturnType<(9 | 10 | 12)['toString']>;
      search: string;
    };
    export type Response = {
      _links: { last: string; self: string; first: string; next: string; previous: string };
      _metadata: {
        page: number;
        page_elements: number;
        total_elements: number;
        total_pages: number;
      };
      data: Employee.Read.Response[];
    };
  }

  export namespace EmployeesLight {
    export type Params = Employees.Params;
    export type Response = {
      _links: { last: string; self: string; first: string; next: string; previous: string };
      _metadata: {
        page: number;
        page_elements: number;
        total_elements: number;
        total_pages: number;
      };
      data: Array<
        Pick<Employee.Read.Response, 'email' | 'id' | 'name'> &
          Partial<Pick<Employee.Read.Response, 'assigned_desk' | 'assigned_parking'>>
      >;
    };
  }

  export namespace Groups {
    export type Response = Group.Item.Read.Response[];
  }

  export namespace Info {
    export namespace Read {
      export type Response = {
        info: { domain: string; logo: string };
        name: string;
        settings: {
          domains: string[];
          features: {
            availability_future_days: number;
            availability_weekly_reservation_limit: number;
            login_token_valid_time: number;
            map_custom_desk_name: boolean;
            map_show_users: boolean;
            require_accept_company_policy: boolean;
            require_custom_terms_privacy: boolean;
            reservations_limit_days: number;
            reservations_limit_number: number;
            reservations_office_minimum: number;
            reservations_office_time_check: number | null;
            reservations_time_between: number;
            support_contacts: string;
            book_for_employee?: boolean;
            map_desk_assigned?: boolean;
          };
          timezone: string;
        };
        slack?: { enabled: boolean };
      };
    }
    export namespace Update {
      export type Body = Read.Response;
      export type Response = { status: boolean };
    }
  }

  export namespace Spaces {
    export namespace Read {
      export type Response = {
        enabled: boolean;
      };
    }
    export namespace Update {
      export type Body = Read.Response;
      export type Response = Read.Response;
    }
    export namespace Activate {
      export type Body = Read.Response;
      export type Response = { status: boolean };
    }
  }

  export namespace Structure {
    export type Response = Array<{
      id: string;
      name: string;
      offices: Array<{
        id: string;
        name: string;
        spaces: Array<{
          id: string;
          name: string;
          type: 'map' | 'open';
          custom_entities?: Array<
            Pick<CustomEntity.Read.Response, 'assigned' | 'id' | 'mapEntityCategory' | 'name'>
          >;
          desks?: Array<Pick<Desk.Read.Response, 'assigned' | 'id' | 'name'>>;
          parkings?: Array<Pick<Parking.Read.Response, 'assigned' | 'id' | 'name'>>;
          rooms?: Array<Pick<Room.Read.Response, 'id' | 'name' | 'seats'>>;
        }>;
      }>;
    }>;
  }

  export namespace SubProcessor {
    export type Response = {
      mixpanel: boolean;
      wootric: boolean;
    };
  }

  export namespace Subscription {
    export type Response = {
      features: {
        custom_email: boolean;
        custom_terms_privacy: boolean;
        safe_c19?: boolean;
        safe_c19_buildings?: Array<Building.Read.Response['id']>;
        survey: boolean;
        work_from_anywhere?: boolean;
      };
      name: string;
      members?: { current: number; total: number };
      status?: {
        current: 'active' | 'deactivated' | 'ended' | 'free_trial' | 'grace';
        expire: ReturnType<Date['toISOString']> | null;
      };
    };
  }

  export namespace Terms {
    export namespace Read {
      export type Response = {
        require_custom_terms_privacy: boolean;
        terms: Array<{
          country: string | null;
          required: boolean;
          text: string;
          value: string | null;
        }>;
      };
    }
    export namespace Update {
      export type Body = Read.Response;
      export type Response = Body;
    }
  }

  export namespace UploadLogo {
    export type Body = FormData;
    export type Response = {
      logo: { url: string };
    };
  }
}
