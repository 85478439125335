// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { RoomStateModel } from '../room';
import { SpaceStateModel } from '../space';
import { RoomsStateModel } from './rooms.model';

export namespace RoomsStateAction {
  export namespace List {
    export namespace Add {
      export class Try {
        static readonly type = '[Rooms] try Add Room to List';
        constructor(public room: RoomStateModel) {}
      }
      export class Success {
        static readonly type = '[Rooms] then Add Room to List success';
        constructor(public rooms: RoomsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Rooms] catch Add Room to List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Rooms] try Read Rooms List';
        constructor(public spaceId: SpaceStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Rooms] then Read Rooms List success';
        constructor(public rooms: RoomsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Rooms] catch Read Rooms List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Remove {
      export class Try {
        static readonly type = '[Rooms] try Remove Room from List';
        constructor(public id: RoomStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Rooms] then Remove Room from List success';
        constructor(public rooms: RoomsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Rooms] catch Remove Room from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Rooms] try Update Room in List';
        constructor(public room: RoomStateModel) {}
      }
      export class Success {
        static readonly type = '[Rooms] then Update Room in List success';
        constructor(public rooms: RoomsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Rooms] catch Update Room in List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Reservations {
    export namespace Read {
      export class Try {
        static readonly type = '[Rooms] try Read Rooms Reservations';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Rooms] then Read Rooms Reservations success';
        constructor(public rooms: RoomsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Rooms] catch Read Rooms Reservations failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
      export class Cancel {
        static readonly type = '[Rooms] catch Read Rooms Reservations cancel';
        constructor(public reason?: string) {}
      }
    }
  }

  export namespace Settings {
    export namespace Read {
      export class Try {
        static readonly type = '[Rooms] try Read Rooms Settings';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Rooms] then Read Rooms Settings success';
        constructor(public rooms: RoomsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Rooms] catch Read Rooms Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Rooms] try Update Rooms Settings';
        constructor(
          public buildingId: BuildingStateModel['id'],
          public data: DeepPartial<Pick<RoomsStateModel, 'checkIn'>>,
        ) {}
      }
      export class Success {
        static readonly type = '[Rooms] then Update Rooms Settings success';
        constructor(public rooms: RoomsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Rooms] catch Update Rooms Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
