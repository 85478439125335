import { Emails } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { mapEmailToClient } from '../email/email.helper';
import { EmailsStateModel } from './emails.model';

export function mapDesignSettingsToApi(
  designSettings: Partial<EmailsStateModel['designSettings']>,
): DeepPartial<Emails.Design.Update.Params> {
  return {
    ...(designSettings?.brandColor || designSettings?.brandTextColor
      ? {
          colors: { brand: designSettings?.brandColor, brand_text: designSettings?.brandTextColor },
        }
      : {}),
    footer: designSettings?.footer,
    isDefault: designSettings?.isDefault,
    logo: designSettings?.logo,
  };
}

export function mapDesignSettingsToClient(
  designSettings: Emails.Design.Read.Response,
): EmailsStateModel['designSettings'] {
  return {
    brandColor: designSettings.colors.brand,
    brandTextColor: designSettings.colors.brand_text,
    footer: designSettings.footer,
    isDefault: designSettings.isDefault,
    logo: designSettings.logo,
  };
}

export function mapEmailsToClient(emails: Emails.List.Response): EmailsStateModel['list'] {
  return emails.map(mapEmailToClient);
}

export function mapSmtpSettingsToApi(
  smtpSettings: Partial<EmailsStateModel['smtpSettings']>,
): DeepPartial<Emails.Smtp.Update.Params> {
  return {
    auth: { password: smtpSettings?.password, username: smtpSettings?.username },
    from: { email: smtpSettings?.senderEmail, name: smtpSettings?.senderName },
    host: smtpSettings?.host,
    isDefault: smtpSettings?.isDefault,
    port: smtpSettings?.port,
    tls_enabled: smtpSettings?.isTlsEnabled,
  };
}

export function mapSmtpSettingsToClient(
  smtpSettings: Emails.Smtp.Read.Response,
): EmailsStateModel['smtpSettings'] {
  return {
    host: smtpSettings.host ?? '',
    isDefault: smtpSettings.isDefault,
    isTlsEnabled: smtpSettings.tls_enabled,
    password: smtpSettings.auth.password,
    port: smtpSettings.port ?? undefined,
    senderEmail: smtpSettings.from.email,
    senderName: smtpSettings.from.name,
    username: smtpSettings.auth.username,
  };
}
