// tslint:disable: no-namespace

import { Company } from '../company';
import { Employee } from '../employee';

export namespace Group {
  export namespace Members {
    export namespace Add {
      export type Params = {
        id: Item.Read.Response['id'];
        members: Array<Employee.Read.Response['id']>;
      };
      export type Response = Array<{
        error: string;
        id: Employee.Read.Response['id'];
        status: boolean;
      }>;
    }
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = Company.Employees.Response['data'];
    }
    export namespace Remove {
      export type Params = {
        id: Item.Read.Response['id'];
        members: Array<Employee.Read.Response['id']>;
      };
      export type Response = Array<{
        error: string;
        id: Employee.Read.Response['id'];
        status: boolean;
      }>;
    }
  }

  export namespace Item {
    export namespace Create {
      export type Error = string;
      export type Params = Omit<Read.Response, 'budget' | 'everyone' | 'id' | 'members'>;
      export type Response = Read.Response;
    }
    export namespace Delete {
      export type Params = {
        id: Read.Response['id'];
      };
      export type Response = {
        status: boolean;
      };
    }
    export namespace Read {
      export type Params = {
        id: Response['id'];
      };
      export type Response = {
        color: string;
        id: string;
        name: string;
        budget?: { amount: number | null; mode: string; recurrency: null };
        description?: string;
        everyone?: boolean;
        limits?: {
          booking: { enabled: boolean; count: number; days: number };
          future_days: number;
        };
        members?: number;
        permissions?: {
          spaces: { enabled: boolean; list: string[] };
          weekdays: {
            enabled: boolean;
            list: Array<{ day: number; end: string; start: string }>;
          };
        };
        uid?: string;
      };
    }
    export namespace Update {
      export type Params = Omit<Read.Response, 'budget' | 'everyone' | 'members'> & {
        budget?: { amount: number };
      };
      export type Response = Read.Response;
    }
  }
}
