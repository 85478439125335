import { SpaceAmenities } from '../space';
import { CompanionClient } from './room.type';

export class RoomStateModel {
  active!: boolean;
  coordinates!: Array<{ lat: number; lng: number }>;
  id!: string;
  name!: string;
  calendarEmail?: string;
  companionClients?: CompanionClient[];
  amenities?: SpaceAmenities;
  pictures?: string[];
  seats?: number;
}
