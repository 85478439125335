// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from './building.model';

export namespace BuildingStateAction {
  export namespace Create {
    export class Try {
      static readonly type = '[Building] try Create Building';
      constructor(
        public name: NonNullable<BuildingStateModel['name']>,
        public address: NonNullable<BuildingStateModel['address']>,
        public coordinates: NonNullable<BuildingStateModel['coordinates']>,
        public timezone: NonNullable<BuildingStateModel['timezone']>,
      ) {}
    }
    export class Success {
      static readonly type = '[Building] then Create Building success';
      constructor(public building: BuildingStateModel) {}
    }
    export class Failure {
      static readonly type = '[Building] catch Create Building failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Building] try Delete Building';
      constructor(public id: BuildingStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Building] then Delete Building success';
      constructor(public building: BuildingStateModel) {}
    }
    export class Failure {
      static readonly type = '[Building] catch Delete Building failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Building] try Read Building';
      constructor(public id: BuildingStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Building] then Read Building success';
      constructor(public building: BuildingStateModel) {}
    }
    export class Failure {
      static readonly type = '[Building] catch Read Building failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Building] try Update Building';
      constructor(
        public id: BuildingStateModel['id'],
        public data: DeepPartial<BuildingStateModel>,
        public options = { skipSetState: false },
      ) {}
    }
    export class Success {
      static readonly type = '[Building] then Update Building success';
      constructor(public building: BuildingStateModel) {}
    }
    export class Failure {
      static readonly type = '[Building] catch Update Building failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
