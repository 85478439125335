import { Role } from '@nibol/auth';
import { BuildingStateModel } from '../building';
import { GroupStateModel } from '../group';
import { Status } from './employee.type';

export class EmployeeStateModel {
  email!: string;
  id!: string;
  assignedBuildings?: Array<BuildingStateModel['id']>;
  customEntity?: string;
  customEntityBuilding?: string;
  customEntityOffice?: string;
  customEntitySpace?: string;
  desk?: string;
  deskBuilding?: string;
  deskOffice?: string;
  deskSpace?: string;
  error?: string;
  firstname?: string;
  groups?: GroupStateModel[];
  invitationDate?: Date;
  lastname?: string;
  parking?: string;
  parkingBuilding?: string;
  parkingOffice?: string;
  parkingSpace?: string;
  picture?: string;
  roles?: Role[];
  status?: Status;
}
