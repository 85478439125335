import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Stats } from './stats.type';

@Injectable({ providedIn: 'root' })
export class StatsService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  desksOccupancy(body: Stats.Body): Observable<Stats.Desks.Occupancy.Response> {
    return this.httpClient
      .post<Stats.Desks.Occupancy.Response>(`${this.apiRootUrl}/stats/desks/occupancy`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  desksBookings(body: Stats.Body): Observable<Stats.Desks.Bookings.Response> {
    return this.httpClient
      .post<Stats.Desks.Bookings.Response>(`${this.apiRootUrl}/stats/desks/bookings`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  activeMembers(body: Stats.Body): Observable<Stats.Members.Actives.Response> {
    return this.httpClient
      .post<Stats.Members.Actives.Response>(`${this.apiRootUrl}/stats/members/actives`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
