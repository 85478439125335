<feature-toggle-provider [features]="featuretoggles$ | async">
  <ng-container *ngIf="isInProgress$ | async; else appShell">
    <nib-app-shell-ghost [logo]="logo" [showAvatar]="true"></nib-app-shell-ghost>
  </ng-container>
  <ng-template #appShell>
    <nib-app-shell-wizard
      *ngIf="isWizard$ | async; else appShellAuth"
      [completionPercentage]="completionPercentage$ | async"
      [logo]="logo"
    ></nib-app-shell-wizard>
    <ng-template #appShellAuth>
      <nib-app-shell-auth
        *ngIf="showAuthShell$ | async; else appShellPublic"
        [avatar]="companyLogo$ | async"
        [breadcrumbs]="breadcrumbs$ | async"
        [fullHeight]="fullHeight$ | async"
        [fullWidth]="fullWidth$ | async"
        [logo]="logo"
        [logoLink]="homepage"
        [scope]="companyFullname$ | async"
        [showHeader]="showHeader$ | async"
        [showMenu]="showMenu$ | async"
        [showTitle]="showTitle$ | async"
        [title]="title$ | async"
        [user]="userFullname$ | async"
        [withTabs]="withTabs$ | async"
        [ngClass]="appShellAuthClasses$ | async"
      >
        <nib-app-shell-menu
          *nibLet="hasInvalidSubscription$ | async as hasInvalidSubscription"
          class="bus-app-shell-menu"
        >
          <nib-app-shell-menu-item
            *featureToggle="'TOGGLE_ENABLE_DASHBOARD'"
            [disabled]="hasInvalidSubscription"
            [to]="['/dashboard']"
          >
            <nib-icon [name]="'dashboard'"></nib-icon> {{ 'dashboard_title' | translate }}
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item *featureToggle="'!TOGGLE_ENABLE_MEMBERS'" [disabled]="true">
            <nib-icon [name]="'employees-directory'"></nib-icon>
            {{ 'members_title' | translate }}
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item
            *featureToggle="'TOGGLE_ENABLE_MEMBERS'"
            [disabled]="hasInvalidSubscription"
            [to]="['/members']"
          >
            <nib-icon
              [name]="'employees-directory'"
              [nibAppShellExplodeMenuItem]="!hasInvalidSubscription"
            ></nib-icon>
            <span [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
              {{ 'members_title' | translate }}
            </span>
            <nib-app-shell-menu-item [to]="['/members', 'directory']">
              {{ 'members_directory_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_SHOW_GROUPS'"
              [to]="['/members', 'groups']"
            >
              {{ 'members_groups_title' | translate }}
            </nib-app-shell-menu-item>
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item *featureToggle="'!TOGGLE_ENABLE_INTEGRATIONS'" [disabled]="true">
            <nib-icon [name]="'integrations'"></nib-icon> {{ 'integrations_title' | translate }}
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item
            *featureToggle="'TOGGLE_ENABLE_INTEGRATIONS'"
            [disabled]="hasInvalidSubscription"
            [to]="['/integrations']"
          >
            <nib-icon [name]="'integrations'"></nib-icon> {{ 'integrations_title' | translate }}
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item *ngIf="hasSurveyFeature$ | async" [to]="['/surveys']">
            <nib-icon [name]="'survey'"></nib-icon>
            <span> {{ 'surveys_title' | translate }} </span>
            <nib-chip [color]="'informative'" position="end">
              {{ 'main_badge_beta' | translate }}
            </nib-chip>
          </nib-app-shell-menu-item>
          <ng-container *featureToggle="'TOGGLE_ENABLE_WORKFROMANYWHERE'">
            <nib-app-shell-menu-item [to]="['/work-from-anywhere']">
              <nib-icon [name]="'seats'"></nib-icon>
              <span> {{ 'workfromanywhere_title' | translate }} </span>
              <nib-chip [color]="'nibol'" position="end">
                {{ 'main_badge_new' | translate }}
              </nib-chip>
            </nib-app-shell-menu-item>
          </ng-container>

          <ng-container *ngFor="let building of buildings$ | async; trackBy: trackByBuildingId">
            <nib-app-shell-menu-divider>
              {{ building.name }}
            </nib-app-shell-menu-divider>
            <nib-app-shell-menu-item
              [disabled]="hasInvalidSubscription"
              [to]="['/buildings', building.id, 'building']"
            >
              <nib-icon
                [name]="'business'"
                [nibAppShellExplodeMenuItem]="!hasInvalidSubscription"
              ></nib-icon>
              <span [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
                {{ 'building_building_title' | translate }}
              </span>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'building', 'general']">
                {{ 'building_general_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'building', 'offices']">
                {{ 'building_offices_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'building', 'settings']">
                {{ 'building_settings_title' | translate }}
              </nib-app-shell-menu-item>
            </nib-app-shell-menu-item>
            <ng-container *ngIf="existsSafeC19$ | async">
              <nib-app-shell-menu-item
                *featureToggle="'TOGGLE_ENABLE_SAFE'"
                [disabled]="hasInvalidSubscription"
                [to]="['/buildings', building.id, 'safe']"
              >
                <nib-icon [name]="'safe'" [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
                </nib-icon>
                <span [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
                  {{ 'safe_title' | translate }}
                </span>
                <nib-app-shell-menu-item [to]="['/buildings', building.id, 'safe', 'settings']">
                  {{ 'safe_settings_title' | translate }}
                </nib-app-shell-menu-item>
                <nib-app-shell-menu-item
                  [to]="['/buildings', building.id, 'safe', 'contact-tracing']"
                >
                  {{ 'safe_contacttracing_title' | translate }}
                </nib-app-shell-menu-item>
              </nib-app-shell-menu-item>
            </ng-container>
            <nib-app-shell-menu-item
              [disabled]="hasInvalidSubscription"
              [to]="['/buildings', building.id, 'desks']"
            >
              <nib-icon [name]="'desk'" [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
              </nib-icon>
              <span [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
                {{ 'desks_title' | translate }}
              </span>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'desks', 'activities']">
                {{ 'desks_activities_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'desks', 'settings']">
                {{ 'desks_settings_title' | translate }}
              </nib-app-shell-menu-item>
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item [to]="['/buildings', building.id, 'rooms']">
              <nib-icon [name]="'rooms'" [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
              </nib-icon>
              <span [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
                {{ 'rooms_title' | translate }}
              </span>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'rooms', 'activities']">
                {{ 'rooms_activities_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'rooms', 'settings']">
                {{ 'rooms_settings_title' | translate }}
              </nib-app-shell-menu-item>
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item *featureToggle="'!TOGGLE_ENABLE_VISITORS'" [disabled]="true">
              <nib-icon [name]="'visitors'"></nib-icon> {{ 'visitors_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_VISITORS'"
              [disabled]="hasInvalidSubscription"
              [to]="['/buildings', building.id, 'visitors']"
            >
              <nib-icon [name]="'visitors'" [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
              </nib-icon>
              <span [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
                {{ 'visitors_title' | translate }}
              </span>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'visitors', 'activities']">
                {{ 'visitors_activities_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'visitors', 'settings']">
                {{ 'visitors_settings_title' | translate }}
              </nib-app-shell-menu-item>
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_PARKING'"
              [disabled]="hasInvalidSubscription"
              [to]="['/buildings', building.id, 'parkings']"
            >
              <nib-icon [name]="'parkings'" [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
              </nib-icon>
              <span [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
                {{ 'parking_title' | translate }}
              </span>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'parkings', 'activities']">
                {{ 'parkings_activities_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'parkings', 'settings']">
                {{ 'parkings_settings_title' | translate }}
              </nib-app-shell-menu-item>
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_DELIVERIES'"
              [disabled]="hasInvalidSubscription"
              [to]="['/buildings', building.id, 'deliveries']"
            >
              <nib-icon
                [name]="'deliveries'"
                [nibAppShellExplodeMenuItem]="!hasInvalidSubscription"
              >
              </nib-icon>
              <span [nibAppShellExplodeMenuItem]="!hasInvalidSubscription">
                {{ 'deliveries_title' | translate }}
              </span>
              <nib-app-shell-menu-item
                [to]="['/buildings', building.id, 'deliveries', 'activities']"
              >
                {{ 'deliveries_activities_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item [to]="['/buildings', building.id, 'deliveries', 'settings']">
                {{ 'deliveries_settings_title' | translate }}
              </nib-app-shell-menu-item>
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item *featureToggle="'!TOGGLE_ENABLE_DELIVERIES'" [disabled]="true">
              <nib-icon [name]="'deliveries'"></nib-icon> {{ 'deliveries_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_CUSTOM_ENTITIES'"
              [disabled]="hasInvalidSubscription"
              [to]="['/buildings', building.id, 'custom-entities']"
            >
              <nib-icon [name]="'custom-entities'"> </nib-icon>
              <span>{{ 'customentities_title' | translate }}</span>
              <nib-chip [color]="'nibol'" position="end">
                {{ 'main_badge_new' | translate }}
              </nib-chip>
            </nib-app-shell-menu-item>
          </ng-container>

          <nib-app-shell-menu-divider></nib-app-shell-menu-divider>
          <nib-app-shell-menu-item
            id="bus-app-shell-add-new-building"
            [disabled]="hasInvalidSubscription"
            (onClick)="openAddNewBuildingDialog(confirmAdditionContent)"
          >
            <nib-icon [name]="'plus-small'"></nib-icon>
            {{ 'main_sidebar_addnewbuilding' | translate }}
            <ng-template #confirmAdditionContent>
              <bus-add-new-building-dialog></bus-add-new-building-dialog>
            </ng-template>
          </nib-app-shell-menu-item>

          <ng-container *ngxPermissionsOnly="'admin'">
            <nib-app-shell-menu-divider>
              {{ 'main_sidebar_admin' | translate }}
            </nib-app-shell-menu-divider>
            <nib-app-shell-menu-item [to]="'/admin/demo'">
              <nib-icon [name]="'settings'"></nib-icon> {{ 'demo_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item [to]="'/admin/enable-features'">
              <nib-icon [name]="'feature-toggle'"></nib-icon>
              {{ 'toggle_title' | translate }}
            </nib-app-shell-menu-item>
          </ng-container>

          <ng-container *featureToggle="'TOGGLE_ENABLE_SLACK'" position="end">
            <nib-app-shell-menu-divider
              *ngIf="(hasSlack$ | async) && (isSlackEnabled$ | async) === false"
              class="bus-app-shell-menu-adv"
            >
              <bus-adv-slack></bus-adv-slack>
            </nib-app-shell-menu-divider>
          </ng-container>
          <nib-app-shell-menu-item
            [withPrefix]="false"
            (onClick)="openHelpCenterOnNewTab()"
            position="end"
          >
            {{ 'main_sidebar_helpcenter' | translate }}
            <nib-icon [name]="'external-link'" position="end"></nib-icon>
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item [to]="'/billing'" [withPrefix]="false" position="end">
            {{ 'company_billing_title' | translate }}
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item
            [disabled]="hasInvalidSubscription"
            [to]="'/settings'"
            [withPrefix]="false"
            position="end"
          >
            {{ 'settings_title' | translate }}
            <span class="bus-app-shell-version" position="end">v{{ version$ | async }}</span>
          </nib-app-shell-menu-item>
        </nib-app-shell-menu>
        <bus-subscription-banner *ngIf="showSubscriptionBanner$ | async"></bus-subscription-banner>
        <bus-google-workspace-directory-restore-integration-dialog>
        </bus-google-workspace-directory-restore-integration-dialog>
      </nib-app-shell-auth>
      <ng-template #appShellPublic>
        <nib-app-shell-public [logo]="logo"></nib-app-shell-public>
      </ng-template>
    </ng-template>
  </ng-template>
</feature-toggle-provider>
