import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Email } from './email.type';

@Injectable({ providedIn: 'root' })
export class EmailService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  read(params: Email.Read.Params): Observable<Email.Read.Response> {
    return this.httpClient
      .get<Email.Read.Response>(`${this.apiRootUrl}/company/settings/email/template`, {
        params: {
          ...(params?.default ? { default: params.default } : {}),
          key: params.key,
        },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  reset({ key, language }: Email.Reset.Params): Observable<Email.Reset.Response> {
    return this.httpClient
      .request<Email.Reset.Response>(
        'delete',
        `${this.apiRootUrl}/company/settings/email/template`,
        { body: { key, language } },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  test({ key, language }: Email.Test.Params): Observable<Email.Test.Response> {
    return this.httpClient
      .post<Email.Test.Response>(`${this.apiRootUrl}/company/settings/email/template/test`, {
        key,
        language,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update({ key, content }: Email.Update.Params): Observable<Email.Update.Response> {
    return this.httpClient
      .put<Email.Update.Response>(`${this.apiRootUrl}/company/settings/email/template`, {
        key,
        content,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
