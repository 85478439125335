import { Injectable } from '@angular/core';
import { CompanyService, EmployeeService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import {
  append,
  compose,
  iif as ngxsIif,
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { defer, iif, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { EmployeeStateModel } from '../employee/employee.model';
import { EmployeesStateAction } from './employees.actions';
import { mapEmployeesToClient, mapReadEmployeesApiParams } from './employees.helper';
import { EmployeesStateModel } from './employees.model';

@State<EmployeesStateModel>({
  name: 'employees',
  defaults: {
    currentPage: -1,
    list: [],
    overallElements: 0,
    reachedElement: -1,
    reachedPage: -1,
    totalElements: 0,
    totalPages: 1,
  },
})
@Injectable()
export class EmployeesState {
  constructor(
    private readonly employeeService: EmployeeService,
    private readonly companyService: CompanyService,
  ) {}

  @Action(EmployeesStateAction.List.Add.Try)
  addItemInList(
    { dispatch, getState, setState }: StateContext<EmployeesStateModel>,
    { employee }: EmployeesStateAction.List.Add.Try,
  ) {
    try {
      setState(
        patch({
          list: insertItem(employee, 0),
          reachedElement: getState().reachedElement + 1,
          totalElements: getState().totalElements + 1,
        }),
      );

      dispatch(new EmployeesStateAction.List.Add.Success(getState()));
    } catch (error) {
      dispatch(new EmployeesStateAction.List.Add.Failure(error));

      throw error;
    }
  }

  @Action(EmployeesStateAction.List.Delete.Try)
  deleteItemsFromList(
    { dispatch, getState, setState }: StateContext<EmployeesStateModel>,
    { ids }: EmployeesStateAction.List.Delete.Try,
  ) {
    return this.employeeService.delete({ ids }).pipe(
      catchError(error => {
        dispatch(new EmployeesStateAction.List.Delete.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(
          patch({
            list: compose(
              ...ids.map(id => removeItem<EmployeeStateModel>(employee => employee?.id === id)),
            ),
            reachedElement: getState().reachedElement - ids.length,
            totalElements: getState().totalElements - ids.length,
          }),
        );

        dispatch(new EmployeesStateAction.List.Delete.Success(getState()));
      }),
    );
  }

  @Action(EmployeesStateAction.List.Invite.Try)
  inviteItemsFromList(
    { dispatch, getState, setState }: StateContext<EmployeesStateModel>,
    { ids }: EmployeesStateAction.List.Invite.Try,
  ) {
    return this.employeeService.invite({ ids }).pipe(
      catchError(error => {
        dispatch(new EmployeesStateAction.List.Invite.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(
          patch({
            list: compose(
              ...ids.map(id =>
                updateItem<EmployeeStateModel>(
                  employeeStore =>
                    employeeStore?.id === id && employeeStore.status === 'not_invited',
                  patch<EmployeeStateModel>({ status: 'inviting' }),
                ),
              ),
            ),
          }),
        );

        dispatch(new EmployeesStateAction.List.Invite.Success(getState()));
      }),
    );
  }

  @Action(EmployeesStateAction.List.Read.Try)
  readList(
    { dispatch, getState, setState }: StateContext<EmployeesStateModel>,
    { filters }: EmployeesStateAction.List.Read.Try,
  ) {
    return iif(
      () =>
        (getState().roleFilter ?? '') === (filters?.role ?? '') &&
        (getState().searchFilter ?? '') === (filters?.search ?? ''),
      of({}),
      of({}).pipe(
        tap(() => {
          setState(
            patch<EmployeesStateModel>({
              currentPage: -1,
              list: [],
              reachedElement: -1,
              reachedPage: -1,
              totalElements: 0,
              totalPages: 1,
            }),
          );
        }),
      ),
    ).pipe(
      switchMap(() =>
        iif(
          () =>
            getState().currentPage + 1 < getState().totalPages &&
            getState().reachedElement < getState().totalElements,
          this.companyService.employees(
            mapReadEmployeesApiParams({
              elements: 100,
              page: getState().currentPage + 1,
              role: filters?.role,
              search: filters?.search,
            }),
          ),
          defer(() => {
            dispatch(new EmployeesStateAction.List.Read.Cancel());
          }),
        ).pipe(
          catchError(error => {
            dispatch(new EmployeesStateAction.List.Read.Failure(error));

            throw error;
          }),
          tap(({ data, _metadata }) => {
            setState(
              patch<EmployeesStateModel>({
                ...(typeof filters === 'undefined' ||
                (filters?.role === '' && filters.search === '')
                  ? { overallElements: _metadata.total_elements }
                  : {}),
                ...(_metadata.page > getState().currentPage
                  ? {
                      reachedPage: _metadata.page,
                      reachedElement: getState().list.length + data.length,
                    }
                  : {}),
                currentPage: _metadata.page,
                list: compose(
                  ...mapEmployeesToClient(data).map(employee =>
                    ngxsIif<EmployeeStateModel[]>(
                      employeesFromStore =>
                        employeesFromStore?.some(
                          employeeFromStore => employeeFromStore.id === employee.id,
                        ) ?? false,
                      updateItem<EmployeeStateModel>(
                        employeeStore => employeeStore?.id === employee.id,
                        patch(employee),
                      ),
                      append([employee]),
                    ),
                  ),
                ),
                roleFilter: filters?.role,
                searchFilter: filters?.search,
                totalElements: _metadata.total_elements,
                totalPages: _metadata.total_pages,
              }),
            );

            dispatch(new EmployeesStateAction.List.Read.Success(getState()));
          }),
        ),
      ),
    );
  }

  @Action(EmployeesStateAction.List.Update.Try)
  updateItemInList(
    { dispatch, getState, setState }: StateContext<EmployeesStateModel>,
    { employee }: EmployeesStateAction.List.Update.Try,
  ) {
    try {
      setState(
        patch({
          list: updateItem<EmployeeStateModel>(
            employeeStore => employeeStore?.id === employee.id,
            patch(employee),
          ),
        }),
      );

      dispatch(new EmployeesStateAction.List.Update.Success(getState()));
    } catch (error) {
      dispatch(new EmployeesStateAction.List.Update.Failure(error));

      throw error;
    }
  }
}
