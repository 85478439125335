import { ChangeDetectionStrategy, Component, ViewContainerRef } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bus-app',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
