// tslint:disable: no-namespace max-classes-per-file

import { SurveyStateModel } from '../survey';

export namespace SurveysStateAction {
  export namespace Read {
    export class Try {
      static readonly type = '[Surveys] try Read Surveys List';
    }
    export class Success {
      static readonly type = '[Surveys] then Read Surveys List success';
      constructor(public surveys: SurveyStateModel[]) {}
    }
    export class Failure {
      static readonly type = '[Surveys] catch Read Surveys List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
