import { Selector } from '@ngxs/store';
import { EmailsStateModel } from './emails.model';
import { EmailsState } from './emails.state';
import { DesignSettings, SmtpSettings } from './emails.type';

export class EmailsSelectors {
  @Selector([EmailsState])
  static designSettings({ designSettings }: EmailsStateModel): EmailsStateModel['designSettings'] {
    return designSettings;
  }

  @Selector([EmailsSelectors.designSettings])
  static isDefaultDesignSettings(
    designSettings: EmailsStateModel['designSettings'],
  ): DesignSettings['isDefault'] {
    return designSettings?.isDefault ?? false;
  }

  @Selector([EmailsSelectors.smtpSettings])
  static isDefaultSmtpSettings(
    smtpSettings: EmailsStateModel['smtpSettings'],
  ): SmtpSettings['isDefault'] {
    return smtpSettings?.isDefault ?? false;
  }

  @Selector([EmailsState])
  static list({ list }: EmailsStateModel): EmailsStateModel['list'] {
    return list;
  }

  @Selector([EmailsSelectors.designSettings])
  static logo(designSettings?: DesignSettings): Partial<DesignSettings>['logo'] {
    return designSettings?.logo;
  }

  @Selector([EmailsState])
  static smtpSettings({ smtpSettings }: EmailsStateModel): EmailsStateModel['smtpSettings'] {
    return smtpSettings;
  }
}
