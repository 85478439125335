import { Selector } from '@ngxs/store';
import { DesksStateModel } from './desks.model';
import { DesksState } from './desks.state';

export class DesksSelectors {
  @Selector([DesksState])
  static checkIn({ checkIn }: DesksStateModel): DesksStateModel['checkIn'] {
    return checkIn;
  }

  @Selector([DesksSelectors.reachedElement, DesksSelectors.totalElements])
  static hasFurtherReservations(reachedElement: number, totalElements: number): boolean {
    return reachedElement < totalElements;
  }

  @Selector([DesksState])
  static isViewColleaguesEnabled({
    isViewColleaguesEnabled,
  }: DesksStateModel): DesksStateModel['isViewColleaguesEnabled'] {
    return isViewColleaguesEnabled;
  }

  @Selector([DesksState])
  static list({ list }: DesksStateModel): DesksStateModel['list'] {
    return list;
  }

  @Selector([DesksState])
  static reachedElement({
    reservationsReachedElement,
  }: DesksStateModel): DesksStateModel['reservationsReachedElement'] {
    return reservationsReachedElement;
  }

  @Selector([DesksState])
  static reservations({ reservations }: DesksStateModel): DesksStateModel['reservations'] {
    return reservations;
  }

  @Selector([DesksState])
  static totalElements({
    reservationsTotalElements,
  }: DesksStateModel): DesksStateModel['reservationsTotalElements'] {
    return reservationsTotalElements;
  }
}
