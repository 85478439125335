import { Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, State, StateContext } from '@ngxs/store';
import { AuthStateAction } from '@nibol/auth';
import { UserSelectors, UserStateAction } from '@nibol/shared';
import { AppSelectors, GenericErrorAction } from '@nibol/store';
import { TranslationService } from '@nibol/translation';
import { ToastService } from '@nibol/ui';
import { BuildingStateAction } from './building';
import { CompanyStateAction } from './company';
import { CustomEntitiesCategoryStateAction } from './custom-entities';
import { CustomEntityStateAction } from './custom-entity';
import { DeliveriesStateAction } from './deliveries';
import { DeskStateAction } from './desk';
import { DesksStateAction } from './desks';
import { EmailStateAction } from './email';
import { EmailsStateAction } from './emails';
import { EmployeeStateAction } from './employee';
import { EmployeesStateAction } from './employees';
import { GroupStateAction } from './group';
import { IntegrationsStateAction } from './integrations';
import { OfficeStateAction } from './office';
import { ParkingStateAction } from './parking';
import { ParkingsStateAction } from './parkings';
import { RoomStateAction } from './room';
import { SafeStateAction } from './safe';
import { SpaceStateAction } from './space';

@State({
  name: 'feedbacksaga',
})
@Injectable()
export class FeedbackSaga {
  @SelectSnapshot(UserSelectors.email) email!: string;
  @SelectSnapshot(AppSelectors.notifications) notifications!: boolean;

  constructor(
    private readonly toastService: ToastService,
    private readonly translationService: TranslationService,
  ) {}

  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Add.Failure)
  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Install.Failure)
  @Action(IntegrationsStateAction.Microsoft365Rooms.Add.Failure)
  @Action(IntegrationsStateAction.OktaDirectory.Install.Failure)
  addIntegrationFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_addingerror_title'),
        this.translationService.translate('integrations_addingerror_text'),
        'negative',
      );
    }
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Add.Success)
  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Install.Success)
  @Action(IntegrationsStateAction.Microsoft365Rooms.Add.Success)
  @Action(IntegrationsStateAction.OktaDirectory.Install.Success)
  addIntegrationSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_addingsuccess_title'),
        this.translationService.translate('integrations_addingsuccess_text'),
        'positive',
      );
    }
  }

  @Action(GroupStateAction.AddMembers.Success)
  addMembersSuccess(
    {}: StateContext<unknown>,
    { addedMembers }: GroupStateAction.AddMembers.Success,
  ) {
    if (this.notifications) {
      if (addedMembers.length === 1) {
        this.toastService.open(
          this.translationService.translate('members_group_addingone_title'),
          this.translationService.translate('members_group_addingone_text', {
            email: addedMembers[0].email,
          }),
          'positive',
        );
      } else {
        this.toastService.open(
          this.translationService.translate('members_group_addingother_title'),
          this.translationService.translate('members_group_addingother_text', {
            emails: addedMembers.map(addedMember => addedMember.email).join(', '),
          }),
          'positive',
        );
      }
    }
  }

  @Action(AuthStateAction.Login.Success)
  @Action(AuthStateAction.LoginTemporary.Success)
  authLoginSuccess() {
    this.toastService.open(
      this.translationService.translate('login_toast_success_title'),
      this.translationService.translate('login_toast_success_text'),
      'positive',
    );
  }

  @Action(AuthStateAction.Registration.Failure)
  authRegistrationFailure(
    {}: StateContext<unknown>,
    { error }: AuthStateAction.Registration.Failure,
  ) {
    switch (error.error) {
      case 'email-already-registered':
        this.toastService.open(
          this.translationService.translate('signup_accountalreadycreated_title'),
          this.translationService.translate('signup_accountalreadycreated_text'),
          'negative',
        );
        break;

      default:
        this.toastService.open(
          this.translationService.translate('signup_genericerror_title'),
          this.translationService.translate('signup_genericerror_text'),
          'negative',
        );
        break;
    }
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Connect.Failure)
  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Connect.Failure)
  @Action(IntegrationsStateAction.Slack.Connect.Failure)
  connectIntegrationFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_connectfailure_title'),
        this.translationService.translate('integrations_connectfailure_text'),
        'negative',
      );
    }
  }

  @Action(IntegrationsStateAction.OktaDirectory.Connect.Failure)
  connectOktaDirectoryIntegrationFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_connectfailure_title'),
        this.translationService.translate('integrations_connectoktafailure_text'),
        'negative',
      );
    }
  }

  @Action(IntegrationsStateAction.OktaDirectory.Connect.Success)
  connectOktaDirectoryIntegrationSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_connectsuccess_title'),
        this.translationService.translate('integrations_connectsuccess_text'),
        'positive',
      );
    }
  }

  @Action(EmployeeStateAction.Create.Failure)
  createEmployeeFailure({}: StateContext<unknown>, { error }: EmployeeStateAction.Create.Failure) {
    if (this.notifications) {
      switch (error.error) {
        case 'user-email-already-registered':
          this.toastService.open(
            this.translationService.translate('members_addingerror_emailalreadyregistered_title'),
            this.translationService.translate('members_addingerror_emailalreadyregistered_text'),
            'negative',
          );
          break;
        case 'no-members-availability-manual-sub':
          this.toastService.open(
            this.translationService.translate('members_addingerror_nomembersavailable_title'),
            this.translationService.translate('members_addingerror_nomembersavailable_text'),
            'negative',
          );
          break;

        default:
          this.toastService.open(
            this.translationService.translate('create_toast_genericerror_title'),
            this.translationService.translate('create_toast_genericerror_text'),
            'negative',
          );
          break;
      }
    }
  }

  @Action(EmployeeStateAction.Create.Success)
  createEmployeeSuccess(
    {}: StateContext<unknown>,
    { employee: { email }, sendInvitation }: EmployeeStateAction.Create.Success,
  ) {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate(
          sendInvitation ? 'members_addinginvitingsuccess_title' : 'members_addingsuccess_title',
        ),
        this.translationService.translate(
          sendInvitation ? 'members_addinginvitingsuccess_text' : 'members_addingsuccess_text',
          { email },
        ),
        'positive',
      );
    }
  }

  @Action(BuildingStateAction.Create.Failure)
  @Action(CustomEntitiesCategoryStateAction.Create.Failure)
  @Action(CustomEntityStateAction.Create.Failure)
  @Action(DeskStateAction.Create.Failure)
  @Action(GroupStateAction.Create.Failure)
  @Action(OfficeStateAction.Create.Failure)
  @Action(ParkingStateAction.Create.Failure)
  @Action(RoomStateAction.Create.Failure)
  @Action(SpaceStateAction.Create.Failure)
  createFailure({}: StateContext<unknown>, { error }: GenericErrorAction) {
    if (this.notifications) {
      switch (error.error) {
        case 'group-uid-already-used':
          this.toastService.open(
            this.translationService.translate('update_toast_groupuidalreadyused_title'),
            this.translationService.translate('update_toast_groupuidalreadyused_text'),
            'negative',
          );
          break;

        default:
          this.toastService.open(
            this.translationService.translate('create_toast_genericerror_title'),
            this.translationService.translate('create_toast_genericerror_text'),
            'negative',
          );
          break;
      }
    }
  }

  @Action(BuildingStateAction.Create.Success)
  @Action(CustomEntitiesCategoryStateAction.Create.Success)
  @Action(CustomEntityStateAction.Create.Success)
  @Action(DeskStateAction.Create.Success)
  @Action(GroupStateAction.Create.Success)
  @Action(OfficeStateAction.Create.Success)
  @Action(ParkingStateAction.Create.Success)
  @Action(RoomStateAction.Create.Success)
  @Action(SpaceStateAction.Create.Success)
  createSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('create_toast_genericsuccess_title'),
        this.translationService.translate('create_toast_genericsuccess_text'),
        'positive',
      );
    }
  }

  @Action(BuildingStateAction.Delete.Failure)
  @Action(CustomEntitiesCategoryStateAction.Delete.Failure)
  @Action(CustomEntityStateAction.Delete.Failure)
  @Action(DesksStateAction.Reservations.Delete.Failure)
  @Action(DeskStateAction.Delete.Failure)
  @Action(EmployeesStateAction.List.Delete.Failure)
  @Action(GroupStateAction.Delete.Failure)
  @Action(OfficeStateAction.Delete.Failure)
  @Action(ParkingsStateAction.Reservations.Delete.Failure)
  @Action(ParkingStateAction.Delete.Failure)
  @Action(RoomStateAction.Delete.Failure)
  @Action(SpaceStateAction.Delete.Failure)
  deleteFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('delete_toast_genericerror_title'),
        this.translationService.translate('delete_toast_genericerror_text'),
        'negative',
      );
    }
  }

  @Action(BuildingStateAction.Delete.Success)
  @Action(CustomEntitiesCategoryStateAction.Delete.Success)
  @Action(CustomEntityStateAction.Delete.Success)
  @Action(DesksStateAction.Reservations.Delete.Success)
  @Action(DeskStateAction.Delete.Success)
  @Action(EmployeesStateAction.List.Delete.Success)
  @Action(GroupStateAction.Delete.Success)
  @Action(OfficeStateAction.Delete.Success)
  @Action(ParkingsStateAction.Reservations.Delete.Success)
  @Action(ParkingStateAction.Delete.Success)
  @Action(RoomStateAction.Delete.Success)
  @Action(SpaceStateAction.Delete.Success)
  deleteSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('delete_toast_genericsuccess_title'),
        this.translationService.translate('delete_toast_genericsuccess_text'),
        'positive',
      );
    }
  }

  @Action(RoomStateAction.PairingCode.Failure)
  genericFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('main_genericerror_title'),
        this.translationService.translate('main_genericerror_text'),
        'negative',
      );
    }
  }

  @Action(GroupStateAction.RemoveMembers.Success)
  removeMembersSuccess(
    {}: StateContext<unknown>,
    { removedMembers }: GroupStateAction.RemoveMembers.Success,
  ) {
    if (this.notifications) {
      if (removedMembers.length === 1) {
        this.toastService.open(
          this.translationService.translate('members_group_removingone_title'),
          this.translationService.translate('members_group_removingone_text', {
            email: removedMembers[0].email,
          }),
          'positive',
        );
      } else {
        this.toastService.open(
          this.translationService.translate('members_group_removingother_title'),
          this.translationService.translate('members_group_removingother_text', {
            emails: removedMembers.map(removedMember => removedMember.email).join(', '),
          }),
          'positive',
        );
      }
    }
  }

  @Action(EmailsStateAction.SmtpSettings.Test.Failure)
  @Action(EmailStateAction.Test.Failure)
  sendTestFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('customemails_smtptestfailure_title'),
        this.translationService.translate('customemails_smtptestfailure_text', {
          email: this.email,
        }),
        'negative',
      );
    }
  }

  @Action(EmailsStateAction.SmtpSettings.Test.Success)
  @Action(EmailStateAction.Test.Success)
  sendTestSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('customemails_smtptestsuccess_title'),
        this.translationService.translate('customemails_smtptestsuccess_text', {
          email: this.email,
        }),
        'positive',
      );
    }
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Sync.Failure)
  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Sync.Failure)
  @Action(IntegrationsStateAction.OktaDirectory.Sync.Failure)
  syncIntegrationFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_syncfailure_title'),
        this.translationService.translate('integrations_syncfailure_text'),
        'negative',
      );
    }
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Sync.Success)
  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Sync.Success)
  @Action(IntegrationsStateAction.OktaDirectory.Sync.Success)
  syncIntegrationSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_syncsuccess_title'),
        this.translationService.translate('integrations_syncsuccess_text'),
        'positive',
      );
    }
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Uninstall.Failure)
  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Uninstall.Failure)
  @Action(IntegrationsStateAction.Kisi.Uninstall.Failure)
  @Action(IntegrationsStateAction.Sofia.Uninstall.Failure)
  @Action(IntegrationsStateAction.Microsoft365Rooms.Uninstall.Failure)
  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Uninstall.Failure)
  @Action(IntegrationsStateAction.OktaDirectory.Uninstall.Failure)
  @Action(IntegrationsStateAction.Slack.Uninstall.Failure)
  uninstallIntegrationFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_unistallingfailure_title'),
        this.translationService.translate('integrations_unistallingfailure_text'),
        'negative',
      );
    }
  }

  @Action(IntegrationsStateAction.GoogleWorkspaceDirectory.Uninstall.Success)
  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Uninstall.Success)
  @Action(IntegrationsStateAction.Kisi.Uninstall.Success)
  @Action(IntegrationsStateAction.Sofia.Uninstall.Success)
  @Action(IntegrationsStateAction.Microsoft365Rooms.Uninstall.Success)
  @Action(IntegrationsStateAction.MicrosoftAzureActiveDirectory.Uninstall.Success)
  @Action(IntegrationsStateAction.OktaDirectory.Uninstall.Success)
  @Action(IntegrationsStateAction.Slack.Uninstall.Success)
  uninstallIntegrationSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('integrations_unistallingsuccess_title'),
        this.translationService.translate('integrations_unistallingsuccess_text'),
        'positive',
      );
    }
  }

  @Action(BuildingStateAction.Update.Failure)
  @Action(CompanyStateAction.CompanyPolicy.Update.Failure)
  @Action(CompanyStateAction.Info.Update.Failure)
  @Action(CompanyStateAction.Spaces.Update.Failure)
  @Action(CustomEntityStateAction.Update.Failure)
  @Action(DeliveriesStateAction.Settings.Update.Failure)
  @Action(DeskStateAction.Update.Failure)
  @Action(EmailsStateAction.DesignSettings.Update.Failure)
  @Action(EmailsStateAction.SmtpSettings.Update.Failure)
  @Action(EmailStateAction.Reset.Failure)
  @Action(EmailStateAction.Update.Failure)
  @Action(GroupStateAction.Update.Failure)
  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Update.Failure)
  @Action(IntegrationsStateAction.Kisi.Update.Failure)
  @Action(IntegrationsStateAction.Microsoft365Rooms.Update.Failure)
  @Action(OfficeStateAction.Update.Failure)
  @Action(ParkingStateAction.Update.Failure)
  @Action(RoomStateAction.Update.Failure)
  @Action(SafeStateAction.Settings.Update.Failure)
  @Action(SpaceStateAction.Update.Failure)
  @Action(UserStateAction.Notifications.Update.Failure)
  @Action(UserStateAction.Profile.Update.Failure)
  updateFailure({}: StateContext<unknown>, { error }: GenericErrorAction) {
    if (this.notifications) {
      switch (error.error) {
        case 'group-uid-already-used':
          this.toastService.open(
            this.translationService.translate('update_toast_groupuidalreadyused_title'),
            this.translationService.translate('update_toast_groupuidalreadyused_text'),
            'negative',
          );
          break;

        default:
          this.toastService.open(
            this.translationService.translate('update_toast_genericerror_title'),
            this.translationService.translate('update_toast_genericerror_text'),
            'negative',
          );
          break;
      }
    }
  }

  @Action(BuildingStateAction.Update.Success)
  @Action(CompanyStateAction.CompanyPolicy.Update.Success)
  @Action(CompanyStateAction.Info.Update.Success)
  @Action(CompanyStateAction.Spaces.Update.Success)
  @Action(CustomEntityStateAction.Update.Success)
  @Action(DeliveriesStateAction.Settings.Update.Success)
  @Action(DeskStateAction.Update.Success)
  @Action(EmailsStateAction.DesignSettings.Update.Success)
  @Action(EmailsStateAction.SmtpSettings.Update.Success)
  @Action(EmailStateAction.Reset.Success)
  @Action(EmailStateAction.Update.Success)
  @Action(GroupStateAction.Update.Success)
  @Action(IntegrationsStateAction.GoogleWorkspaceRooms.Update.Success)
  @Action(IntegrationsStateAction.Kisi.Update.Success)
  @Action(IntegrationsStateAction.Microsoft365Rooms.Update.Success)
  @Action(OfficeStateAction.Update.Success)
  @Action(ParkingStateAction.Update.Success)
  @Action(RoomStateAction.Update.Success)
  @Action(SafeStateAction.Settings.Update.Success)
  @Action(SpaceStateAction.Update.Success)
  @Action(UserStateAction.Notifications.Update.Success)
  @Action(UserStateAction.Profile.Update.Success)
  updateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('update_toast_genericsuccess_title'),
        this.translationService.translate('update_toast_genericsuccess_text'),
        'positive',
      );
    }
  }

  // @todo(heavybeard): when employee updating is completed in api check for what
  @Action(EmployeeStateAction.Update.Failure)
  updateRoleFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('update_toast_rolefailure_title'),
        this.translationService.translate('update_toast_rolefailure_text'),
        'negative',
      );
    }
  }

  // @todo(heavybeard): when employee updating is completed in api check for what
  @Action(EmployeeStateAction.Update.Success)
  updateRoleSuccess({}: StateContext<unknown>, { employee }: EmployeeStateAction.Update.Success) {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('update_toast_rolesuccess_title'),
        this.translationService.translate('update_toast_rolesuccess_text', {
          email: employee.email,
        }),
        'positive',
      );
    }
  }
}
