import { Injectable } from '@angular/core';
import { BuildingService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { catchError, first, tap } from 'rxjs/operators';
import { OfficeStateModel } from '../office/office.model';
import { OfficesStateAction } from './offices.actions';

@State<OfficeStateModel[]>({
  name: 'offices',
  defaults: [],
})
@Injectable()
export class OfficesState {
  constructor(private readonly buildingService: BuildingService) {}

  @Action(OfficesStateAction.Add.Try)
  add(
    { dispatch, getState, setState }: StateContext<OfficeStateModel[]>,
    { office }: OfficesStateAction.Add.Try,
  ) {
    try {
      setState(append([office]));

      dispatch(new OfficesStateAction.Add.Success(getState()));
    } catch (error) {
      dispatch(new OfficesStateAction.Add.Failure(error));

      throw error;
    }
  }

  @Action(OfficesStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<OfficeStateModel[]>,
    { id }: OfficesStateAction.Read.Try,
  ) {
    return this.buildingService.offices(id).pipe(
      first(),
      catchError(error => {
        dispatch(new OfficesStateAction.Read.Failure(error));

        throw error;
      }),
      tap(offices => {
        setState(offices);

        dispatch(new OfficesStateAction.Read.Success(getState()));
      }),
    );
  }

  @Action(OfficesStateAction.Remove.Try)
  remove(
    { dispatch, getState, setState }: StateContext<OfficeStateModel[]>,
    { id }: OfficesStateAction.Remove.Try,
  ) {
    try {
      setState(removeItem(office => office?.id === id));

      dispatch(new OfficesStateAction.Remove.Success(getState()));
    } catch (error) {
      dispatch(new OfficesStateAction.Remove.Failure(error));

      throw error;
    }
  }

  @Action(OfficesStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<OfficeStateModel[]>,
    { office }: OfficesStateAction.Update.Try,
  ) {
    try {
      setState(updateItem(officeStore => officeStore?.id === office.id, patch(office)));

      dispatch(new OfficesStateAction.Update.Success(getState()));
    } catch (error) {
      dispatch(new OfficesStateAction.Update.Failure(error));

      throw error;
    }
  }
}
