import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, RouterStateSnapshot } from '@angular/router';
import { BuildingsState, BuildingStateModel } from '@business/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BuildingsGuard implements CanActivate {
  @Dispatch() navigateToBuilding = (path: string, queryParams?: Params) =>
    new Navigate([path], queryParams);

  @Select(BuildingsState) buildings$!: Observable<BuildingStateModel[]>;

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const buildings = await this.buildings$
      .pipe(
        filter(buildingsStore => buildingsStore.length > 0),
        first(),
      )
      .toPromise();

    this.navigateToBuilding(
      `/buildings/${buildings[0].id}/${state.url.split('?')[0]}`,
      route.queryParams,
    );

    return false;
  }
}
