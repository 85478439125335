import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { environment } from '@business/environment';
import { FeatureToggle } from '@business/feature-toggles';
import {
  BuildingsState,
  BuildingStateModel,
  CompanySelectors,
  IntegrationsSelectors,
  SubscriptionFeatures,
} from '@business/store';
import { Version } from '@business/version';
import { Select } from '@ngxs/store';
import { FrontendySelectors, UserSelectors } from '@nibol/shared';
import { Breadcrumb, FeatureTogglesState, RouterSelectors, VersionState } from '@nibol/store';
import { TranslationService } from '@nibol/translation';
import { DialogService } from '@nibol/ui';
import { Observable } from 'rxjs';
import { AppShellSelectors } from './app-shell.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bus-app-shell',
  styleUrls: ['app-shell.component.scss'],
  templateUrl: 'app-shell.component.html',
})
export class AppShellComponent {
  @Select(AppShellSelectors.appShellAuthClasses) appShellAuthClasses$!: Observable<string[]>;
  @Select(RouterSelectors.breadcrumbs) breadcrumbs$!: Observable<Breadcrumb[]>;
  @Select(BuildingsState) buildings$!: Observable<BuildingStateModel[]>;
  @Select(CompanySelectors.fullname) companyFullname$!: Observable<string>;
  @Select(CompanySelectors.logo) companyLogo$!: Observable<string>;
  @Select(RouterSelectors.completionPercentage) completionPercentage$!: Observable<number>;
  @Select(CompanySelectors.existsFeature(SubscriptionFeatures.safe_c19))
  existsSafeC19$!: Observable<Record<FeatureToggle, boolean>>;
  @Select(FeatureTogglesState) featuretoggles$!: Observable<Record<FeatureToggle, boolean>>;
  @Select(FrontendySelectors.fullHeight) fullHeight$!: Observable<boolean>;
  @Select(FrontendySelectors.fullWidth) fullWidth$!: Observable<boolean>;
  @Select(CompanySelectors.hasInvalidSubscription) hasInvalidSubscription$!: Observable<boolean>;
  @Select(CompanySelectors.hasFeature(SubscriptionFeatures.slack)) hasSlack$!: Observable<boolean>;
  @Select(CompanySelectors.hasFeature(SubscriptionFeatures.survey))
  hasSurveyFeature$!: Observable<boolean>;
  @Select(CompanySelectors.hasFeature(SubscriptionFeatures.work_from_anywhere))
  hasWorkFromAnywhereFeature$!: Observable<boolean>;
  @Select(FrontendySelectors.isInProgress) isInProgress$!: Observable<boolean>;
  @Select(FrontendySelectors.isReady) isReady$!: Observable<boolean>;
  @Select(IntegrationsSelectors.isSlackEnabled) isSlackEnabled$!: Observable<boolean>;
  @Select(RouterSelectors.isWizard) isWizard$!: Observable<boolean>;
  @Select(AppShellSelectors.showAuthShell) showAuthShell$!: Observable<boolean>;
  @Select(FrontendySelectors.showHeader) showHeader$!: Observable<boolean>;
  @Select(FrontendySelectors.showMenu) showMenu$!: Observable<boolean>;
  @Select(AppShellSelectors.showSubscriptionBanner) showSubscriptionBanner$!: Observable<boolean>;
  @Select(FrontendySelectors.showTitle)
  showTitle$!: Observable<boolean>;
  @Select(FrontendySelectors.title) title$!: Observable<string>;
  @Select(UserSelectors.fullname) userFullname$!: Observable<string>;
  @Select(VersionState) version$!: Observable<Version>;
  @Select(FrontendySelectors.withTabs) withTabs$!: Observable<boolean>;

  homepage = environment.homepage;
  logo = environment.logo;

  constructor(
    private readonly dialogService: DialogService,
    private readonly translationService: TranslationService,
  ) {}

  openAddNewBuildingDialog(content: TemplateRef<unknown>): void {
    this.dialogService.open(this.translationService.translate('building_adding_title'), content);
  }

  openHelpCenterOnNewTab(): void {
    window.open(this.translationService.translate('main_support_link'), '_blank', 'noopener');
  }

  trackByBuildingId(_index: number, building: BuildingStateModel): string {
    return building.id;
  }
}
