import { Injectable } from '@angular/core';
import { CustomEntitiesCategoryService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { append, removeItem } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { CustomEntitiesCategoryStateAction } from './custom-entities-categories.actions';
import { CustomEntitiesCategoryStateModel } from './custom-entities-categories.model';

@State<CustomEntitiesCategoryStateModel[]>({
  name: 'customentitiescategories',
  defaults: [],
})
@Injectable()
export class CustomEntitiesCategoriesState {
  constructor(private readonly customEntitiesCategoryService: CustomEntitiesCategoryService) {}

  @Action(CustomEntitiesCategoryStateAction.Create.Try)
  create(
    { dispatch, getState, setState }: StateContext<CustomEntitiesCategoryStateModel[]>,
    { buildingId, customEntitiesCategory }: CustomEntitiesCategoryStateAction.Create.Try,
  ) {
    return this.customEntitiesCategoryService
      .create({ name: customEntitiesCategory.name, building_id: buildingId })
      .pipe(
        catchError(error => {
          dispatch(new CustomEntitiesCategoryStateAction.Create.Failure(error));

          throw error;
        }),
        tap(({ id }) => {
          setState(
            append<CustomEntitiesCategoryStateModel>([
              { ...customEntitiesCategory, id, entities: [] },
            ]),
          );

          dispatch(new CustomEntitiesCategoryStateAction.Create.Success(getState()));
        }),
      );
  }

  @Action(CustomEntitiesCategoryStateAction.Delete.Try)
  delete(
    { dispatch, getState, setState }: StateContext<CustomEntitiesCategoryStateModel[]>,
    { id }: CustomEntitiesCategoryStateAction.Delete.Try,
  ) {
    return this.customEntitiesCategoryService.delete({ id }).pipe(
      catchError(error => {
        dispatch(new CustomEntitiesCategoryStateAction.Delete.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(removeItem(customEntitiesCategory => customEntitiesCategory?.id === id));

        dispatch(new CustomEntitiesCategoryStateAction.Delete.Success(getState()));
      }),
    );
  }

  @Action(CustomEntitiesCategoryStateAction.List.Try)
  list(
    { dispatch, getState, setState }: StateContext<CustomEntitiesCategoryStateModel[]>,
    { buildingId }: CustomEntitiesCategoryStateAction.List.Try,
  ) {
    return this.customEntitiesCategoryService.list({ building_id: buildingId }).pipe(
      catchError(error => {
        dispatch(new CustomEntitiesCategoryStateAction.List.Failure(error));

        throw error;
      }),
      tap(categories => {
        setState(
          categories.map(category => ({
            name: category.name,
            id: category.id,
            entities: Array.from({ length: category.count }),
          })),
        );

        dispatch(new CustomEntitiesCategoryStateAction.List.Success(getState()));
      }),
    );
  }
}
