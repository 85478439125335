import { Injectable } from '@angular/core';
import { RoomsService, SpaceService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import {
  append,
  compose,
  iif as ngxsIif,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { defer, iif } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Reservation } from '../reservations';
import { RoomStateModel } from '../room';
import { mapRoomToClient } from '../room/room.helper';
import { RoomsStateAction } from './rooms.actions';
import {
  mapRoomsReservationsApiParams,
  mapRoomsReservationsToClient,
  mapRoomsSettingsToApi,
  mapRoomsSettingsToClient,
} from './rooms.helper';
import { RoomsStateModel } from './rooms.model';

@State<RoomsStateModel>({
  name: 'rooms',
  defaults: {
    list: [],
    reservations: [],
    reservationsCurrentPage: -1,
    reservationsReachedElement: -1,
    reservationsReachedPage: -1,
    reservationsTotalElements: 0,
    reservationsTotalPages: 1,
  },
})
@Injectable()
export class RoomsState {
  constructor(
    private readonly roomsService: RoomsService,
    private readonly spaceService: SpaceService,
  ) {}

  @Action(RoomsStateAction.List.Add.Try)
  addItemInList(
    { dispatch, getState, setState }: StateContext<RoomsStateModel>,
    { room }: RoomsStateAction.List.Add.Try,
  ) {
    try {
      setState(
        patch({
          list: append([room]),
        }),
      );

      dispatch(new RoomsStateAction.List.Add.Success(getState()));
    } catch (error) {
      dispatch(new RoomsStateAction.List.Add.Failure(error));

      throw error;
    }
  }

  @Action(RoomsStateAction.List.Read.Try)
  readListItem(
    { dispatch, getState, setState }: StateContext<RoomsStateModel>,
    { spaceId }: RoomsStateAction.List.Read.Try,
  ) {
    return this.spaceService.rooms({ space_id: spaceId }).pipe(
      catchError(error => {
        dispatch(new RoomsStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(rooms => {
        setState(patch({ list: rooms.map(mapRoomToClient) }));

        dispatch(new RoomsStateAction.List.Read.Success(getState()));
      }),
    );
  }

  @Action(RoomsStateAction.Reservations.Read.Try)
  readReservations(
    { dispatch, getState, setState }: StateContext<RoomsStateModel>,
    { buildingId }: RoomsStateAction.Reservations.Read.Try,
  ) {
    return iif(
      () =>
        getState().reservationsCurrentPage + 1 < getState().reservationsTotalPages &&
        getState().reservationsReachedElement < getState().reservationsTotalElements,
      this.roomsService.reservations(
        mapRoomsReservationsApiParams({
          buildingId,
          elements: 40,
          page: getState().reservationsCurrentPage + 1,
        }),
      ),
      defer(() => {
        dispatch(new RoomsStateAction.Reservations.Read.Cancel());
      }),
    ).pipe(
      catchError(error => {
        dispatch(new RoomsStateAction.Reservations.Read.Failure(error));

        throw error;
      }),
      tap(({ data, _metadata }) => {
        setState(
          patch<RoomsStateModel>({
            ...(_metadata.page > getState().reservationsCurrentPage
              ? {
                  reservationsReachedPage: _metadata.page,
                  reservationsReachedElement: getState().reservations.length + data.length,
                }
              : {}),
            reservations: compose(
              ...mapRoomsReservationsToClient(data).map(reservation =>
                ngxsIif<Reservation[]>(
                  reservationsFromStore =>
                    reservationsFromStore?.some(
                      reservationFromStore => reservationFromStore.id === reservation.id,
                    ) ?? false,
                  updateItem<Reservation>(
                    reservationStore => reservationStore?.id === reservation.id,
                    patch(reservation),
                  ),
                  append([reservation]),
                ),
              ),
            ),
            reservationsCurrentPage: _metadata.page,
            reservationsTotalElements: _metadata.total_elements,
            reservationsTotalPages: _metadata.total_pages,
          }),
        );

        dispatch(new RoomsStateAction.Reservations.Read.Success(getState()));
      }),
    );
  }

  @Action(RoomsStateAction.Settings.Read.Try)
  readSettings(
    { dispatch, getState, setState }: StateContext<RoomsStateModel>,
    { buildingId }: RoomsStateAction.Settings.Read.Try,
  ) {
    return this.roomsService.readSettings({ id: buildingId }).pipe(
      catchError(error => {
        dispatch(new RoomsStateAction.Settings.Read.Failure(error));

        throw error;
      }),
      tap(roomsSettings => {
        setState(patch(mapRoomsSettingsToClient(roomsSettings)));

        dispatch(new RoomsStateAction.Settings.Read.Success(getState()));
      }),
    );
  }

  @Action(RoomsStateAction.List.Remove.Try)
  removeItemFromList(
    { dispatch, getState, setState }: StateContext<RoomsStateModel>,
    { id }: RoomsStateAction.List.Remove.Try,
  ) {
    try {
      setState(
        patch({
          list: removeItem<RoomStateModel>(room => room?.id === id),
        }),
      );

      dispatch(new RoomsStateAction.List.Remove.Success(getState()));
    } catch (error) {
      dispatch(new RoomsStateAction.List.Remove.Failure(error));

      throw error;
    }
  }

  @Action(RoomsStateAction.List.Update.Try)
  updateItemInList(
    { dispatch, getState, setState }: StateContext<RoomsStateModel>,
    { room }: RoomsStateAction.List.Update.Try,
  ) {
    try {
      setState(
        patch({
          list: updateItem<RoomStateModel>(roomStore => roomStore?.id === room.id, patch(room)),
        }),
      );

      dispatch(new RoomsStateAction.List.Update.Success(getState()));
    } catch (error) {
      dispatch(new RoomsStateAction.List.Update.Failure(error));

      throw error;
    }
  }

  @Action(RoomsStateAction.Settings.Update.Try)
  updateSettings(
    { dispatch, getState, setState }: StateContext<RoomsStateModel>,
    { buildingId, data }: RoomsStateAction.Settings.Update.Try,
  ) {
    return this.roomsService.updateSettings(mapRoomsSettingsToApi(buildingId, data)).pipe(
      catchError(error => {
        dispatch(new RoomsStateAction.Settings.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ ...data }));

        dispatch(new RoomsStateAction.Settings.Update.Success(getState()));
      }),
    );
  }
}
