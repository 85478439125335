// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';
import { CustomEntitiesCategoryStateModel } from './custom-entities-categories.model';

export namespace CustomEntitiesCategoryStateAction {
  export namespace Create {
    export class Try {
      static readonly type = '[Custom Entities Category] try Create Custom Entities Category';
      constructor(
        public buildingId: BuildingStateModel['id'],
        public customEntitiesCategory: Pick<CustomEntitiesCategoryStateModel, 'name'>,
      ) {}
    }
    export class Success {
      static readonly type =
        '[Custom Entities Category] then Create Custom Entities Category success';
      constructor(public customEntitiesCategory: CustomEntitiesCategoryStateModel[]) {}
    }
    export class Failure {
      static readonly type =
        '[Custom Entities Category] catch Create Custom Entities Category failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Delete {
    export class Try {
      static readonly type = '[Custom Entities Category] try Delete Custom Entities Category';
      constructor(public id: CustomEntitiesCategoryStateModel['id']) {}
    }
    export class Success {
      static readonly type =
        '[Custom Entities Category] then Delete Custom Entities Category success';
      constructor(public customEntitiesCategory: CustomEntitiesCategoryStateModel[]) {}
    }
    export class Failure {
      static readonly type =
        '[Custom Entities Category] catch Delete Custom Entities Category failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace List {
    export class Try {
      static readonly type = '[Custom Entities Category] try Read Custom Entities Categories List';
      constructor(public buildingId: BuildingStateModel['id']) {}
    }
    export class Success {
      static readonly type =
        '[Custom Entities Category] then Read Custom Entities Categories List success';
      constructor(public customEntitiesCategories: CustomEntitiesCategoryStateModel[]) {}
    }
    export class Failure {
      static readonly type =
        '[Custom Entities Category] catch Read Custom Entities Categories List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
