// tslint:disable: no-namespace

import { DeepPartial } from '@nibol/shared';
import { Employee } from '../employee';
import { Group } from '../group';
import { Space } from '../space';

export namespace Desk {
  export namespace Create {
    export type Params = {
      space_id: Space.Read.Response['id'];
      desk: DeepPartial<
        Pick<Read.Response, 'active' | 'amenities' | 'assigned' | 'coordinates' | 'name'>
      >;
    };
    export type Response = {
      id: string;
      status: boolean;
    };
  }

  export namespace Delete {
    export type Params = {
      id: Read.Response['id'];
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace GenerateName {
    export type Params = {
      space_id: Space.Read.Response['id'];
    };
    export type Response = {
      name: string;
    };
  }

  export namespace Read {
    export type Params = {
      id: Response['id'];
    };
    export type Response = {
      active: boolean;
      amenities: { [key: string]: boolean };
      coordinates: { lat: number; lng: number };
      id: string;
      name: string;
      assigned?: {
        exclusive_assignment: boolean;
        assignee: Array<{
          entity: 'group' | 'user';
          entity_id: Employee.Read.Response['id'] | Group.Item.Read.Response['id'];
        }>;
      };
    };
  }

  export namespace Update {
    export type Body = DeepPartial<
      Pick<Read.Response, 'active' | 'amenities' | 'coordinates' | 'id' | 'name'>
    > &
      Partial<Pick<Read.Response, 'assigned'>>;
    export type Response = Read.Response;
  }
}
