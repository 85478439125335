// tslint:disable: no-namespace max-classes-per-file

import { OfficeStateModel } from '../office';
import { SpaceStateModel } from '../space/space.model';
import { SpacesStateModel } from './spaces.model';

export namespace SpacesStateAction {
  export namespace Add {
    export class Try {
      static readonly type = '[Spaces] try Add Space to List';
      constructor(public space: SpaceStateModel) {}
    }
    export class Success {
      static readonly type = '[Spaces] then Add Space to List success';
      constructor(public spaces: SpacesStateModel) {}
    }
    export class Failure {
      static readonly type = '[Spaces] catch Add Space to List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Read {
    export class Try {
      static readonly type = '[Spaces] try Read Spaces List';
      constructor(public id: OfficeStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Spaces] then Read Spaces List success';
      constructor(public spaces: SpacesStateModel) {}
    }
    export class Failure {
      static readonly type = '[Spaces] catch Read Spaces List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Remove {
    export class Try {
      static readonly type = '[Spaces] try Remove Space from List';
      constructor(public id: SpaceStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Spaces] then Remove Space from List success';
      constructor(public spaces: SpacesStateModel) {}
    }
    export class Failure {
      static readonly type = '[Spaces] catch Remove Space from List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Spaces] try Update Space in List';
      constructor(public space: SpaceStateModel) {}
    }
    export class Success {
      static readonly type = '[Spaces] then Update Space in List success';
      constructor(public spaces: SpacesStateModel) {}
    }
    export class Failure {
      static readonly type = '[Spaces] catch Update Space in List failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
