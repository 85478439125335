// tslint:disable: no-namespace

import { DeepPartial } from '@nibol/shared';
import { Space } from '../space';

export namespace Room {
  export namespace Companion {
    export namespace Clients {
      export type Params = { room_id: Read.Response['id'] };
      export type Response = Array<{
        id: string;
        expired: true;
        lastRefresh: string;
        name: string;
      }>;
    }
    export namespace PairingCode {
      export type Params = { room_id: Read.Response['id'] };
      export type Response = { status: boolean; code: string; validity: number };
    }
    export namespace UnpairAllClients {
      export type Body = { room_id: Read.Response['id'] };
      export type Response = { status: boolean };
    }
  }

  export namespace Create {
    export type Body = {
      space_id: Space.Read.Response['id'];
      room: DeepPartial<
        Pick<Read.Response, 'active' | 'amenities' | 'calendar_email' | 'coordinates' | 'name'>
      >;
    };
    export type Response = { room_id: string; status: boolean };
  }

  export namespace Delete {
    export type Body = { id: Read.Response['id'] };
    export type Response = { status: boolean };
  }

  export namespace Read {
    export type Params = { id: Response['id'] };
    export type Response = {
      active: boolean;
      amenities: { [key: string]: boolean };
      coordinates: Array<{ lat: number; lng: number }>;
      id: string;
      name: string;
      picture: string | null;
      seats: number;
      calendar_email?: string | null;
    };
  }

  export namespace Update {
    export type Body =
      | Read.Response
      | (Pick<Read.Response, 'amenities' | 'calendar_email' | 'coordinates' | 'id' | 'picture'> & {
          space_id: string;
        });
    export type Response = { status: boolean };
  }

  export namespace UploadPhoto {
    export type Body = FormData;
    export type Response = { picture: { url: string } };
  }
}
