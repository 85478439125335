import { Survey } from '@business/api';
import { LanguageName } from '@nibol/translation';
import { SurveyStateModel } from './survey.model';
import { SurveyAnswer, SurveyAnswerType, SurveyQuestion } from './survey.type';

export function mapSurveyToClient(
  survey: Pick<Survey.Questions.Response, 'active' | 'id' | 'name'>,
): Pick<SurveyStateModel, 'id' | 'name'> {
  return { id: survey.id, name: survey.name };
}

export function mapSurveyQuestionsToClient(
  questions: Survey.Questions.Response['questions'],
  languageName: LanguageName,
): SurveyStateModel['questions'] {
  return questions.map(question => ({
    text: question.text.text[languageName],
    type: question.type,
    isRequired: question.required,
    answers: question.answers.map(answer =>
      mapSurveyAnswerToClient(
        answer,
        question.outcome.find(outcome => outcome.value === answer.value),
        languageName,
      ),
    ),
    errorMessage: mapErrorMessaggeToClient(
      question.outcome.find(outcome => outcome.value === false),
      languageName,
    ),
  }));
}

export function mapSurveyTriggersToClient({
  triggers,
}: Survey.Triggers.Response): SurveyStateModel['triggers'] {
  return triggers.map(trigger => ({
    audiences: trigger.audiences?.map(audience => audience.entity_data.id) ?? null,
    hookAction: trigger.hook.action,
    hookEntity: trigger.hook.entity,
    hookTime: trigger.hook.time,
    locations: trigger.locations?.map(location => location.entity_data.id) ?? null,
  }));
}
function mapErrorMessaggeToClient(
  outcome: Survey.Questions.Response['questions'][number]['outcome'][number] | undefined,
  languageName: LanguageName,
): SurveyQuestion['errorMessage'] {
  return typeof outcome?.args === 'string' ? outcome?.args : outcome?.args.text[languageName];
}
function mapSurveyAnswerToClient(
  answer: Survey.Questions.Response['questions'][number]['answers'][number],
  outcome: Survey.Questions.Response['questions'][number]['outcome'][number] | undefined,
  languageName: LanguageName,
): SurveyAnswer {
  return {
    result: mapResultToClient(outcome?.type),
    text: answer.text.text[languageName],
    type: mapAnswerTypeToClient(answer.value),
  };
}
function mapAnswerTypeToClient(
  value: Survey.Questions.Response['questions'][number]['answers'][number]['value'],
): SurveyAnswerType {
  switch (typeof value) {
    case 'boolean':
      return value ? 'yes' : 'no';

    default:
      return 'no';
  }
}
function mapResultToClient(
  type?: Survey.Questions.Response['questions'][number]['outcome'][number]['type'],
): SurveyAnswer['result'] {
  return type ?? '';
}
