// tslint:disable: no-namespace

import { Email } from '../email/email.type';

export namespace Emails {
  export namespace Design {
    export namespace Read {
      export type Response = {
        colors: { brand: string; brand_text: string };
        footer: string;
        logo: string;
        isDefault: boolean;
      };
    }
    export namespace Reset {
      export type Response = Read.Response;
    }
    export namespace Update {
      export type Params = Read.Response;
      export type Response = Params;
    }
  }

  export namespace List {
    export type Response = Array<
      Pick<Email.Read.Response, 'isDefault' | 'key' | 'title' | 'trigger'> &
        Partial<Pick<Email.Read.Response, 'editor'>>
    >;
  }

  export namespace Smtp {
    export namespace Read {
      export type Response = {
        auth: { username: string; password: string };
        from: { name: string; email: string };
        host: string | null;
        isDefault: boolean;
        port: number | null;
        tls_enabled: boolean;
      };
    }
    export namespace Test {
      export type Response = { status: boolean };
    }
    export namespace Reset {
      export type Response = Read.Response;
    }
    export namespace Update {
      export type Params = Read.Response;
      export type Response = Params;
    }
  }
}
