import { CustomEntitiesCategoryStateModel } from '../custom-entities';
import { DeskType } from '../desk';
import { EmployeeStateModel } from '../employee';
import { GroupStateModel } from '../group';
import { ParkingType } from '../parking';
import { SpaceAmenities } from '../space';
import { CustomEntityType } from './custom-entity.type';

export class CustomEntityStateModel {
  active!: boolean;
  category!: CustomEntitiesCategoryStateModel['id'];
  coordinates!: { lat: number; lng: number };
  exclusivityAssignment!: boolean;
  id!: string;
  name!: string;
  type!: CustomEntityType | DeskType | ParkingType;
  amenities?: SpaceAmenities;
  audiences?: Array<EmployeeStateModel['id'] | GroupStateModel['id']> | null;
}
