// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { SafeStateModel } from './safe.model';

export namespace SafeStateAction {
  export namespace Settings {
    export namespace Read {
      export class Try {
        static readonly type = '[Safe] try Read Safe Settings';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Safe] then Read Safe Settings success';
        constructor(public visitors: SafeStateModel) {}
      }
      export class Failure {
        static readonly type = '[Safe] catch Read Safe Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Safe] try Update Safe Settings';
        constructor(
          public buildingId: BuildingStateModel['id'],
          public data: DeepPartial<SafeStateModel>,
        ) {}
      }
      export class Success {
        static readonly type = '[Safe] then Update Safe Settings success';
        constructor(public visitors: SafeStateModel) {}
      }
      export class Failure {
        static readonly type = '[Safe] catch Update Safe Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
