import { CheckIn } from '../building/building.type';
import { Reservation } from '../reservations';
import { RoomStateModel } from '../room/room.model';

export class RoomsStateModel {
  list!: RoomStateModel[];
  reservations!: Reservation[];
  reservationsCurrentPage!: number;
  reservationsReachedElement!: number;
  reservationsReachedPage!: number;
  reservationsTotalElements!: number;
  reservationsTotalPages!: number;
  checkIn?: CheckIn;
}
