import { Group } from '@business/api';
import { DeepPartial } from '@nibol/shared';
import { GroupStateModel } from './group.model';

export function mapGroupToApi(
  group: DeepPartial<GroupStateModel>,
): DeepPartial<Group.Item.Update.Params> {
  return {
    ...(typeof group.budget !== 'undefined' ? { budget: { amount: group.budget } } : {}),
    color: group.color,
    description: group.description,
    id: group.id,
    limits: group.limits,
    name: group.name,
    permissions: group.permissions,
    uid: group.uid,
  };
}

export function mapGroupToClient(group: Group.Item.Read.Response): GroupStateModel {
  return {
    budget: group.budget?.amount ?? 0,
    color: group.color,
    description: group.description,
    id: group.id,
    limits: group.limits,
    members:
      typeof group.members !== 'undefined'
        ? new Array(group.members)
            .fill({ email: '', error: 'ghost', id: 'nib-ghost-size-md' })
            .map(member => ({
              ...member,
              desk: Math.round(Math.random()) ? 'desk' : undefined,
              id: Math.round(Math.random()) ? 'nib-ghost-size-md' : 'nib-ghost-size-sm',
            }))
        : [],
    name: group.name,
    permissions: group.permissions,
    totalMembers: group.members ?? 0,
    uid: group.uid ?? group.id,
  };
}
