import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import {
  BuildingStateModel,
  CompanySelectors,
  OfficeStateModel,
  SpaceStateModel,
} from '@business/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MapGuard implements CanActivate {
  @Dispatch() navigateToMap = (path: string) => new Navigate([path]);

  @Select(CompanySelectors.availableMapTypeSpace) availableMapTypeSpace$!: Observable<
    Array<{ building: BuildingStateModel; office: OfficeStateModel; space: SpaceStateModel }>
  >;

  async canActivate() {
    const { building, office, space } = await this.availableMapTypeSpace$
      .pipe(
        filter(spaces => spaces.length > 0),
        switchMap(spaces => of(spaces[0])),
        first(),
      )
      .toPromise();

    this.navigateToMap(
      `buildings/${building?.id}/building/offices/${office?.id}/spaces/${space?.id}/map`,
    );

    return false;
  }
}
