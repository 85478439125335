import { Selector } from '@ngxs/store';
import { SurveyStateModel } from './survey.model';
import { SurveyState } from './survey.state';

export class SurveySelectors {
  @Selector([SurveyState])
  static fullname({ name }: SurveyStateModel): SurveyStateModel['name'] {
    return name;
  }

  @Selector([SurveyState])
  static id({ id }: SurveyStateModel): SurveyStateModel['id'] {
    return id;
  }

  @Selector([SurveyState])
  static triggers({ triggers }: SurveyStateModel): SurveyStateModel['triggers'] {
    return triggers;
  }
}
