import { Parkings } from '@business/api';
import { DeepPartial, mapTimeNumberToTimeString } from '@nibol/shared';
import { parse } from 'date-fns';
import { BuildingStateModel } from '../building';
import { mapIsFulldayReservation } from '../reservations';
import { ParkingsStateModel } from './parkings.model';

export function mapParkingsReservationsToClient(
  reservations: Parkings.Reservations.Read.Response['data'],
): ParkingsStateModel['reservations'] {
  return reservations.map(reservation => ({
    building: reservation.building,
    office: reservation.office,
    space: reservation.space,
    parking: reservation.parking,
    from: parse(
      `${reservation.time.day}${mapTimeNumberToTimeString(reservation.time.from || 0)}`,
      'yyyyMMddHH:mm',
      new Date(),
    ),
    id: reservation.id,
    isFullday: mapIsFulldayReservation(reservation.time),
    to: parse(
      `${reservation.time.day}${mapTimeNumberToTimeString(reservation.time.to || 0)}`,
      'yyyyMMddHH:mm',
      new Date(),
    ),
    user: {
      firstname: reservation.user.name,
      email: reservation.user.email,
    },
  }));
}

export function mapParkingsReservationsApiParams({
  buildingId,
  elements,
  page,
}: {
  buildingId: BuildingStateModel['id'];
  elements: ParkingsStateModel['reservations']['length'];
  page: ParkingsStateModel['reservationsCurrentPage'];
}): Parkings.Reservations.Read.Params {
  return { building_id: buildingId, elements: elements.toString(), page: page.toString() };
}

export function mapParkingsSettingsToApi(
  buildingId: BuildingStateModel['id'],
  data: DeepPartial<Pick<ParkingsStateModel, 'checkIn' | 'isViewColleaguesEnabled'>>,
): Parkings.Settings.Update.Body {
  return {
    id: buildingId,
    parkings: {
      map_show_users: data.isViewColleaguesEnabled,
      reservation_checkin_settings: {
        after_from: data.checkIn?.interval?.to,
        before_from: data.checkIn?.interval?.from,
        missed_policy: data.checkIn?.missingPolicy,
        nearby_distance: data.checkIn?.nearbyDistance || null,
      },
      reservation_checkin: data.checkIn?.isEnabled,
    },
  };
}

export function mapParkingsSettingsToClient({
  parkings: { map_show_users, reservation_checkin, reservation_checkin_settings },
}: Parkings.Settings.Read.Response): Pick<
  ParkingsStateModel,
  'checkIn' | 'isViewColleaguesEnabled'
> {
  return {
    checkIn: {
      interval: {
        from: reservation_checkin_settings?.before_from,
        to: reservation_checkin_settings?.after_from,
      },
      isEnabled: reservation_checkin,
      missingPolicy: reservation_checkin_settings?.missed_policy,
      nearbyDistance: reservation_checkin_settings.nearby_distance || undefined,
    },
    isViewColleaguesEnabled: map_show_users,
  };
}
