// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { ParkingStateModel } from '../parking/parking.model';
import { Reservation } from '../reservations';
import { SpaceStateModel } from '../space';
import { ParkingsStateModel } from './parkings.model';

export namespace ParkingsStateAction {
  export namespace List {
    export namespace Add {
      export class Try {
        static readonly type = '[Parkings] try Add Parking to List';
        constructor(public parking: ParkingStateModel) {}
      }
      export class Success {
        static readonly type = '[Parkings] then Add Parking to List success';
        constructor(public parkings: ParkingsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Parkings] catch Add Parking to List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Parkings] try Read Parkings List';
        constructor(public spaceId: SpaceStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Parkings] then Read Parkings List success';
        constructor(public parkings: ParkingsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Parkings] catch Read Parkings List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Remove {
      export class Try {
        static readonly type = '[Parkings] try Remove Parking from List';
        constructor(public id: ParkingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Parkings] then Remove Parking from List success';
        constructor(public parkings: ParkingsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Parkings] catch Remove Parking from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Parkings] try Update Parking in List';
        constructor(public parking: ParkingStateModel) {}
      }
      export class Success {
        static readonly type = '[Parkings] then Update Parking in List success';
        constructor(public parkings: ParkingsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Parkings] catch Update Parking in List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Reservations {
    export namespace Delete {
      export class Try {
        static readonly type = '[Parkings] try Delete Parkings Reservations';
        constructor(public ids: Array<Reservation['id']>) {}
      }
      export class Success {
        static readonly type = '[Parkings] then Delete Parkings Reservations success';
        constructor(public parkings: ParkingsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Parkings] catch Delete Parkings Reservations failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Parkings] try Read Parkings Reservations';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Parkings] then Read Parkings Reservations success';
        constructor(public parkings: ParkingsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Parkings] catch Read Parkings Reservations failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
      export class Cancel {
        static readonly type = '[Parkings] catch Read Parkings Reservations cancel';
        constructor(public reason?: string) {}
      }
    }
  }

  export namespace Settings {
    export namespace Read {
      export class Try {
        static readonly type = '[Parkings] try Read Parkings Settings';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Parkings] then Read Parkings Settings success';
        constructor(public parkings: ParkingsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Parkings] catch Read Parkings Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Parkings] try Update Parkings Settings';
        constructor(
          public buildingId: BuildingStateModel['id'],
          public data: DeepPartial<Pick<ParkingsStateModel, 'checkIn'>>,
        ) {}
      }
      export class Success {
        static readonly type = '[Parkings] then Update Parkings Settings success';
        constructor(public parkings: ParkingsStateModel) {}
      }
      export class Failure {
        static readonly type = '[Parkings] catch Update Parkings Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
