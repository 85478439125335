import { Selector } from '@ngxs/store';
import { SafeStateModel } from './safe.model';
import { SafeState } from './safe.state';

export class SafeSelectors {
  @Selector([SafeState])
  static isEnabledForBooking({
    isEnabledForBooking,
  }: SafeStateModel): SafeStateModel['isEnabledForBooking'] {
    return isEnabledForBooking;
  }

  @Selector([SafeState])
  static isEnabledForCheckIn({
    isEnabledForCheckIn,
  }: SafeStateModel): SafeStateModel['isEnabledForCheckIn'] {
    return isEnabledForCheckIn;
  }
}
