import { Injectable } from '@angular/core';
import { OfficeService } from '@business/api';
import { Action, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { DeepPartial } from '@nibol/shared';
import { catchError, first, tap } from 'rxjs/operators';
import { SpaceStateModel } from '../space';
import { mapSpaceToClient } from '../space/space.helper';
import { SpacesStateAction } from './spaces.actions';
import { SpacesStateModel } from './spaces.model';

@State<SpacesStateModel>({
  name: 'spaces',
  defaults: [],
})
@Injectable()
export class SpacesState {
  constructor(private readonly officeService: OfficeService) {}

  @Action(SpacesStateAction.Add.Try)
  add(
    { dispatch, getState, setState }: StateContext<SpacesStateModel>,
    { space }: SpacesStateAction.Add.Try,
  ) {
    try {
      setState(
        append<DeepPartial<SpaceStateModel>>([space]),
      );

      dispatch(new SpacesStateAction.Add.Success(getState()));
    } catch (error) {
      dispatch(new SpacesStateAction.Add.Failure(error));

      throw error;
    }
  }

  @Action(SpacesStateAction.Read.Try)
  read(
    { dispatch, getState, setState }: StateContext<SpacesStateModel>,
    { id }: SpacesStateAction.Read.Try,
  ) {
    return this.officeService.spaces(id).pipe(
      first(),
      catchError(error => {
        dispatch(new SpacesStateAction.Read.Failure(error));

        throw error;
      }),
      tap(spaces => {
        setState(spaces.map(mapSpaceToClient));

        dispatch(new SpacesStateAction.Read.Success(getState()));
      }),
    );
  }

  @Action(SpacesStateAction.Remove.Try)
  remove(
    { dispatch, getState, setState }: StateContext<SpacesStateModel>,
    { id }: SpacesStateAction.Remove.Try,
  ) {
    try {
      setState(removeItem(space => space?.id === id));

      dispatch(new SpacesStateAction.Remove.Success(getState()));
    } catch (error) {
      dispatch(new SpacesStateAction.Remove.Failure(error));

      throw error;
    }
  }

  @Action(SpacesStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<SpacesStateModel>,
    { space }: SpacesStateAction.Update.Try,
  ) {
    try {
      setState(
        updateItem<DeepPartial<SpaceStateModel>>(
          spaceStore => spaceStore?.id === space.id,
          patch<DeepPartial<SpaceStateModel>>(space),
        ),
      );

      dispatch(new SpacesStateAction.Update.Success(getState()));
    } catch (error) {
      dispatch(new SpacesStateAction.Update.Failure(error));

      throw error;
    }
  }
}
